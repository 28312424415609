import React from 'react'
import { Link } from 'react-router-dom'
import TitlePage from 'view/components/text/titlePage/titlePage'
import './error.css'

const Error404: React.FC = () => {
  return (
    <div className="error-content">
      <div className="container">
        <TitlePage>404</TitlePage>
        <h2>Página não encontrada</h2>
        <Link to={'/'}>Voltar para o início</Link>
      </div>
    </div>
  )
}

export default Error404
