import { cityByEstateList } from 'aplication/useCases/city/cityByEstateList'
import React, { useEffect, useState } from 'react'
import { SelectField, useFormContext } from 'view/components/formComponent'

type Props = {
  required?: boolean
}

const SelectCityField: React.FC<Props> = ({ required }) => {
  const [options, setOptions] = useState<any[]>([])
  const [uf, setUf] = useState<string>('')
  const { fields } = useFormContext()

  function loadOptions(uf: string) {
    cityByEstateList(uf).then(result => {
      const newOptions: any[] = []
      if (result) {
        result.forEach((option: any) => {
          newOptions.push({ value: option.id, label: option.descricao })
        })

        setOptions(newOptions)
      }
    })
  }

  useEffect(() => {
    const ufField = fields.find(field => field.name === 'uf')
    if (ufField && ufField.value !== '') setUf(ufField.value)
  }, [fields])

  useEffect(() => {
    loadOptions(uf)
  }, [uf])

  return (
    <SelectField
      name="city"
      placeholder="Cidade"
      options={options}
      required={required}
    />
  )
}

export default SelectCityField
