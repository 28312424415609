import React from 'react'
import { TField } from '..'

type Props = {
  field: TField
}

const InvalidMessage: React.FC<Props> = ({ field }) => {
  if (!field?.isValid) {
    return (
      <div className="field-invalid-message color-danger">
        {field.validationMessage}
      </div>
    )
  } else {
    return <></>
  }
}

export default InvalidMessage
