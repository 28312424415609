import React, { useContext, useEffect, useRef, useState } from 'react'
import { User } from 'aplication/entities/User'
import { TBooking } from 'domain/entities/TBooking'
import Button from 'view/components/button/button'
import Card from 'view/components/card/card'
import { Dialog, TDialogRef } from 'view/components/dialog'
import { useAuthContext } from 'view/contexts/authContext'
import { BookingViewContext } from '../booking'
import AddDriverForm from './addDriverForm/addDriverForm'
import BookingDrivers from './bookingDrivers'
import { DriversContext } from './bookingDriversList'
import { objectIsEmpty } from 'aplication/utils/app/testValues'

const BookingDriversForm: React.FC = () => {
  const { loggedUser } = useAuthContext()
  const bookingContext = useContext(BookingViewContext)
  const { drivers } = useContext(DriversContext)
  const [show, setShow] = useState<boolean>(false)
  const dialogRef = useRef<TDialogRef>({} as TDialogRef)

  function onClick() {
    setShow(!show)
  }

  function renderForm() {
    if (!show) return <></>
    return (
      <Card id="booking-drivers" className="booking-drivers">
        <Card.content>
          <Dialog ref={dialogRef} scroll />
          <h3>Condutores</h3>
          <AddDriverForm />
          <BookingDrivers />
          <div className="driver-text font-size-normal">
            <b>Atenção:</b>
            <p>
              No momento da retirada do veículo devem ser apresentados o
              documento de identificação e CNH do condutor.
            </p>
            {/* <p>
              Ao incluir um ou mais condutores, será cobrado um valor adicional
              de {priceConverter(organizationInfo?.driverTax)} por diária, para
              cada condutor.
            </p> */}
          </div>
        </Card.content>
      </Card>
    )
  }

  function isPJClientAndHasNotDrivers(
    loggedUser?: User,
    bookingData?: TBooking
  ): boolean {
    const userType =
      loggedUser && loggedUser.person && loggedUser?.person.clientType === 'PJ'
    if (!userType) return false

    const drivers = bookingData?.drivers
    if (!drivers || drivers.length <= 0) {
      return true
    }

    return false
  }

  function renderButton() {
    if (show) return <></>
    return (
      <Button
        className="show-booking-drivers-form"
        style="orange"
        onClick={onClick}
      >
        Adicionar condutores
      </Button>
    )
  }

  useEffect(() => {
    if (!loggedUser || objectIsEmpty(loggedUser)) return
    if (loggedUser?.person.clientType === 'PJ') {
      setShow(true)
      return
    } else if (
      loggedUser?.person.clientType === 'PF' &&
      (!drivers || drivers.length <= 0)
    ) {
      return
    }
    setShow(true)
  }, [drivers])

  useEffect(() => {
    bookingContext.eventController.subscribeEvent({
      id: 'booking-drive-check',
      name: 'booking-confirm',
      action(values) {
        if (
          !isPJClientAndHasNotDrivers(values?.loggedUser, values?.bookingData)
        ) {
          return true
        }
        dialogRef.current.changeMessage(
          'É Obrigatório adicionar no mínimo um condutor à reserva.',
          'danger'
        )
        dialogRef.current.toggle(false)
        setTimeout(() => dialogRef.current.toggle(true), 1000)
      }
    })
  }, [])

  return (
    <>
      {renderButton()}
      {renderForm()}
    </>
  )
}

export default BookingDriversForm
