import React from 'react'
import { InputField } from 'view/components/formComponent'

type Props = {
  required?: boolean
}

const InputPhoneField: React.FC<Props> = ({ required }) => {
  function setInputMask(inputValue: string): string {
    const maskedValue = inputValue
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d{1,2})/, '($1)$2')
      .replace(/(\d{5})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1')

    return maskedValue
  }

  return (
    <InputField
      name="phone"
      placeholder="Telefone"
      regexMask={setInputMask}
      required={required}
    />
  )
}

export default InputPhoneField
