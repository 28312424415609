import { AuthApiService } from 'aplication/apiServices/authApiService/authApiService'
import { TLoginResolve } from 'aplication/entities/TLoginResolve'
import { User } from 'aplication/entities/User'
import { objectIsEmpty } from 'aplication/utils/app/testValues'
import { TEmpty } from 'domain/entities/TEmpty'
import { TLoginPostData } from 'domain/entities/TLoginPostData'
import { TToken } from 'domain/entities/TToken'
import { useEffect, useState } from 'react'
import { currentUser } from '../currentUser/currentUser'
import { authDataParser } from './authDataParser'
import { useAuthToken } from './useAuthToken'

export type TUseAuth = {
  loaded: boolean
  login: (data: TLoginPostData) => Promise<TLoginResolve>
  refresh: () => void
  logout: () => void
  firstAppToken: () => void
  loadCurrentUser: () => void
  loggedUser: User | TEmpty | null
}

export function useAuth(): TUseAuth {
  const {
    setStorageToken,
    getStorageToken,
    removeStorageToken,
    storageUserUuid,
    getUserUuid
  } = useAuthToken()
  const [loaded, setLoaded] = useState<boolean>(false)
  const [loggedUser, setLoggedUser] = useState<User | TEmpty | null>(null)
  const apiService = new AuthApiService()

  function defineLoaded(tk: TToken) {
    if (!tk || objectIsEmpty(tk)) return
    setTimeout(() => setLoaded(true), 200)
  }

  function load(data: TLoginPostData) {
    setLoaded(false)
    apiService.load(data).then(response => {
      if (!response || response.statusCode !== 200 || !response.body) {
        return
      }
      const tk = authDataParser(response.body)
      setStorageToken(tk)
      defineLoaded(tk)
    })
  }

  function appTokenLogin(): void {
    load({
      username: process.env.REACT_APP_API_USER,
      password: process.env.REACT_APP_API_PASS
    })
  }

  function refresh() {
    const token = getStorageToken()
    if (!token || !token.refresh) appTokenLogin()
    setLoaded(false)
    apiService.refresh(token.refresh).then(response => {
      if (!response || response.statusCode !== 200 || !response.body) {
        appTokenLogin()
        return
      }
      const tk = authDataParser(response.body)
      setStorageToken(tk)
      defineLoaded(tk)
    })
  }

  function login(data: TLoginPostData): Promise<TLoginResolve> {
    const resolveData: TLoginResolve = {
      hasUser: false,
      isActivated: false
    }

    return apiService.login(data).then(response => {
      if (!response || !response.body) {
        return resolveData
      }

      if (
        response.body?.data?.cliente?.uuid ||
        (response.body.uuid && response.body.uuid !== '')
      ) {
        const uuid = response.body.uuid || response.body.data.cliente.uuid
        storageUserUuid(uuid)
        resolveData.hasUser = true
      }

      if (
        (response.body.data?.access_token &&
          response.body.data?.refresh_token) ||
        response.body.data?.user?.is_active
      ) {
        resolveData.isActivated = true
        const user = new User(response.body.data)
        user.isActivated = true
        setStorageToken({
          access: response.body.data.access_token || '',
          refresh: response.body.data.refresh_token || ''
        })
        setTimeout(() => setLoggedUser(user), 1000)
      }

      return resolveData
    })
  }

  function loadCurrentUser() {
    const storageUserId = getUserUuid()
    if (storageUserId === '') return

    currentUser(storageUserId).then(user => {
      if (!user || Object.keys(user).length <= 0) {
        setLoggedUser({} as User)
      } else {
        setLoggedUser(user)
        return user
      }
    })
  }

  function firstAppToken() {
    const tk = getStorageToken()
    if (tk && !objectIsEmpty(tk)) {
      refresh()
    } else {
      appTokenLogin()
    }
  }

  function logout() {
    removeStorageToken()
    appTokenLogin()
    setLoggedUser({})
  }

  return {
    login,
    logout,
    refresh,
    firstAppToken,
    loadCurrentUser,
    loaded,
    loggedUser
  }
}
