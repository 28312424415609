import { bookingCardDataParserApi } from 'aplication/useCases/booking/bookingDataParser'
import {
  isoDateToAmericanDate,
  isoDateToTimeDisplay
} from 'aplication/utils/app/dateTimeCare/dateTimeCare'
import { TCondutorApi } from 'domain/entities/api/TApiRentingData'
import { TBookingCardData } from 'domain/entities/TBooking'
import {
  TDriver,
  TRentingData,
  TRentingEvent
} from 'domain/entities/TRentingData'
import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'

type TCreateRenting = {
  rentingData: TRentingData
  cardData: TBookingCardData | null
}

export class CreateRentingDataService extends ApiService {
  private buildDateFormat(rentingEvent: TRentingEvent | undefined) {
    if (!rentingEvent) return false
    const { date, hour } = rentingEvent
    const stringDate = isoDateToAmericanDate(date, '/')
    const stringTime = isoDateToTimeDisplay(hour)
    const newDate = new Date(`${stringDate} ${stringTime}`)
    return newDate.toISOString()
  }

  async load(params: TCreateRenting): Promise<HttpResponse> {
    const { rentingData, cardData } = params
    const pickupDate = rentingData.pickup
    const returnDate = rentingData.return

    function makeCardData() {
      if (cardData && cardData.useCard) {
        return {
          use_card: cardData.useCard
        }
      } else if (cardData) {
        return {
          card: !cardData.cardUuid
            ? bookingCardDataParserApi(cardData)
            : undefined,
          card_uuid: cardData.cardUuid || undefined
        }
      }
      return null
    }

    function makeCondutor(drivers?: TDriver[]): TCondutorApi[] {
      if (!drivers || drivers.length <= 0) return [] as TCondutorApi[]
      return drivers.map(driver => {
        return {
          cnh_condutor: driver.cnh,
          nome_condutor: driver.name,
          cod_seguranca_cnh_condutor: driver.cnhSecurityNumber
        }
      })
    }

    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/reserva/`,
      method: 'post',
      body: {
        renovacao: rentingData?.renovationId || undefined,
        tipo_contrato: 'Por Grupo de Veículo',
        grupo: rentingData.carGroup?.id,
        tipo_cliente: 'Pessoa Juridica',
        cliente: rentingData.client,
        data_hora_inicio: this.buildDateFormat(pickupDate),
        data_hora_fim: this.buildDateFormat(returnDate),
        forma_pagamento: 'À vista', // rentingData.payFormat,
        valor_total: rentingData.totalToPay,
        local_retirada: rentingData.pickup?.local,
        local_devolucao: rentingData.return?.local,
        acessorios_solicitados: rentingData.resources,
        installments: cardData?.installments,
        ...makeCardData(),
        condutor: makeCondutor(params.rentingData.drivers),
        protecao: rentingData.protection?.id,
        reserva_valores: {
          nome_grupo: rentingData.carGroup?.name,
          descricao_grupo: '',
          local_retirada: rentingData.pickup?.local,
          local_devolucao: rentingData.return?.local,
          total_diarias: rentingData.daily,
          valor_diaria: rentingData.dailyValue,
          valor_calcao: rentingData.carGroup?.caucao,
          valor_desconto: rentingData.discount?.discountValue,
          percentagem_desconto: rentingData.discount?.discountPercent,
          valor_protecao: rentingData.protection?.price,
          acessorios: rentingData.resourcesDetail || [],
          custo_condutor: rentingData.driversTotalTax || 0,
          taxa_retirada: rentingData.pickup?.location?.tax || 0,
          taxa_devolucao: rentingData.return?.location?.tax || 0,
          horas_extras: rentingData.extraHour || 0,
          horas_extras_valor: rentingData.extraHourValue || 0
        }
      },
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
