import { objectIsEmpty } from 'aplication/utils/app/testValues'
import { TOrganizationInfoImage } from 'domain/entities/TOrganizationInfo'
import React, { useEffect, useState } from 'react'
import { useAppContext } from 'view/contexts/appContext/appContext'
import './pageContainerPromo.css'

type TProps = {
  onlyMobile?: boolean
}

const PageContainerPromo: React.FC<TProps> = ({ onlyMobile }) => {
  const { organizationInfo } = useAppContext()
  const [imageInfo, setImageInfo] = useState<TOrganizationInfoImage>(
    {} as TOrganizationInfoImage
  )

  useEffect(() => {
    if (!organizationInfo) {
      setImageInfo({} as TOrganizationInfoImage)
      return
    }
    setImageInfo({
      desktop: organizationInfo.imgRodape,
      mobile: organizationInfo.imgRodapeMb,
      link: organizationInfo.linkImgRodape
    })
  }, [organizationInfo])

  function renderMobileOnly() {
    if (!imageInfo || objectIsEmpty(imageInfo)) return <></>
    return (
      <img
        src={imageInfo.mobile || ''}
        className="page-container-promo-img-mobile_size"
      />
    )
  }

  function renderContent() {
    if (!imageInfo || objectIsEmpty(imageInfo)) return <></>
    return (
      <div className="page-container-promo">
        <img
          src={imageInfo.desktop || ''}
          className="page-container-promo-img"
        />
        <img
          src={imageInfo.mobile || ''}
          className="page-container-promo-img-mobile"
        />
      </div>
    )
  }

  return <>{onlyMobile ? renderMobileOnly() : renderContent()}</>
}

export default PageContainerPromo
