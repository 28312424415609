import React, { useContext } from 'react'
import { SelectFieldContext, TOption } from './selectFieldContext'

type TProps = {
  option?: TOption
  index: number
}

const SelectFieldOption: React.FC<TProps> = ({ option, index }) => {
  const { setSelected, setOpen } = useContext(SelectFieldContext)

  function clickOption(e: any) {
    const option = e.target
    setSelected({ label: option.innerHTML, value: option.id })
    setOpen(false)
  }

  return (
    <span
      className="select-option"
      id={option?.value}
      onClick={clickOption}
      tabIndex={index}
    >
      {option?.label}
    </span>
  )
}

export default SelectFieldOption
