import React, { useEffect, useState } from 'react'
import Button from 'view/components/button/button'
import { formatDateToSave } from 'aplication/useCases/booking/bookingFormUtils'
import { objectIsEmpty } from 'aplication/utils/app/testValues'
import { FormComponent } from 'view/components/formComponent'
import { useModalContext } from 'view/components/modal/modalContext'
import BookingDateField from 'view/componentsPages/bookingForm/bookingFormFields/bookingDateField'
import BookingTimeField from 'view/componentsPages/bookingForm/bookingFormFields/bookingTimeField'
import { useAppContext } from 'view/contexts/appContext/appContext'
import { useBookingDataContext } from 'view/contexts/bookingDataContext'
import DashboardBookingEditLocationField from './dashboardBookingEditLocationField'

const DashboardBookingForm: React.FC = () => {
  const { toggleOpen } = useModalContext()
  const { getLocation } = useAppContext().locations
  const [minDateReturn, setMinDateReturn] = useState<Date>()
  const { bookingData, updateBookingData } = useBookingDataContext()

  function setMinDate(date?: string) {
    if (!date) return undefined
    const newDate = new Date(date)
    newDate.setDate(newDate.getDate() + 1)
    return newDate
  }

  function submitForm(value: any) {
    if (!bookingData || objectIsEmpty(bookingData)) {
      return
    }

    const returnEvent = {
      location: getLocation(value?.returnLocation),
      local: value?.returnLocation,
      date: value?.returnDate,
      hour: value?.returnTime
    }

    const rentingData = {
      ...bookingData,
      return: formatDateToSave(returnEvent)
    }

    updateBookingData(rentingData)
    toggleOpen(false)
  }

  useEffect(() => {
    if (!bookingData || !bookingData.pickup || !bookingData.pickup.date) return
    if (!minDateReturn) {
      const date = setMinDate(bookingData.pickup.date)
      setMinDateReturn(date)
    }
  }, [bookingData])

  if (!bookingData || !minDateReturn) return <></>

  return (
    <FormComponent onSubmit={submitForm}>
      <h3 className="font-emphasis">
        Adicione novos dados de devolução do veículo
      </h3>
      <DashboardBookingEditLocationField />
      <BookingDateField
        name="returnDate"
        listening="returnLocation"
        minDate={minDateReturn}
      />
      <BookingTimeField
        name="returnTime"
        listeningDate="returnDate"
        listeningLocation="returnLocation"
      />
      <Button type="submit">Salvar</Button>
    </FormComponent>
  )
}

export default DashboardBookingForm
