import { TBookingDateBlock } from 'aplication/useCases/bookingDateBlocks/TBookingDateBlock'
import { bookingDateBlockList } from 'aplication/useCases/bookingDateBlocks/bookingDateBlockList'
import React, { createContext, useContext, useEffect } from 'react'

export type TBookingFormContext = {
  bookingDateBlocks: TBookingDateBlock[]
}

const BookingFormContext = createContext({} as TBookingFormContext)

const BookingFormProvider: React.FC = ({ children }) => {
  const bookingDateBlocks = bookingDateBlockList()

  function loadBookignDateBlocks() {
    if (bookingDateBlocks.data && bookingDateBlocks.data.length > 0) {
      return
    }
    bookingDateBlocks.load()
  }

  useEffect(() => {
    loadBookignDateBlocks()
  }, [])

  return (
    <BookingFormContext.Provider
      value={{
        bookingDateBlocks: bookingDateBlocks.data
      }}
    >
      {children}
    </BookingFormContext.Provider>
  )
}

export default BookingFormProvider

export function useBookingFormContext(): TBookingFormContext {
  return useContext(BookingFormContext)
}
