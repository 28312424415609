import React from 'react'
import { newShortcode } from '../../../aplication/useCases/shortCodes/shortCodesFunctions'
import scListedButtons from '../scListedButtons'
import './scTextBox.css'

type TAttributes = {
  title?: string
  text?: string
  linkButtonUrl?: string
  listedButton?: string
  buttonText?: string
  buttonSize?: string
}

const ScTextBoxComponent: React.FC<TAttributes> = ({
  title,
  text,
  listedButton,
  linkButtonUrl,
  buttonText,
  buttonSize
}) => {
  text = text || ''
  title = title || ''
  buttonSize = buttonSize || 'md'
  buttonText = buttonText || ''
  linkButtonUrl = linkButtonUrl || ''

  function renderListedButton() {
    const lstdButton = listedButton ? scListedButtons[listedButton] : null
    if (!lstdButton) return <></>
    const atr: any = { text: buttonText, size: buttonSize }
    return React.createElement(lstdButton, atr)
  }

  function renderLinkButton() {
    if (!linkButtonUrl) return <></>
    return (
      <a href={linkButtonUrl} className={`btn btn-${buttonSize} btn-orange`}>
        {buttonText}
      </a>
    )
  }

  return (
    <div className="text-box">
      <div className="title-section font-emphasis">
        <h2 dangerouslySetInnerHTML={{ __html: title }} />
      </div>
      <div className="text-section font-exo color-primary">
        <p dangerouslySetInnerHTML={{ __html: text }} />
        {renderListedButton()}
        {renderLinkButton()}
      </div>
    </div>
  )
}

export default newShortcode({
  scName: 'scTextBox',
  Component: ScTextBoxComponent
})
