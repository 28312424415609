import { CarGroupApiService } from 'aplication/apiServices/carGroupApiService/carGroupApiService'
import { TCarGroupCombo } from 'aplication/entities/TCarGroupCombo'
import { useState } from 'react'
import { carGroupComboParser } from './carGroupDataParser'

type TUseCargroupCombo = {
  data: TCarGroupCombo[]
  loadData: () => void
}

export function useCarGroupCombo(params?: {
  [key: string]: string
}): TUseCargroupCombo {
  const [data, setData] = useState<TCarGroupCombo[]>([] as TCarGroupCombo[])

  function loadData() {
    const apiService = new CarGroupApiService()

    apiService.combo(params).then(response => {
      if (
        !response ||
        response.statusCode !== 200 ||
        !response.body ||
        !response.body.results
      ) {
        return
      }
      const carGroups = carGroupComboParser(response.body.results)
      setData(carGroups)
    })
  }

  return {
    data,
    loadData
  }
}
