import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'
import { TCarBrandApi } from './carBrandApiTypes'

type TResponseBody = {
  results?: TCarBrandApi[]
}

export class CarBrandApiService extends ApiService {
  async load(): Promise<HttpResponse<TResponseBody>> {
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/marcas/`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
