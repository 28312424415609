import { BookingApiSevice } from 'aplication/apiServices/bookingApiService/bookingApiService'
import { HttpResponse } from 'aplication/apiServices/protocols/IHttpClient'
import { useAuthContext } from 'view/contexts/authContext'

type THook = {
  removeBooking: (bookingId: string) => Promise<HttpResponse>
}

export function useRemoveBooking(): THook {
  const { loggedUser } = useAuthContext()

  return {
    async removeBooking(bookingId: string) {
      return new Promise(resolve => {
        const apiService = new BookingApiSevice()
        apiService.remove(bookingId).then(response => {
          if (!response || response.statusCode !== 200 || !response.body) {
            console.error('booking_remove_error')
            return
          }
          if (!loggedUser) return
          resolve(response)
        })
      })
    }
  }
}
