import { ProtectionDataService } from 'aplication/apiServices/protectionsDataService/protectionsDataService'
import { TApiProtection } from 'domain/entities/api/TApiProtection'
import { TProtection } from 'domain/entities/TProtection'

export async function protectionsList(): Promise<TProtection[] | null> {
  const dataService = new ProtectionDataService()
  const response = await dataService.load()

  if (response.statusCode === 200) {
    return response.body.results.map((p: TApiProtection) => {
      const price = p.valor
      return {
        id: p.id,
        title: p.tipo,
        description: p.descricao,
        price: parseFloat(price)
      }
    })
  }

  return null
}
