import { HttpResponse } from 'aplication/apiServices/protocols/IHttpClient'
import { ApiService } from '../apiService'

export class CarGroupApiService extends ApiService {
  async load(page?: number): Promise<HttpResponse> {
    const paged = page ? `?page=${page}` : ''
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/grupos/${paged}`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }

  async combo(params?: { [key: string]: string }): Promise<HttpResponse> {
    let query = ''
    if (params) {
      query = this.makeQuery(params)
    }

    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/grupos/${
        query ? `?${query}` : ''
      }`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }

  async read(id: number): Promise<HttpResponse> {
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/grupos/${id}/`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
