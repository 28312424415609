import React, { useContext } from 'react'
import Button from 'view/components/button/button'
import { useFormContext } from 'view/components/formComponent'
import { CarShopContext } from '../carShopContext'

const CarShopFormCleanFilter: React.FC = () => {
  const { cleanFilters } = useContext(CarShopContext)
  const { resetFields } = useFormContext()

  function onClick() {
    cleanFilters()
    resetFields()
  }

  return (
    <Button className="clean-button" onClick={onClick}>
      Limpar filtros
    </Button>
  )
}

export default CarShopFormCleanFilter
