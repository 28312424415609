import {
  TCarGroupApi,
  TCarGroupFeatureApi
} from 'aplication/apiServices/carGroupApiService/carGroupApiServiceTypes'
import { TCarGroupCombo } from 'aplication/entities/TCarGroupCombo'
import { protectionDataParser } from '../protection/protectionDataParser'
import { TCarGroup, TCarGroupFeature } from './carGroupTypes'

export function carGroupFeatureDataParser(
  data: TCarGroupFeatureApi
): TCarGroupFeature {
  if (!data) return {} as TCarGroupFeature
  return {
    description: data.descricao,
    id: data.id,
    image: data.image,
    isDeleted: data.is_deleted,
    slug: data.slug,
    uuid: data.uuid
  }
}

export function carGroupFeatureArrayDataParser(
  data?: TCarGroupFeatureApi[]
): TCarGroupFeature[] {
  if (!data || data.length <= 0) return [] as TCarGroupFeature[]
  return data.map(carGroupApi => carGroupFeatureDataParser(carGroupApi))
}

export function carGroupDataParser(data: TCarGroupApi): TCarGroup {
  if (!data) return {} as TCarGroup
  return {
    caucao: parseFloat(data.taxa_caucao),
    description: data.descricao,
    features: carGroupFeatureArrayDataParser(data.caracteristicas),
    id: data.id,
    img: data.imagens,
    name: data.nome,
    price: parseFloat(data.valor_diaria),
    uuid: data.uuid,
    protection: protectionDataParser(data.protecoes[0]),
    available: data.total_estoque
  }
}

export function carGroupArrayDataParser(data: TCarGroupApi[]): TCarGroup[] {
  if (!data || data.length <= 0) return [] as TCarGroup[]
  return data.map(carGroupApi => carGroupDataParser(carGroupApi))
}

export function carGroupComboParser(data: TCarGroupApi[]): TCarGroupCombo[] {
  if (!data || data.length <= 0) return [] as TCarGroupCombo[]
  return data.map(carGroup => ({
    value: `${carGroup.id}`,
    label: carGroup.nome
  }))
}
