import { useRef } from 'react'
import { TDialogRef } from './dialog'

type TUseDialog = {
  ref: React.MutableRefObject<TDialogRef>
  use: TDialogRef
}

export function useDialog(): TUseDialog {
  const ref = useRef<TDialogRef>({} as TDialogRef)

  return {
    ref,
    get use() {
      return ref.current
    }
  }
}
