import React from 'react'
import TitlePage from 'view/components/text/titlePage/titlePage'
import { DashboardSingleTemplate } from '../../dashboardSingleTemplate'
import AccountEditPasswordForm from './accountEditPasswordForm'
import AccountEditPersonForm from './accountEditPersonForm'
import './accountEdit.css'

const AccountEdit: React.FC = () => {
  return (
    <DashboardSingleTemplate>
      <div className="account-edit-content">
        <TitlePage>Editar dados</TitlePage>
        <div className="form-account">
          <AccountEditPersonForm />
          <AccountEditPasswordForm />
        </div>
      </div>
    </DashboardSingleTemplate>
  )
}

export default AccountEdit
