import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import { useAppContext } from 'view/contexts/appContext/appContext'
import { WhatsappButton } from 'view/componentsPages/whatsappButton'
import MainMenuDropDown from './mainMenuDropDown/mainMenuDropDown'
import MainTopNotifications from '../mainTopNotifications/mainTopNotifications'
import InstagramButton from 'view/componentsPages/instagramButton/instagramButton'
import './mainMenu.css'

type TMenuLink = {
  link?: string
  label: string
  onClick?: (params?: any) => void
}

const MainMenu: React.FC = () => {
  const { modalContactRef } = useAppContext()

  function scrollTo(el: any) {
    setTimeout(() => {
      el.scrollIntoView({ behavior: 'smooth' })
    }, 300)
  }

  const mainMenu: TMenuLink[] = [
    { link: '/quem-somos#aboutus-page', label: 'Quem somos' },
    {
      link: '/aluguel-de-carros',
      label: 'Aluguel de carros'
    },
    {
      link: '/seminovos',
      label: 'Seminovos'
    },
    {
      link: '/terceirizacao-de-frota',
      label: 'Terceirização de frota'
    },
    {
      label: 'Contato',
      onClick: () => {
        modalContactRef.current.toggleContactForm()
      }
    }
  ]

  function loadMenu(menuItens: TMenuLink[]) {
    return menuItens.map((item, index) => {
      return (
        <li key={index}>
          <Link to={item.link || '#'} onClick={item.onClick} scroll={scrollTo}>
            {item.label}
          </Link>
        </li>
      )
    })
  }

  return (
    <div className="main-menu">
      <div className="main-nav-icon">
        <WhatsappButton />
      </div>
      <div className="main-nav-icon">
        <InstagramButton />
      </div>
      <ul className="main-nav">{loadMenu(mainMenu)}</ul>
      <MainTopNotifications />
      <MainMenuDropDown />
    </div>
  )
}

export default MainMenu
