import { HttpResponse } from 'aplication/apiServices/protocols/IHttpClient'
import { TCarShopFiltersApi } from './carShopFiltersTypes'
import { ApiService } from '../apiService'

type TResponseData = TCarShopFiltersApi

export class CarShopFilters extends ApiService {
  async load(): Promise<HttpResponse<TResponseData>> {
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/veiculos-filtros-disponiveis/`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
