import React from 'react'
import { useHistory } from 'react-router-dom'
import './button.css'

type Props = {
  id?: string
  type?: 'button' | 'reset' | 'submit'
  className?: string
  full?: boolean
  size?: 'bg' | 'md' | 'sm' | 'sm-x'
  style?: 'orange' | 'transparent' | 'link'
  link?: string
  disabled?: boolean
  title?: string
  onClick?: (event: any) => void
}

const Button: React.FC<Props> = ({
  id,
  children,
  className,
  type,
  full,
  size,
  style,
  link,
  disabled,
  title,
  onClick
}) => {
  const history = useHistory()

  type = type || 'button'
  full = full || false
  size = size || 'md'

  function setClassStyle() {
    let classStyle = 'btn'
    if (full) classStyle += ' btn-full'
    if (size) classStyle += ` btn-${size}`
    if (style) classStyle += ` btn-${style}`
    if (className) classStyle += ` ${className}`
    return classStyle
  }

  function linkTo(link: string) {
    history.push(link)
  }

  function defaultOnCLick(e: any) {
    if (onClick) onClick(e)
    if (link) linkTo(link)
  }

  return (
    <button
      id={id}
      type={type}
      className={setClassStyle()}
      onClick={defaultOnCLick}
      disabled={disabled}
      title={title}
    >
      {children}
    </button>
  )
}

export default Button
