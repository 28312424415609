import React, { useEffect } from 'react'
import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch
} from 'react-router-dom'
import LoginPageForm from './loginPageForm'
import RequestAccountForm from './requestAccountForm'
import RequestAccountCodeForm from './requestAccountCodeForm'
import RequestAccountPasswordForm from './requestAccountPasswordForm'
import { useAuthContext } from 'view/contexts/authContext'
import { useQuery } from 'aplication/utils/app/useQuery'
import { useAppContext } from 'view/contexts/appContext/appContext'
import './loginPage.css'

const LoginPage: React.FC = () => {
  const { isUserLogged, loggedUser } = useAuthContext()
  const { organizationInfo } = useAppContext()
  const { path } = useRouteMatch()
  const history = useHistory()
  const query = useQuery()

  useEffect(() => {
    const status = query.get('status')
    if (!isUserLogged()) return

    if (query.get('by_checkout')) {
      if (loggedUser?.person.clientType === 'PJ') {
        history.replace('/locacao/grupos')
        return
      }
      history.replace('/locacao/pagamento')
    } else if (status && status === 'unlogged') {
      history.goBack()
    } else {
      history.replace('/dashboard')
    }
  }, [loggedUser])

  return (
    <div
      className="login-background"
      style={{
        backgroundImage: `url(${organizationInfo?.imgBackgroundLogin})`
      }}
    >
      <div className="container">
        <div className="login-box">
          <Switch>
            <Route exact path={`${path}/`} component={LoginPageForm} />
            <Route
              path={`${path}/recuperar_conta`}
              component={RequestAccountForm}
            />
            <Route
              path={`${path}/recuperar_conta_codigo`}
              component={RequestAccountCodeForm}
            />
            <Route
              path={`${path}/recuperar_conta_senha`}
              component={RequestAccountPasswordForm}
            />
          </Switch>
          <div className="login-new-account">
            Não tem uma conta? <Link to="/cadastro">Crie uma agora.</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
