import { useRef } from 'react'
import { TModalRef } from '.'

type TUseModal = {
  ref: React.MutableRefObject<TModalRef>
  use: TModalRef
}

export function useModal(): TUseModal {
  const ref = useRef<TModalRef>({} as TModalRef)
  return {
    ref,
    get use() {
      return ref.current
    }
  }
}
