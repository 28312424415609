import React from 'react'
import { CarouselDotsNavigator } from 'view/components/carousel/carouselDotsNavigator'
import Carousel from '../carousel/carousel'
import CarouselHeader from '../carousel/carouselHeader'
import CarouselItens from '../carousel/carouselItens'
import './carouselFullPage.css'

type TProps = {
  title?: string
  link?: string
  linkText?: string
  items: any[]
  className?: string
  itemRender: (itemData: any, index: number) => JSX.Element
  autoScroll?: boolean
  autoScrollTimeout?: number
}

const CarouselFullPage: React.FC<TProps> = ({
  title,
  link,
  linkText,
  items,
  className,
  itemRender,
  autoScroll,
  autoScrollTimeout
}) => {
  function renderItem(): any {
    return items.map((item: any, index: number) => {
      return itemRender(item, index)
    })
  }

  function renderCarousel() {
    if (!items || items.length <= 0) return <></>
    return (
      <div
        className={`carousel-full-page ${
          className || ''
        } carousel-gradient-box`}
      >
        <div className="carousel-gradient-left carousel-gradient has-header"></div>
        <Carousel autoScroll={autoScroll} autoScrollTimeOut={autoScrollTimeout}>
          <CarouselHeader title={title || ''} link={link} linkText={linkText} />
          <CarouselItens>
            <div className="null-item carousel-item"></div>
            {renderItem()}
            <div className="null-item carousel-item"></div>
          </CarouselItens>
          <CarouselDotsNavigator />
        </Carousel>
        <div className="carousel-gradient-right carousel-gradient has-header"></div>
      </div>
    )
  }

  return <>{renderCarousel()}</>
}

export default CarouselFullPage
