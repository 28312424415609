import { HttpResponse } from 'aplication/apiServices/protocols/IHttpClient'
import { TTokenApi } from 'domain/entities/api/TTokenApi'
import { TLoginPostData } from 'domain/entities/TLoginPostData'
import { ApiService } from '../apiService'
import { TLoginApi } from './TLoginApi'

export class AuthApiService extends ApiService {
  async load(body: TLoginPostData): Promise<HttpResponse<TTokenApi>> {
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/token/`,
      method: 'post',
      body
    })
  }

  async refresh(refresh: string): Promise<HttpResponse<TTokenApi>> {
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/token/refresh/`,
      method: 'post',
      body: { refresh }
    })
  }

  async login(body: TLoginPostData): Promise<HttpResponse<TLoginApi>> {
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/login/`,
      method: 'post',
      body,
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
