import React, { useEffect, useState } from 'react'
import { SelectField } from 'view/components/formComponent'
import { useAppContext } from 'view/contexts/appContext/appContext'

type Props = {
  name: string
  className?: string
  placeholder: string
  value?: string
  onChange?: (value: any) => void
  fieldRef?: any
}

const SelectFieldLocations: React.FC<Props> = ({
  name,
  className,
  placeholder,
  value,
  fieldRef,
  onChange
}) => {
  const [options, setOptions] = useState<any[]>([])
  const { locations } = useAppContext().locations

  function loadLocations() {
    const locationsList: any[] = [
      { value: '0', label: 'Selecione um local...' }
    ]
    if (locations) {
      locations.forEach(location => {
        locationsList.push({
          label: location.label,
          value: location.id?.toString()
        })
      })
      setOptions(locationsList)
    }
  }

  useEffect(() => {
    loadLocations()
  }, [locations])

  return (
    <SelectField
      name={name}
      className={className}
      value={value}
      placeholder={placeholder}
      options={options}
      onChange={onChange}
      required
      fieldRef={fieldRef}
    />
  )
}

export default SelectFieldLocations
