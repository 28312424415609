import React from 'react'
import { Link } from 'react-router-dom'
import CarouselArrows from './carouselArrows'

type TProps = {
  title?: string
  linkText?: string
  link?: string
}

const CarouselHeader: React.FC<TProps> = ({ title, linkText, link }) => {
  function loadLink() {
    if (!link) return <></>
    return (
      <Link className="carrousel-header-showmore" to={link || '#'}>
        {linkText || 'Veja mais'}
      </Link>
    )
  }

  return (
    <div className="container carousel-header">
      <div className="full-container">
        <h2>{title}</h2>
        <div className="carrousel-header-buttons">
          <CarouselArrows />
          {loadLink()}
        </div>
      </div>
    </div>
  )
}

export default CarouselHeader
