import { TDriverApi } from 'domain/entities/api/TDriverApi'
import { TDriver } from 'domain/entities/TRentingData'

export function driverDataParser(data: TDriverApi): TDriver {
  if (!data) return {} as TDriver
  return {
    cnh: data.cnh_condutor,
    cnhSecurityNumber: data.cod_seguranca_cnh_condutor,
    name: data.nome_condutor
  }
}

export function driverArrayParser(data: TDriverApi[]): TDriver[] {
  if (!data || data.length <= 0) return [] as TDriver[]
  return data.map(driver => driverDataParser(driver))
}
