import { TBlogPost } from 'domain/entities/TBlogPost'
import React from 'react'
import Card from 'view/components/card/card'
import './postCard.css'

type TProps = {
  post: TBlogPost
}

const PostCard: React.FC<TProps> = ({ post }) => {
  return (
    <Card
      image={post.image}
      className="blog-post-card"
      link={`/conteudo/${post.slug}`}
    >
      <Card.content>{post.title}</Card.content>
    </Card>
  )
}

export default PostCard
