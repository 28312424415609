import React from 'react'
import { useMainMenu } from './mainMenuMobile/mainMenuMobile'
import { Link, useHistory } from 'react-router-dom'
import { MainMenu } from './mainMenu'
import { useAppContext } from 'view/contexts/appContext/appContext'
import WhatsappButtonMobile from 'view/componentsPages/whatsappButton/whatsappButtonMobile'
import NotificationButton from './mainTopNotifications/notificationButton'
import { useAuthContext } from 'view/contexts/authContext'
import InstagramButton from 'view/componentsPages/instagramButton/instagramButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import './mainTopBar.css'

const MainTopBar: React.FC = () => {
  const history = useHistory()
  const { isUserLogged } = useAuthContext()
  const { toggleMainMenuMobile, returnMainMenu } = useMainMenu()
  const { organizationInfo } = useAppContext()

  function renderNotifications() {
    if (!isUserLogged()) return <></>
    return (
      <NotificationButton
        onClick={() => history.push('/dashboard/notificacoes')}
      />
    )
  }

  return (
    <div className="top-bar">
      <div className="container top-bar-content">
        <div className="logo">
          <Link to="/">
            <img src={organizationInfo?.logo} />
          </Link>
        </div>
        <div className="tob-bar-nav-mobile">
          {renderNotifications()}
          <WhatsappButtonMobile />
          <InstagramButton />
          <button
            type="button"
            onClick={toggleMainMenuMobile}
            className="toggle-main-menu top-bar-nav-item"
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
          {returnMainMenu()}
        </div>

        <div className="tob-bar-nav">
          <MainMenu />
        </div>
      </div>
    </div>
  )
}

export default MainTopBar
