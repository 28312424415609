import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from 'view/components/button/button'
import { useRentingData } from 'view/hooksPages'
import { SuccessPageTemplate } from 'view/template/successPageTemplate'

const AccountActivationSuccess: React.FC = () => {
  const { hasRentingData } = useRentingData()
  const history = useHistory()

  function openActivation() {
    console.log(hasRentingData())
    const byCheckout = hasRentingData() ? '?by_checkout=1' : ''
    history.replace(`/login${byCheckout}`)
  }

  return (
    <SuccessPageTemplate
      title="Ativação realizada com sucesso!"
      buttons={
        <Button style="orange" onClick={openActivation}>
          Fazer login
        </Button>
      }
    >
      {/* Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eaque labore
      quasi ducimus optio enim. Consequuntur et accusantium veniam numquam, a
      neque quod aliquam. Quam corrupti illo, beatae voluptatum sunt reiciendis. */}
    </SuccessPageTemplate>
  )
}

export default AccountActivationSuccess
