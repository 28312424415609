import React from 'react'
import { BookingDataProvider } from 'view/contexts/bookingDataContext'
import BookingValuesProvider from 'view/contexts/bookingValuesContext'
import DashboardBookingRouter from './dashboardBookingRouter'
import './dashboardBooking.css'

const DashboardBooking: React.FC = () => {
  return (
    <BookingDataProvider>
      <BookingValuesProvider>
        <DashboardBookingRouter />
      </BookingValuesProvider>
    </BookingDataProvider>
  )
}

export default DashboardBooking
