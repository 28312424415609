import { faRetweet } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TBooking } from 'domain/entities/TBooking'
import React from 'react'
import { Link } from 'react-router-dom'
import './bookingCardBookingCode.css'

type TProps = {
  booking: TBooking
}

const BookingCardBookingCode: React.FC<TProps> = ({ booking }) => {
  const renewParent = booking.renewParent

  function renderParent() {
    if (!renewParent) return <></>
    return (
      <>
        <FontAwesomeIcon
          icon={faRetweet}
          className="booking-card-renew-icon"
          title={`reserva "${booking.code}" é renovação da reserva "${renewParent.code}".`}
        />
        <Link
          to={`/dashboard/reserva/${renewParent?.uuid}`}
          className="booking-card-renew-link"
        >
          {renewParent?.code}
        </Link>
      </>
    )
  }

  return (
    <div className="booking-code">
      {booking?.code}
      {renderParent()}
    </div>
  )
}

export default BookingCardBookingCode
