import { newShortcode } from 'aplication/useCases/shortCodes/shortCodesFunctions'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FaIcons from '@fortawesome/free-solid-svg-icons'
import './scStyledList.css'

type TProps = {
  options?: string
  itemIcon?: string // FontAwesome icon name
}

const ScStyledList: React.FC<TProps> = ({ options, itemIcon }) => {
  const optionsList: string[] = options?.split('|') || []
  const faIcons: { [key: string]: any } = FaIcons

  return (
    <ul className="sc-styled-lyst">
      {optionsList.map((option, index) => (
        <li key={index}>
          {itemIcon ? <FontAwesomeIcon icon={faIcons[itemIcon]} /> : ''}

          <span dangerouslySetInnerHTML={{ __html: option }} />
        </li>
      ))}
    </ul>
  )
}

export default newShortcode({
  scName: 'scStyledList',
  Component: ScStyledList
})
