import React, { useContext } from 'react'
import { useBookingCalc } from 'aplication/useCases/booking/useBookingCalc'
import { useHistory } from 'react-router-dom'
import { Checkout } from 'view/componentsPages/checkout'
import { useAuthContext } from 'view/contexts/authContext'
import { useBookingDataContext } from 'view/contexts/bookingDataContext'
import { BookingViewContext } from './booking'

const BookingCheckout: React.FC = () => {
  const bookingContext = useContext(BookingViewContext)
  const history = useHistory()
  const path = history.location.pathname.split('/')[1]
  const { loggedUser } = useAuthContext()
  const { bookingData } = useBookingDataContext()

  useBookingCalc()

  function checkoutConfirm() {
    bookingContext.eventController.triggerEvent(
      'booking-confirm',
      {
        loggedUser,
        bookingData
      },
      result => {
        if (result) {
          history.push(`/${path}/pagamento`)
        }
      }
    )
  }

  return <Checkout bookingData={bookingData} onConfirm={checkoutConfirm} />
}

export default BookingCheckout
