import {
  TCarShopCarApi,
  TCarShopCarImgApi
} from 'aplication/apiServices/carShopCars/carShopCarsApiServiceTypes'
import { objectIsEmpty } from 'aplication/utils/app/testValues'
import { carFeaturesDataTypeParserArray } from '../carFeatures/carFeatures'
import { carMakerDataTypeParser } from '../carMaker/carMaker'
import { TCarShopCar } from './carShopTypes'

function typeParserImages(imagesApi: TCarShopCarImgApi[]): string[] {
  const destaq = imagesApi.find(i => i.usar_como_destaque)
  if (destaq) {
    const destaqIdx = imagesApi.findIndex(i => i.usar_como_destaque)
    imagesApi.splice(destaqIdx, 1)
    imagesApi.unshift(destaq)
  }

  return imagesApi.map(img => img.image)
}

export function carShopDataTypeParser(apiCar: TCarShopCarApi): TCarShopCar {
  if (!apiCar || objectIsEmpty(apiCar)) return {} as TCarShopCar
  return {
    color: apiCar.cor,
    doors: apiCar.total_portas,
    features: carFeaturesDataTypeParserArray(apiCar.caracteristica),
    fuel: apiCar.tipo_combustivel,
    id: apiCar.id,
    images: typeParserImages(apiCar.seminovo_imagem),
    km: apiCar.km_atual,
    maker: carMakerDataTypeParser(apiCar.marca),
    model: apiCar.modelo,
    passangers: apiCar.total_passageiros,
    placa: apiCar.placa,
    price: apiCar.valor,
    status: apiCar.situacao,
    uuid: apiCar.uuid,
    year: apiCar.ano
  }
}

export function carShopDataTypeParserArray(
  apiCars: TCarShopCarApi[]
): TCarShopCar[] {
  return apiCars.map(car => {
    return carShopDataTypeParser(car)
  })
}
