import { useCarBrand } from 'aplication/useCases/userCarBrand/userCarBrand'
import React, { useContext } from 'react'
import Card from 'view/components/card/card'
import { Carousel } from 'view/components/carousel'
import CarouselFullPage from 'view/components/carouselFullPage/carouselFullPage'
import { CarShopContext } from 'view/pages/carShop/carShopList/carShopContext'
import './carouselCarBrand.css'

const CarouselCarBrand: React.FC = () => {
  const { filters, updateFilters } = useContext(CarShopContext)
  const { carBrands } = useCarBrand()

  function onClickBrand(data: any) {
    updateFilters({ maker: data.slug })
  }

  function renderBrands(data: any, index: number) {
    const isSelected = filters.maker === data.slug
    return (
      <Carousel.item
        key={index}
        id={`carousel-item-${index}`}
        className={isSelected ? 'selected' : ''}
        onClick={() => {
          onClickBrand(data)
        }}
      >
        <Card>
          <Card.content>
            <img src={data.image} />
          </Card.content>
        </Card>
      </Carousel.item>
    )
  }

  function loadCarousel() {
    if (!carBrands || carBrands.length <= 0) {
      return <></>
    }
    return (
      <div className="carousel-car-brand">
        <CarouselFullPage items={carBrands} itemRender={renderBrands} />
      </div>
    )
  }

  return <>{loadCarousel()}</>
}

export default CarouselCarBrand
