import { useCarShopFiltersFeatures } from 'aplication/useCases/carShop/useCarShopFiltersFeatures'
import React, { useEffect, useState } from 'react'
import { SelectField } from 'view/components/formComponent'
import { TOption } from 'view/components/formComponent/fields/selectField/selectFieldContext'

const CarShopFormSelectFeatures: React.FC = () => {
  const [options, setOptions] = useState<TOption[]>([] as TOption[])

  const { features } = useCarShopFiltersFeatures()

  function renderOptions() {
    if (!features || features.length <= 0) return
    const newOptions: TOption[] = []
    features.forEach(feature => {
      newOptions.push({ label: feature.description, value: feature.slug })
    })
    setOptions(newOptions)
  }

  useEffect(() => renderOptions(), [features])

  return (
    <SelectField name={'features'} placeholder="Recursos" options={options} />
  )
}

export default CarShopFormSelectFeatures
