import { CarShopCarsApiService } from 'aplication/apiServices/carShopCars/carShopCarsApiService'
import { useState } from 'react'
import { carShopDataTypeParserArray } from './carShopDataParsers'
import { TCarShopCar, TCarShopFilters } from './carShopTypes'

export type TUseCarShopCars = {
  cars: TCarShopCar[]
  totalCars: number
  loadCars: (filters?: TCarShopFilters) => void
  loadMoreCars: () => void
  loading: boolean
}

export function UseCarShopCars(): TUseCarShopCars {
  const [cars, setCars] = useState<TCarShopCar[]>([] as TCarShopCar[])
  const [totalCars, setTotalCars] = useState<number>(0)
  const [nextPage, setNextPage] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(false)

  function makeStringFilters(
    filters?: TCarShopFilters & { [key: string]: any }
  ): string {
    let urlFilter = ''

    const filtersString: { [key: string]: any } = {
      fuel: 'tipo_combustivel',
      maker: 'slug_marca',
      features: 'slug_caracteristica',
      color: 'cor',
      year: 'ano'
    }

    if (filters) {
      for (const filter in filters) {
        if (!filters[filter] || filters[filter] === '') continue
        if (urlFilter !== '') urlFilter += '&'
        urlFilter += `${filtersString[filter]}=${filters[filter]}`
      }
    }

    return urlFilter
  }

  function loadCars(filters?: TCarShopFilters) {
    const apiService = new CarShopCarsApiService()
    const filtersString = makeStringFilters(filters)
    setLoading(true)
    apiService.load(filtersString).then(response => {
      if (
        !response ||
        response.statusCode !== 200 ||
        !response.body ||
        !response.body.results
      ) {
        console.error('car_shop_cars_load_error')
        return
      }
      const allCars = carShopDataTypeParserArray(response.body.results)
      setNextPage(2)
      setCars(allCars)
      setTotalCars(response.body.count)

      setTimeout(() => {
        setLoading(false)
      }, 100)
    })
  }

  function loadNextPage(page: number, filters?: TCarShopFilters) {
    setLoading(false)
    const apiService = new CarShopCarsApiService()
    const filtersString = makeStringFilters(filters)
    apiService.load(filtersString, page).then(response => {
      if (
        !response ||
        response.statusCode !== 200 ||
        !response.body ||
        !response.body.results
      ) {
        console.error('car_shop_cars_load_error')
        return
      }
      const newCars = carShopDataTypeParserArray(response.body.results)
      setTimeout(() => {
        setCars([...cars, ...newCars])
      }, 100)
    })
  }

  function hasMoreCars() {
    return cars.length >= totalCars
  }

  function loadMoreCars() {
    if (!hasMoreCars) return
    loadNextPage(nextPage)
    setNextPage(nextPage + 1)
  }

  return { cars, totalCars, loading, loadCars, loadMoreCars }
}
