import { TFaqApi } from 'domain/entities/api/TFaqApi'
import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'

type TResponseBody = {
  results: TFaqApi[]
  count: number
  previus: number
  next: string
}

export class FaqDataService extends ApiService {
  async load(params: {
    page?: number
    query?: string
  }): Promise<HttpResponse<TResponseBody>> {
    const { page, query } = params
    const qry = query ? `q=${query}` : ''
    const paged = page ? `page=${page}` : ''

    function qryString() {
      if (qry !== '' && paged !== '') {
        return `?${qry}&${paged}`
      } else if (paged !== '') {
        return `?${paged}`
      } else if (qry !== '') {
        return `?${qry}`
      } else {
        return ''
      }
    }

    return this.httpClient.request({
      url: `
        ${process.env.REACT_APP_BASE_URL}/perguntas-frequentes/${qryString()}
      `,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }

  async read(slug: string): Promise<HttpResponse<TResponseBody>> {
    return this.httpClient.request({
      url: `
      ${process.env.REACT_APP_BASE_URL}/perguntas-frequentes/?q=${slug}`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
