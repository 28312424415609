import { HttpResponse } from 'aplication/apiServices/protocols/IHttpClient'
import { TCarFeatureApi } from '../carShopCars/carShopCarsApiServiceTypes'
import { ApiService } from '../apiService'

type TResponseData = {
  results: TCarFeatureApi[]
}

export class CarFeaturesApiService extends ApiService {
  async load(): Promise<HttpResponse<TResponseData>> {
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/caracteristicas-veiculos/`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }

  async read(id: number): Promise<HttpResponse<TCarFeatureApi>> {
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/caracteristicas-veiculos/${id}/`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
