import { CredCardApiService } from 'aplication/apiServices/credCardApiService/credCardApiService'
import { HttpResponse } from 'aplication/apiServices/protocols/IHttpClient'
import { TCredCard } from 'domain/entities/TCredCard'
import { useAuthContext } from 'view/contexts/authContext'
import { credCardDataParserToApi } from './credCardsDataParser'

type THook = {
  createCredCard: (formData: TCredCard) => Promise<HttpResponse>
  deleteCredCard: (credCardUuId: string) => Promise<HttpResponse>
  setMainCredCard: (credCardUuId: string) => Promise<HttpResponse>
}

export function adminCredCard(): THook {
  const { loggedUser } = useAuthContext()
  const apiService = new CredCardApiService()

  async function createCredCard(formData: TCredCard): Promise<HttpResponse> {
    return new Promise((resolve, reject) => {
      if (!loggedUser || !loggedUser.person || !loggedUser.person.id) {
        reject(new Error('undefined user'))
      }
      formData.client = loggedUser?.person.id
      const body = credCardDataParserToApi(formData)
      apiService.create(body).then(response => {
        if (
          !response ||
          (response.statusCode !== 200 && response.statusCode !== 201) ||
          !response.body
        ) {
          console.error(`cred_cards_create_error [${response.body.msg}]`)
        }
        resolve(response)
      })
    })
  }

  async function deleteCredCard(credCardUuId: string): Promise<HttpResponse> {
    return new Promise((resolve, reject) => {
      if (!credCardUuId) {
        reject(new Error('cred card undefined'))
      }
      apiService.delete(credCardUuId).then(response => {
        if (!response || response.statusCode !== 200 || !response.body) {
          console.error('cred_cards_delete_error')
        }
        resolve(response)
      })
    })
  }

  async function setMainCredCard(credCardUuId: string): Promise<HttpResponse> {
    return new Promise((resolve, reject) => {
      if (!credCardUuId) {
        reject(new Error('cred card undefined'))
      }
      apiService.setMain(credCardUuId).then(response => {
        if (!response || response.statusCode !== 200 || !response.body) {
          console.error('cred_cards_set_main_error')
        }
        resolve(response)
      })
    })
  }

  return { createCredCard, deleteCredCard, setMainCredCard }
}
