import { SiteContactPost } from 'aplication/useCases/siteContactPost/siteContactPost'
import { TSiteContactPost } from 'aplication/useCases/siteContactPost/siteContactPostTypes'
import {
  isoDateToDateDisplay,
  isoDateToTimeDisplay
} from 'aplication/utils/app/dateTimeCare/dateTimeCare'
import { TBookingEvent } from 'domain/entities/TBooking'
import React, { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Button from 'view/components/button/button'
import { TDialogRef, Dialog } from 'view/components/dialog'
import { FormComponent, InputField } from 'view/components/formComponent'
import {
  InputEmailField,
  InputPhoneField
} from 'view/componentsPages/customFields'
import { useAppContext } from 'view/contexts/appContext/appContext'
import { useBookingDataContext } from 'view/contexts/bookingDataContext'

type TProps = {
  groupName?: string
}

const CarGroupQueueBtn: React.FC<TProps> = ({ groupName }) => {
  const { bookingData, resetBookingData } = useBookingDataContext()
  const { modalDialogRef } = useAppContext()
  const theModal = modalDialogRef.current.modal.current
  const dialogRef = useRef<TDialogRef>({} as TDialogRef)
  const history = useHistory()

  function mountDateTimeMsg(bookingEvent?: TBookingEvent) {
    if (!bookingEvent) return ''
    const date = isoDateToDateDisplay(bookingEvent.date)
    const time = isoDateToTimeDisplay(bookingEvent.hour)
    return `${date} - ${time}`
  }

  function bookingTime() {
    return `
    Grupo: ${groupName}
    retirada: ${mountDateTimeMsg(bookingData.pickup)}
    devolução: ${mountDateTimeMsg(bookingData.return)}
    `
  }

  function closeBookingProcess() {
    resetBookingData()
    theModal.toggleOpen(false)
    history.push('/')
  }

  function modalConfirmContent() {
    modalDialogRef.current.setContent(
      <>
        <h2>Fila de espera</h2>
        <p>
          <b>Registro realizado com sucesso.</b>
          <br /> Deseja Continuar com a reserva?
        </p>
        <div className="btn-group">
          <Button onClick={() => theModal.toggleOpen(false)}>Sim</Button>
          <Button style="orange" onClick={closeBookingProcess}>
            Não
          </Button>
        </div>
      </>
    )
  }

  function confirmQueue(data: TSiteContactPost) {
    data.subject = 'Reserva de Veículo'
    data.title = 'Fila de espera'
    data.text = bookingTime()
    console.log(data)
    SiteContactPost(data).then(response => {
      dialogRef.current.loadMessage('Registrando na fila...')
      setTimeout(() => {
        if (response.statusCode !== 200 && response.statusCode !== 201) {
          dialogRef.current.changeMessage(
            'Ocorreu um erro no registro.',
            'danger'
          )
          return
        }
        setTimeout(() => modalConfirmContent(), 1500)
      }, 1500)
    })
  }

  function buildModalContent() {
    return (
      <div>
        <h2>Fila de espera</h2>
        <p>
          Preencha os dados e confirme para ser contactado quando houver
          disponibilidade de veículo no grupo <b>{groupName}</b>, para o
          período:
        </p>
        <p>
          <b>retirada:</b> {mountDateTimeMsg(bookingData.pickup)}
          <br />
          <b>devolução:</b> {mountDateTimeMsg(bookingData.return)}
        </p>
        <FormComponent onSubmit={confirmQueue}>
          <Dialog ref={dialogRef} />
          <InputField name="name" placeholder="Nome" required />
          <InputPhoneField />
          <InputEmailField required />
          <Button type="submit" style="orange">
            Confirmar
          </Button>
        </FormComponent>
      </div>
    )
  }

  function onClick() {
    modalDialogRef.current.setClassName('car-group-queue-modal')
    modalDialogRef.current.setContent(buildModalContent())
    theModal.toggleOpen(true)
  }

  return (
    <Button onClick={onClick}>
      <span style={{ fontSize: '0.8em' }}>Fila de espera</span>
    </Button>
  )
}

export default CarGroupQueueBtn
