import React from 'react'
import TitlePage from 'view/components/text/titlePage/titlePage'
import CarList from './carList/carList'
import CarouselCarBrand from 'view/componentsPages/carouselCarBrand/carouselCarBrand'
import CarShopProvider from './carShopContext'
import CarShopFormFilter from './carShopFormFilter/carShopFormFilter'
import CarShopContent from './carShopContent/carShopContent'
import PageBannerTop from 'view/componentsPages/pageBannerTop/pageBannerTop'
import PageBannerContainer from 'view/componentsPages/pageBannerContainer/pageBannerContainer'

const CarShopList: React.FC = () => {
  return (
    <CarShopProvider>
      <PageBannerTop bannerLocation="seminovos_topo" />
      <div className="container">
        <TitlePage>Seminovos</TitlePage>
      </div>
      <CarouselCarBrand />
      <div className="container">
        <CarShopFormFilter />
        <CarList />
        <CarShopContent />
        <PageBannerContainer bannerLocation="seminovos_rodape" />
      </div>
    </CarShopProvider>
  )
}

export default CarShopList
