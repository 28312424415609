import React, { useRef } from 'react'
import Button from 'view/components/button/button'
import { Modal, TModalRef } from 'view/components/modal'
import DashboardCredCardForm from './dashboardCredCardForm'

const DashboardCredCardAdd: React.FC = () => {
  const modalRef = useRef<TModalRef>({} as TModalRef)

  function openModal() {
    modalRef.current.toggleOpen(true)
  }

  return (
    <>
      <Modal
        className="modal-cred-card-add"
        content={<DashboardCredCardForm />}
        ref={modalRef}
      />
      <Button onClick={openModal} className="cred-card-add-btn" style="orange">
        Adicionar
      </Button>
    </>
  )
}

export default DashboardCredCardAdd
