import { slideImagesLoader } from 'aplication/useCases/slideImages/slideImagesLoader'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Carousel,
  CarouselArrows,
  CarouselDotsNavigator,
  CarouselItens
} from 'view/components/carousel'
import LoadingContent from 'view/componentsPages/loadingContent/loadingContent'
import './homePromo.css'

const HomePromo: React.FC = () => {
  const { slideImages, loadSlideImages, loading } = slideImagesLoader()

  function renderCarouselItens() {
    if (!slideImages || slideImages.length <= 0) return <></>
    return slideImages.map((img, index) => {
      return (
        <Carousel.item id={`carousel-item-${index + 1}`} key={index}>
          <Link to={img.link || '#'}>
            <img src={img.desktop || ''} className="desktop" />
            <img src={img.mobile || ''} className="mobile" />
          </Link>
        </Carousel.item>
      )
    })
  }

  function renderNavigators() {
    if (slideImages.length < 2) return <></>
    return (
      <>
        <CarouselDotsNavigator />
      </>
    )
  }

  function renderContent() {
    if (loading) return <LoadingContent />
    if (!slideImages || slideImages.length <= 0) return <></>
    return (
      <Carousel className="carousel-home-top" autoScroll>
        <CarouselItens>{renderCarouselItens()}</CarouselItens>
        {renderNavigators()}
      </Carousel>
    )
  }

  useEffect(() => {
    loadSlideImages('home_topo')
  }, [])

  return <>{renderContent()}</>
}

export default HomePromo
