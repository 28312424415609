import { SiteContactSubjectApiService } from 'aplication/apiServices/siteContactSubjectsApiService/siteContactSubjectsApiService'
import { TSiteContactSubject } from 'domain/entities/TSiteContactSubject'
import { useEffect, useState } from 'react'
import { siteContactSubjectArrayParser } from './siteContactSubjectDataParser'

type TSiteContactSubjectCombo = {
  data: TSiteContactSubject[]
}

export function siteContactSubjectCombo(): TSiteContactSubjectCombo {
  const [data, setData] = useState<TSiteContactSubject[]>(
    [] as TSiteContactSubject[]
  )

  function load() {
    const apiService = new SiteContactSubjectApiService()
    apiService.load().then(response => {
      if (!response || !response.body || !response.body.results) return
      const dataParsed = siteContactSubjectArrayParser(response.body?.results)
      setData(dataParsed)
    })
  }

  useEffect(() => {
    load()
  }, [])

  return { data }
}
