import { useAuthToken } from 'aplication/useCases/auth/useAuthToken'
import { IDataService } from 'domain/entities/IDataService'
import { TToken } from 'domain/entities/TToken'
import { AxiosHttpClient } from 'infra/http/AxiosHttpClient'
import { HttpClient, HttpResponse } from './protocols/IHttpClient'

export type TApiServiceParams = { token?: TToken }

export class ApiService implements IDataService {
  readonly httpClient: HttpClient = new AxiosHttpClient()
  readonly token?: TToken

  constructor() {
    const { getStorageToken } = useAuthToken()
    this.token = getStorageToken()
  }

  async load(params?: any): Promise<HttpResponse> {
    return await this.httpClient.request({
      url: '/',
      method: 'get'
    })
  }

  makeQuery(query: { [key: string]: any }): string {
    let queryString = ''
    if (query && Object.keys(query).length > 0) {
      Object.keys(query).forEach(q => {
        if (query[q] && query[q] !== '') {
          queryString += `${q}=${query[q]}&`
        }
      })
    }
    return queryString
  }
}
