import { TRentingData } from 'domain/entities/TRentingData'

export function saveRentingData(data: TRentingData): boolean {
  try {
    const dataName = 'rentingData'
    const dataString = JSON.stringify(data)
    const hasDataInStorage = sessionStorage.getItem(dataName)
    if (!hasDataInStorage) {
      sessionStorage.setItem(dataName, dataString)
    } else {
      const dataToUpdate = JSON.parse(hasDataInStorage)

      const newData = JSON.stringify({ ...dataToUpdate, ...data })

      sessionStorage.setItem(dataName, newData)
    }
    return true
  } catch (error: any) {
    if (error.name === 'SyntaxError') {
      console.log('Is not a JSON')
    }
    return false
  }
}

export function readRentingData(): TRentingData {
  try {
    const rentingData: string | null = sessionStorage.getItem('rentingData')
    return !rentingData ? null : JSON.parse(rentingData)
  } catch (error: any) {
    if (error.name === 'SyntaxError') {
      console.log('Is not a JSON')
    }
    return {} as TRentingData
  }
}

export function deleteRentingData(): boolean {
  try {
    sessionStorage.removeItem('rentingData')
    return true
  } catch (error: any) {
    if (error.name === 'SyntaxError') {
      console.log('Is not a JSON')
    }
    return false
  }
}
