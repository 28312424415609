import { THtmlContentApi } from 'domain/entities/api/THtmlContentApi'
import { THtmlContent } from 'domain/entities/THtmlContent'

export function htmlContentdataParser(data: THtmlContentApi): THtmlContent {
  if (!data) return {} as THtmlContent
  return {
    id: data.id,
    uuid: data.uuid,
    title: data.title,
    slug: data.slug,
    content: data.content
  }
}

export function htmlContentArrayParser(
  data: THtmlContentApi[]
): THtmlContent[] {
  if (!data || data.length <= 0) return [] as THtmlContent[]
  return data.map(content => htmlContentdataParser(content))
}
