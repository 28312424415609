import React, { useEffect, useRef, useState } from 'react'

type Props = {
  id: string
  title: string
  content: string
  open?: boolean
}

const FaqItem: React.FC<Props> = ({ title, content, id, open }) => {
  const [show, setShow] = useState<boolean>(false)
  const itemRef = useRef<any>()

  function onClick(event: any) {
    event.preventDefault()
    setShow(!show)
  }

  useEffect(() => {
    if (open) {
      setShow(true)
      setTimeout(() => {
        itemRef.current.scrollIntoView({ behavior: 'smooth' })
      }, 500)
    }
  }, [open])

  return (
    <div id={id} className={`faq-item ${show ? 'show' : ''}`} ref={itemRef}>
      <a href="#" className="faq-item-title" onClick={onClick}>
        {title}
      </a>
      <div
        className="faq-item-content"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  )
}

export default FaqItem
