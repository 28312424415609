import React, { useEffect, useRef } from 'react'
import { useFormContext } from 'view/components/formComponent'
import { useDisabledController } from 'view/hooksPages'
import BookingTimeField from './bookingTimeField'
import ReactDatePicker from 'react-datepicker'
import { bookingFormFieldListener } from './bookingFormFieldListener'

const BookingTimePickupField: React.FC = () => {
  const fieldRef = useRef<ReactDatePicker>()
  const { fields, updateField } = useFormContext()
  const { disabled } = useDisabledController(fields, 'pickupDate')

  function onChange() {
    fields.forEach(field => {
      if (!['returnDate', 'returnTime'].includes(field.name)) return
      updateField({ ...field, value: '', isValid: true })
    })
  }

  // escura o valor de pickupDate para abrir automatico
  const pickupDate = bookingFormFieldListener('pickupDate')
  useEffect(() => {
    if (!pickupDate.change || pickupDate.change === '0') return
    fieldRef.current?.setFocus()
  }, [pickupDate.change])

  return (
    <BookingTimeField
      name="pickupTime"
      listeningLocation="pickupLocation"
      listeningDate="pickupDate"
      disabled={disabled}
      onChange={onChange}
      fieldRef={fieldRef}
    />
  )
}

export default BookingTimePickupField
