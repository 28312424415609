import { TCarShopFilters } from 'aplication/useCases/carShop/carShopTypes'
import {
  TUseCarShopCars,
  UseCarShopCars
} from 'aplication/useCases/carShop/useCarShopCars'
import React, { createContext, useEffect, useState } from 'react'

export type TCarShopContext = {
  cars: TUseCarShopCars
  filters: { [key: string]: any } & TCarShopFilters
  updateFilters: (newValues: TCarShopFilters) => void
  cleanFilters: () => void
}
export const CarShopContext = createContext<TCarShopContext>(
  {} as TCarShopContext
)

const CarShopProvider: React.FC = ({ children }) => {
  const cars = UseCarShopCars()
  const [filters, setFilters] = useState<TCarShopFilters>({} as TCarShopFilters)

  function updateFilters(newValues: TCarShopFilters) {
    setFilters({ ...filters, ...newValues })
  }

  function cleanFilters() {
    setFilters({})
  }

  useEffect(() => {
    cars.loadCars(filters)
  }, [filters])

  return (
    <CarShopContext.Provider
      value={{ filters, cars, updateFilters, cleanFilters }}
    >
      {children}
    </CarShopContext.Provider>
  )
}

export default CarShopProvider
