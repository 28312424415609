import { UseCarShopCars } from 'aplication/useCases/carShop/useCarShopCars'
import React, { useEffect } from 'react'
import CarouselFullPage from 'view/components/carouselFullPage/carouselFullPage'
import Carousel from '../../components/carousel/carousel'
import CarCard from '../carCard/carCard'
import LoadingContent from '../loadingContent/loadingContent'
import './carouselSeminovos.css'

const CarouselSeminovos: React.FC = () => {
  const { cars, loadCars, loading } = UseCarShopCars()

  function renderCarousel(data: any, index: number): any {
    return (
      <Carousel.item id={`carousel-item-${index}`} key={index}>
        <CarCard car={data} features />
      </Carousel.item>
    )
  }

  function renderContent() {
    if (loading) {
      return (
        <div className="container">
          <LoadingContent />
        </div>
      )
    } else {
      return (
        <CarouselFullPage
          title="Seminovos"
          link="/seminovos"
          linkText="Veja todos"
          className="carousel-seminovos"
          items={cars}
          itemRender={renderCarousel}
          autoScroll
          autoScrollTimeout={10000}
        />
      )
    }
  }

  useEffect(() => loadCars(), [])

  return <>{renderContent()}</>
}

export default CarouselSeminovos
