import { TNewUserApiToService } from 'domain/entities/api/TNewUserApi'
import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'

export class UserApiService extends ApiService {
  async load(props: TNewUserApiToService): Promise<HttpResponse> {
    const { user, cnhFile, addressFile } = props

    const data = JSON.stringify(user)

    const body = new FormData()
    body.append('data', data)
    body.append('cnh_file', cnhFile)
    body.append('comprovante_endereco_file', addressFile)

    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/cliente/`,
      method: 'post',
      body,
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
