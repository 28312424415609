import appTexts from 'aplication/localData/appText/appText'
import { createRenting } from 'aplication/useCases/createRenting/createRenting'
import { objectIsEmpty } from 'aplication/utils/app/testValues'
import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Dialog, TDialogRef } from 'view/components/dialog'
import BookingPaymentFormSimple from 'view/componentsPages/bookingPaymentForm/bookingPaymentFormSimple'
import BookingPaymentPage from 'view/componentsPages/bookingPaymentPage/bookingPaymentPage'
import PaymentUserPj from 'view/componentsPages/bookingPaymentUserPj/bookingPaymentUserPj'
import LoadingContent from 'view/componentsPages/loadingContent/loadingContent'
import { useAuthContext } from 'view/contexts/authContext'
import { useBookingDataContext } from 'view/contexts/bookingDataContext'
import { usePageLoader } from 'view/hooksPages'

const DashBoardBookingEditPayment: React.FC = () => {
  const { loggedUser } = useAuthContext()
  const history = useHistory()
  const { bookingData } = useBookingDataContext()
  const { loaded } = usePageLoader()

  const dialogRef = useRef<TDialogRef>({} as TDialogRef)

  function startBookingData() {
    if (
      !bookingData ||
      objectIsEmpty(bookingData) ||
      bookingData.status !== 'em_andamento' ||
      !bookingData.dailyTotal
    ) {
      history.push('/dashboard')
    }
  }

  function postAction(values: any) {
    dialogRef.current.loadMessage('Solicitando renovação...')

    createRenting(bookingData, values).then(response => {
      if (response.statusCode === 201) {
        const bookingId = response.body.uuid
        const path = history.location.pathname.split('/')[1]
        history.push(`/${path}/reserva/${bookingId}/renovar/success`)
      } else {
        const message = appTexts[response.body?.detail]
        const defaultMsg = appTexts.default_reserva_post_error
        dialogRef.current.changeMessage(message || defaultMsg, 'danger')
      }
    })
  }

  function selectPaymentByUser() {
    const userType = loggedUser?.person.clientType

    if (userType !== 'PJ') {
      return <BookingPaymentFormSimple postAction={postAction} />
    } else {
      return <PaymentUserPj onSubmit={postAction} dialogRef={dialogRef} />
    }
  }

  useEffect(() => {
    startBookingData()
  }, [])

  function renderContent() {
    if (!loaded) {
      return (
        <div className="container">
          <LoadingContent />
        </div>
      )
    } else {
      return (
        <BookingPaymentPage title="Pagamento de renovação">
          <Dialog ref={dialogRef} />
          {selectPaymentByUser()}
        </BookingPaymentPage>
      )
    }
  }

  return <>{renderContent()}</>
}

export default DashBoardBookingEditPayment
