import React, { useEffect, useRef } from 'react'
import { useFormContext } from 'view/components/formComponent/formComponent'
import SelectFieldLocations from '../selectFieldLocations/selectFieldLocations'
import { TSelectComponentRef } from 'view/components/formComponent/fields/selectField/selectField'
import { bookingFormFieldListener } from './bookingFormFieldListener'

type Props = {
  className?: string
  showReturnLocation: boolean
}

const ReturnLocationField: React.FC<Props> = ({ showReturnLocation }) => {
  const fieldRef = useRef<TSelectComponentRef>()
  const { fields, updateField, resetReturnDataFields } = useFormContext()

  function setReturnValue() {
    const pickupLocation = fields.find(f => f.name === 'pickupLocation')
    const returnLocation = fields.find(f => f.name === 'returnLocation')
    const sameValue = pickupLocation?.value === returnLocation?.value

    if (!showReturnLocation && !sameValue) {
      setTimeout(() => {
        updateField({ name: 'returnLocation', value: pickupLocation?.value })
      }, 500)
    }
  }

  function onChange() {
    resetReturnDataFields()
  }

  useEffect(() => {
    setReturnValue()
  }, [fields])

  // escura o valor de pickupTime para abrir automatico
  const pickupTime = bookingFormFieldListener('pickupTime')
  useEffect(() => {
    if (!showReturnLocation || !pickupTime.change) {
      return
    }
    fieldRef.current?.setFocus()
  }, [pickupTime.change])

  return (
    <SelectFieldLocations
      className={!showReturnLocation ? 'hidden' : ''}
      name="returnLocation"
      placeholder="Local de devolução"
      onChange={onChange}
      fieldRef={fieldRef}
    />
  )
}

export default ReturnLocationField
