import React, { useContext } from 'react'
import CarCard from 'view/componentsPages/carCard/carCard'
import LoadingContent from 'view/componentsPages/loadingContent/loadingContent'
import LoadMoreResults from 'view/componentsPages/loadMoreResults/loadMoreResults'
import { CarShopContext } from '../carShopContext'
import './carList.css'

const CarList: React.FC = () => {
  const { cars, totalCars, loading, loadMoreCars } =
    useContext(CarShopContext).cars

  function renderCards() {
    if (!loading) {
      if (!cars || cars.length <= 0) return <></>
      return (
        <>
          {cars.map((car, index) => {
            return <CarCard key={index} car={car} />
          })}
          <LoadMoreResults
            data={cars}
            totalData={totalCars}
            loadMoreAction={loadMoreCars}
          />
        </>
      )
    } else {
      return <LoadingContent />
    }
  }

  return <div className="car-list">{renderCards()}</div>
}

export default CarList
