import { DomVersionApiService } from 'aplication/apiServices/domVersionApiService/domVersionApiService'
import { TDomVersion } from 'aplication/entities/TDomVersion'
import { useState } from 'react'
import { domVersionDataParser } from './domVersionDataParser'

type TDomVersionReader = {
  data: TDomVersion
  load: () => void
}

export function domVersionReader(): TDomVersionReader {
  const [data, setData] = useState<TDomVersion>({} as TDomVersion)

  function load() {
    const apiService = new DomVersionApiService()

    apiService.read().then(response => {
      if (
        !response ||
        response.statusCode !== 200 ||
        !response.body ||
        !response.body.results
      ) {
        return
      }

      setData(domVersionDataParser(response.body))
    })
  }

  return {
    data,
    load
  }
}
