import React, { useEffect } from 'react'
import MainTopBar from './mainTopBar/mainTopBar'
import MainFooter from './mainFooter/mainFooter'
import ModalCookies from 'view/componentsPages/modalCookie/modalCookie'
import { useAppContext } from 'view/contexts/appContext/appContext'
import Helmet from 'react-helmet'
import './normalize.css'
import './mainTemplate.css'
import './mainTemplateText.css'
import './mainTemplateColors.css'
import './mainTemplateUtils.css'
import './mainTemplateAnimations.css'
import { useLocation } from 'react-router-dom'
import { domVersionReader } from 'aplication/useCases/domVersion/domVersionReader'

const MainTemplate: React.FC = ({ children }) => {
  const { organizationInfo, pageInfos } = useAppContext()
  const { pageTitle, pageDescription } = pageInfos.data
  const location = useLocation()
  const domVersion = domVersionReader()

  useEffect(() => {
    pageInfos.update()
  }, [location])

  useEffect(() => {
    domVersion.load()
  }, [])

  return (
    <>
      {/* META TAGS DINAMICAS */}
      <Helmet>
        <link rel="dns-prefetch" href="https://api.realizarentacar.com.br" />
        <meta name="dom-version" content={domVersion.data?.hash} />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <meta
          name="description"
          content={organizationInfo?.siteDescription || ''}
        />
        <link rel="canonical" href="https://realizarentacar.com.br/" />

        <meta name="theme-color" content="#2180a7" />
        <meta property="og:locale" content="pt_BR" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Realiza Rent a Car | ${pageTitle} `}
        />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content="https://realizarentacar.com.br/" />
        <meta property="og:site_name" content="Realiza Rent a Car" />
        <meta property="og:image" content={pageInfos.data.pageImg} />
        <meta property="og:image:width" content={pageInfos.data.pageImgWidth} />
        <meta
          property="og:image:height"
          content={pageInfos.data.pageImgHeigth}
        />
        <meta property="og:image:type" content={pageInfos.data.pageImgType} />

        {/* metatags para o facebook */}

        <meta
          property="article:publisher"
          content="https://www.facebook.com/realizarentacar"
        />
        <meta
          property="article:modified_time"
          content={new Date().toISOString()}
        />

        {/* meta tags para o twitter */}
        <meta name="twitter:card" content={pageInfos.data.pageImg} />
        <meta
          name="twitter:title"
          content={`Realiza Rent a Car | ${pageTitle || ''}`}
        />
        <meta name="twitter:description" content={pageDescription || ''} />

        {/* pre carregamento de apis do Google */}
        <link rel="dns-prefetch" href="//ajax.googleapis.com" />
        <link rel="dns-prefetch" href="//apis.google.com" />
        <link rel="dns-prefetch" href="//google-analytics.com" />
        <link rel="dns-prefetch" href="//www.google-analytics.com" />
        <link rel="dns-prefetch" href="//ssl.google-analytics.com" />
        <link rel="dns-prefetch" href="//youtube.com" />
        <link rel="dns-prefetch" href="//addthis.com" />
        <link rel="dns-prefetch" href="//s7.addthis.com" />
        {/* <link rel="dns-prefetch" href="//googletagmanager.com" /> */}
        <link rel="dns-prefetch" href="//maps.googleapis.com" />

        {/* ícones para browsers mobile android/iphone */}
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="shortcut icon" href="/favicon.ico" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-config" content="/browserconfig.xml" />

        <meta name="msvalidate.01" content="2A9CFB2B2F296561747FAB81D53155D7" />
        <meta name="yandex-verification" content="7590f17a816601c6" />
        <meta
          name="google-site-verification"
          content="0vgKrD6YSw72CdJq_C_F5U3HcLpsOHRY8jp-O4yx-eE"
        />

        <title>{organizationInfo?.siteTitle || ''}</title>
        <meta name="sitename" content={organizationInfo?.siteName || ''} />

        {/* script do RD STATION para whatsapp */}
        <script
          type="text/javascript"
          async
          src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/d191cafa-28b8-4a75-a339-32d38c4a5c41-loader.js"
        ></script>
      </Helmet>

      <div className="template-wrapper">
        <MainTopBar />
        <div className="main-content">{children}</div>
        <ModalCookies />
        <MainFooter />
      </div>
    </>
  )
}

export default MainTemplate
