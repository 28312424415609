import React, { useRef } from 'react'
import Button from 'view/components/button/button'

type TProps = {
  size?: 'bg' | 'md' | 'sm' | 'sm-x'
  text?: string
}

const PJModalFormButton: React.FC<TProps> = ({ size, text }) => {
  size = size || 'bg'
  text = text || 'Envie sua proposta'

  return (
    <>
      <Button style="orange" size={size} className="modal-formpj-open">
        Quero receber uma proposta
      </Button>
    </>
  )
}

export default PJModalFormButton
