import React from 'react'
import TitlePage from 'view/components/text/titlePage/titlePage'
import './successPageTemplate.css'

type Props = {
  title: string
  className?: string
  buttons?: JSX.Element
}

const SuccessPageTemplate: React.FC<Props> = ({
  children,
  title,
  buttons,
  className
}) => {
  return (
    <div className="container">
      <div className={`success-page ${className}`}>
        <TitlePage>{title}</TitlePage>
        <div className="success-page-text">{children}</div>
        <div className="success-page-buttons">{buttons}</div>
      </div>
    </div>
  )
}

export default SuccessPageTemplate
