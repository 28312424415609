import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ErrorPages } from 'view/pages/errorPages'
import AppContextProvider from '../contexts/appContext/appContext'
import HomePage from 'view/pages/homePage/homePage'
import MainTemplate from 'view/template/mainTemplate/mainTemplate'
import RentingPage from 'view/pages/rentingPage/rentingPage'
import { Dashboard } from 'view/pages/dashboard'
import { LoginPage } from 'view/pages/loginPage_'
import { RouterScrollTop } from 'view/components/routerScrollTop'
import FaqRoutes from 'view/pages/faqPage'
import { AboutUs } from 'view/pages/aboutUs'
import AccountCreate from 'view/pages/dashboard/account/accountCreate/accountCreate'
import { AccountCreateSuccess } from 'view/pages/dashboard/account/accountCreateSuccess'
import { AccountActivationSuccess } from 'view/pages/dashboard/account/accountActivationSuccess'
import AboutUsEthic from 'view/pages/aboutUsEthic/aboutUsEthic'
import RentingContract from 'view/pages/rentingContract/rentingContract'
import { CarShop } from 'view/pages/carShop'
import Error404 from 'view/pages/error/error404'
import AboutUsPrivacyPolicy from 'view/pages/aboutUsPrivacyPolicy/aboutUsPrivacyPolicy'
import AboutUsService from 'view/pages/aboutUsService/aboutUsService'
import { AuthContextProvider } from 'view/contexts/authContext'
import Blog from 'view/pages/blog/blog'
import BookingPj from 'view/pages/bookingPJ/bookingPj'
import BookingACar from 'view/pages/bookingACar/bookingACar'
import ContactSuccess from 'view/pages/contactSuccess/contactSuccess'

const AppRouting: React.FC = () => {
  return (
    <Router>
      <AuthContextProvider>
        <AppContextProvider>
          <MainTemplate>
            <RouterScrollTop>
              <Switch>
                <Route exact path="/" component={HomePage} />
                <Route path="/contato" component={HomePage} />
                <Route path="/aluguel-de-carros" component={BookingACar} />
                <Route path="/locacao" component={RentingPage} />
                <Route path="/terceirizacao-de-frota" component={BookingPj} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/login" component={LoginPage} />
                <Route path="/conteudo" component={Blog} />

                <Route path="/seminovos" component={CarShop} />
                <Route path="/cadastro" component={AccountCreate} />
                <Route
                  path="/usuario-cadastrado-com-sucesso"
                  component={AccountCreateSuccess}
                />
                <Route
                  path="/ativacao-de-usuario-concluida-com-sucesso"
                  component={AccountActivationSuccess}
                />
                <Route
                  path="/mensagem-enviada-com-sucesso"
                  component={ContactSuccess}
                />
                <Route path="/faq" component={FaqRoutes} />
                <Route path="/quem-somos" component={AboutUs} />
                <Route path="/codigo-de-etica" component={AboutUsEthic} />
                <Route
                  path="/contrato-de-locacao"
                  component={RentingContract}
                />
                <Route
                  path="/politica-de-privacidade"
                  component={AboutUsPrivacyPolicy}
                />
                <Route path="/servico/:slug" component={AboutUsService} />
                <Route path="/error" component={ErrorPages} />
                <Route path="*" component={Error404} />
              </Switch>
            </RouterScrollTop>
          </MainTemplate>
        </AppContextProvider>
      </AuthContextProvider>
    </Router>
  )
}

export default AppRouting
