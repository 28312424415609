import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'
import { TUserEmailExistsApi } from './TUserEmailExistsApi'

export class UserEmailExists extends ApiService {
  async load(email: string): Promise<HttpResponse<TUserEmailExistsApi>> {
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/check-cliente-email?email=${email}`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
