import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'

type TUserDataUpdate = {
  documento: string
}

export class RecoveryAccount extends ApiService {
  async load(userData: TUserDataUpdate): Promise<HttpResponse> {
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/recovery-account/`,
      method: 'post',
      body: userData,
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
