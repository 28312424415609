import { TCarMakerApi } from 'aplication/apiServices/carShopCars/carShopCarsApiServiceTypes'
import { objectIsEmpty } from 'aplication/utils/app/testValues'
import { TCarMaker } from './carMakerTypes'

export function carMakerDataTypeParser(apiData: TCarMakerApi): TCarMaker {
  if (!apiData || objectIsEmpty(apiData)) return {} as TCarMaker
  return {
    description: apiData.descricao,
    id: apiData.id,
    image: apiData.image,
    name: apiData.nome,
    slug: apiData.slug,
    uuid: apiData.uuid
  }
}
