import React from 'react'
import { InputField } from 'view/components/formComponent'
import { isValidCnpj } from 'view/components/formComponent/formComponentValidation/formComponentValidators'

type TProps = {
  required?: boolean
}

const InputCnpjField: React.FC<TProps> = ({ required }) => {
  function setInputMask(value: string) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
  }

  return (
    <InputField
      name="cnpj"
      placeholder="00.000.000/0000-00"
      regexMask={setInputMask}
      required={required}
      validators={[isValidCnpj]}
    />
  )
}

export default InputCnpjField
