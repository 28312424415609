import { TApiRentLocation } from 'domain/entities/api/TApiRentLocation'
import { TBookingApiLocal } from 'domain/entities/api/TBookingApi'
import { TEmpty } from 'domain/entities/TEmpty'
import { TLocation } from 'domain/entities/TLocation'

function setVariation(variation: string) {
  const variations: TEmpty = {
    'SEGUNDA A SÁBADO': {
      dom: null,
      hollydays: null // feriado
    },
    'SEGUNDA A SÁBADO E FERIADOS': {
      dom: null
    },
    'TODOS OS DIAS EXCETO FERIADOS': {
      hollydays: null
    },
    'SEGUNDA A DOMINGO': {
      hollydays: null
    },
    'TODOS OS DIAS INCLUINDO FERIADOS': {}
  }

  return variations[variation] || {}
}

export function locationDataParser(data: TBookingApiLocal): TLocation {
  if (!data) return {} as TLocation
  return {
    id: data.id,
    city: data.cidade.id,
    name: data.uuid,
    label: `${data.nome} | ${data.descricao}`,
    tax: data.valor_taxa,
    default: {
      minHour: data.horario_abertura_semana,
      maxHour: data.horario_fechamento_semana
    },
    sab: {
      minHour: data.horario_abertura_sabado,
      maxHour: data.horario_fechamento_sabado
    },
    dom: {
      minHour: data.horario_abertura_domingo,
      maxHour: data.horario_fechamento_domingo
    },
    hollydays: {
      minHour: data.horario_abertura_feriados,
      maxHour: data.horario_fechamento_feriados
    },
    tempoAntecedencia: data.tempo_antecedencia,
    ...setVariation(data.periodo_funcionamento)
  }
}

export function locationArrayDataParser(data: TBookingApiLocal[]): TLocation[] {
  if (!data || data.length <= 0) return [] as TLocation[]
  return data.map(location => locationDataParser(location))
}
