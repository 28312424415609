import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { Modal } from 'view/components/modal'
import { TModalRef } from 'view/components/modal/modal'
import { BookingForm } from '../bookingForm'
import './modalBookingForm.css'

export type TModalBookingFormRef = {
  modal: {
    current: TModalRef
  }
  toggleBookingModal: () => void
}

const ModalBookingForm: React.ForwardRefRenderFunction<TModalBookingFormRef> = (
  props,
  ref
) => {
  const modalBookingRef = useRef<TModalRef>({} as TModalRef)

  function toggleBookingModal() {
    modalBookingRef.current?.toggleOpen()
  }

  useImperativeHandle(ref, () => {
    return { toggleBookingModal, modal: modalBookingRef }
  })

  return (
    <Modal
      transparent
      className="modal-booking-form"
      ref={modalBookingRef}
      content={<BookingForm onConfirm={toggleBookingModal} />}
    />
  )
}

export default forwardRef(ModalBookingForm)
