import { useCarShopFiltersOptions } from 'aplication/useCases/carShop/useCarShopFilters'
import React, { useEffect, useState } from 'react'
import { SelectField } from 'view/components/formComponent'
import { TOption } from 'view/components/formComponent/fields/selectField/selectFieldContext'

const CarShopFormSelectColor: React.FC = () => {
  const [options, setOptions] = useState<TOption[]>([] as TOption[])

  const { filtersOptions } = useCarShopFiltersOptions()

  function renderOptions() {
    if (!filtersOptions || Object.keys(filtersOptions).length <= 0) return
    const newOptions: TOption[] = []
    filtersOptions.color.forEach(option => {
      newOptions.push({ label: option, value: option })
    })
    setOptions(newOptions)
  }

  useEffect(() => renderOptions(), [filtersOptions])

  return <SelectField name={'color'} placeholder="Cor" options={options} />
}

export default CarShopFormSelectColor
