import { TRentingData } from 'domain/entities/TRentingData'
import React, { createContext, useContext } from 'react'
import { useRentingData } from 'view/hooksPages'

type TBookingDataContext = {
  bookingData: TRentingData
  updateBookingData: (data: TRentingData) => void
  resetBookingData: () => void
}

const BookingDataContext = createContext<TBookingDataContext>(
  {} as TBookingDataContext
)

type TProps = {
  localStorage?: boolean
}

export const BookingDataProvider: React.FC<TProps> = ({
  localStorage,
  children
}) => {
  const { rentingData, updateRentingData, resetRentingData } =
    useRentingData(localStorage)

  return (
    <BookingDataContext.Provider
      value={{
        bookingData: rentingData,
        updateBookingData: updateRentingData,
        resetBookingData: resetRentingData
      }}
    >
      {children}
    </BookingDataContext.Provider>
  )
}

export function useBookingDataContext(): TBookingDataContext {
  return useContext(BookingDataContext)
}
