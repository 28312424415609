import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'
import { TSlideImageApi } from './TSlideImageApi'

type TResponseData = {
  results: TSlideImageApi[]
  count: number
}

export class SlideImagesApiService extends ApiService {
  async load(position: string): Promise<HttpResponse<TResponseData>> {
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/images-banners/?position=${position}`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
