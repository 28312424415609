import { resourcesList } from 'aplication/useCases/resourcesList/resourcesList'
import { objectIsEmpty } from 'aplication/utils/app/testValues'
import { TResource } from 'domain/entities/TResource'
import { useEffect, useState } from 'react'
import { TField } from 'view/components/formComponent'
import { useBookingDataContext } from 'view/contexts/bookingDataContext'

export type TUseResources = {
  resources: TResource[]
  resourcesTotal: number
  resourcesTotalDaily: number
  resourcesValue: number[]
  resourcesDetail: any[]
  countResources: () => { [key: string]: any }
  resourcesToRentingData: (fields: TField[]) => {
    values: number[]
    details: any[]
  }
  updateResourcesValue: (params: { values: number[]; details: any[] }) => void
  calcRentingViewResourcesTotal: (resourcesList?: any[] | null) => number
}

export function useResources(): TUseResources {
  const { bookingData } = useBookingDataContext()
  const [resources, setResources] = useState<TResource[]>([] as TResource[])
  const [resourcesTotal, setResourcesTotal] = useState<number>(0)
  const [resourcesTotalDaily, setResourcesTotalDaily] = useState<number>(0)
  const [resourcesValue, setResourcesValue] = useState<number[]>([])
  const [resourcesDetail, setResourcesDetail] = useState<any[]>([])

  function loadResources(): void {
    resourcesList().then(response => {
      if (response && response.results) {
        setResources(response.results)
      }
    })
  }

  function updateResourcesValue(params: { values: number[]; details: any[] }) {
    setResourcesValue(params.values)
    setResourcesDetail(params.details)
  }

  function countResources(): { [key: string]: any } {
    const values: { [key: string]: any } = {}
    resources.forEach(resource => {
      if (!bookingData.resources) return
      let counter = 0
      for (const r of bookingData.resources) {
        if (r === resource.id) {
          counter = counter + 1
        }
      }
      values[resource.id] = counter
    })

    return values
  }

  function resourcesToPost(resourceId: number, resourceTotal: number) {
    const resource = resources.find(rs => rs.id === resourceId)
    return {
      id: resource?.id,
      quantidade: resourceTotal
    }
  }

  function resourcesToRentingData(fields: TField[]): {
    values: number[]
    details: any[]
  } {
    const values: number[] = []
    const details: any[] = []
    fields.forEach(field => {
      for (let i = 0; i < field.value; i++) {
        values.push(parseInt(field.name))
      }
      if (!field.value || field.value <= 0) return
      details.push(resourcesToPost(parseInt(field.name), parseInt(field.value)))
    })
    return { values, details }
  }

  function calcResourcesTotal(): void {
    let total = 0
    const count = countResources()
    resources.forEach(resource => {
      if (count[resource.id]) {
        total = total + resource.price * count[resource.id]
      }
    })
    setResourcesTotal(total)
  }

  function calcTotalDaily() {
    if (!bookingData || !bookingData.daily) return
    setResourcesTotalDaily(resourcesTotal * bookingData.daily)
  }

  function calcRentingViewResourcesTotal(resourcesList?: any[] | null): number {
    let total = 0
    if (resourcesList) {
      resourcesList.forEach(r => {
        total += r.quantidade * r.valor
      })
    }
    return total
  }

  useEffect(() => {
    loadResources()
  }, [])

  useEffect(() => {
    if (!bookingData || objectIsEmpty(bookingData)) return
    calcResourcesTotal()
  }, [resourcesValue, bookingData])

  useEffect(() => {
    calcTotalDaily()
  }, [resourcesTotal])

  return {
    resources,
    resourcesTotal,
    resourcesTotalDaily,
    resourcesValue,
    resourcesDetail,
    countResources,
    resourcesToRentingData,
    updateResourcesValue,
    calcRentingViewResourcesTotal
  }
}
