import { TCarFeatureApi } from 'aplication/apiServices/carShopCars/carShopCarsApiServiceTypes'
import { objectIsEmpty } from 'aplication/utils/app/testValues'
import { TCarFeature } from './carFeaturesTypes'

export function carFeaturesDataTypeParser(
  apiData: TCarFeatureApi
): TCarFeature {
  if (!apiData || objectIsEmpty(apiData)) return {} as TCarFeature
  return {
    description: apiData.descricao,
    image: apiData.image,
    id: apiData.id,
    isDeleted: apiData.is_deleted,
    slug: apiData.slug,
    uuid: apiData.uuid
  }
}

export function carFeaturesDataTypeParserArray(
  apiData: TCarFeatureApi[]
): TCarFeature[] {
  return apiData.map(data => {
    return carFeaturesDataTypeParser(data)
  })
}
