import React from 'react'
import IconLoading from 'view/components/icons/iconLoading/iconLoading'
import './loadingContent.css'

type TProps = {
  height?: number
}

const LoadingContent: React.FC<TProps> = ({ height }) => {
  const heightString = `${height || 400}px`
  return (
    <div className="loading-content" style={{ height: heightString }}>
      <IconLoading size="big" />
    </div>
  )
}

export default LoadingContent
