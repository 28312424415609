import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import FaqPage from './faqPage'
import FaqSingle from './faqSingle/faqSingle'

const FaqRoutes: React.FC = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path} component={FaqPage} />
      <Route exact path={`${path}/:slug`} component={FaqSingle} />
    </Switch>
  )
}

export default FaqRoutes
