import { CityApiService } from 'aplication/apiServices/cityApiService/cityApiService'

export async function cityByEstateList(estateId?: string): Promise<any | null> {
  const dataService = new CityApiService()
  const response = await dataService.load(estateId)

  if (response.statusCode === 200) {
    return response.body
  }

  return null
}
