/* eslint-disable camelcase */
import { UserToken } from 'aplication/entities/UserToken/UserToken'
import { parseToAppClient } from 'aplication/useCases/clientFactory/clientFactory'
import { TApiUser } from 'domain/entities/api/TApiUser'
import { TClient } from 'domain/entities/TClient'

export class User {
  public token: UserToken = new UserToken('rd_utoken')
  readonly id: number = 0
  readonly is_superuser: boolean = false
  readonly username: string = ''
  readonly first_name: string = ''
  readonly email: string = ''
  readonly user_permissions: any[] = []
  readonly groups: any[] = []
  readonly person: TClient = {} as TClient
  isActivated = false

  constructor(responseUser?: TApiUser) {
    if (!responseUser || !responseUser.user) return
    this.id = responseUser.user.id
    this.is_superuser = responseUser.user.is_superuser
    this.username = responseUser.user.username
    this.first_name = responseUser.user.first_name
    this.email = responseUser.user.email
    this.user_permissions = responseUser.user.user_permissions
    this.groups = responseUser.user.groups
    this.person = parseToAppClient(responseUser.cliente)

    if (responseUser.access_token) {
      this.token = new UserToken('rd_utoken', responseUser.access_token)
    }
  }
}
