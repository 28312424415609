import { useCarGroupCombo } from 'aplication/useCases/carGroup/useCarGroupCombo'
import React, { useEffect } from 'react'
import CheckboxGroupField from 'view/components/formComponent/fields/checkboxGroupField/checkboxGroupField'

const BookingPjCarGroupsSelect: React.FC = () => {
  const carGroupCombo = useCarGroupCombo({ proposta_pj: '1' })
  useEffect(() => {
    carGroupCombo.loadData()
  }, [])
  return (
    <>
      <CheckboxGroupField
        name="groups"
        label="Selecione os grupos de veículos desejados:"
        options={carGroupCombo.data}
        required
      />
    </>
  )
}

export default BookingPjCarGroupsSelect
