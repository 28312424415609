import React, { useContext, useEffect, useState } from 'react'
import {
  FormComponent,
  SelectField,
  TField
} from 'view/components/formComponent'
import { CarShopContext } from '../carShopContext'
import { TCarShopFilters } from 'aplication/useCases/carShop/carShopTypes'
import './carShopFormFilter.css'
import Button from 'view/components/button/button'
import CarShopFormCleanFilter from './carShopFormCleanFilter'
import CarShopFormSelectFeatures from './carShopFormSelectFeatures'
import CarShopFormSelectColor from './carShopFormSelectFColor'
import CarShopFormSelectFuel from './carShopFormSelectFuel'
import CarShopFormSelectYears from './carShopFormSelectYears'

const CarShopFormFilter: React.FC = () => {
  const { filters, updateFilters } = useContext(CarShopContext)
  const [formValues, setFormValues] = useState({})

  function onFormChange(data: TField[]) {
    let formFilters: TCarShopFilters = {} as TCarShopFilters

    data.forEach(d => {
      const newFilter: { [key: string]: any } = {}
      newFilter[d.name] = d.value
      formFilters = { ...formFilters, ...newFilter }
    })

    updateFilters(formFilters)
  }

  useEffect(() => {
    if (Object.keys(filters).length <= 0) {
      setFormValues({})
    }
  }, [filters])

  return (
    <FormComponent
      onFormChange={onFormChange}
      fieldValues={formValues}
      className="car-shop-filters"
    >
      <CarShopFormSelectFeatures />
      <CarShopFormSelectFuel />
      <CarShopFormSelectColor />
      <CarShopFormSelectYears />
      <CarShopFormCleanFilter />
    </FormComponent>
  )
}

export default CarShopFormFilter
