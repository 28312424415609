import { priceConverter } from 'aplication/utils/app/valueConverter'
import React, { useEffect, useState } from 'react'
import Button from 'view/components/button/button'
import { TDialogRef } from 'view/components/dialog'
import { FormComponent } from 'view/components/formComponent'
import { useAuthContext } from 'view/contexts/authContext'
import { useBookingDataContext } from 'view/contexts/bookingDataContext'

type TProps = {
  onSubmit: (values: any) => void
  dialogRef: { current: TDialogRef }
}

const PaymentUserPj: React.FC<TProps> = ({ onSubmit, dialogRef }) => {
  const { bookingData } = useBookingDataContext()
  const { loggedUser } = useAuthContext()

  const [blocked, setBlocked] = useState<boolean>(false)
  function creditAfterRent() {
    const userCredit = loggedUser?.person.clientCreditNow
    if (!bookingData || !bookingData.totalToPay) return 0
    return priceConverter(userCredit - bookingData?.totalToPay)
  }

  function isBlocked() {
    const userCredit = loggedUser?.person.clientCreditNow
    const userCarLimit = loggedUser?.person.clientCarLimitNow
    if (!bookingData || !bookingData.totalToPay) {
      setBlocked(true)
      return
    }
    const balance = userCredit - bookingData.totalToPay
    const isBlocked = balance < 0 || userCarLimit <= 0
    setBlocked(isBlocked)
  }

  useEffect(() => {
    isBlocked()
  }, [])

  useEffect(() => {
    if (blocked) {
      dialogRef.current.changeMessage(
        'A Reserva não pode ser confirmada por motivo de crédito ou limite de veículos insuficientes. Entre em contato com a Realiza para mais informações.',
        'danger'
      )
    }
  }, [blocked])

  return (
    <>
      <div className="payment-card-form">
        <FormComponent onSubmit={onSubmit}>
          <div className="form-session">
            <h2>Cliente Pessoa Jurídica</h2>
            <span>
              <b>Crédito: </b>
              {priceConverter(loggedUser?.person.clientCreditNow)}
              <br />
              <b>Saldo após reserva: </b>
              {creditAfterRent()}
            </span>
          </div>
          <div className="form-session"></div>
          <div className="form-session">
            <Button type="submit" size="bg" disabled={blocked} full>
              Confirmar reserva
            </Button>
          </div>
        </FormComponent>
      </div>
    </>
  )
}

export default PaymentUserPj
