import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TDriver } from 'domain/entities/TRentingData'
import React from 'react'
import Button from 'view/components/button/button'
import './driver.css'

type TProps = {
  id?: string
  driver: TDriver
  removeDriver?: (index: string) => void
}

const Driver: React.FC<TProps> = ({ id, driver, removeDriver }) => {
  function onClick(event: any) {
    const index = event.target.closest('.driver-card').id
    if (removeDriver) removeDriver(index)
  }

  function renderRemoveButtom() {
    if (!removeDriver) return <></>
    return (
      <Button onClick={onClick} size="sm" className="bg-danger">
        <FontAwesomeIcon icon={['fas', 'times']} />
      </Button>
    )
  }

  return (
    <div id={`${id}`} className="driver-card">
      <span>
        <b>Nome:</b> {driver.name}
      </span>
      <span>
        <b>CNH:</b>
        {driver.cnh}
      </span>
      <span>
        <b>CNH Número de segurança:</b>
        {driver.cnhSecurityNumber}
      </span>
      {renderRemoveButtom()}
    </div>
  )
}

export default Driver
