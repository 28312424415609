import React from 'react'
import ErrorPageTemplate from 'view/template/errorPageTemplate/errorPageTemplate'

const Error401: React.FC = () => {
  return (
    <ErrorPageTemplate
      title={'Error 401'}
      message="Usuário não tem permissão para acessar a página"
    />
  )
}

export default Error401
