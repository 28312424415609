import React from 'react'
import { newShortcode } from 'aplication/useCases/shortCodes/shortCodesFunctions'
import './scIconBlocks.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar, faCog, faShieldAlt } from '@fortawesome/free-solid-svg-icons'

type TAttributes = {
  itens?: string
}

const ScIconBlocksComponent: React.FC<TAttributes> = ({ itens }) => {
  const icons: { [key: string]: any } = {
    shield: faShieldAlt,
    cog: faCog,
    car: faCar
  }

  function getIcon(icon: string) {
    if (!icon) return ''
    return icons[icon]
  }

  function renderItens() {
    const itensArray = itens?.split(' | ')
    return itensArray?.map((item: string, index: number) => {
      const i = item.split(',')
      const title = i[0].replace('title:', '').trim()
      const icon = i[1].replace('icon:', '').trim()
      return (
        <div key={index} className="icon-block">
          <div className="icon">
            <FontAwesomeIcon icon={getIcon(icon)} />
          </div>
          <h3>
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </h3>
        </div>
      )
    })
  }

  return <div className="icon-blocks">{renderItens()}</div>
}

export default newShortcode({
  scName: 'scIconBlocks',
  Component: ScIconBlocksComponent
})
