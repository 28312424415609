import appTexts from 'aplication/localData/appText/appText'
import { userEmailExists } from 'aplication/useCases/userEmailExists/userEmailExists'
import React, { useEffect } from 'react'
import { InputField, useFormContext } from 'view/components/formComponent'
import { isEmail } from 'view/components/formComponent/formComponentValidation/formComponentValidators'

type Props = {
  required?: boolean
  checkExists?: boolean
}

const InputEmailField: React.FC<Props> = ({ required }) => {
  const form = useFormContext()
  const { load, data } = userEmailExists()

  function onBlur() {
    const emailField = form.fields.find(field => field.name === 'email')
    load(emailField?.value)
  }

  function validate(exists: boolean) {
    const validadeValues = {
      name: 'email',
      isValid: true,
      validationMessage: ''
    }
    if (exists) {
      validadeValues.isValid = false
      validadeValues.validationMessage = appTexts.email_exists_validation_msg
    }
    form.updateField(validadeValues)
  }

  useEffect(() => {
    validate(data)
  }, [data])

  return (
    <InputField
      type="email"
      name="email"
      placeholder="E-mail"
      required={required}
      validators={[isEmail]}
      maxLength={200}
      autoComplete="off"
      onBlur={onBlur}
    />
  )
}

export default InputEmailField
