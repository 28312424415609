import { useState } from 'react'
import { CarStockApiService } from 'aplication/apiServices/carStockApiService/CarStockApiService'
import { carStockArrayParser } from './carStockDataParser'
import { ICarGroup } from 'domain/entities/ICarGroup'
import { useBookingDataContext } from 'view/contexts/bookingDataContext'

export type TCarStockList = {
  carGroups: ICarGroup[]
  loadMoreCarGroups: () => void
  totalGroups: number
  loaded: boolean
  loadData: () => void
}

export function carStockList(): TCarStockList {
  const { bookingData } = useBookingDataContext()
  const [carGroups, setCarGroups] = useState<ICarGroup[]>([] as ICarGroup[])
  const [totalGroups, setTotalGroups] = useState<number>(0)
  const [nextPage, setNextPage] = useState<number>(1)
  const [loaded, setLoaded] = useState<boolean>(false)
  const apiService = new CarStockApiService()

  function loadData() {
    if (
      !bookingData ||
      !bookingData.pickup ||
      !bookingData.pickup.location ||
      !bookingData.pickup.location.city
    ) {
      setLoaded(true)
      return
    }
    const cityId = bookingData.pickup?.location?.city
    setLoaded(false)
    apiService.load(cityId).then(response => {
      if (
        !response ||
        response.statusCode !== 200 ||
        !response.body ||
        !response.body.results
      ) {
        console.error('car_group_load_error')
        return
      }
      const dataArray = carStockArrayParser(response.body.results)
      setNextPage(2)
      setCarGroups(dataArray)
      setTotalGroups(response.body.count)
      setLoaded(true)
    })
  }

  function carGroupNextPage(page: number) {
    const cityId = bookingData.pickup?.location?.city
    if (!cityId) return
    apiService.load(cityId, page).then(response => {
      if (
        !response ||
        response.statusCode !== 200 ||
        !response.body ||
        !response.body.results
      ) {
        console.error('car_group_load_error')
        return
      }
      const dataArray = carStockArrayParser(response.body.results)
      setTimeout(() => {
        setCarGroups([...carGroups, ...dataArray])
      }, 500)
    })
  }

  function hasMoreCarGroups() {
    return carGroups.length >= totalGroups
  }

  function loadMoreCarGroups() {
    if (!hasMoreCarGroups) return
    carGroupNextPage(nextPage)
    setNextPage(nextPage + 1)
  }

  return { carGroups, loadMoreCarGroups, totalGroups, loaded, loadData }
}
