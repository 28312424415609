import React from 'react'
import { Link } from 'react-router-dom'
import './mainFooterLastMenu.css'

const MainFooterLastMenu: React.FC = () => {
  return (
    <>
      <ul className="main-footer-last-menu">
        <li>
          <Link to="/terceirizacao-de-frota">Terceirização de frota</Link>
        </li>
        <li>
          <Link to="/aluguel-de-carros">Aluguel de carros</Link>
        </li>
        <li>
          <Link to="/seminovos">Seminovos</Link>
        </li>
        <li>
          <Link to="/quem-somos#aboutus-services">Serviços</Link>
        </li>
      </ul>

      <ul className="links">
        <li>
          <Link to="/codigo-de-etica">Código de ética e conduta</Link>
        </li>
        <li>
          <Link to="/politica-de-privacidade">Política de privacidade</Link>
        </li>
        <li>
          <Link to="/contrato-de-locacao">Contrato de locação</Link>
        </li>
      </ul>
    </>
  )
}

export default MainFooterLastMenu
