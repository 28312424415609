import React from 'react'
import './contactPhone.css'
import { useAppContext } from 'view/contexts/appContext/appContext'

const ContactPhone: React.FC = () => {
  const { organizationInfo } = useAppContext()

  return (
    <div className="contact-phone font-emphasis medium">
      {organizationInfo?.phone}
    </div>
  )
}

export default ContactPhone
