import React, { createContext, useContext, useEffect, useState } from 'react'
import { TField, TFormComponentContext } from './formComponentsTypes'
import { validateAllFields } from './formComponentValidation/formComponentValidation'
import './formComponent.css'

const FormComponentContext = createContext<TFormComponentContext>(
  {} as TFormComponentContext
)

type Props = {
  className?: string
  fieldValues?: { [key: string]: any }
  onSubmit?: (values: any, resetFields: () => void) => void
  onFormChange?: (fields: TField[]) => void
}

const FormComponent: React.FC<Props> = ({
  children,
  className,
  fieldValues,
  onSubmit,
  onFormChange
}) => {
  const [isRegister, setIsRegister] = useState<boolean>(true)
  const [isDataBind, setIsDataBind] = useState<boolean>(true)
  const [values, setValues] = useState<any>({})
  const [fields, setFields] = useState<TField[]>([] as TField[])

  function updateField(field: TField, bind?: boolean): void {
    setIsRegister(false)
    setIsDataBind(bind || false)
    setFields(oldFields => {
      const findField = oldFields.find(f => f.name === field.name)
      if (!findField) {
        return oldFields
      } else {
        oldFields = oldFields.map(f => {
          if (findField.name === f.name) return { ...f, ...field }
          return f
        })
      }
      return oldFields
    })
  }

  function registerField(field: TField) {
    setIsRegister(true)
    field = { ...field, value: '', isValid: true }
    setFields(oldFields => {
      const findField = oldFields.find(f => f.name === field.name)
      if (!findField) {
        oldFields.push(field)
      }
      return oldFields
    })
  }

  function updateValues() {
    const newValues: { [key: string]: any } = {}
    fields.forEach(field => {
      newValues[field.name] = field.value
    })
    setValues(newValues)
  }

  function resetFields(notThis?: string[]) {
    fields.forEach(field => {
      if (notThis?.includes(field.name)) return
      updateField({ ...field, value: '', isValid: true })
    })
  }

  function resetReturnDataFields() {
    fields.forEach(field => {
      if (!['returnDate', 'returnTime'].includes(field.name)) return
      updateField({ ...field, value: '', isValid: true })
    })
  }

  function buildRandleSubmit() {
    return (e: any) => {
      e.preventDefault()
      if (validateAllFields(fields, updateField)) {
        if (onSubmit) onSubmit(values, resetFields)
      }
    }
  }

  useEffect(() => {
    updateValues()
    if (onFormChange && !isRegister) {
      onFormChange(fields)
    }
  }, [fields])

  useEffect(() => {
    if (fieldValues && Object.keys(fieldValues).length > 0) {
      fields.forEach(field => {
        const name = field.name
        const value = fieldValues[name]
        if (!value || value === '') return
        updateField({ name, value }, true)
      })
    }
  }, [fieldValues])

  return (
    <FormComponentContext.Provider
      value={{
        fields,
        isRegister,
        isDataBind,
        updateField,
        registerField,
        resetFields,
        resetReturnDataFields
      }}
    >
      <form onSubmit={buildRandleSubmit()} className={className}>
        {children}
      </form>
    </FormComponentContext.Provider>
  )
}

export default FormComponent

export function useFormContext(): TFormComponentContext {
  return useContext(FormComponentContext)
}
