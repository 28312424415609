import React, { useEffect, useState } from 'react'
import { useAuthContext } from 'view/contexts/authContext'
import { TBookingCardData } from 'domain/entities/TBooking'
import BookingPaymentFormForm from './bookingPaymentFormForm'
import { useCredCards } from 'aplication/useCases/credCards/useCredCards'
import BookingPaymentFormFormSelectCard from './bookingPaymentFormFormSelectCard'
import './bookingPaymentForm.css'

type TProps = {
  postAction: (values: any) => void
}

const BookingPaymentFormSimple: React.FC<TProps> = ({ postAction }) => {
  const { loggedUser } = useAuthContext()
  const { credCards, loadCards } = useCredCards()
  const [showForm, setShowForm] = useState<boolean>(false)

  function onSubmitPayment(values: TBookingCardData) {
    postAction(values)
  }

  function hasCredCards() {
    return !(!credCards || credCards.length <= 0)
  }

  function selectCardOrAddNew() {
    if (showForm || !credCards || credCards.length <= 0) {
      return (
        <BookingPaymentFormForm
          onSubmit={onSubmitPayment}
          newCardBtnAction={() => setShowForm(false)}
          hasCard={hasCredCards()}
        />
      )
    }
    return (
      <>
        <BookingPaymentFormFormSelectCard
          onSubmit={onSubmitPayment}
          credCards={credCards}
          newCardBtnAction={() => setShowForm(true)}
        />
      </>
    )
  }

  useEffect(() => {
    if (!loggedUser || !loggedUser.person) return
    loadCards()
  }, [loggedUser])

  return <div className="payment-card-form">{selectCardOrAddNew()}</div>
}

export default BookingPaymentFormSimple
