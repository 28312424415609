import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import FaqItem from './faqItem'
import { useQuery } from 'aplication/utils/app/useQuery'
import LoadingContent from 'view/componentsPages/loadingContent/loadingContent'
import LoadMoreResults from 'view/componentsPages/loadMoreResults/loadMoreResults'
import './faq.css'
import { useAppContext } from 'view/contexts/appContext/appContext'

type Props = {
  limitItens?: number
  search?: string
  totalCols?: number
}

const Faq: React.FC<Props> = ({ limitItens, search, totalCols }) => {
  const query = useQuery()
  const { faqList, loaded, totalFaq, loadMoreFaqList, setQuery } =
    useAppContext().faq
  const totalColumns = totalCols || 2

  useEffect(() => {
    if (!search && search !== '') return
    setQuery(search)
  }, [search])

  function loadItens(colNumber: number) {
    if (!faqList || !faqList.length) return false
    const totalItensColumn = Math.round(faqList.length / totalColumns)
    const lastIndex = totalItensColumn * colNumber
    const firstIndex = lastIndex - totalItensColumn + 1

    const columnItens = faqList.filter((i, index) => {
      const itemNumber = index + 1
      if (itemNumber >= firstIndex && itemNumber <= lastIndex) {
        return i
      }
      return null
    })

    return columnItens.map((item, index) => {
      const count = index + 1
      if (limitItens && count > limitItens) return ''

      return (
        <FaqItem
          key={index}
          id={`${item.id}`}
          title={item.question}
          content={item.answer}
          open={query.get('item') === `${item.id}`}
        />
      )
    })
  }

  function renderItens() {
    const cols = Array.from(Array(totalColumns).keys())
    return cols.map(index => {
      const col = index + 1
      const itens = loadItens(col)
      if (itens && itens.length <= 0) return ''
      return (
        <div key={col} className="faq-list-col">
          {loadItens(col)}
        </div>
      )
    })
  }

  function renderLoadMoreBtn() {
    if (limitItens) return <></>
    return (
      <LoadMoreResults
        data={faqList}
        totalData={totalFaq}
        loadMoreAction={loadMoreFaqList}
      />
    )
  }

  function loadColItens() {
    if (loaded) {
      return (
        <>
          {renderItens()}
          {renderLoadMoreBtn()}
        </>
      )
    } else {
      return <LoadingContent height={200} />
    }
  }

  function renderLinkToPage() {
    if (!limitItens) return
    return (
      <div className="faq-button">
        <Link to="/faq">Veja mais</Link>
      </div>
    )
  }

  return (
    <div className="faq">
      <div className="faq-list">{loadColItens()}</div>
      {renderLinkToPage()}
    </div>
  )
}

export default Faq
