import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormComponent } from '../../components/formComponent'
import PickupLocationField from './bookingFormFields/pickupLocationField'
import { TField } from 'view/components/formComponent/formComponentsTypes'
import ReturnLocationField from './bookingFormFields/returnLocationField'
import { bookingFormRentingData } from './bookingFormRentingData'
import BookingDateReturnField from './bookingFormFields/bookingDateReturnField'
import BookingDatePickupField from './bookingFormFields/bookingDatePickupField'
import BookingTimePickupField from './bookingFormFields/bookingTimePickupField'
import BookingTimeReturnField from './bookingFormFields/bookingTimeReturnField'
import BookingButtonSubmit from './bookingFormFields/bookingButtonSubmit'
import BookingToggleReturnLocation from './bookingFormFields/bookingToggleReturnLocation'
import BookingFormProvider from './bookingFormProvider'
import BookingFormAlerts from './bookingFormAlerts/bookingFormAlerts'
import './bookingForm.css'

type Props = {
  onConfirm?: () => void
  title?: string
}

const BookingForm: React.FC<Props> = ({ onConfirm, title }) => {
  const history = useHistory()
  const [showReturnLocation, setShowReturnLocation] = useState<boolean>(false)
  const [formFields, setFormFields] = useState<TField[]>([] as TField[])
  const { buildRentingData, fieldValues } = bookingFormRentingData()
  function onSubmit() {
    if (onConfirm) onConfirm()
    buildRentingData(formFields)
    history.push('/locacao/grupos')
  }

  function toggleReturnLocation(checked: boolean) {
    setShowReturnLocation(checked)
  }

  function returnLocationClass() {
    return showReturnLocation ? 'has-return-location' : ''
  }

  function onFormChange(fields: TField[]) {
    setFormFields(fields)
  }

  useEffect(() => {
    setShowReturnLocation(fieldValues.hasReturnLocation || false)
  }, [fieldValues])

  return (
    <BookingFormProvider>
      <div id="locacao" className={`booking-form ${returnLocationClass()}`}>
        <h2 className="booking-form-title">{title || 'Alugue um carro'}</h2>
        <FormComponent
          fieldValues={fieldValues}
          onFormChange={onFormChange}
          onSubmit={onSubmit}
        >
          <BookingFormAlerts />

          <div className="form-session form-session-pickup">
            <h3 className="booking-form-subtitle">Retirada</h3>
            <PickupLocationField />
            <BookingDatePickupField />
            <BookingTimePickupField />
          </div>

          <div className="form-session form-session-return">
            <h3 className="booking-form-subtitle">Devolução</h3>
            <ReturnLocationField showReturnLocation={showReturnLocation} />
            <BookingDateReturnField />
            <BookingTimeReturnField />
            <BookingButtonSubmit />
          </div>
          <div className="form-session form-session-has-return-location">
            <BookingToggleReturnLocation onChange={toggleReturnLocation} />
          </div>
        </FormComponent>
      </div>
    </BookingFormProvider>
  )
}

export default BookingForm
