import React, { useEffect, useState } from 'react'
import { useBookingCalc } from 'aplication/useCases/booking/useBookingCalc'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Checkout } from 'view/componentsPages/checkout'
import { useBookingDataContext } from 'view/contexts/bookingDataContext'

const DashboardBookingEditCheckout: React.FC = () => {
  const { bookingData } = useBookingDataContext()
  const [checkoutDisabled, setcheckoutDisabled] = useState<boolean>(true)
  const history = useHistory()
  const { path, url } = useRouteMatch()

  useBookingCalc()

  function validateBookingData() {
    if (
      !bookingData ||
      !bookingData ||
      !bookingData.pickup ||
      !bookingData.pickup.date ||
      !bookingData.return ||
      !bookingData.return.date ||
      !bookingData.carGroup ||
      !bookingData.carGroup.price ||
      !bookingData.daily
    ) {
      return false
    }
    return true
  }

  function checkoutConfirm() {
    history.push(`${url}/pagar`)
  }

  useEffect(() => {
    if (validateBookingData()) {
      setcheckoutDisabled(false)
    } else {
      setcheckoutDisabled(true)
    }
  }, [bookingData])

  return (
    <Checkout
      bookingData={bookingData}
      onConfirm={checkoutConfirm}
      disabled={checkoutDisabled}
    />
  )
}

export default DashboardBookingEditCheckout
