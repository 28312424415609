import { useState } from 'react'
import { BookingApiSevice } from 'aplication/apiServices/bookingApiService/bookingApiService'
import { TBooking } from 'domain/entities/TBooking'
import { bookingDataParser } from './bookingDataParser'

export type TUseBooking = {
  booking: TBooking
  loaded: boolean
  readBooking: (clientId: string, bookingId: string) => void
}

export function useBooking(): TUseBooking {
  const [booking, setBooking] = useState<TBooking>({} as TBooking)
  const [loaded, setLoaded] = useState<boolean>(false)

  function readBooking(clientId: string, bookingId: string) {
    setLoaded(false)
    const apiService = new BookingApiSevice()
    apiService.read(clientId, bookingId).then(response => {
      if (
        !response ||
        response.statusCode !== 200 ||
        !response.body ||
        !response.body.results
      ) {
        return
      }
      const bookingParsed = bookingDataParser(response.body.results[0])
      setBooking(bookingParsed)
      setLoaded(true)
    })
  }

  return { booking, loaded, readBooking }
}
