import { CreateRentingDataService } from 'aplication/apiServices/createRentingDataService/createRentingDataService'
import { HttpResponse } from 'aplication/apiServices/protocols/IHttpClient'
import { TBookingCardData } from 'domain/entities/TBooking'
import { TRentingData } from 'domain/entities/TRentingData'

export async function createRenting(
  rentingData: TRentingData,
  cardData: TBookingCardData | null
): Promise<HttpResponse> {
  const dataService = new CreateRentingDataService()
  return await dataService.load({ rentingData, cardData })
}
