import React from 'react'
import Button from 'view/components/button/button'
import { ContentMessage } from '../contentMessage'

type Props = {
  title: string
  text: string
  buttonText: string
  buttonOnClick: (event: any) => void
}

const InvalidRentingDataMessage: React.FC<Props> = props => {
  const { title, text, buttonText, buttonOnClick } = props
  return (
    <ContentMessage
      title={title}
      buttons={
        <Button style="orange" onClick={buttonOnClick}>
          {buttonText}
        </Button>
      }
    >
      <p>{text}</p>
    </ContentMessage>
  )
}

export default InvalidRentingDataMessage
