import { useOrganizationServices } from 'aplication/useCases/organizationServices/organizationServices'
import { TOrganizationService } from 'aplication/useCases/organizationServices/organizationServicesTypes'
import React, { useEffect, useState } from 'react'
import { useAppContext } from 'view/contexts/appContext/appContext'
import { useShortcodes } from 'view/hooksPages/useShortCodes'
import './carShopContent.css'

const CarShopContent: React.FC = () => {
  const shortCodes = useShortcodes()
  const { pageInfos } = useAppContext()
  const { services, filterData } = useOrganizationServices()
  const [service, setService] = useState<TOrganizationService>(
    {} as TOrganizationService
  )

  useEffect(() => {
    setService(filterData('seminovos'))
  }, [services])

  useEffect(() => {
    if (!service) return
    pageInfos.update({
      pageTitle: service?.title,
      pageDescription: service?.brief
    })
  }, [service])

  return (
    <>
      <div
        className="car-shop-content"
        dangerouslySetInnerHTML={{ __html: shortCodes.run(service?.content) }}
      />
    </>
  )
}

export default CarShopContent
