import { useBlogPosts } from 'aplication/useCases/blogPosts/useBlogPosts'
import React, { useEffect } from 'react'
import CarouselHeader from 'view/components/carousel/carouselHeader'
import PostCard from '../blogPost/postCard/postCard'
import LoadingContent from '../loadingContent/loadingContent'
import './carouselConteudo.css'
import {
  Carousel,
  CarouselDotsNavigator,
  CarouselItens
} from 'view/components/carousel'

type TProps = {
  title?: string
  link?: string
  textLink?: string
}

const CarouselConteudo: React.FC<TProps> = ({ title, link, textLink }) => {
  const { posts, loading, loadPosts } = useBlogPosts()
  function renderCarousel(post: any, index: number): any {
    return (
      <Carousel.item id={`carousel-item-${index}`} key={index}>
        <PostCard {...{ post }} key={index} />
      </Carousel.item>
    )
  }

  function renderPosts(): any {
    return posts.map((post, index) => {
      if (index >= 4) return ''
      return renderCarousel(post, index)
    })
  }

  function renderContent() {
    if (!posts || posts.length <= 0) return <></>
    if (loading) return <LoadingContent />
    return (
      <div className="conteudo-list-list">
        <div className="container">
          <Carousel>
            <CarouselHeader
              title={title || 'Conteúdo'}
              link={link}
              linkText={textLink}
            />
            <CarouselItens>{renderPosts()}</CarouselItens>
            <CarouselDotsNavigator orange />
          </Carousel>
        </div>
      </div>
    )
  }

  useEffect(() => {
    loadPosts()
  }, [])

  return <>{renderContent()}</>
}

export default CarouselConteudo
