import { TCarShopCar } from 'aplication/useCases/carShop/carShopTypes'
import React from 'react'
import './carDescriptions.css'

type TProps = {
  car: TCarShopCar
}

const CarDescriptions: React.FC<TProps> = ({ car }) => {
  const itens = [
    { img: '/media/icons/icon_car.png', value: car?.maker.name }, // fabricante
    { img: '/media/icons/icon_car.png', value: car?.model }, // Modelo
    { img: '/media/icons/icon_date.png', value: car?.year }, // ano
    { img: '/media/icons/icon_km.png', value: car?.km }, // km
    { img: '/media/icons/icon_color.png', value: car?.color }, // cor
    { img: '/media/icons/icon_fuel.png', value: car?.fuel }, // combustivel
    { img: '/media/icons/icon_door.png', value: car?.doors } // portas
  ]

  function renderItens() {
    return itens.map((iten, index) => {
      if (!iten.value) return <></>
      return (
        <li key={index}>
          <span className="icon">
            <img src={iten.img} />
          </span>
          {iten.value}
        </li>
      )
    })
  }

  return <ul className="car-content-description">{renderItens()}</ul>
}

export default CarDescriptions
