import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TOverride } from 'aplication/utils/app/appTypes'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { TFieldElement, TValidator } from '../../formComponentsTypes'
import FieldProvider, { FieldContext } from '../fieldContext'
import { requiredCheckbox } from './checkboxFieldValidator'
import './checkboxField.css'

type TProps = TOverride<
  TFieldElement,
  {
    label: string | JSX.Element
    labelLink?: boolean
    checked?: boolean
    onChange?: (value: boolean) => void
  }
>

const CheckboxComponent: React.FC = () => {
  const { updateField, fieldProps, stateValue } = useContext(FieldContext)
  const { label, labelLink, name, checked, disabled, onChange } = fieldProps
  const inputCheckoutRef = useRef<any>()
  const [isChecked, setIsChecked] = useState<boolean>(checked)

  function updateValue() {
    const value = !isChecked
    if (onChange) onChange(value)
    setIsChecked(value)
    updateField({ name, value })
  }

  function onClickLabel(e: any) {
    e.preventDefault()
    if (disabled) return
    updateValue()
  }

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  useEffect(() => {
    setIsChecked(stateValue)
  }, [stateValue])

  return (
    <div className={`field-checkbox ${disabled ? 'disabled' : ''}`}>
      <div
        className={`checkbox-styled${isChecked ? ' checkbox-checked' : ''}`}
        onClick={onClickLabel}
      >
        <FontAwesomeIcon
          className="checkbox-checked-icon"
          icon={['fas', 'check']}
        />
      </div>
      <input
        className="checkbox-input"
        type="checkbox"
        name={name}
        // checked={checked}
        defaultChecked={checked}
        disabled={disabled} // <------- ARRUMAR ESSE
        ref={inputCheckoutRef}
      />
      <span
        className={`checkbox-label ${labelLink ? 'labelLink' : ''}`}
        onClick={(e: any) => {
          if (labelLink) return
          onClickLabel(e)
        }}
      >
        {label}
      </span>
    </div>
  )
}

const CheckboxField: React.FC<TProps> = props => {
  function injectValidator(validators?: TValidator[]) {
    return !validators ? [requiredCheckbox] : [...validators, requiredCheckbox]
  }

  const fieldProps = {
    ...props,
    validators: injectValidator(props.validators)
  }

  return (
    <FieldProvider fieldProps={fieldProps}>
      <CheckboxComponent />
    </FieldProvider>
  )
}

export default CheckboxField
