import shortCodes from 'view/shortCodes'

type TReturn = {
  run: (content: string) => string
}

export function useShortcodes(): TReturn {
  function execShortcodes(sCodeString: string, content: string) {
    shortCodes.forEach(sc => {
      content = sc(sCodeString, content)
    })
    return content
  }

  function run(content: string): string {
    if (!content) return content
    const shortCodesFound = content.match(/\[(.*?)(.*?)]/g)
    if (!shortCodesFound) return content
    shortCodesFound.forEach(sCode => {
      content = execShortcodes(sCode, content)
    })
    return content
  }

  return { run }
}
