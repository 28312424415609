import React from 'react'
import { TResource } from 'domain/entities/TResource'
import RentInfo from '../../pages/rentingPage/booking/rentInfo/rentInfo'
import { priceConverter } from 'aplication/utils/app/valueConverter'
import { InputField } from 'view/components/formComponent'
import './resource.css'

type Props = {
  resource: TResource
  canEdit?: boolean
}

const Resource: React.FC<Props> = ({ resource, canEdit }) => {
  function renderInput() {
    if (!canEdit) return
    return (
      <InputField
        type="number"
        name={`${resource.id}`}
        placeholder="0"
        min={0}
        max={resource.available}
      />
    )
  }

  function renderQuantity() {
    if (canEdit) return
    return <div className="quantity">quantidade: {resource.quantity}</div>
  }

  return (
    <RentInfo.item>
      <RentInfo.content>
        <div className="additional">
          <div className="info">
            <h3>{resource.title}</h3>
            <p>{resource.description}</p>
          </div>
          <div className="values">
            {renderQuantity()}
            <div className="price">
              {priceConverter(resource.price)} <span>/DIÁRIA</span>
            </div>
            {renderInput()}
          </div>
        </div>
      </RentInfo.content>
    </RentInfo.item>
  )
}

export default Resource
