import React from 'react'
import { InputField } from 'view/components/formComponent'
import CnhSecuritynumberField from 'view/components/formComponent/fields/cnhSecuritynumberField/cnhSecuritynumberField'
import InputCnhField from 'view/componentsPages/customFields/inputCnhField/inputCnhField'
import SelectCityField from 'view/componentsPages/customFields/selectCityField/selectCityField'

const AccountEditPersonPFFields: React.FC = () => {
  return (
    <div className="form-session">
      <InputField
        name="documento"
        placeholder="Documento"
        disabled
        title="Documento"
      />
      <InputField name="name" placeholder="Nome" maxLength={200} title="Nome" />
      <InputField name="email" placeholder="Email" title="Email" />
      <InputField name="cep" placeholder="CEP" title="CEP" />
      <InputField name="address" placeholder="Endereço" title="Endereço" />
      <InputField name="addressDistrict" placeholder="Bairro" title="Bairro" />
      <InputField
        name="complement"
        placeholder="Complemento"
        title="Complemento"
      />
      <InputField name="phone" placeholder="Telefone" title="Telefone" />
      <SelectCityField />
      <InputCnhField />
      <CnhSecuritynumberField />
      <InputField type="hidden" name="uuid" placeholder="" />
    </div>
  )
}

export default AccountEditPersonPFFields
