import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'
import { TBookingApiLocal } from 'domain/entities/api/TBookingApi'

type TResponseData = {
  results: TBookingApiLocal[]
}

export class LocationsApiService extends ApiService {
  async load(): Promise<HttpResponse<TResponseData>> {
    return this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/locais-retirada-devolucao/`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
