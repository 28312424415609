import { TField } from '../formComponentsTypes'
import { requiredValidator } from './formComponentValidators'

type TVaidationREsult = { result: boolean; message: string }

function runValidators(field: TField, fields: TField[]): TVaidationREsult {
  let validators = [requiredValidator]
  if (field && field.validators) {
    validators = [...validators, ...field.validators]
  }
  for (const validator of validators) {
    if (!validator.run(field, fields)) {
      return { result: false, message: validator.message }
    }
  }
  return { result: true, message: '' }
}

export function validateAllFields(
  fields: TField[],
  updateField: (field: TField) => void
): boolean {
  let allValid = true
  if (fields) {
    fields.forEach(field => {
      if (field.isRequired) {
        const { name } = field
        const validation = runValidators(field, fields)
        const isValid = validation.result
        const validationMessage = validation.message
        if (!isValid) allValid = false
        updateField({ name, isValid, validationMessage })
      }
    })
  }

  return allValid
}

export function hasInvalidField(fields: TField[]): boolean {
  let hasInvalid = false
  if (fields) {
    fields.forEach(field => {
      if (!field.isValid) hasInvalid = true
    })
  }
  return hasInvalid
}
