import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'

export class AboutUsDataService extends ApiService {
  async load(): Promise<HttpResponse> {
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/quem-somos/`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
