import React from 'react'
import { CheckboxField, useFormContext } from 'view/components/formComponent'

type TProps = {
  onChange: (checked: boolean) => void
}

const BookingToggleReturnLocation: React.FC<TProps> = ({ onChange }) => {
  const { resetReturnDataFields } = useFormContext()

  function handleChange(checked: boolean) {
    if (onChange) onChange(checked)
    resetReturnDataFields()
  }

  return (
    <CheckboxField
      name="hasReturnLocation"
      label="Outro local de devolução"
      onChange={handleChange}
    />
  )
}

export default BookingToggleReturnLocation
