import { TCityApi } from 'domain/entities/api/TCityApi'
import { TCity } from 'domain/entities/TCity'

export function cityDataParser(data: TCityApi): TCity {
  if (!data) return {} as TCity
  return {
    description: data.descricao,
    ibgeCode: data.codigo_ibge,
    id: data.id,
    uf: data.estado
  }
}

export function cityArrayParser(data: TCityApi[]): TCity[] {
  if (!data || data.length <= 0) return [] as TCity[]
  return data.map(city => cityDataParser(city))
}
