import React from 'react'
import { Col, Row } from 'antd'
import './pageTitle.css'

const PageTitle: React.FC = ({ children }) => {
  return (
    <Row>
      <Col span={24}>
        <div className="page-title">
          <h2>{children}</h2>
        </div>
      </Col>
    </Row>
  )
}

export default PageTitle
