import React, { useContext } from 'react'
import { userActivation } from 'aplication/useCases/userActivation/userActivation'
import { FormComponent, InputField } from 'view/components/formComponent'
import { ModalUserActivationContext } from '../modalUserActivation'
import { useAuthContext } from 'view/contexts/authContext'

type Props = {
  onSuccess?: () => void
}

const UserActivationForm: React.FC<Props> = ({ onSuccess }) => {
  const { dialog, modal } = useContext(ModalUserActivationContext)
  const { userUuid } = useAuthContext()
  const clientUuid = userUuid

  function onChange(value: string) {
    if (value.length === 6) {
      dialog.use.loadMessage('Enviando o código...')

      userActivation({
        clientUuid,
        activationCode: value
      }).then(result => {
        if (result) {
          dialog.use.changeMessage('Ativação realizada com sucesso.', 'success')
          setTimeout(() => {
            modal.use.toggleOpen()
            if (onSuccess) onSuccess()
          }, 1500)
        } else {
          setTimeout(() => {
            dialog.use.changeMessage(
              'Não foi possível realizar a ativação.',
              'danger'
            )
          }, 1000)
        }
      })
    }
  }

  return (
    <>
      <FormComponent>
        <InputField name="activation_code" maxLength={6} onChange={onChange} />
      </FormComponent>
    </>
  )
}

export default UserActivationForm
