/* eslint-disable camelcase */
import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'
import { TUserPasswordPostApi } from './TUserPasswordPostApi'

export class UserUpdatePassword extends ApiService {
  async load(body: TUserPasswordPostApi): Promise<HttpResponse> {
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/update-user-password/`,
      method: 'post',
      body,
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
