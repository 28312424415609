import React from 'react'
import { useParams } from 'react-router-dom'
import Button from 'view/components/button/button'
import { SuccessPageTemplate } from 'view/template/successPageTemplate'

const DashboardBookingEditPaymentSuccess: React.FC = () => {
  const params: { id: string } = useParams()

  return (
    <SuccessPageTemplate
      className="booking-done"
      title="Renovação de reserva concluída com sucesso!"
      buttons={
        <>
          <Button style="transparent" link={`/dashboard/reserva/${params.id}`}>
            Ver detalhes da reserva
          </Button>
          <Button style="orange" link="/dashboard">
            Ver minhas reservas
          </Button>
          <Button style="link" link="/">
            Ir para página inicial
          </Button>
        </>
      }
    />
  )
}

export default DashboardBookingEditPaymentSuccess
