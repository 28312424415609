/* eslint-disable camelcase */
import { RecoveryAccountPassword } from 'aplication/apiServices/recoveryAccountPassword/recoveryAccountPassword'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from 'view/components/button/button'
import { Dialog, TDialogRef } from 'view/components/dialog'
import { FormComponent, InputField } from 'view/components/formComponent'
import PasswordField from 'view/components/formComponent/fields/passwordField/passwordField'
import {
  confirmPassword,
  isSecurePassword
} from 'view/components/formComponent/formComponentValidation/formComponentValidators'
import TitlePage from 'view/components/text/titlePage/titlePage'
import { useAuthContext } from 'view/contexts/authContext'

const RequestAccountPasswordForm: React.FC = () => {
  const dialogRef = useRef<TDialogRef>({} as TDialogRef)
  const history = useHistory()
  const { userUuid } = useAuthContext()
  const [formValues, setFormValues] = useState<any>({})
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false)

  function errorAction(response: any) {
    dialogRef.current?.changeMessage(response.body.msg, 'danger')
  }

  function successAction(response: any) {
    dialogRef.current?.changeMessage(response.body.msg, undefined, true)
    setTimeout(() => {
      history.replace('/login')
    }, 1000)
  }

  function onSubmit(value: any) {
    setSubmitDisabled(true)
    const { cliente_uuid, password } = value
    dialogRef.current?.loadMessage('Enviando dados...')
    const apiService = new RecoveryAccountPassword()
    apiService.load({ cliente_uuid, password }).then(response => {
      if (!response) return
      setTimeout(() => {
        if (response.statusCode !== 200) {
          errorAction(response)
          setSubmitDisabled(false)
        } else {
          successAction(response)
        }
      }, 500)
    })
  }

  useEffect(() => {
    if (!userUuid) {
      history.replace('/login')
    }
    setFormValues({ cliente_uuid: userUuid })
  }, [])

  return (
    <>
      <Dialog ref={dialogRef} />
      <TitlePage>Nova Senha</TitlePage>
      <FormComponent onSubmit={onSubmit} fieldValues={formValues}>
        <span>Adicione abaixo a nova senha para sua conta.</span>
        <div className="form-session">
          <PasswordField
            name="password"
            placeholder="Senha"
            validators={[isSecurePassword]}
            required
          />
          <PasswordField
            name="passwordConfirm"
            placeholder="Confirme a Senha"
            validators={[confirmPassword]}
            required
          />
          <InputField name="cliente_uuid" type="hidden" maxLength={100} />
        </div>
        <Button type="submit" full disabled={submitDisabled}>
          Enviar
        </Button>
      </FormComponent>
    </>
  )
}

export default RequestAccountPasswordForm
