import React, { useEffect, useRef } from 'react'
import appTexts from 'aplication/localData/appText/appText'
import { bookingRetryPayment } from 'aplication/useCases/booking/bookingRetryPayment'
import { useBooking } from 'aplication/useCases/booking/useBooking'
import { objectIsEmpty } from 'aplication/utils/app/testValues'
import { useHistory, useParams } from 'react-router-dom'
import { Dialog, TDialogRef } from 'view/components/dialog'
import BookingPaymentPage from 'view/componentsPages/bookingPaymentPage/bookingPaymentPage'
import { useAuthContext } from 'view/contexts/authContext'
import { useBookingDataContext } from 'view/contexts/bookingDataContext'
import BookingPaymentFormSimple from 'view/componentsPages/bookingPaymentForm/bookingPaymentFormSimple'
import { usePageLoader } from 'view/hooksPages'
import LoadingContent from 'view/componentsPages/loadingContent/loadingContent'

const DashBoardBookingPayment: React.FC = () => {
  const history = useHistory()
  const params: any = useParams()

  const { loaded } = usePageLoader()
  const { loggedUser } = useAuthContext()
  const { booking, readBooking } = useBooking()
  const { updateBookingData } = useBookingDataContext()

  const dialogRef = useRef<TDialogRef>({} as TDialogRef)

  function startBookingData() {
    if (!booking || objectIsEmpty(booking)) return
    if (booking.status !== 'pagamento_nao_autorizado') {
      history.push('/dashboard')
    }
    updateBookingData({ ...booking })
  }

  function postAction(values: any) {
    if (!booking || !booking.numberId) return
    bookingRetryPayment({
      card: values,
      bookingId: booking.numberId
    }).then(response => {
      if (response.statusCode === 200) {
        const path = history.location.pathname
        history.push(`${path}/success`)
      } else {
        const message = appTexts[response.body?.detail]
        const defaultMsg = appTexts.default_reserva_post_error
        dialogRef.current.changeMessage(message || defaultMsg, 'danger')
      }
    })
  }

  useEffect(() => {
    readBooking(loggedUser?.person.uuid, params.id)
  }, [loggedUser])

  useEffect(() => {
    startBookingData()
  }, [booking])

  function renderContent() {
    if (!loaded) {
      return (
        <div className="container">
          <LoadingContent />
        </div>
      )
    } else {
      return (
        <BookingPaymentPage title="Pagamento de reserva">
          <Dialog ref={dialogRef} />
          <BookingPaymentFormSimple postAction={postAction} />
        </BookingPaymentPage>
      )
    }
  }

  return <>{renderContent()}</>
}

export default DashBoardBookingPayment
