import React from 'react'

const AboutusMap: React.FC = () => {
  return (
    <div id="aboutus-map" className="aboutus-map">
      <div className="container">
        <h2>Localização</h2>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1944.015180869452!2d-38.484241!3d-12.969909!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x277e3fb81b35819f!2sRealiza%20Rent%20a%20Car!5e0!3m2!1spt-BR!2sbr!4v1641992190592!5m2!1spt-BR!2sbr"
        width="100%"
        height="500"
        style={{ border: 0 }}
        allowFullScreen={true}
        loading="lazy"
      ></iframe>
    </div>
  )
}

export default AboutusMap
