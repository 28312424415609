import React, { useEffect } from 'react'
import { useBlogPostRead } from 'aplication/useCases/blogPosts/useBlogPostRead'
import { isoDateToDateDisplay } from 'aplication/utils/app/dateTimeCare/dateTimeCare'
import { useLocation, useParams } from 'react-router-dom'
import TitlePage from 'view/components/text/titlePage/titlePage'
import LoadingContent from 'view/componentsPages/loadingContent/loadingContent'
import { useAppContext } from 'view/contexts/appContext/appContext'
import './blogSingle.css'
import CarouselConteudoWithBox from 'view/componentsPages/carouselConteudo/carouselConteudoWithBox/carouselConteudoWithBox'
import AboutusServices from 'view/componentsPages/aboutusServices/aboutusServices'

function formatContentImageUrl(content?: string) {
  if (!content) return content
  return content?.replaceAll(
    '../../../../../media',
    `${process.env.REACT_APP_BASE_URL}/media`
  )
}

const BlogSingle: React.FC = () => {
  const { pageInfos } = useAppContext()
  const params: { id: string } = useParams()
  const { post, loading, readPost } = useBlogPostRead()
  const location = useLocation()

  function renderContent() {
    if (loading) return <LoadingContent />
    return (
      <>
        <TitlePage>{post?.title}</TitlePage>
        <div className="blog-post-single-createdat">
          {`Atualizado em ${isoDateToDateDisplay(post?.updatedAt)}`}
        </div>
        <div className="blog-post-single-img">
          <img src={post?.image} />
        </div>
        <div
          className="blog-post-single-content"
          dangerouslySetInnerHTML={{
            __html: formatContentImageUrl(post?.content) || ''
          }}
        />
      </>
    )
  }

  useEffect(() => {
    readPost(params.id)
  }, [location])

  useEffect(() => {
    if (!post) return
    pageInfos.update({
      pageTitle: post?.title,
      pageDescription: post?.brief,
      pageImg: post?.image,
      pageImgHeigth: '200',
      pageImgWidth: '255'
    })
  }, [post])

  return (
    <div className="blog-post-single">
      <div className="container">{renderContent()}</div>
      <CarouselConteudoWithBox title="Você pode gostar" />
      <AboutusServices title="Conheça nossos serviços" />
    </div>
  )
}

export default BlogSingle
