import React, { useEffect, useState } from 'react'
import Button from 'view/components/button/button'
import './modalCookie.css'

const ModalCookies: React.FC = () => {
  const [open, setOpen] = useState<boolean>(true)
  const [cookieAgreement, setCookieAgreement] = useState<string>()

  function close() {
    localStorage.setItem('cookieAgreement', 'agree')
    setCookieAgreement('agree')
  }

  function checkAgreement() {
    setOpen(cookieAgreement !== 'agree')
  }

  useEffect(() => {
    const getCookieAgreement = localStorage.getItem('cookieAgreement')
    if (!getCookieAgreement) return
    setCookieAgreement(getCookieAgreement)
  }, [])

  useEffect(() => {
    checkAgreement()
  }, [cookieAgreement])

  if (!open) return <></>

  return (
    <div className="cookie-bar">
      <div className="container">
        <span>
          Este site utiliza cookies para personalizar anúncios e melhorar sua
          experiência em nossos serviços. Ao continuar navegando você concorda
          com nossa política de privacidade.
        </span>
        <Button style="orange" onClick={close}>
          Entendi
        </Button>
      </div>
    </div>
  )
}

export default ModalCookies
