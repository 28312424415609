import { newShortcode } from 'aplication/useCases/shortCodes/shortCodesFunctions'
import React from 'react'
import './scImage.css'

type TProps = {
  url?: string
  alt?: string
  subtitle?: string
}

const ScImage: React.FC<TProps> = ({ url, alt, subtitle }) => {
  return (
    <p className="sc-image-box">
      <img src={url} alt={alt} />
      <span
        className="sc-image-box-subtitle"
        dangerouslySetInnerHTML={{ __html: subtitle || '' }}
      />
    </p>
  )
}

export default newShortcode({
  scName: 'scImage',
  Component: ScImage
})
