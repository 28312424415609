import React, { useEffect, useState } from 'react'
import { TCountry } from 'aplication/useCases/country/TCountry'
import { countryList } from 'aplication/useCases/country/countryList'
import { SelectField } from 'view/components/formComponent'

type Props = {
  required?: boolean
}

const SelectCountryField: React.FC<Props> = ({ required }) => {
  const [options, setOptions] = useState<any[]>([])
  const { data, load } = countryList()

  function loadOptions() {
    const newOptions: any[] = []
    if (data) {
      data.forEach((country: TCountry) => {
        newOptions.push({ value: country.id, label: country.name })
      })

      setOptions(newOptions)
    }
  }

  useEffect(() => {
    loadOptions()
  }, [data])

  useEffect(() => {
    load()
  }, [])

  return (
    <SelectField
      name="countryOrigin"
      placeholder="Selecione seu país de origem"
      options={options}
      required={required}
    />
  )
}

export default SelectCountryField
