import React from 'react'
import TitlePage from 'view/components/text/titlePage/titlePage'
import BookingPaymentCheckout from '../bookingPaymentCheckout/bookingPaymentCheckout'

type TProps = {
  title?: string
}

const BookingPaymentPage: React.FC<TProps> = ({ title, children }) => {
  return (
    <div className="container">
      <TitlePage>{title || 'Pagamento da reserva'}</TitlePage>
      <div className="payment-content">
        <div className="payment-checkout">
          <BookingPaymentCheckout />
        </div>
        {children}
      </div>
    </div>
  )
}

export default BookingPaymentPage
