import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { TStyleSize } from 'view/viewTypes'
import './iconLoading.css'

type Props = {
  size?: TStyleSize
}

const IconLoading: React.FC<Props> = ({ size }) => {
  size = size || 'small'

  return (
    <span className={`icon-loading font-size-${size}`}>
      <FontAwesomeIcon icon={['fas', 'spinner']} />
    </span>
  )
}

export default IconLoading
