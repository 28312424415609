export function queryStringFiltersMaker(filters: {
  [key: string]: any
}): string {
  let quereyString = ''
  Object.keys(filters).forEach(filter => {
    if (!filters[filter] || filters[filter] === '') return
    quereyString += `${filter}=${filters[filter]}&`
  })
  return quereyString
}
