import { objectIsEmpty } from 'aplication/utils/app/testValues'
import React, { createContext, useEffect, useRef, useState } from 'react'
import { useFormContext } from '../formComponent'
import { TField } from '../formComponentsTypes'
import InvalidMessage from './fieldInvalidMessage'

type TFieldContext = {
  field: TField
  stateValue: any
  fieldProps: any
  updateField: (field: TField) => void
}

export const FieldContext = createContext<TFieldContext>({} as TFieldContext)

type TProps = {
  fieldProps: any
}

const FieldProvider: React.FC<TProps> = ({ children, fieldProps }) => {
  const fieldRef = useRef<any>()
  const className = fieldProps?.className || ''
  const [stateValue, setStateValue] = useState<any>()

  const { fields, registerField, updateField } = useFormContext()

  function getField() {
    const { name } = fieldProps
    return fields.find(f => f.name === name) || ({} as TField)
  }

  function addInvalidClass(field: TField) {
    const theFieldRef = fieldRef.current
    if (!theFieldRef || !theFieldRef.classList || objectIsEmpty(field)) return
    if (!field?.isValid) {
      theFieldRef.classList.add('invalid')
    } else {
      theFieldRef.classList.remove('invalid')
    }
  }

  function renderRequiredIndicator() {
    if (!fieldProps.required) return
    return <span className="required-indicator">*</span>
  }

  useEffect(() => {
    const { name, required, validators } = fieldProps
    registerField({ name, isRequired: required, validators })
  }, [])

  useEffect(() => {
    const field = getField()
    setStateValue(field?.value)
    addInvalidClass(field)
  }, [fields])

  return (
    <FieldContext.Provider
      value={{ field: getField(), stateValue, updateField, fieldProps }}
    >
      <div
        className={`field field-${fieldProps.name} ${className || ''}`}
        ref={fieldRef}
      >
        {renderRequiredIndicator()}
        {children}
        <InvalidMessage field={getField()} />
      </div>
    </FieldContext.Provider>
  )
}

export default FieldProvider
