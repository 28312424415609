import React, { useEffect, useRef, useState } from 'react'
import { CarouselContext } from './carouselContext'
import { Component, ItemProps, TPosition } from './carouselTypes'
import CarouselElement from './carouselElement'
import './carousel.css'

type Props = {
  id?: string
  className?: string
  autoScroll?: boolean
  autoScrollTimeOut?: number
}

const Carousel: React.FC<Props> & Component = ({
  children,
  className,
  autoScroll,
  autoScrollTimeOut
}) => {
  const carouselRef = useRef<any>()
  const [currentPosition, setCurrentPosition] = useState<TPosition>({
    id: 0,
    xPosition: 0
  } as TPosition)
  const [positions, setPositions] = useState<TPosition[]>([] as TPosition[])
  const [scrollInterval, setScrollInterval] = useState<any>()

  function scrollToPosition(position: TPosition) {
    let carousel = carouselRef.current
    if (!carousel) return null
    carousel = carouselRef.current.querySelector('.carousel-itens')
    carousel.scrollLeft = position.xPosition
  }

  function updateCurrentposition(positionId: number) {
    const newPosition = positions.find(position => position.id === positionId)
    if (newPosition) {
      setCurrentPosition(newPosition)
      scrollToPosition(newPosition)
    }
  }

  function getCarouselValues() {
    let carousel = carouselRef.current
    if (!carousel) return null
    carousel = carouselRef.current.querySelector('.carousel-itens')
    const scrollWidth = carousel.scrollWidth
    const offsetWidth = carousel.offsetWidth
    const itens = carousel.querySelectorAll('.carousel-item:not(.null-item)')
    const itemWidth = itens[0].offsetWidth
    const nullItemWdt = carousel.querySelector('.null-item')

    return { scrollWidth, offsetWidth, itens, itemWidth, nullItemWdt }
  }

  function makeScrollPositions(totalDots: number) {
    const carouselValues = getCarouselValues()
    if (!carouselValues) return
    const { itemWidth } = carouselValues

    const dts = Array.from(Array(totalDots).keys())
    const dotsPositions: TPosition[] = []
    dts.forEach(d => {
      dotsPositions.push({ id: d, xPosition: d * itemWidth })
    })
    return dotsPositions
  }

  function calcTotalPositions() {
    const carouselValues = getCarouselValues()
    if (!carouselValues) return false
    const { scrollWidth, offsetWidth, itemWidth, itens } = carouselValues

    let totalPositions = Math.ceil((scrollWidth - offsetWidth) / itemWidth)

    if (itemWidth === offsetWidth) {
      totalPositions = itens.length
    } else {
      totalPositions++
    }

    return totalPositions
  }

  function buildPositions() {
    const totalPositions = calcTotalPositions()
    if (!totalPositions) return
    const dotsPositions = makeScrollPositions(totalPositions)
    if (!dotsPositions) return
    setPositions([...dotsPositions])
  }

  function loadOnWindowResize() {
    let resizing: any
    window.addEventListener('resize', () => {
      window.clearTimeout(resizing)
      resizing = setTimeout(() => {
        buildPositions()
        updateCurrentposition(0)
      }, 100)
    })
  }

  function setCurrentWindowOnScroll() {
    const carousel = carouselRef.current.querySelector('.carousel-itens')
    let isScrolling: any
    carousel.addEventListener('scroll', () => {
      window.clearTimeout(isScrolling)
      isScrolling = setTimeout(() => {
        updateCurrentposition(carousel.scrollLeft)
      }, 100)
    })
  }

  function runAutoScroll(currentPositionId: number) {
    if (!autoScroll) return
    const timeout = autoScrollTimeOut || 7000
    clearInterval(scrollInterval)
    const interval = setInterval(() => {
      let newPosition = currentPositionId + 1
      if (currentPositionId >= positions.length - 1) {
        newPosition = 0
      }
      updateCurrentposition(newPosition)
    }, timeout)
    setScrollInterval(interval)
  }

  useEffect(() => {
    buildPositions()
    updateCurrentposition(0)
    loadOnWindowResize()
    setCurrentWindowOnScroll()
  }, [])

  useEffect(() => {
    runAutoScroll(currentPosition.id)
  }, [positions, currentPosition])

  return (
    <CarouselContext.Provider
      value={{
        scrollRef: carouselRef,
        positions,
        currentPosition,
        scrollInterval,
        updateCurrentposition,
        runAutoScroll,
        scrollToPosition
      }}
    >
      <div className={className}>
        <CarouselElement>{children}</CarouselElement>
      </div>
    </CarouselContext.Provider>
  )
}

const Item: React.FC<ItemProps> = ({ children, id, className, onClick }) => {
  return (
    <div
      id={id}
      className={`carousel-item ${className || ''}`}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

Carousel.item = Item

export default Carousel
