import React, { createContext, useContext, useRef } from 'react'
import { ModalBookingForm } from 'view/componentsPages/modalBookingForm'
import { TModalBookingFormRef } from 'view/componentsPages/modalBookingForm/modalBookingForm'

export type TRentingPageContext = {
  modalBookingRef: any
}

const RentingPageContext = createContext<TRentingPageContext>(
  {} as TRentingPageContext
)

const RentingPageProvider: React.FC = ({ children }) => {
  const modalBookingRef = useRef<TModalBookingFormRef>(
    {} as TModalBookingFormRef
  )

  return (
    <RentingPageContext.Provider value={{ modalBookingRef }}>
      {children}
      <ModalBookingForm ref={modalBookingRef} />
    </RentingPageContext.Provider>
  )
}

export default RentingPageProvider

export function useRentingPageContext(): TRentingPageContext {
  return useContext(RentingPageContext)
}
