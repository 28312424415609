import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef } from 'react'
import { InputField } from 'view/components/formComponent'
import { TModalRef } from 'view/components/modal'
import Modal from 'view/components/modal/modal'
import './inputCnhField.css'

const InputCnhField: React.FC = () => {
  const modalRef = useRef<TModalRef>({} as TModalRef)

  function onClick() {
    modalRef.current.toggleOpen()
  }

  function setInputNumberMask(inputValue: string): string {
    return inputValue.match(/\d+/g)?.join('') || ''
  }

  return (
    <div className="cnh-register-number">
      <InputField
        name="cnh"
        placeholder="CNH - número de registro"
        maxLength={11}
        regexMask={setInputNumberMask}
        title="Número da CNH"
      />
      <span onClick={onClick}>
        <FontAwesomeIcon icon={['fas', 'info-circle']} />
      </span>

      <Modal
        className="modal-cnh-register-number"
        ref={modalRef}
        content={
          <>
            <div className="modal-cnh-register-number-content">
              O número de registro encontra-se no canto inferior esquerdo da
              frente da sua CNH.
            </div>
            <img src="/media/outros/cnh_frente.png" />
          </>
        }
      />
    </div>
  )
}

export default InputCnhField
