import React from 'react'
import Notification from './notification/notification'
import { TUserNotification } from 'aplication/useCases/userNotifications/useUserNotificationsTypes'
import './notificationsList.css'

type TProps = {
  notifications: TUserNotification[]
  removeNotification: (id: string) => void
}

const NotificationsList: React.FC<TProps> = ({
  notifications,
  removeNotification
}) => {
  function removeNotificationAction(e: any) {
    const notificationItem = e.target.closest('.notification')
    if (!notificationItem) return
    const notificationId = notificationItem.id
    removeNotification(notificationId)
  }

  function renderNotifications() {
    if (!notifications || notifications.length <= 0) {
      return <div className="notifications-empty">Não há notificações.</div>
    }

    return notifications.map((notification, index) => {
      return (
        <Notification
          key={index}
          index={index}
          notification={notification}
          remove={removeNotificationAction}
        />
      )
    })
  }

  return <div className="notifications-list">{renderNotifications()}</div>
}

export default NotificationsList
