import { TPageInfos } from 'aplication/entities/TPageInfos'
import { useState } from 'react'

export type TUsePageInfos = {
  data: TPageInfos
  update: (values?: TPageInfos) => void
}

export function usePageInfos(): TUsePageInfos {
  const defaultData = {
    pageTitle: '',
    pageDescription:
      'Somos especialistas em mobilidade: Seminovos, Aluguel de carros e Terceirização de frotas.',
    pageImg: '/media/site/d5e978d4-20ad-497d-94f8-afb3c8702bcf.jpeg',
    pageImgType: 'image/jpeg',
    pageImgWidth: '533',
    pageImgHeigth: '128'
  }

  const [data, setData] = useState<TPageInfos>(defaultData)

  function update(values?: TPageInfos) {
    if (!values) values = defaultData
    setData(values)
  }

  return {
    data,
    update
  }
}
