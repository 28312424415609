import { CardBrandApiSevice } from 'aplication/apiServices/cardBrandApiService/cardBrandApiService'
import { useState } from 'react'

type TUseCardBrand = {
  brand: string | null
  loadData: (cardNumber: string) => void
}

export function useCardBrand(): TUseCardBrand {
  const [brand, setBrand] = useState<string | null>('')

  function loadData(cardNumber: string) {
    const apiService = new CardBrandApiSevice()
    apiService.load(cardNumber).then(response => {
      if (
        !response ||
        response.statusCode !== 200 ||
        !response.body ||
        !response.body.provider
      ) {
        setBrand(null)
        return
      }
      setBrand(response.body.provider)
    })
  }

  return { brand, loadData }
}
