import { Col, Row } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { PageTitle } from 'view/components'
import Page from 'view/components/page/page'

type Props = {
  title: string
  message: string
}

const ErrorPageTemplate: React.FC<Props> = ({ title, message }) => {
  return (
    <Page>
      <Row>
        <Col span={12} offset={6}>
          <PageTitle>{title}</PageTitle>
          <div style={{ textAlign: 'center' }}>
            <h2>{message}</h2>
            <Link to="/">Ir para a página inicial</Link>
          </div>
        </Col>
      </Row>
    </Page>
  )
}

export default ErrorPageTemplate
