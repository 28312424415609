import React, { useEffect } from 'react'
import { useUserNotifications } from 'aplication/useCases/userNotifications/useUserNotifications'
import NotificationsList from 'view/componentsPages/notificationsList/notificationsList'
import LoadMoreResults from 'view/componentsPages/loadMoreResults/loadMoreResults'
import { useTabContext } from 'view/components/tabs/tabs'
import LoadingContent from 'view/componentsPages/loadingContent/loadingContent'

type TProps = {
  read?: boolean
  id?: string
}

const DashboardNotificationsPageList: React.FC<TProps> = ({ read, id }) => {
  const { activeTab } = useTabContext()

  const {
    notifications,
    totalNotifications,
    loaded,
    removeNotification,
    loadNotifications,
    loadMoreNotifications
  } = useUserNotifications()

  function loadMore() {
    loadMoreNotifications(read)
  }

  useEffect(() => {
    if (!activeTab) return
    if (activeTab.id === id) {
      loadNotifications(read)
    }
  }, [activeTab])

  function renderContent() {
    if (!loaded) {
      return (
        <>
          <LoadingContent height={200} />
        </>
      )
    } else {
      return (
        <>
          <NotificationsList
            notifications={notifications}
            removeNotification={removeNotification}
          />

          <LoadMoreResults
            data={notifications}
            totalData={totalNotifications}
            loadMoreAction={loadMore}
          />
        </>
      )
    }
  }

  return <>{renderContent()}</>
}

export default DashboardNotificationsPageList
