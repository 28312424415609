import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'
import { TBookingDateBlockApi } from './TBookingDateBlockApi'

type TResponse = {
  results: TBookingDateBlockApi[]
}

export class BookingDateBlocksApiService extends ApiService {
  async load(): Promise<HttpResponse<TResponse>> {
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/bloqueio-por-data/`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
