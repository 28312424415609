import {
  diffBetweenDates,
  diffHoursBetweenDates,
  isoDateToDate
} from 'aplication/utils/app/dateTimeCare/dateTimeCare'
import { TBooking } from 'domain/entities/TBooking'
import { TRentingData } from 'domain/entities/TRentingData'

function rentingEventValidation(data: any): boolean {
  return Object.values(data).some(
    p => !p || p === '' || p === 'NaN:NaN' || p === 'NaN-NaN-NaN'
  )
}

export function dateTimeValidator(rentingData: TRentingData | null): boolean {
  if (
    !rentingData ||
    !rentingData.pickup ||
    !rentingData.return ||
    rentingEventValidation(rentingData.pickup) ||
    rentingEventValidation(rentingData.return)
  ) {
    return true
  }
  return false
}

export function pickupValidation(bookingData: TBooking | null): boolean {
  const now = new Date()
  const pickupDay = bookingData?.pickup?.date.split('T')[0]
  const pickupHour = bookingData?.pickup?.hour.split('T')[1]
  const pickupDate = isoDateToDate(`${pickupDay}T${pickupHour}`)

  if (!pickupDate) return true

  if (
    !bookingData ||
    !bookingData.pickup ||
    !bookingData.pickup.date ||
    !bookingData.pickup.hour ||
    pickupDate.getTime() < now.getTime() ||
    (diffBetweenDates(now, pickupDate) === 0 &&
      diffHoursBetweenDates(now, pickupDate) < 1)
  ) {
    return true
  }

  return false
}

export function carGroupValidator(rentingData: TRentingData | null): boolean {
  if (!rentingData || !rentingData.carGroup) {
    return true
  }
  return false
}
