import React from 'react'
import { Link } from 'react-router-dom'
import PageContainerPromo from 'view/componentsPages/pageContainerPromo/pageContainerPromo'
import { useAuthContext } from 'view/contexts/authContext'
import './dashboardSidebar.css'

const DashboardSidebar: React.FC = () => {
  const { loggedUser, logoutUser } = useAuthContext()

  return (
    <div className="dashboard-sidebar">
      <h3>{loggedUser?.person.name}</h3>
      <ul className="dashboard-menu-sidebar">
        <li>
          <Link to="/dashboard">Minhas Reservas</Link>
        </li>
        <li>
          <Link to="/dashboard/minha_conta">Meus dados</Link>
        </li>
        <li>
          <Link to="/dashboard/notificacoes">Notificações</Link>
        </li>
        <li>
          <Link to="/dashboard/cartoes-de-credito">Cartões de crédito</Link>
        </li>
        <li>
          <Link to="#" onClick={logoutUser}>
            Sair
          </Link>
        </li>
      </ul>

      <div className="dashboard-sidebar-promo">
        <PageContainerPromo onlyMobile />
      </div>
    </div>
  )
}

export default DashboardSidebar
