import React, { useEffect, useState } from 'react'
import { slideImagesLoader } from 'aplication/useCases/slideImages/slideImagesLoader'
import './pageBannerTop.css'

type TProps = {
  bannerLocation?: string
}

const PageBannerTop: React.FC<TProps> = ({ bannerLocation }) => {
  if (!bannerLocation) return <></>

  const { slideImages, loadSlideImages } = slideImagesLoader()
  const [images, setImages] = useState<{ desktop?: string; mobile?: string }>()
  const randonSelect = Math.floor(Math.random() * slideImages.length)

  useEffect(() => {
    loadSlideImages(bannerLocation)
  }, [])

  useEffect(() => {
    if (!slideImages || slideImages.length <= 0 || !slideImages[0]) return
    setImages(slideImages[randonSelect])
  }, [slideImages])

  function renderTopImage() {
    if (!images) return <></>
    const { desktop, mobile } = images
    return (
      <div className="page-banner-top-image">
        <img src={desktop} className="page-banner-top-image-desktop" />
        <img src={mobile} className="page-banner-top-image-mobile" />
      </div>
    )
  }

  return <>{renderTopImage()}</>
}

export default PageBannerTop
