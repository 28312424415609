import { useQuery } from 'aplication/utils/app/useQuery'
import React from 'react'
import Button from 'view/components/button/button'
import { SuccessPageTemplate } from 'view/template/successPageTemplate'
import './bookingDone.css'

const BookingDone: React.FC = () => {
  const query = useQuery()
  const bookingId = query.get('id')

  return (
    <SuccessPageTemplate
      className="booking-done"
      title="Reserva solicitada!"
      buttons={
        <>
          <Button style="transparent" link={`/dashboard/reserva/${bookingId}`}>
            Ver detalhes da reserva
          </Button>
          <Button style="orange" link="/dashboard">
            Ver minhas reservas
          </Button>
          <Button style="link" link="/">
            Ir para página inicial
          </Button>
        </>
      }
    >
      Muito bem, a sua solicitação de reserva foi enviada com sucesso! <br />{' '}
      Caso os dados estejam de acordo com as políticas da empresa, você receberá
      uma mensagem de confirmação da locação no e-mail cadastrado. Se houverem
      pendências de documentos, a nossa equipe entrará em contato contigo em um
      prazo de 24 horas para solicitá-los. A Realiza Rent a Car agradece pela
      preferência!
    </SuccessPageTemplate>
  )
}

export default BookingDone
