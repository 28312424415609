import React from 'react'
import './servicePageTemplate.css'

type TProps = {
  children: React.ReactNode
}

const ServicePageTemplate: React.FC<TProps> = ({ children }) => {
  return (
    <div className="service-page-template">
      <div className="container">{children}</div>
    </div>
  )
}

export default ServicePageTemplate
