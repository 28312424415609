import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { Modal } from 'view/components/modal'
import { TModalRef } from 'view/components/modal/modal'
import { ContactForm } from './contactForm'
import './modalContactForm.css'

export type TModalContactFormRef = {
  toggleContactForm: () => void
}

const ModalContactForm: React.ForwardRefRenderFunction<TModalContactFormRef> = (
  props,
  ref
) => {
  const modalRef = useRef<TModalRef>({} as TModalRef)

  function toggleContactForm() {
    modalRef.current.toggleOpen()
  }

  useImperativeHandle(ref, () => {
    return { toggleContactForm }
  })

  return (
    <Modal
      className="modal-contact-form"
      ref={modalRef}
      content={<ContactForm />}
    />
  )
}

export default forwardRef(ModalContactForm)
