import {
  TNewUserApi,
  TNewUserApiToService
} from 'domain/entities/api/TNewUserApi'
import { TNewUser } from 'domain/entities/TNewUser'

export function userDataParserToApi(data: TNewUser): TNewUserApi {
  if (!data) return {} as TNewUserApi
  return {
    bairro: data.addressDistrict,
    cep: data.cep,
    cidade: data.city,
    cnh: data.cnh,
    documento: data.document,
    email: data.email,
    logradouro: data.address,
    nome: data.name,
    numero_seguranca_cnh: data.cnhSecurityNumber,
    password: data.password,
    telefone: data.phone,
    tipo_documento: data.documentType,
    pais: data.countryOrigin || 'br'
  }
}

export function userDataParserToApiService(
  data: TNewUser
): TNewUserApiToService {
  if (!data) return {} as TNewUserApiToService
  return {
    user: userDataParserToApi(data),
    addressFile: data.addressFile,
    cnhFile: data.cnhFile
  }
}
