import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'
import { TPrivacyPolicyApi } from './privacyPolicyApiServiceTypes'

type TResponseBody = {
  results?: TPrivacyPolicyApi[]
}

export class PrivacyPolicyApiService extends ApiService {
  async load(): Promise<HttpResponse<TResponseBody>> {
    return this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/politica-de-privacidade/`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
