import React, { useEffect, useState } from 'react'

type TPageLoader = {
  loaded: boolean
  showContentIfLoaded: (content: JSX.Element) => JSX.Element
}

export function usePageLoader(timeout?: number): TPageLoader {
  const [loaded, setLoaded] = useState(false)

  function loadPage() {
    setTimeout(() => {
      setLoaded(true)
    }, timeout || 500)
  }

  function showContentIfLoaded(content: JSX.Element): JSX.Element {
    if (!loaded) return <></>
    return content
  }

  useEffect(() => {
    loadPage()
  }, [])

  return {
    get loaded() {
      return loaded
    },
    showContentIfLoaded
  }
}
