import React, { useContext, useEffect, useRef, useState } from 'react'
import { SelectFieldContext, TOption } from './selectFieldContext'
import SelectFieldOption from './selectFieldOption'

const SelectFieldOptions: React.FC = () => {
  const { open, options } = useContext(SelectFieldContext)
  const [thisOptions, setThisOptions] = useState<TOption[]>(options)
  const inputRef = useRef<any>()

  function loadOptions() {
    return thisOptions.map((o: any, index: number) => {
      return <SelectFieldOption key={index} option={o} index={index} />
    })
  }

  function onSearch(event: any) {
    const value = event.target.value
    let newOptions = options
    if (value !== '') {
      const regex = new RegExp(`^${value}`, 'i')
      newOptions = options.filter(o => regex.test(o.label))
    }
    setThisOptions(newOptions)
  }

  useEffect(() => {
    if (!open) {
      inputRef.current.value = ''
      setThisOptions(options)
      return
    }
    inputRef.current.focus()
  }, [open])

  useEffect(() => {
    if (!options) return
    setThisOptions(options)
  }, [options])

  return (
    <div
      className={`animation-fade-in select-styled-options${
        open ? ' open' : ''
      }`}
    >
      <div className="select-search">
        <input type="text" ref={inputRef} onChange={onSearch} />
      </div>
      <div className="options-list">{loadOptions()}</div>
    </div>
  )
}

export default SelectFieldOptions
