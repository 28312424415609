export function priceConverter(
  price?: string | number | null,
  styled?: boolean
): string | string[] {
  if (!price) price = 0
  if (typeof price === 'string') price = parseFloat(price.replace('.', ''))
  const stringPrice = price.toLocaleString('pt-BR', {
    minimumFractionDigits: 2
  })
  const arrayPrice = stringPrice.split(',')
  if (!styled) {
    return `R$ ${arrayPrice[0]},${arrayPrice[1] ? arrayPrice[1] : '00'}`
  } else {
    return [
      `R$ ${arrayPrice[0]}`,
      `${arrayPrice[1] ? `,${arrayPrice[1]}` : ',00'}`
    ]
  }
}
