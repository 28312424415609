import { priceConverter } from 'aplication/utils/app/valueConverter'
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Button from 'view/components/button/button'
import { Modal } from 'view/components/modal'
import { TModalRef } from 'view/components/modal/modal'
import { useAppContext } from 'view/contexts/appContext/appContext'
import { useBookingDataContext } from 'view/contexts/bookingDataContext'
import './bookingPaymentModalMessage.css'

export type TBookingPaymentModalMessageRef = {
  toggleModal: (values: any) => void
}

type TProps = {
  onConfirm: (values: any) => void
  customMessage?: JSX.Element
}

const BookingPaymentModalMessage: React.ForwardRefRenderFunction<
  TBookingPaymentModalMessageRef,
  TProps
> = ({ onConfirm, customMessage }, ref) => {
  const modalRef = useRef<TModalRef>({} as TModalRef)
  const { bookingData } = useBookingDataContext()
  const { faq } = useAppContext()
  const [values, setValues] = useState<any>({})

  function toggleModal(values: any) {
    modalRef.current?.toggleOpen()
    setValues(values)
  }

  function confirmHandle() {
    if (onConfirm) onConfirm(values)
    modalRef.current?.toggleOpen()
  }

  useImperativeHandle(ref, () => {
    return { toggleModal }
  })

  return (
    <Modal
      className="modal-payment-message"
      ref={modalRef}
      content={
        <>
          <div className="message-content">
            <h2>Fique atento!</h2>
            <p>
              No momento da retirada do veículo, deve ser apresentado um
              documento de identificação e CNH, do cliente e condutor.
            </p>
            {customMessage}
          </div>

          <Button style="orange" size="md" full onClick={confirmHandle}>
            Continuar
          </Button>
        </>
      }
    />
  )
}

export default forwardRef(BookingPaymentModalMessage)
