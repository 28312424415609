import { RentingContractServer } from 'aplication/apiServices/rentingContractService/rentingContractService'
import React, { useEffect, useState } from 'react'
import TitlePage from 'view/components/text/titlePage/titlePage'
import { useAppContext } from 'view/contexts/appContext/appContext'

const RentingContract: React.FC = () => {
  const [pdfUrl, setPdfUrl] = useState<string>('')

  useEffect(() => {
    const apiService = new RentingContractServer()
    apiService.load().then(response => {
      if (response.statusCode !== 200) return
      setPdfUrl(response.body.results[0].upload_file)
    })
  }, [])

  return (
    <div className="container">
      <TitlePage>Contrato de Locação</TitlePage>
      <div style={{ width: '100%', marginBottom: '50px' }}>
        <embed
          src={pdfUrl}
          style={{ width: '100%', height: '600px' }}
          type="application/pdf"
        />
      </div>
    </div>
  )
}

export default RentingContract
