import { BookingApiSevice } from 'aplication/apiServices/bookingApiService/bookingApiService'
import { HttpResponse } from 'aplication/apiServices/protocols/IHttpClient'
import { TBookingCardData } from 'domain/entities/TBooking'
import { bookingCardDataParserApi } from './bookingDataParser'

export function bookingRetryPayment(body: {
  bookingId: number
  card: TBookingCardData
}): Promise<HttpResponse> {
  const { card, bookingId } = body
  const apiService = new BookingApiSevice()
  return apiService.retryPayment({
    reserva: bookingId,
    installments: parseInt(card.installments),
    card: !card.cardUuid ? bookingCardDataParserApi(card) : undefined,
    card_uuid: card.cardUuid || undefined
  })
}
