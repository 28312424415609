import { useRemoveBooking } from 'aplication/useCases/booking/removeBooking'
import {
  diffHoursBetweenDates,
  timeIsBefore
} from 'aplication/utils/app/dateTimeCare/dateTimeCare'
import { TBookingEvent } from 'domain/entities/TBooking'
import React, { useContext, useRef, useState } from 'react'
import Button from 'view/components/button/button'
import { Modal, TModalRef } from 'view/components/modal'
import { useAppContext } from 'view/contexts/appContext/appContext'
import { DashboardBookingsContext } from '../../dashboardBookings/dashboardBookings'
import { BookingCardContext } from '../bookingCard'
import './bookingCancelButton.css'

const BookingCancelButton: React.FC = () => {
  const { organizationInfo } = useAppContext()
  const { bookingData, dialogRef } = useContext(BookingCardContext)
  const { reloadBookings } = useContext(DashboardBookingsContext)
  const { removeBooking } = useRemoveBooking()
  const modalRef = useRef<TModalRef>({} as TModalRef)
  const [content, setContent] = useState<JSX.Element>()

  function openModal() {
    modalRef.current.toggleOpen(true)
  }

  function mergeBookingEnventDateTime(event: TBookingEvent): string {
    const date = event.date.split('T')[0]
    const time = event.hour.split('T')[1]
    return `${date}T${time}`
  }

  function canDisableNow() {
    if (
      !bookingData ||
      !bookingData.pickup ||
      !bookingData.pickup.date ||
      !bookingData.pickup.hour
    ) {
      return
    }
    const currentTime = new Date()
    const pickupTime = new Date(mergeBookingEnventDateTime(bookingData.pickup))
    return (
      bookingData.status === 'aguardando_retirada' &&
      diffHoursBetweenDates(currentTime, pickupTime) > 72
    )
  }

  function cancelBooking(bookingId: string) {
    dialogRef.current.loadMessage('Cancelando reserva...')
    removeBooking(bookingId).then(response => {
      if (!response.body.success) {
        dialogRef.current.changeMessage(response.body.detail, 'danger')
      }
      dialogRef.current.changeMessage('Reserva cancelada', 'success')
      if (response.body.success) setTimeout(() => reloadBookings(), 500)
    })
  }

  function confirmCancelBooking() {
    modalRef.current.toggleOpen(false)
    if (!bookingData.id) return
    cancelBooking(bookingData.id)
  }

  function unconfirmCancelBooking() {
    modalRef.current.toggleOpen(false)
  }
  function renderDisableNow() {
    return (
      <>
        <p>
          <b>Tem certeza que deseja cancelar esta reserva?</b>
        </p>
        <div className="row-button">
          <Button onClick={confirmCancelBooking}>Sim</Button>
          <Button style="orange" onClick={unconfirmCancelBooking}>
            Não
          </Button>
        </div>
      </>
    )
  }

  function renderNotDisableNow() {
    return (
      <>
        <p>
          <b>
            Você decidiu para cancelar uma reserva em processo de análise ou que
            marca menos de 72 horas para a retirada do veículo.
          </b>
        </p>
        <p>
          Para continuar esta solicitação de cancelamento, entre em contato com
          a Realiza através do número <b>{organizationInfo?.phone}</b>, ou pelo{' '}
          <b>
            <a
              href={`https://api.whatsapp.com/send?phone=55${organizationInfo?.whatsapp}`}
              target="_blank"
              rel="noreferrer"
              className="color-secondary"
            >
              Whatsapp
            </a>
          </b>
          .
        </p>
      </>
    )
  }

  function onChangeModal(open: boolean): void {
    if (open && !canDisableNow()) {
      setContent(renderNotDisableNow())
      return
    }
    setContent(renderDisableNow())
  }

  function renderContent() {
    if (bookingData.status !== 'aguardando_retirada') return <></>
    return (
      <>
        <Button
          size="sm-x"
          className="booking-card-cancel-btn"
          title="Cancelar esta reserva"
          onClick={openModal}
        >
          cancelar
        </Button>
        <Modal
          ref={modalRef}
          content={content}
          className="cancel-booking-modal"
          onChange={onChangeModal}
        />
      </>
    )
  }

  return <>{renderContent()}</>
}

export default BookingCancelButton
