import { objectIsEmpty } from 'aplication/utils/app/testValues'
import { TRentingData } from 'domain/entities/TRentingData'
import {
  deleteRentingData,
  readRentingData,
  saveRentingData
} from 'domain/useCases/rentingStepsDataControl/rentingStepsDataControl'
import { useEffect, useState } from 'react'

type TUseRentingData = {
  rentingData: TRentingData
  hasRentingData: () => boolean
  updateRentingData: (data: TRentingData) => void
  resetRentingData: () => void
}

export function useRentingData(localStorage?: boolean): TUseRentingData {
  const srd = localStorage ? readRentingData() : {}
  const [rentingData, setRentingData] = useState<TRentingData>(srd)

  function updateRentingData(data: TRentingData) {
    setRentingData({ ...rentingData, ...data })
  }

  function loadRentingData() {
    const storageRentingData = readRentingData()
    if (!rentingData || objectIsEmpty(rentingData)) return
    if (storageRentingData) setRentingData(storageRentingData)
  }

  function hasRentingData(): boolean {
    const localStorageData = readRentingData()
    return localStorageData && !objectIsEmpty(localStorageData)
  }

  function resetRentingData() {
    deleteRentingData()
    setRentingData({})
  }

  useEffect(() => {
    if (!localStorage) return
    if (!rentingData || Object.keys(rentingData).length <= 0) {
      loadRentingData()
      return
    }
    saveRentingData(rentingData)
  }, [rentingData])

  return { rentingData, updateRentingData, hasRentingData, resetRentingData }
}
