import React, { useContext, useEffect, useRef } from 'react'
import { SelectFieldContext, TSelected } from './selectFieldContext'
import SelectFieldOptions from './selectFieldOptions'

type TProps = {
  options: any[]
}

const SelectFieldStyled: React.FC<TProps> = () => {
  const { selected, open, setOpen, setSelected } =
    useContext(SelectFieldContext)
  const selectRef = useRef<any>()

  function clickSelect() {
    if (open) setOpen(false)
    if (!open) setOpen(true)
  }

  function detectOutClick() {
    document.addEventListener('mousedown', (e: any) => {
      if (selectRef.current && !selectRef.current.contains(e.target)) {
        setOpen(false)
      }
    })
  }

  function detectFocusOut() {
    document.addEventListener('focus', (e: any) => {
      if (selectRef.current && !selectRef.current.contains(e.target)) {
        setOpen(false)
      }
    })
  }

  function onKeyDown(e: any) {
    if (e.key === 'Tab') {
      setOpen(false)
      return
    }
    if (e.key === 'Escape') {
      e.preventDefault()
      setOpen(false)
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault()
      const firstOption = selectRef.current.querySelector(
        '.select-option:nth-child(1)'
      )
      const target = e.target
      if (!target.className.includes('select-option') && firstOption) {
        setOpen(true)
        firstOption.focus()
      } else {
        const next = target.nextElementSibling
        if (next) {
          next.focus()
        } else if (firstOption) {
          firstOption.focus()
        }
      }
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault()
      const target = e.target
      const searchInput = selectRef.current.querySelector(
        '.select-search input'
      )
      if (target.className.includes('select-option')) {
        const prev = target.previousElementSibling
        if (prev) {
          prev.focus()
        } else {
          searchInput.focus()
        }
      }
    }

    if (e.key === 'Enter') {
      e.preventDefault()
      const target = e.target
      if (target.className.includes('select-option')) {
        setSelected({ label: target.innerHTML, value: target.id })
        setOpen(false)
      }
    }
  }

  function isPlaceholder() {
    if (!selected || !selected.value || selected.value === '') {
      return 'select-placeholder'
    }
    return ''
  }

  function renderLabel(selected: TSelected): string {
    const limit = 32
    const label = selected.label
    const etc = label.length > limit ? '...' : ''
    return `${selected.label.slice(0, limit)}${etc}`
  }

  useEffect(() => {
    detectOutClick()
    detectFocusOut()
  }, [])

  return (
    <div
      className={`select-styled ${open ? 'open' : ''}`}
      ref={selectRef}
      tabIndex={0}
      onKeyDown={onKeyDown}
    >
      <span
        className={`select-styled-selected ${isPlaceholder()}`}
        onClick={clickSelect}
      >
        {renderLabel(selected)}
      </span>
      <SelectFieldOptions />
    </div>
  )
}

export default SelectFieldStyled
