import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import BlogList from './blogList/blogList'
import BlogSingle from './blogSingle/blogSingle'

const Blog: React.FC = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path} component={BlogList} />
      <Route exact path={`${path}/:id`} component={BlogSingle} />
    </Switch>
  )
}

export default Blog
