import React from 'react'
import Tabs from 'view/components/tabs/tabs'
import TitlePage from 'view/components/text/titlePage/titlePage'
import { DashboardSingleTemplate } from '../dashboardSingleTemplate'
import DashboardNotificationsPageList from './dashboardNotificationsPageList'

const DashboardNotificationsPage: React.FC = () => {
  return (
    <DashboardSingleTemplate>
      <TitlePage>Notificações</TitlePage>
      <Tabs
        tabs={[
          {
            id: 'not_read',
            title: 'Não Lidas',
            content: (
              <div className="notifications-list">
                <DashboardNotificationsPageList id="not_read" />
              </div>
            )
          },
          {
            id: 'read',
            title: 'Lidas',
            content: (
              <div className="notifications-list">
                <DashboardNotificationsPageList read id="read" />
              </div>
            )
          }
        ]}
      />
    </DashboardSingleTemplate>
  )
}

export default DashboardNotificationsPage
