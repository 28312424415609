import { TApiBlogPost } from 'domain/entities/api/TApiBlogPost'
import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'

type TResponseData = {
  results: TApiBlogPost[]
  count: number
}

export class BlogPostApiService extends ApiService {
  async load(
    filters?: string,
    page?: number
  ): Promise<HttpResponse<TResponseData>> {
    const urlFilters = filters ? `?${filters}` : ''
    const paged = page ? `page=${page}` : ''

    function pagedAndFilters() {
      if (urlFilters !== '' && paged !== '') {
        return `${urlFilters}&${paged}`
      } else if (paged !== '') {
        return `?${paged}`
      } else {
        return urlFilters
      }
    }

    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/blog-post/${pagedAndFilters()}`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }

  async read(slug: string): Promise<HttpResponse<TResponseData>> {
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/blog-post/?q=${slug}`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
