import React from 'react'
import RentInfo from 'view/pages/rentingPage/booking/rentInfo/rentInfo'
import LocationDateTimeEditBtn from './locationDateTimeEditBtn'
import './locationDateTime.css'
import {
  isoDateToDateDisplay,
  isoDateToTimeDisplay
} from 'aplication/utils/app/dateTimeCare/dateTimeCare'

type Props = {
  pickupData: any
  returnData: any
  canEdit?: boolean
  pickupTitle?: string
  hidePickupLocation?: boolean
  customStartDate?: {
    title?: string
  }
  customFinalDate?: {
    content?: JSX.Element
  }
}

const LocationDateTime: React.FC<Props> = ({
  pickupData,
  returnData,
  canEdit,
  hidePickupLocation,
  customStartDate,
  customFinalDate
}) => {
  function showPickupLocation() {
    if (hidePickupLocation) return <></>
    return (
      <span className="rent-info-location">
        {pickupData?.location?.label || pickupData?.local}
      </span>
    )
  }

  function showData(data: any) {
    if (!data || !data.date || !data.hour) return <></>
    return (
      <>
        {isoDateToDateDisplay(data.date)} - {isoDateToTimeDisplay(data?.hour)}
      </>
    )
  }

  return (
    <>
      <RentInfo.item>
        <RentInfo.header>
          <h3>{customStartDate?.title || 'Retirada'}</h3>
          {canEdit ? <LocationDateTimeEditBtn /> : ''}
        </RentInfo.header>
        <RentInfo.content>
          {showPickupLocation()}
          <span className="rent-info-date">{showData(pickupData)}</span>
        </RentInfo.content>
      </RentInfo.item>
      <RentInfo.item>
        <RentInfo.header>
          <h3>Devolução</h3>
          {canEdit ? <LocationDateTimeEditBtn /> : ''}
        </RentInfo.header>
        <RentInfo.content>
          <span className="rent-info-location">
            {returnData?.location?.label || returnData?.local}
          </span>
          <span className="rent-info-date">{showData(returnData)}</span>
          {customFinalDate?.content || ''}
        </RentInfo.content>
      </RentInfo.item>
    </>
  )
}

export default LocationDateTime
