import React, { forwardRef } from 'react'

const CarouselItens: React.ForwardRefRenderFunction<any, any> = (
  { children },
  ref
) => {
  return (
    <div className="carousel-itens" ref={ref}>
      {children}
    </div>
  )
}

export default forwardRef(CarouselItens)
