import { TAppContext, TAppValues } from './appContextTypes'
import React, { createContext, useContext, useRef, useState } from 'react'
import {
  ModalContactForm,
  TModalContactFormRef
} from 'view/componentsPages/modalContactForm'
import { ModalUserActivation } from 'view/componentsPages/modalUserActivation'
import { TModalUserActivation } from 'view/componentsPages/modalUserActivation/modalUserActivation'
import { useOrganizationInfo } from 'aplication/useCases/useOrganizationInfo/useOrganizationInfo'
import { useUserNotifications } from 'aplication/useCases/userNotifications/useUserNotifications'
import { useLocations } from 'aplication/useCases/locations/useLocations'
import ModalDialog, {
  TModalDialogRef
} from 'view/componentsPages/modalDialog/modalDialog'
import { useFaqList } from 'aplication/useCases/faq/useFaqList'
import { usePageInfos } from 'aplication/useCases/pageInfos/usePageInfos'

const AppContext = createContext<TAppContext>({} as TAppContext)

const AppContextProvider: React.FC = ({ children }) => {
  const [appValues, setAppValues] = useState<TAppValues>({})

  const modalContactRef = useRef<TModalContactFormRef>(
    {} as TModalContactFormRef
  )
  const modalUserActivationRef = useRef<TModalUserActivation>(
    {} as TModalUserActivation
  )

  const modalDialogRef = useRef<TModalDialogRef>({} as TModalDialogRef)

  function updateValues(values: TAppValues) {
    setAppValues({ ...appValues, ...values })
  }

  const contextValue = {
    appValues,
    pageInfos: usePageInfos(),
    modalContactRef,
    modalUserActivationRef,
    modalDialogRef,
    updateValues,
    faq: useFaqList(),
    notifications: useUserNotifications(),
    locations: useLocations(),
    ...useOrganizationInfo()
  }

  return (
    <AppContext.Provider value={contextValue}>
      {children}
      <ModalContactForm ref={modalContactRef} />
      <ModalUserActivation ref={modalUserActivationRef} />
      <ModalDialog ref={modalDialogRef} />
    </AppContext.Provider>
  )
}

export default AppContextProvider

/**
 * ! NÃO USE
 */
export function useAppValues(): TAppContext {
  return useContext(AppContext)
}

export function useAppContext(): TAppContext {
  return useContext(AppContext)
}
