import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './checkboxStyled.css'

type TProps = {
  id: string
  label: string
  value: string
  name: string
  onChange?: (e: any) => void
  isChecked: (value: string) => boolean
}

const CheckboxStyled: React.FC<TProps> = ({
  onChange,
  isChecked,
  id,
  label,
  value,
  name
}) => {
  return (
    <div className="checkbox-stld">
      <input
        id={id}
        name={name}
        type={'checkbox'}
        value={value || ''}
        onChange={onChange}
        defaultChecked={isChecked(value)}
      />
      <label htmlFor={id}>
        <FontAwesomeIcon icon={['far', 'check-square']} />
        <FontAwesomeIcon icon={['far', 'square']} /> {label}
      </label>
    </div>
  )
}

export default CheckboxStyled
