import React from 'react'
import { TCarFeature } from 'aplication/useCases/carFeatures/carFeaturesTypes'
import { Tag } from 'view/components/tag'
import './carFeaturesTags.css'

type TProps = {
  features?: TCarFeature[]
}

const CarFeaturesTags: React.FC<TProps> = ({ features }) => {
  function renderTags() {
    if (!features) return <></>
    const filteredFeatures = features.filter((_, index) => index <= 4)
    return filteredFeatures.map((feature, index) => {
      return <Tag key={index}>{feature.description}</Tag>
    })
  }
  return <div className="features-tags">{renderTags()}</div>
}

export default CarFeaturesTags
