import { TApiBlogPost } from 'domain/entities/api/TApiBlogPost'
import { TBlogPost } from 'domain/entities/TBlogPost'

export function blogPostDataParser(data: TApiBlogPost): TBlogPost {
  if (!data) return {} as TBlogPost
  return {
    content: data.content,
    createdAt: data.created_at,
    createdBy: data.created_by,
    id: data.id,
    image: data.image,
    slug: data.slug,
    title: data.title,
    updatedAt: data.updated_at,
    uuid: data.uuid,
    brief: data.brief
  }
}

export function blogPostArrayParser(data: TApiBlogPost[]): TBlogPost[] {
  if (!data || data.length <= 0) return [] as TBlogPost[]
  return data.map(blogPost => blogPostDataParser(blogPost))
}
