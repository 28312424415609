import { cpfValidator } from 'aplication/useCases/cpfValidator/cpfValidator'
import { TValidator } from '..'
import { TField } from '../formComponentsTypes'
import { cnpjValidation } from 'aplication/useCases/cnpjValidator/cnpjValidator'

export const requiredValidator: TValidator = {
  run: (field: TField) => {
    const { value, isRequired } = field
    if (!isRequired) return true
    if (value === null || value === undefined || value === '') {
      return false
    }
    return true
  },
  message: 'Este campo não pode ser vazio.'
}

export const isEmail: TValidator = {
  run: function isEmail(field) {
    const value = field.value
    // eslint-disable-next-line no-useless-escape
    const rgx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
    return rgx.test(`${value}`)
  },

  message: 'Este não é um e-mail válido.'
}

export const isSecurePassword: TValidator = {
  run: function (field) {
    /**
     * 1 - possui um dígio ao menos
     * 2 - possui uma letra ao menos
     * 3 - possio no mínimo 7 caracteres
     */
    const password = `${field.value}`
    const charactersValidate = /(?=.*\d)(?=.*[a-zA-Z]).{7,}/gm
    const sequence = /1234|4321|4567|7654|abcd/gm

    if (!charactersValidate.exec(password) || password.match(sequence)) {
      return false
    }

    return true
  },

  message: 'Esta senha não é válida.'
}

export const confirmPassword: TValidator = {
  run: function (field, fields) {
    const value = field.value
    const passwordFieldValue = fields.find(f => f.name === 'password')?.value
    return value === passwordFieldValue
  },

  message: 'Senhas diferentes.'
}

export const isValidCpf: TValidator = {
  run: function (field) {
    const value = `${field.value}`

    return cpfValidator(value)
  },

  message: 'CPF inválido.'
}

export const isValidCnpj: TValidator = {
  run: function (field) {
    const value = field.value

    return cnpjValidation(value)
  },

  message: 'CNPJ inválido'
}
