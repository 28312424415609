import { TAddressByCepApi } from 'domain/entities/api/TAddressByCepApi'
import { TAddressByCep } from 'domain/entities/TAddressByCep'

export function addressByCepDataParser(data: TAddressByCepApi): TAddressByCep {
  if (!data) return {} as TAddressByCep
  return {
    address: data.logradouro,
    addressDistrict: data.bairro,
    city: data.cidade,
    uf: data.uf
  }
}
