import { TSiteContactSubjectApi } from 'aplication/apiServices/siteContactSubjectsApiService/TSiteContactSubject'
import { TSiteContactSubject } from 'domain/entities/TSiteContactSubject'

export function siteContactSubjectDataParser(
  data: TSiteContactSubjectApi
): TSiteContactSubject {
  if (!data) return {} as TSiteContactSubject
  return { id: data.id, subject: data.assunto }
}

export function siteContactSubjectArrayParser(
  data: TSiteContactSubjectApi[]
): TSiteContactSubject[] {
  if (!data || data.length <= 0) return [] as TSiteContactSubject[]
  return data.map(d => siteContactSubjectDataParser(d))
}
