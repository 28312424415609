import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'view/components/button/button'
import './bookingCardOptions.css'
import BookingCancelButton from '../bookingCancelButton/bookingCancelButton'

type TMenuOption = {
  label: string
  link: string
  conditions?: boolean[]
}

type TProps = {
  bookingId?: string | null
  bookingStatus?: string
}

const BookingCardOptions: React.FC<TProps> = ({ bookingId, bookingStatus }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const elementRef = useRef<any>()

  const menuOptions: TMenuOption[] = [
    { label: 'Ver', link: `/dashboard/reserva/${bookingId}` },
    {
      label: 'Renovar',
      link: `/dashboard/reserva/${bookingId}/renovar`,
      conditions: [bookingStatus === 'em_andamento']
    },
    {
      label: 'Pagar',
      link: `/dashboard/reserva/${bookingId}/pagar`,
      conditions: [bookingStatus === 'pagamento_nao_autorizado']
    }
  ]

  function renderMenuOptions() {
    return menuOptions.map((option, index) => {
      if (option.conditions && option.conditions.every(cond => !cond)) {
        return <></>
      }
      return (
        <li key={index}>
          <Link to={option.link}>{option.label}</Link>
        </li>
      )
    })
  }

  function renderMenu() {
    if (isOpen) {
      return (
        <ul className="booking-card-options-menu">{renderMenuOptions()}</ul>
      )
    }
  }

  function toggleMenu() {
    setIsOpen(!isOpen)
  }

  function detectOutClick() {
    document.addEventListener('mousedown', (e: any) => {
      if (elementRef.current && !elementRef.current.contains(e.target)) {
        setIsOpen(false)
      }
    })
  }

  useEffect(() => {
    detectOutClick()
  }, [])

  return (
    <div className="booking-card-options" ref={elementRef}>
      <BookingCancelButton />
      <Button
        className={`booking-card-options-btn ${isOpen ? 'open' : ''}`}
        size="sm-x"
        onClick={toggleMenu}
      >
        <FontAwesomeIcon icon={['fas', 'ellipsis-h']} />
      </Button>
      {renderMenu()}
    </div>
  )
}

export default BookingCardOptions
