import React, { useContext } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import { TFieldElement } from '../..'
import FieldProvider, { FieldContext } from '../fieldContext'
import 'react-datepicker/dist/react-datepicker.css'
import './dateField.css'

registerLocale('pt-BR', ptBR)

type TProps = TFieldElement & {
  minDate?: Date
  filterDate?: (date: Date) => boolean
  onChange?: (value: Date) => void
  disableWrite?: boolean
  fieldRef?: any
}

const DateComponent: React.FC = () => {
  const { updateField, stateValue, fieldProps } = useContext(FieldContext)
  const {
    name,
    minDate,
    disabled,
    onChange,
    filterDate,
    disableWrite,
    placeholder,
    fieldRef
  } = fieldProps
  function handleChange(date: Date) {
    if (onChange) onChange(date)
    updateField({ name, value: date })
  }

  function disableWriteAction(e: any) {
    e.preventDefault()
    return false
  }

  return (
    <DatePicker
      selected={stateValue}
      onChange={handleChange}
      locale="pt-BR"
      placeholderText={placeholder || 'Data'}
      filterDate={filterDate}
      dateFormat={'dd/MM/yyyy'}
      minDate={minDate}
      disabled={disabled}
      onKeyDown={disableWrite ? disableWriteAction : undefined}
      ref={fieldRef}
    />
  )
}

const DateField: React.FC<TProps> = props => {
  return (
    <FieldProvider fieldProps={props}>
      <DateComponent />
    </FieldProvider>
  )
}

export default DateField
