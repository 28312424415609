import { TCountryApi } from 'aplication/apiServices/countriesApiservice/TCountryApi'
import { TCountry } from './TCountry'

export function countryDataParser(data: TCountryApi): TCountry {
  if (!data) return {} as TCountry
  return {
    createdAt: data.created_at,
    createdBy: data.created_by,
    deletedAt: data.deleted_at,
    enabled: data.enabled,
    id: data.id,
    isDeleted: data.is_deleted,
    name: data.nome,
    sigla: data.sigla,
    updatedAt: data.updated_at,
    uuid: data.uuid
  }
}

export function countryArrayParser(data: TCountryApi[]): TCountry[] {
  if (!data || data.length <= 0) return [] as TCountry[]
  return data.map(d => countryDataParser(d))
}
