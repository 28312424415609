import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'

type TData = {
  // eslint-disable-next-line camelcase
  cliente_uuid: string
  // eslint-disable-next-line camelcase
  password: string
}

export class RecoveryAccountPassword extends ApiService {
  async load(data: TData): Promise<HttpResponse> {
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/update-user-password/`,
      method: 'post',
      body: data,
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
