import React from 'react'
import './fixedTopBar.css'

type TProps = {
  transparentBG?: boolean
}

const FixedTopBar: React.FC<TProps> = ({ children, transparentBG }) => {
  return (
    <div className={`fixed-top-bar ${transparentBG ? 'transparent' : ''}`}>
      <div className="container">{children}</div>
    </div>
  )
}

export default FixedTopBar
