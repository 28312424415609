import React from 'react'
import { CarouselArrows, CarouselDotsNavigator } from 'view/components/carousel'
import './carouselPageHeaderNavs.css'

const CarouselPageHeaderNavs: React.FC = () => {
  return (
    <div className="container carousel-navigators">
      <CarouselDotsNavigator />
      <CarouselArrows />
    </div>
  )
}

export default CarouselPageHeaderNavs
