import { UserEmailExists } from 'aplication/apiServices/userEmailExists/UserEmailExists'
import { useState } from 'react'

type TReturn = {
  data: boolean
  load: (email: string) => void
}

export function userEmailExists(): TReturn {
  const [data, setData] = useState<boolean>(false)
  const apiService = new UserEmailExists()

  function load(email: string) {
    if (!email) return
    apiService.load(email).then(response => {
      if (!response || response.statusCode !== 200 || !response.body) return
      setData(response.body.email_existe)
    })
  }

  return { data, load }
}
