import React, { useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Button from 'view/components/button/button'
import { Dialog, TDialogRef } from 'view/components/dialog'
import {
  CheckboxField,
  FormComponent,
  InputField,
  InputFileField,
  SelectField
} from 'view/components/formComponent'
import SelectCityField from 'view/componentsPages/customFields/selectCityField/selectCityField'
import TitlePage from 'view/components/text/titlePage/titlePage'
import {
  InputCepField,
  InputDocumentField,
  InputEmailField,
  InputPhoneField
} from 'view/componentsPages/customFields'
import PasswordField from 'view/components/formComponent/fields/passwordField/passwordField'
import {
  confirmPassword,
  isSecurePassword
} from 'view/components/formComponent/formComponentValidation/formComponentValidators'
import './accountCreate.css'
import CnhSecuritynumberField from 'view/components/formComponent/fields/cnhSecuritynumberField/cnhSecuritynumberField'
import { useAuthContext } from 'view/contexts/authContext'
import { useAppContext } from 'view/contexts/appContext/appContext'
import { UserApiService } from 'aplication/apiServices/userApiService/userApiService'
import { userDataParserToApiService } from 'aplication/useCases/user/userDataParser'
import { TNewUser } from 'domain/entities/TNewUser'
import SelectUfField from 'view/componentsPages/customFields/selectUfField/selectUfField'
import SelectOriginCountry from 'view/componentsPages/customFields/selectOriginCountry/selectOriginCountry'
import appTexts from 'aplication/localData/appText/appText'
import InputCnhField from 'view/componentsPages/customFields/inputCnhField/inputCnhField'

const AccountCreate: React.FC = () => {
  const history = useHistory()
  const dialogRef = useRef<TDialogRef>({} as TDialogRef)
  const { organizationInfo } = useAppContext()
  const { updateUserUuid } = useAuthContext()
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false)

  function onSubmit(formData: TNewUser) {
    setSubmitDisabled(true)
    dialogRef.current?.loadMessage('Enviando dados...')
    const serviceApi = new UserApiService()
    const formDataSend = userDataParserToApiService(formData)
    serviceApi.load(formDataSend).then(response => {
      if (response.statusCode !== 200 && response.statusCode !== 201) {
        const msg = appTexts[response.body.msg] || 'Cadastro não realizado.'
        dialogRef.current?.changeMessage(msg, 'danger')
        setTimeout(() => {
          setSubmitDisabled(false)
        }, 500)
        return
      }
      updateUserUuid(response.body.uuid)
      setTimeout(() => {
        history.replace('/usuario-cadastrado-com-sucesso')
      }, 1000)
    })
  }

  return (
    <div
      className="account-create-background"
      style={{
        backgroundImage: `url(${organizationInfo?.imgBackgroundLogin})`
      }}
    >
      <div className="container">
        <div className="account-create-box">
          <Dialog ref={dialogRef} />
          <TitlePage>Cadastro</TitlePage>
          <Link
            className="account-create-pjlink"
            to={'/terceirizacao-de-frota?modalpjopen=true'}
          >
            (Para empresas, clique aqui.)
          </Link>
          <div className="form-account">
            <FormComponent onSubmit={onSubmit}>
              <div className="form-session">
                <InputField
                  name="name"
                  placeholder="Nome completo"
                  required
                  maxLength={200}
                />
                <SelectField
                  name="documentType"
                  placeholder="Selecione uma opção"
                  options={[
                    { value: 'CPF', label: 'Brasileiro' },
                    { value: 'PASSAPORTE', label: 'Outra Nacionalidade' }
                  ]}
                  required
                />
                <SelectOriginCountry />
                <InputDocumentField />
                <InputEmailField required />
                <InputCepField required />
                <InputField
                  name="address"
                  placeholder="Endereço"
                  required
                  maxLength={200}
                />
                <InputField
                  name="addressDistrict"
                  placeholder="Bairro"
                  maxLength={100}
                  required
                />
                <InputField
                  name="complement"
                  placeholder="Complemento"
                  maxLength={200}
                />
                <SelectUfField required />
                <SelectCityField required />
                <InputPhoneField required />
                <InputCnhField />
                <CnhSecuritynumberField />

                <InputFileField
                  name="cnhFile"
                  placeholder="Anexar arquivo da CNH"
                />
                <InputFileField
                  name="addressFile"
                  placeholder="Anexar Comprovante de residência"
                />

                <PasswordField
                  name="password"
                  placeholder="Senha"
                  validators={[isSecurePassword]}
                  required
                />

                <div className="password-rules color-gray1">
                  {appTexts.display_field_password_instructions}
                </div>

                <PasswordField
                  name="passwordConfirm"
                  placeholder="Confirme a Senha"
                  validators={[confirmPassword]}
                  required
                  maxLength={20}
                />
              </div>
              <div className="form-session">
                <CheckboxField
                  name="okTerms"
                  label={
                    <>
                      Li e concordo com a{' '}
                      <Link to={'/politica-de-privacidade'} target="_blank">
                        Política de Privacidade
                      </Link>{' '}
                      e{' '}
                      <Link to={'contrato-de-locacao'} target="_blank">
                        Termos de uso do serviço
                      </Link>
                    </>
                  }
                  required
                  labelLink
                />
                <CheckboxField
                  name="okEmail"
                  label="Autorizo o envio de e-mails promocionais da Realiza."
                />
              </div>
              <div className="form-button">
                <Button type="submit" full size="bg" disabled={submitDisabled}>
                  Criar minha conta
                </Button>
              </div>
            </FormComponent>
          </div>
          <div className="swith_to_login">
            Já tem uma conta? <Link to="/login">Login</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountCreate
