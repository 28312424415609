import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import FixedTopBar from 'view/pages/rentingPage/fixedTopBar/fixedTopBar'
import RentingClientInfoBar from 'view/pages/rentingPage/rentingClientInfoBar/rentingClientInfoBar'
import DashboardBookingEdit from './dashboardBookingEdit'
import DashBoardBookingEditPayment from './dashboardBookingEditPayment'
import DashboardBookingEditPaymentSuccess from './dashboardBookingEditPaymentSuccess'

const DashboardBookingEditRouter: React.FC = () => {
  const { path } = useRouteMatch()

  return (
    <>
      <FixedTopBar transparentBG>
        <RentingClientInfoBar />
      </FixedTopBar>
      <Switch>
        <Route exact path={`${path}`} component={DashboardBookingEdit} />
        <Route
          exact
          path={`${path}/pagar`}
          component={DashBoardBookingEditPayment}
        />
        <Route
          exact
          path={`${path}/success`}
          component={DashboardBookingEditPaymentSuccess}
        />
      </Switch>
    </>
  )
}

export default DashboardBookingEditRouter
