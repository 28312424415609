import React from 'react'
import { newShortcode } from '../../aplication/useCases/shortCodes/shortCodesFunctions'

type TAttributes = {
  url?: string
  text?: string
  size?: string
}

const ScLinkButtonComponent: React.FC<TAttributes> = ({ url, size, text }) => {
  size = size || 'md'
  text = text || ''
  url = url || ''

  return (
    <a href={url} className={`btn btn-${size} btn-orange`}>
      <span dangerouslySetInnerHTML={{ __html: text }} />
    </a>
  )
}

export default newShortcode({
  scName: 'scButton',
  Component: ScLinkButtonComponent
})
