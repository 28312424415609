import { useState } from 'react'
import { BlogPostApiService } from 'aplication/apiServices/blogPost/blogPostApiService'
import { TBlogPost } from 'domain/entities/TBlogPost'
import { blogPostDataParser } from './blogPostDataParser'

type TUseBlogPostsRead = {
  post: TBlogPost
  loading: boolean
  readPost: (id: string) => void
}

export function useBlogPostRead(): TUseBlogPostsRead {
  const [post, setPost] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)

  function readPost(slug: string) {
    setLoading(true)
    const apiService = new BlogPostApiService()
    apiService.read(slug).then(response => {
      if (!response || response.statusCode !== 200 || !response.body) {
        console.error('post_read_error')
        return
      }
      const firstFronArray = response.body.results[0]
      const newPost = blogPostDataParser(firstFronArray)
      setPost(newPost)
      setTimeout(() => {
        setLoading(false)
      }, 100)
    })
  }

  return { post, readPost, loading }
}
