import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
// import { useAppContext } from 'view/contexts/appContext/appContext'
import './whatsappButton.css'

type TProps = {
  className?: string
  text?: string
}

const WhatsappButton: React.FC<TProps> = ({ className, text }) => {
  // const { organizationInfo } = useAppContext()
  // const whatsAppNumber = organizationInfo?.whatsapp
  //   ?.replace('(', '')
  //   .replace(')', '')
  //   .replace('-', '')
  //   .replace(' ', '')

  function onClick() {
    const targetButton = document.getElementById('rd-floating_button-ly4nl66c')
    if (targetButton) {
      targetButton.click()
    }
  }

  return (
    <div className={`whatsapp-button ${className || ''}`}>
      <a className="btn-slave-whats" href={'#whats'} onClick={onClick}>
        <FontAwesomeIcon icon={['fab', 'whatsapp']} />
        <span>{text || 'Fale conosco'}</span>
      </a>
    </div>
  )
}

export default WhatsappButton
