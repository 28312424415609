import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'
import { TBookingPjDataApi } from './TBookingPjDataApi'

export class BookingPjApiService extends ApiService {
  async postData(body: TBookingPjDataApi): Promise<HttpResponse> {
    return this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/proposta-pj/`,
      method: 'post',
      body,
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
