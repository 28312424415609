import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import DashboardBookingEditRouter from './dashboardBookingEdit/dashboardBookingEditRouter'
import DashBoardBookingPayment from './dashBoardBookingPayment/dashBoardBookingPayment'
import DashboardBookingPaymentSuccess from './dashBoardBookingPayment/dashboardBookingPaymentSuccess'
import DashboardBookingSingle from './dashboardBookingSingle/dashboardBookingSingle'

const DashboardBookingRouter: React.FC = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${path}`} component={DashboardBookingSingle} />
      <Route path={`${path}/renovar`} component={DashboardBookingEditRouter} />
      <Route exact path={`${path}/pagar`} component={DashBoardBookingPayment} />
      <Route
        exact
        path={`${path}/pagar/success`}
        component={DashboardBookingPaymentSuccess}
      />
    </Switch>
  )
}

export default DashboardBookingRouter
