import { FaqDataService } from 'aplication/apiServices/faqDataService/faqDataService'
import { TFaq } from 'domain/entities/TFaq'
import { useEffect, useState } from 'react'
import { faqArrayParser } from './faqDataParser'

export type TUseFaqList = {
  faqList: TFaq[]
  loaded: boolean
  totalFaq: number
  setQuery: (query: string) => void
  loadMoreFaqList: () => void
  findFaqItem: (titleLike: string) => any
}

export function useFaqList(): TUseFaqList {
  const [faqList, setFaqList] = useState<TFaq[]>([] as TFaq[])
  const [loaded, setLoaded] = useState<boolean>(false)
  const [totalFaq, setTotalFaq] = useState<number>(0)
  const [nextPage, setNextPage] = useState<number>(1)
  const [query, setQuery] = useState<string | undefined>()
  const apiService = new FaqDataService()

  function loadData() {
    setLoaded(false)
    apiService.load({ query }).then(response => {
      if (
        !response ||
        response.statusCode !== 200 ||
        !response.body ||
        !response.body.results
      ) {
        console.error('api_faq_load_error')
        return
      }
      setNextPage(2)
      setFaqList(faqArrayParser(response.body.results))
      setTotalFaq(response.body.count)
      setTimeout(() => setLoaded(true), 100)
    })
  }

  function faqListNextPage(params: { page: number; query?: string }) {
    apiService.load(params).then(response => {
      if (
        !response ||
        response.statusCode !== 200 ||
        !response.body ||
        !response.body.results
      ) {
        console.error('api_faq_paged_error')
        return
      }
      const dataArray = faqArrayParser(response.body.results)
      setTimeout(() => {
        setFaqList([...faqList, ...dataArray])
      }, 100)
    })
  }

  function hasMoreFaq() {
    return faqList.length >= totalFaq
  }

  function loadMoreFaqList() {
    if (!hasMoreFaq) return
    faqListNextPage({ page: nextPage, query })
    setNextPage(nextPage + 1)
  }

  function findFaqItem(titleLike: string): string {
    const foundFa = faqList.find(item => item.question.includes(titleLike))
    if (!foundFa) return ''
    return `${foundFa.id}`
  }

  useEffect(() => loadData(), [])
  useEffect(() => {
    if (!query && query !== '') return
    loadData()
  }, [query])

  return { faqList, loaded, totalFaq, loadMoreFaqList, findFaqItem, setQuery }
}
