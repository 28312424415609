import { HttpResponse } from 'aplication/apiServices/protocols/IHttpClient'
import { TApiPagedBody } from 'domain/entities/api/TApiPagedBody'
import { ApiService } from '../apiService'
import { TCountryApi } from './TCountryApi'

type TResponseData = TApiPagedBody & {
  results: TCountryApi[]
}

export class CountriesApiService extends ApiService {
  async load(): Promise<HttpResponse<TResponseData>> {
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/paises/`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
