import React from 'react'
import { useFormContext } from 'view/components/formComponent'
import SelectFieldLocations from 'view/componentsPages/bookingForm/selectFieldLocations/selectFieldLocations'

const DashboardBookingEditLocationField: React.FC = () => {
  const { resetFields } = useFormContext()

  function onChange() {
    resetFields()
  }

  return (
    <SelectFieldLocations
      name="returnLocation"
      placeholder="Local de devolução"
      onChange={onChange}
    />
  )
}

export default DashboardBookingEditLocationField
