import React, { useContext, useEffect, useRef, useState } from 'react'
import { CarouselContext } from '../carouselContext'
import './carouselDotsNavigator.css'

type TProps = {
  orange?: boolean
}

const CarouselDotsNavigator: React.FC<TProps> = ({ orange }) => {
  const { updateCurrentposition, currentPosition, positions } =
    useContext(CarouselContext)
  const navigators = useRef<any>()
  const [current, setCurrent] = useState<number>(0)

  function dotNavigation(event: any) {
    const element = event.target
    if (!element) return
    const dotNumber = parseInt(element?.id.split('-')[2])
    updateCurrentposition(dotNumber)
  }

  function removeCurrentDot() {
    const navigatorElement = navigators.current
    if (!navigatorElement) return
    const hasCurrent = navigatorElement.querySelector('.current')
    if (hasCurrent === null) return
    hasCurrent.classList.remove('current')
  }

  function markCurrentDot() {
    const navigatorElement = navigators.current
    if (!navigatorElement) return
    const currentNav = navigatorElement.querySelector(`#nav-item-${current}`)
    currentNav?.classList.add('current')
  }

  function buildNavigationDots() {
    return positions.map((d, index) => {
      return (
        <li key={index}>
          <span
            id={`nav-item-${index}`}
            className={index === current ? 'current' : ''}
            onClick={dotNavigation}
          ></span>
        </li>
      )
    })
  }

  function setCurrentDot() {
    const penultimate = positions[positions.length - 2]
    positions.forEach(d => {
      if (
        currentPosition.xPosition === d.xPosition ||
        (penultimate &&
          currentPosition.xPosition > penultimate.xPosition &&
          currentPosition.xPosition < d.xPosition)
      ) {
        setCurrent(d.id)
      }
    })
  }

  function renderNavigator() {
    if (!positions || positions.length <= 1) return <></>
    return (
      <div className={`carousel-dot-navigators${orange ? ' orange' : ''}`}>
        <ul ref={navigators}>{buildNavigationDots()}</ul>
      </div>
    )
  }

  useEffect(() => {
    setCurrentDot()
  }, [currentPosition])

  useEffect(() => {
    removeCurrentDot()
    markCurrentDot()
  }, [current])

  return <>{renderNavigator()}</>
}

export default CarouselDotsNavigator
