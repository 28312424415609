import React from 'react'
import Button from 'view/components/button/button'
import Faq from 'view/components/faq/faq'
import { ContactTime } from '../contactTime'
import './faqBlock.css'

const FaqBlock: React.FC = () => {
  return (
    <div className="faq-block">
      <div className="faq-block-info">
        <h2>Perguntas frequentes</h2>
        <div className="faq-block-info-contact">
          Se ainda tiver alguma dúvida entre em contato com nossa{' '}
          <Button style="link">central de atendimento</Button>
        </div>
        <ContactTime />
      </div>
      <div className="faq-block-questions">
        <Faq limitItens={3} totalCols={1} />
      </div>
    </div>
  )
}

export default FaqBlock
