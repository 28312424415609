import appTexts from 'aplication/localData/appText/appText'
import { userUpdatePassword } from 'aplication/useCases/userUpdatePassword/userUpdatePassword'
import { objectIsEmpty } from 'aplication/utils/app/testValues'
import React, { useEffect, useRef, useState } from 'react'
import Button from 'view/components/button/button'
import { Dialog, TDialogRef } from 'view/components/dialog'
import { FormComponent, InputField } from 'view/components/formComponent'
import PasswordField from 'view/components/formComponent/fields/passwordField/passwordField'
import {
  confirmPassword,
  isSecurePassword
} from 'view/components/formComponent/formComponentValidation/formComponentValidators'
import TitlePage from 'view/components/text/titlePage/titlePage'
import { useAuthContext } from 'view/contexts/authContext'

const AccountEditPasswordForm: React.FC = () => {
  const { loggedUser } = useAuthContext()
  const [formData, setFormData] = useState<any>({})
  const dialogRef = useRef<TDialogRef>({} as TDialogRef)

  function onSubmit(data: any, resetFields: () => void) {
    if (!data) return
    const { password, oldPassword } = data
    let msg = 'Enviando dados...'
    dialogRef.current?.loadMessage(msg)
    userUpdatePassword({
      password,
      uuid: loggedUser?.person.uuid,
      oldPassword
    }).then(response => {
      if (!response || response.statusCode !== 200 || !response.body) {
        console.error('client_update_error')
        msg =
          appTexts[response.body.error] ||
          'Não foi possível atualizar os dados.'
        dialogRef.current?.changeMessage(msg, 'danger')
        resetFields()
      } else {
        msg = appTexts[response.body.error] || 'Dados atualizados com sucesso.'
        dialogRef.current?.changeMessage(msg, 'success')
        resetFields()
      }
    })
  }

  return (
    <div className="form-edit-password">
      <Dialog ref={dialogRef} />
      <FormComponent fieldValues={formData} onSubmit={onSubmit}>
        <TitlePage>Editar senha</TitlePage>
        <div className="form-session">
          <PasswordField
            name="oldPassword"
            placeholder="Senha atual"
            required
          />
          <PasswordField
            name="password"
            placeholder="Nova senha"
            validators={[isSecurePassword]}
            required
          />
          <PasswordField
            name="passwordConfirm"
            placeholder="Confirme a nova senha"
            validators={[confirmPassword]}
            required
            maxLength={20}
          />
        </div>
        <div className="form-button">
          <Button type="submit">Editar senha</Button>
        </div>
      </FormComponent>
    </div>
  )
}

export default AccountEditPasswordForm
