import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'
import { TSiteContactApi } from './siteContactApiServiceTypes'

export class SiteContactApiService extends ApiService {
  async load(body: TSiteContactApi): Promise<HttpResponse> {
    return this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/fale-conosco/`,
      method: 'post',
      body,
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
