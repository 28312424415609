import React from 'react'
import { useFormContext } from 'view/components/formComponent'
import { useDisabledController } from 'view/hooksPages'

const BookingButtonSubmit: React.FC = () => {
  const { fields } = useFormContext()
  const { disabled } = useDisabledController(fields, [
    'pickupLocation',
    'pickupDate',
    'pickupTime',
    'returnLocation',
    'returnDate',
    'returnTime'
  ])

  return (
    <button className="form-btn" type="submit" disabled={disabled}>
      Continuar
    </button>
  )
}

export default BookingButtonSubmit
