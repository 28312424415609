import React, { useEffect } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch
} from 'react-router-dom'
import { CarGroups } from './carGroupsList'
import { Booking } from './booking'
import { BookingDone } from './bookingDone'
import './rentingPage.css'
import { PaymentPage } from './paymentPage'
import { BookingDataProvider } from 'view/contexts/bookingDataContext'
import BookingValuesProvider from 'view/contexts/bookingValuesContext'
import RentingPageProvider from './rentingPageContext'
import FixedTopBar from './fixedTopBar/fixedTopBar'
import { useAuthContext } from 'view/contexts/authContext'
import RentingClientInfoBar from './rentingClientInfoBar/rentingClientInfoBar'
import RentingStepsList from 'view/componentsPages/rentingStepsList/rentingStepsList'
import BookingInvalidData from './bookingInvalidData/bookingInvalidData'
import BookingACar from '../bookingACar/bookingACar'

const RentingPage: React.FC = () => {
  const { loadCurrentUser } = useAuthContext()
  const { path } = useRouteMatch()

  useEffect(() => {
    loadCurrentUser()
  }, [])

  return (
    <BookingDataProvider localStorage>
      <BookingValuesProvider>
        <RentingPageProvider>
          <FixedTopBar>
            <RentingClientInfoBar />
            <RentingStepsList />
          </FixedTopBar>
          <div className="renting-page-wrapper">
            <Switch>
              <Route exact path={`${path}`}>
                <Redirect to={`${path}/grupos`} />
              </Route>
              <Route exact path={`${path}/grupos`} component={CarGroups} />
              <Route exact path={`${path}/reserva`} component={Booking} />
              <Route exact path={`${path}/pagamento`} component={PaymentPage} />
              <Route
                exact
                path={`${path}/reserva-invalida`}
                component={BookingInvalidData}
              />
              <Route
                exact
                path={`${path}/reserva_concluida`}
                component={BookingDone}
              />
            </Switch>
          </div>
        </RentingPageProvider>
      </BookingValuesProvider>
    </BookingDataProvider>
  )
}

export default RentingPage
