import { useState } from 'react'
import { BlogPostApiService } from 'aplication/apiServices/blogPost/blogPostApiService'
import { TBlogPost } from 'domain/entities/TBlogPost'
import { blogPostArrayParser } from './blogPostDataParser'

export type TUseBlogPosts = {
  posts: TBlogPost[]
  totalPosts: number
  loading: boolean
  loadPosts: (filter?: any) => void
  loadMorePosts: () => void
}

export function useBlogPosts(): TUseBlogPosts {
  const [posts, setPosts] = useState<TBlogPost[]>([] as TBlogPost[])
  const [totalPosts, setTotalPosts] = useState<number>(0)
  const [nextPage, setNextPage] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(false)

  const apiService = new BlogPostApiService()

  function loadPosts(filters?: string) {
    setLoading(true)
    apiService.load(filters).then(response => {
      if (
        !response ||
        response.statusCode !== 200 ||
        !response.body ||
        !response.body.results
      ) {
        console.error('car_shop_cars_load_error')
        return
      }

      const allPosts = blogPostArrayParser(response.body.results)
      setNextPage(2)
      setPosts(allPosts)
      setTotalPosts(response.body.count)

      setTimeout(() => {
        setLoading(false)
      }, 500)
    })
  }

  function loadNextPage(page: number, filters?: string) {
    setLoading(false)
    apiService.load(filters, page).then(response => {
      if (
        !response ||
        response.statusCode !== 200 ||
        !response.body ||
        !response.body.results
      ) {
        console.error('car_shop_cars_load_error')
        return
      }
      const newPosts = blogPostArrayParser(response.body.results)
      setTimeout(() => {
        setPosts([...posts, ...newPosts])
      }, 500)
    })
  }

  function hasMorePosts() {
    return posts.length >= totalPosts
  }

  function loadMorePosts() {
    if (!hasMorePosts) return
    loadNextPage(nextPage)
    setNextPage(nextPage + 1)
  }

  return {
    loading,
    loadMorePosts,
    loadPosts,
    posts,
    totalPosts
  }
}
