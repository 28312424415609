import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

type TProps = {
  loginType: string
  setLoginType: (value: string) => void
}

const SelectFieldLoginType: React.FC<TProps> = ({
  loginType,
  setLoginType
}) => {
  const options = [
    {
      id: 'cpf',
      label: 'CPF'
    },
    {
      id: 'cnpj',
      label: 'CNPJ'
    },
    {
      id: 'noMask',
      label: 'Passaporte'
    }
  ]

  function onClick(e: any) {
    const value = e.target.value
    setLoginType(value)
  }

  function isChecked(name: string) {
    return loginType === name
  }

  function renderIcon(id: string) {
    if (isChecked(id)) return <FontAwesomeIcon icon={['far', 'check-square']} />
    return <FontAwesomeIcon icon={['far', 'square']} />
  }

  function renderOptions() {
    return options.map((option, index) => {
      return (
        <div key={index}>
          <input
            id={option.id}
            name="loginType"
            type={'radio'}
            value={option.id || ''}
            onClick={onClick}
            defaultChecked={isChecked(option.id)}
          />
          <label htmlFor={option.id}>
            {renderIcon(option.id)} {option.label}
          </label>
        </div>
      )
    })
  }

  return <div className="select-login-type">{renderOptions()}</div>
}

export default SelectFieldLoginType
