import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import PageContainerPromo from 'view/componentsPages/pageContainerPromo/pageContainerPromo'
import { AccountEdit } from './accountEdit'
import { AccountShow } from './accountShow'

const Account: React.FC = () => {
  const { path } = useRouteMatch()

  return (
    <>
      <Switch>
        <Route exact path={`${path}`} component={AccountShow} />
        <Route exact path={`${path}/editar`} component={AccountEdit} />
      </Switch>
    </>
  )
}

export default Account
