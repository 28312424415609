import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'

type TResponseData = {
  foreign: boolean
  corporate: boolean
  type: string
  provider: string
  status: string
  prepaid: boolean
}

export class CardBrandApiSevice extends ApiService {
  async load(cardNumber: string): Promise<HttpResponse<TResponseData>> {
    return this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/find-bin/?card_number=${cardNumber}`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
