import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TCarFeature } from 'aplication/useCases/carFeatures/carFeaturesTypes'
import './carGroupFeatures.css'

type TProps = {
  features?: TCarFeature[]
}

const CarGroupFeatures: React.FC<TProps> = ({ features }) => {
  function renderFeatures() {
    if (!features) return <></>
    return features.map((feature, index) => {
      return (
        <li key={index}>
          <FontAwesomeIcon icon={['fas', 'check']} />
          {feature?.description}
        </li>
      )
    })
  }

  return <ul className="car-features">{renderFeatures()}</ul>
}

export default CarGroupFeatures
