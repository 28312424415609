import React from 'react'
import { renderToString } from 'react-dom/server'

type TNewShortCode = (shortCode: string, content: string) => string

export type TSCAttributes<T = any> = T

export function selectname(sc: string): string | null {
  const name = sc.match(/[\w-]+/)
  if (!name) return null
  return name[0]
}
export function selectAttributes(sc: string): TSCAttributes {
  const attributes: { [key: string]: string } = {}
  const attributesArr = sc.match(/[\w-]+=".*?"/g)
  if (!attributesArr) return attributesArr
  attributesArr.forEach(attribute => {
    const attr = attribute.match(/([\w-]+)="(.*?)"/)
    if (!attr) return
    attributes[attr[1]] = attr[2]
  })
  return attributes
}

export function newShortcode(params: {
  scName: string
  Component: React.FC
}): TNewShortCode {
  const { scName, Component } = params
  return (shortCode: string, content: string): string => {
    const name = selectname(shortCode)
    if (!name || name !== scName) return content
    const attr: TSCAttributes = selectAttributes(shortCode)
    const componentHtml = renderToString(React.createElement(Component, attr))
    return content.replace(shortCode, componentHtml)
  }
}
