import React, { useEffect, useState } from 'react'
import TitlePage from 'view/components/text/titlePage/titlePage'
import { useOrganizationServices } from 'aplication/useCases/organizationServices/organizationServices'
import { TOrganizationService } from 'aplication/useCases/organizationServices/organizationServicesTypes'
import { useHistory, useParams } from 'react-router-dom'
import AboutusServices from 'view/componentsPages/aboutusServices/aboutusServices'
import { useAppContext } from 'view/contexts/appContext/appContext'
import { useShortcodes } from 'view/hooksPages/useShortCodes'

const AboutUsService: React.FC = () => {
  const history = useHistory()
  const params: any = useParams()
  const { pageInfos } = useAppContext()
  const { services, filterData } = useOrganizationServices()
  const [service, setService] = useState<TOrganizationService>(
    {} as TOrganizationService
  )

  const shortCodes = useShortcodes()

  useEffect(() => {
    if (params.slug && params.slug === 'seminovos') {
      history.replace('/seminovos')
    }
    if (params.slug && params.slug === 'terceirizacao-de-frotas') {
      history.replace('/terceirizacao-de-frota')
    }

    setService(filterData(params.slug))
  }, [services, params])

  useEffect(() => {
    if (!service) return
    pageInfos.update({
      pageTitle: service?.title,
      pageDescription: service?.brief
    })
  }, [service])

  return (
    <div id="aboutus-service-page" className="aboutus-page">
      <div className="container">
        <TitlePage>{service?.title}</TitlePage>
        <div
          className="aboutus-content-2"
          dangerouslySetInnerHTML={{ __html: shortCodes.run(service?.content) }}
        />

        <AboutusServices />
      </div>
    </div>
  )
}

export default AboutUsService
