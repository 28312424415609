import React from 'react'
import { useAppContext } from 'view/contexts/appContext/appContext'

type TProps = {
  className?: string
}

const PhoneNumber: React.FC<TProps> = ({ className }) => {
  const { organizationInfo } = useAppContext()
  return (
    <div className={`phone ${className || ''}`}>{organizationInfo?.phone}</div>
  )
}

export default PhoneNumber
