import { TBookingCardData } from 'domain/entities/TBooking'
import { TCredCard } from 'domain/entities/TCredCard'
import React from 'react'
import Button from 'view/components/button/button'
import { FormComponent, SelectField } from 'view/components/formComponent'
import SelectPaymentFormat from './selectPaymentFormat'
type TProps = {
  onSubmit: (values: TBookingCardData) => void
  credCards: TCredCard[]
  newCardBtnAction: () => void
}

const BookingPaymentFormFormSelectCard: React.FC<TProps> = ({
  onSubmit,
  credCards,
  newCardBtnAction
}) => {
  function renderSelectOptions() {
    return credCards.map(card => {
      return {
        label: `${card.cardBrand} | ${card.cardNumber}`,
        value: `${card.uuid}`
      }
    })
  }

  return (
    <>
      <h2>Cartão de Crédito</h2>
      <FormComponent onSubmit={onSubmit}>
        <div className="form-session">
          <SelectField
            name={'cardUuid'}
            options={renderSelectOptions()}
            placeholder="Selecione um cartão"
            required
          />
        </div>
        <div className="form-session">
          <SelectPaymentFormat />
        </div>
        <div className="form-session">
          <Button type="submit" size="bg" full>
            Confirmar
          </Button>
        </div>
      </FormComponent>
      <Button
        className="use-card-btn"
        style="transparent"
        onClick={newCardBtnAction}
      >
        Usar um novo cartão
      </Button>
    </>
  )
}

export default BookingPaymentFormFormSelectCard
