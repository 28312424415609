import { createContext, useContext } from 'react'

type TModalContext = {
  open: boolean
  toggleOpen: (value?: boolean) => void
}

export const ModalContext = createContext<TModalContext>({} as TModalContext)

export function useModalContext() {
  return useContext(ModalContext)
}
