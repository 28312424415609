import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './instagramButton.css'

const InstagramButton: React.FC = () => {
  return (
    <a
      href={'https://www.instagram.com/realizarentacar/'}
      target="_blank"
      rel="noreferrer"
      title="Instagram"
      className="instagram-button"
    >
      <FontAwesomeIcon icon={['fab', 'instagram']} />
    </a>
  )
}

export default InstagramButton
