import { useEffect, useState } from 'react'
import { useBookingDataContext } from 'view/contexts/bookingDataContext'
import { useBookingValuesContext } from 'view/contexts/bookingValuesContext'

type TUseBookingCalc = {
  total: number
}

export function useBookingCalc(): TUseBookingCalc {
  const [total, setTotal] = useState<number>(0)
  const [discountTotal, setDiscountTotal] = useState<number>(0)
  const { bookingData, updateBookingData } = useBookingDataContext()

  const {
    resources,
    driversCalc,
    bookingDaily,
    protections,
    carGroupPriceRules
  } = useBookingValuesContext()

  const { discount } = carGroupPriceRules
  const { resourcesTotalDaily, resourcesTotal } = resources
  const {
    daily,
    dailyTotal,
    dailyValue,
    locationTax,
    extraHour,
    extraHourValue
  } = bookingDaily
  const { protectionTotal, protectionSelected } = protections

  function calcTotal() {
    protections.calcProtectionDailyTotal(
      bookingDaily.daily,
      bookingData.carGroup?.protection
    )

    if (
      !dailyTotal ||
      (!resourcesTotalDaily && resourcesTotalDaily !== 0) ||
      (!dailyTotal && dailyTotal !== 0)
    ) {
      console.error('incomplet_calc')
      return null
    }
    const total =
      dailyTotal +
      locationTax +
      resourcesTotalDaily +
      protectionTotal +
      extraHourValue +
      driversCalc.driversValues.driversTotalTax

    let totalDiscount = 0
    if (daily && discount?.discountValue) {
      totalDiscount = discount?.discountValue * daily
    }

    setTotal(total)
    setDiscountTotal(totalDiscount)
  }

  useEffect(() => {
    calcTotal()
  }, [bookingData, resourcesTotalDaily, protections])

  useEffect(() => {
    updateBookingData({
      daily,
      dailyValue,
      dailyTotal,
      resourcesTotal,
      resourcesTotalDaily,
      discount,
      protectionTotal,
      protection: protectionSelected,
      totalToPay: total,
      discountTotal,
      pickupTax: bookingData.pickup?.location?.tax || 0,
      returnTax: bookingData.return?.location?.tax || 0,
      driversTax: driversCalc.driversValues.driversTax,
      driversTotalTax: driversCalc.driversValues.driversTotalTax,
      extraHour,
      extraHourValue
    })
  }, [total])

  return { total }
}
