import React, { useContext } from 'react'
import Driver from 'view/componentsPages/driver/driver'
import { DriversContext } from './bookingDriversList'

const BookingDrivers: React.FC = () => {
  const { drivers, removeDriver } = useContext(DriversContext)

  function renderDrivers() {
    if (!drivers || drivers.length <= 0) return <></>
    return (
      <div className="drivers-list">
        {drivers.map((driver, index) => {
          return (
            <Driver
              key={index}
              driver={driver}
              id={`${index}`}
              removeDriver={removeDriver}
            />
          )
        })}
      </div>
    )
  }

  return <>{renderDrivers()}</>
}

export default BookingDrivers
