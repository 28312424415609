import { HttpResponse } from 'aplication/apiServices/protocols/IHttpClient'
import { UserUpdateActivatioConde } from 'aplication/apiServices/userUpdateActivatioConde/userUpdateActivatioConde'

export async function userUpdateActivatioConde(
  clientUuid: string
): Promise<HttpResponse> {
  const serviceData = new UserUpdateActivatioConde()
  const result = await serviceData.load(clientUuid)

  return result
}
