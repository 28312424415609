import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'

export class CepApiService extends ApiService {
  async load(cep: string): Promise<HttpResponse> {
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/consulta-cep/?cep=${cep}`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
