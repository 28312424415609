import { nationalities } from 'aplication/localData/countryNationality/countryNationality'
import React, { useEffect, useState } from 'react'
import { SelectField, useFormContext } from 'view/components/formComponent'
import SelectCountryField from '../selectCountryField/selectCountryField'

const SelectOriginCountry: React.FC = () => {
  const [docType, setDocType] = useState<string>()
  const [show, setShow] = useState<boolean>(false)
  const { fields, updateField } = useFormContext()
  function renderField() {
    if (!show) return <></>
    return <SelectCountryField required />
  }

  useEffect(() => {
    const documentField = fields.find(f => f.name === 'documentType')
    if (
      !documentField ||
      !documentField.value ||
      documentField.value === docType
    ) {
      return
    }
    setDocType(documentField.value)
  }, [fields])

  useEffect(() => {
    let showStatus
    if (docType === 'PASSAPORTE') {
      showStatus = true
    } else {
      showStatus = false
    }
    updateField({ name: 'origin', isRequired: showStatus })
    setShow(showStatus)
  }, [docType])

  return <>{renderField()}</>
}

export default SelectOriginCountry
