import { UserIsActivated } from 'aplication/apiServices/userIsActivated/userIsActivated'

export async function userIsActivated(clientUuid: string): Promise<boolean> {
  const apiDataService = new UserIsActivated()
  const response = await apiDataService.load(clientUuid)

  if (!response.body) return false

  return response.body.account_activated
}
