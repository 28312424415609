import { useOrganizationServices } from 'aplication/useCases/organizationServices/organizationServices'
import { TOrganizationService } from 'aplication/useCases/organizationServices/organizationServicesTypes'
import React, { useEffect, useState } from 'react'
import DinamicContent from 'view/components/dinamicContent/dinamicContent'
import TitlePage from 'view/components/text/titlePage/titlePage'
import { BookingForm } from 'view/componentsPages/bookingForm'
import ServicePageTemplate from 'view/componentsPages/servicePageTemplate/servicePageTemplate'

const BookingACar: React.FC = () => {
  const { services, filterData } = useOrganizationServices()
  const [service, setService] = useState<TOrganizationService>(
    {} as TOrganizationService
  )

  useEffect(() => {
    setService(filterData('aluguel-de-carros'))
  }, [services])

  return (
    <div id="booking-a-car-page">
      <ServicePageTemplate>
        <TitlePage>Aluguel de carros</TitlePage>

        <BookingForm title="Faça sua reserva" />

        <DinamicContent content={service?.content} />
      </ServicePageTemplate>
    </div>
  )
}

export default BookingACar
