import { HttpResponse } from 'aplication/apiServices/protocols/IHttpClient'
import { SiteContactApiService } from 'aplication/apiServices/siteContactApiService/siteContactApiService'
import { TSiteContactApi } from 'aplication/apiServices/siteContactApiService/siteContactApiServiceTypes'
import { TSiteContactPost } from './siteContactPostTypes'

function formatPhone(phone: string): string {
  return phone
    .replace('(', '')
    .replace(')', '')
    .replace('-', '')
    .replace(' ', '')
}

function dataTypeParser(data: TSiteContactPost): TSiteContactApi {
  return {
    assunto: data.subject,
    nome_cliente: data.name,
    titulo: data.title,
    telefone: formatPhone(data.phone),
    email: data.email,
    msg: data.text
  }
}

export async function SiteContactPost(
  data: TSiteContactPost
): Promise<HttpResponse> {
  const apiService = new SiteContactApiService()
  return await apiService.load(dataTypeParser(data))
}
