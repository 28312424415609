import React from 'react'
import { InputField, SelectField } from 'view/components/formComponent'
import { useBookingValuesContext } from 'view/contexts/bookingValuesContext'

const SelectPaymentFormat: React.FC = () => {
  const { dailyTotal } = useBookingValuesContext().bookingDaily

  function renderSelect() {
    if (dailyTotal && dailyTotal < 200) {
      return <InputField type="hidden" name="installments" />
    } else {
      return (
        <SelectField
          name="installments"
          options={[
            { value: '1', label: 'À vista' },
            { value: '2', label: '2x sem juros' },
            { value: '3', label: '3x sem juros' },
            { value: '4', label: '4x sem juros' },
            { value: '5', label: '5x sem juros' },
            { value: '6', label: '6x sem juros' },
            { value: '7', label: '7x sem juros' },
            { value: '8', label: '8x sem juros' },
            { value: '9', label: '9x sem juros' },
            { value: '10', label: '10x sem juros' }
          ]}
          placeholder="Número de parcelas"
          required
        />
      )
    }
  }

  return <>{renderSelect()}</>
}

export default SelectPaymentFormat
