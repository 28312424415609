import { useOrganizationServices } from 'aplication/useCases/organizationServices/organizationServices'
import React from 'react'
import { Link } from 'react-router-dom'
import Card from 'view/components/card/card'
import './aboutusServices.css'

type TProps = {
  title?: string
}

const AboutusServices: React.FC<TProps> = ({ title }) => {
  const { services } = useOrganizationServices()

  function renderTitle() {
    if (!title) return <></>
    return <h2>{title}</h2>
  }

  function loadServices() {
    return services.map((s, index) => {
      const element = document.createElement('div')
      element.innerHTML = s.content

      return (
        <li key={index} className="aboutus-service">
          <Card>
            <Card.content>
              <h3>{s.title}</h3>
              <div
                className="aboutus-service-abstracts"
                dangerouslySetInnerHTML={{ __html: s.brief }}
              ></div>
              <Link
                className="aboutus-service-link font-size-normal color-secondary"
                to={`/servico/${s?.slug}`}
              >
                Saiba mais
              </Link>
            </Card.content>
          </Card>
        </li>
      )
    })
  }

  return (
    <div id="aboutus-services" className="aboutus-services">
      <div className="container">
        {renderTitle()}
        <ul className="aboutus-services-list">{loadServices()}</ul>
      </div>
    </div>
  )
}

export default AboutusServices
