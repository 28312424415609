import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { getExtraHours } from 'aplication/utils/app/dateTimeCare/dateTimeCare'
import { useFormContext } from 'view/components/formComponent'
import './bookingFormAlerts.css'
import { useAppContext } from 'view/contexts/appContext/appContext'

const BookingFormAlerts: React.FC = () => {
  const { locations } = useAppContext().locations
  const { fields } = useFormContext()

  // Possíveis alertas
  const [alerts, setAlerts] = useState<
    { id: string; text: string; show: boolean }[]
  >([
    {
      id: 'extra-hour',
      text: 'Será incluída mais uma diária por conta da quantidade de horas extras.',
      show: false
    },
    {
      id: 'return-time',
      text: 'Para maior agilidade, a devolução do veículo será no Estacionamento do Bambuzal, próximo ao aeroporto.',
      show: false
    }
  ])

  /**
   * define valor de um alerta específico dentro do array
   * @param id id do alerta
   * @param value valor a definir
   */
  function setAlertShow(id: string, value: boolean) {
    setAlerts(old => {
      return old.map(alert => {
        if (alert.id === id) {
          alert.show = value
        }
        return alert
      })
    })
  }

  /**
   * Validação para alerta em caso de horas extras que cobram mais uma diária
   */
  function alertAboutExtraHours() {
    const pickupTimeField = fields.find(f => f.name === 'pickupTime')
    const returnTimeField = fields.find(f => f.name === 'returnTime')

    if (!pickupTimeField || !returnTimeField) {
      setAlertShow('extra-hour', false)
      return
    }
    const pickupHour = pickupTimeField.value
    const returnHour = returnTimeField.value

    const extraHours = getExtraHours(pickupHour, returnHour)

    if (extraHours > 5) {
      setAlertShow('extra-hour', true)
      return
    }
    setAlertShow('extra-hour', false)
  }

  /**
   * Em caso de local de devolução ser no "aeroporto de salvador"
   * Quando horário de devolução for igual ou acima das 17horas
   * exibe o alerta
   */
  function alertAboutReturnTime() {
    const fieldLocation = fields.find(f => f.name === 'returnLocation')
    const location = locations.find(l => `${l.id}` === fieldLocation?.value)
    const returnTimeField = fields.find(f => f.name === 'returnTime')

    if (!returnTimeField || !returnTimeField.value) {
      setAlertShow('return-time', false)
      return
    }
    const returnHour: Date = returnTimeField.value
    const hour = returnHour?.getHours()
    if (location?.label.includes('Aeroporto de Salvador') && hour >= 17) {
      setAlertShow('return-time', true)
      return
    }
    setAlertShow('return-time', false)
  }

  /**
   * Exibe os alertas true
   */
  function renderAlert() {
    const toRenderAlerts = alerts.filter(a => a.show)
    if (!toRenderAlerts || toRenderAlerts.length <= 0) return <></>
    return toRenderAlerts.map((alert, index) => {
      return (
        <div key={index} className="booking-form-alert">
          <FontAwesomeIcon icon={faExclamationTriangle} /> {alert.text}
        </div>
      )
    })
  }

  /**
   * executa a validação
   */
  useEffect(() => {
    alertAboutExtraHours()
    alertAboutReturnTime()
  }, [fields])

  return <>{renderAlert()}</>
}

export default BookingFormAlerts
