import { objectIsEmpty } from 'aplication/utils/app/testValues'
import { useEffect, useState } from 'react'
import { useAppContext } from 'view/contexts/appContext/appContext'
import { useAuthContext } from 'view/contexts/authContext'
import { useBookingDataContext } from 'view/contexts/bookingDataContext'

export type TUseCalcDrivers = {
  driversValues: {
    driversTotal: number
    driversTotalTax: number
    driversTax: number
  }
  addDriver: () => void
  removeDriver: () => void
}

export function useCalcDrivers(): TUseCalcDrivers {
  const { loggedUser } = useAuthContext()
  const { organizationInfo } = useAppContext()
  const { bookingData } = useBookingDataContext()
  const [driversTotal, setdriversTotal] = useState<number>(0)
  const [driversTotalTax, setdriversTotalTax] = useState<number>(0)
  const [driversTax, setDriversTax] = useState<number>(0)

  function addDriver() {
    setdriversTotal(driversTotal + 1)
  }

  function removeDriver() {
    setdriversTotal(driversTotal - 1)
  }

  useEffect(() => {
    if (loggedUser?.person && loggedUser?.person.clientType === 'PJ') {
      setDriversTax(0)
    } else if (organizationInfo?.driverTax) {
      setDriversTax(organizationInfo?.driverTax)
    }
  }, [organizationInfo, loggedUser])

  useEffect(() => {
    if (!bookingData || (!bookingData.daily && bookingData.daily !== 0)) return
    setdriversTotalTax(driversTotal * driversTax * bookingData.daily)
  }, [driversTax, driversTotal, loggedUser])

  useEffect(() => {
    if (!bookingData || objectIsEmpty(bookingData)) return
    const { drivers } = bookingData
    if (!drivers || drivers.length < 0) return
    setdriversTotal(drivers.length)
  }, [bookingData])

  const driversValues = { driversTotal, driversTotalTax, driversTax }

  return { driversValues, addDriver, removeDriver }
}
