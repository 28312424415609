import { siteContactSubjectCombo } from 'aplication/useCases/siteContactSubject/siteContactSubjectCombo'
import React, { useEffect, useState } from 'react'
import { SelectField } from 'view/components/formComponent'
import { TOption } from 'view/components/formComponent/fields/selectField/selectFieldContext'

type TProps = {
  required?: boolean
}

const SelectSiteContactSubject: React.FC<TProps> = ({ required }) => {
  const siteContactSubjects = siteContactSubjectCombo()
  const [options, setOptions] = useState<TOption[]>([] as TOption[])

  useEffect(() => {
    const data = siteContactSubjects.data
    console.log(data)
    if (!data || data.length <= 0) return
    data.forEach(d => {
      setOptions(old => {
        const exists = old.find(option => option.value === d.subject)
        if (exists) return old
        old.push({ label: d.subject, value: d.subject })
        return old
      })
    })
  }, [siteContactSubjects.data])

  return (
    <SelectField
      name="subject"
      placeholder="Assunto"
      options={options}
      required={required}
    />
  )
}

export default SelectSiteContactSubject
