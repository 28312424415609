/* eslint-disable camelcase */
import { queryStringFiltersMaker } from 'aplication/utils/app/queryStringFiltersMaker'
import {
  TBookingApi,
  TBookingApiCardData
} from 'domain/entities/api/TBookingApi'
import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'

type TResponseData = {
  results: TBookingApi[]
  count: number
}

export class BookingApiSevice extends ApiService {
  async load(
    clientId?: string | null,
    inProgress?: boolean,
    page?: number,
    filters?: { [key: string]: any }
  ): Promise<HttpResponse<TResponseData>> {
    const inProgressQuery =
      inProgress !== undefined ? `&em_andamento=${inProgress}` : ''
    const paged = page !== undefined ? `&page=${page}` : ''
    const filter = !filters ? '' : `&${queryStringFiltersMaker(filters)}`

    return this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/reserva/?cliente_uuid=${clientId}${inProgressQuery}${paged}${filter}`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }

  async read(
    clientId?: string | null,
    rentId?: string | null
  ): Promise<HttpResponse<TResponseData>> {
    return this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/reserva/?cliente_uuid=${clientId}&uuid=${rentId}`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }

  async remove(
    bookingId?: string | null
  ): Promise<HttpResponse<TResponseData>> {
    return this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/reserva/${bookingId}/`,
      method: 'delete',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }

  async retryPayment(data: {
    reserva: number
    installments: number
    card?: TBookingApiCardData
    card_uuid?: string
  }): Promise<HttpResponse> {
    return this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/retry-payment/`,
      method: 'post',
      body: data,
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
