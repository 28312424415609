import { CarShopContactApiService } from 'aplication/apiServices/carShopContactApiService/carShopContactApiService'
import { TCarShopContactApiService } from 'aplication/apiServices/carShopContactApiService/carShopContactApiServiceTypes'
import { HttpResponse } from 'aplication/apiServices/protocols/IHttpClient'

export type TCarShopContact = {
  car: number
  name: string
  text: string
  phone: string
  email: string
  wantNotify: boolean
}

function dataTypeParser(data: TCarShopContact): TCarShopContactApiService {
  return {
    aceita_notificacoes: data.wantNotify,
    email: data.email,
    nome_cliente: data.name,
    proposta: data.text,
    telefone: data.phone,
    veiculo: data.car
  }
}

export async function carShopPostContact(
  body: TCarShopContact
): Promise<HttpResponse> {
  const apiData = new CarShopContactApiService()
  return await apiData.load(dataTypeParser(body))
}
