import { SiteContactPost } from 'aplication/useCases/siteContactPost/siteContactPost'
import { TSiteContactPost } from 'aplication/useCases/siteContactPost/siteContactPostTypes'
import React, { useRef, useState } from 'react'
import Button from 'view/components/button/button'
import { Dialog, TDialogRef } from 'view/components/dialog'
import {
  CheckboxField,
  FormComponent,
  InputField,
  TextAreaField
} from 'view/components/formComponent'
import { ContactPhone } from 'view/componentsPages/contactPhone'
import { ContactTime } from 'view/componentsPages/contactTime'
import {
  InputEmailField,
  InputPhoneField
} from 'view/componentsPages/customFields'
import SelectSiteContactSubject from 'view/componentsPages/customFields/selectSiteContactSubject/selectSiteContactSubject'
import { WhatsappButton } from 'view/componentsPages/whatsappButton'
import { useAppContext } from 'view/contexts/appContext/appContext'
import { useHistory } from 'react-router-dom'
import './contactForm.css'
import { Captcha, useCapcha } from 'view/components/Captcha'

const ContactForm: React.FC = () => {
  const { modalContactRef } = useAppContext()
  const dialogRef = useRef<TDialogRef>({} as TDialogRef)
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false)
  const { captchaRef, capchaIsInValid } = useCapcha(dialogRef)
  const history = useHistory()

  function formSubmit(data: TSiteContactPost, resetFields: () => void) {
    setSubmitDisabled(true)
    if (capchaIsInValid(setSubmitDisabled)) return
    if (!data) return
    let msg = 'Enviando dados...'
    dialogRef.current?.loadMessage(msg)
    SiteContactPost(data).then(response => {
      if (!response || response.statusCode !== 201 || !response.body) {
        console.error('client_update_error')
        msg = response.body?.detail || 'Não foi possível enviar os dados.'
        dialogRef.current?.changeMessage(msg, 'danger')
      } else {
        msg = 'Dados enviados com sucesso.'
        dialogRef.current?.changeMessage(msg, 'success', true)
        setTimeout(() => {
          modalContactRef.current.toggleContactForm()
          setSubmitDisabled(false)
          history.push('/mensagem-enviada-com-sucesso')
        }, 1000)
      }
    })
    resetFields()
  }

  return (
    <div className="contact-form">
      <h2>Central de atendimento</h2>
      <div className="contact-info">
        <ContactPhone />
        <WhatsappButton text="Via WhatsApp" />
        <ContactTime />
      </div>
      <Dialog ref={dialogRef} />
      <FormComponent onSubmit={formSubmit}>
        <div className="form-session">
          <InputField name="name" placeholder="Nome" maxLength={200} required />
          <InputEmailField required />
          <InputPhoneField required />
          <SelectSiteContactSubject required />
        </div>
        <div className="form-session">
          <TextAreaField
            name="text"
            placeholder="Mensagem"
            maxLength={300}
            required
          />
        </div>
        <div style={{ textAlign: 'center', width: '100%' }}>
          <Captcha ref={captchaRef} />
        </div>
        <div className="form-session terms">
          <CheckboxField
            name="okEmail"
            label="Autorizo o envio de e-mails promocionais da Realiza."
          />
        </div>
        <div className="form-session button">
          <Button type="submit" size="md" disabled={submitDisabled}>
            Enviar
          </Button>
        </div>
      </FormComponent>
    </div>
  )
}

export default ContactForm
