import { useBlogPosts } from 'aplication/useCases/blogPosts/useBlogPosts'
import React, { useEffect } from 'react'
import Card from 'view/components/card/card'
import TitlePage from 'view/components/text/titlePage/titlePage'
import LoadMoreResults from 'view/componentsPages/loadMoreResults/loadMoreResults'
import './blogList.css'

const BlogList: React.FC = () => {
  const { posts, loadPosts, loading, totalPosts, loadMorePosts } =
    useBlogPosts()

  function renderCards() {
    if (!loading) {
      if (!posts || posts.length <= 0) return <></>
      return (
        <>
          {posts.map((post, index) => {
            return (
              <Card
                key={index}
                image={post.image}
                link={`/conteudo/${post.slug}`}
              >
                <Card.content>
                  <div className="post-title">{post.title}</div>
                </Card.content>
              </Card>
            )
          })}
          <LoadMoreResults
            data={posts}
            totalData={totalPosts}
            loadMoreAction={loadMorePosts}
          />
        </>
      )
    }
  }

  useEffect(() => {
    loadPosts()
  }, [])

  return (
    <div>
      <div className="container">
        <TitlePage>Conteúdo</TitlePage>
        <div className="blog-post-list">{renderCards()}</div>
      </div>
    </div>
  )
}

export default BlogList
