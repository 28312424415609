import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuthContext } from 'view/contexts/authContext'
import './mainMenuDropDown.css'

const MainMenuDropDown: React.FC = () => {
  const [show, setShow] = useState<boolean>(false)
  const { isUserLogged, logoutUser } = useAuthContext()

  function onClick(event: any) {
    event.preventDefault()
    setShow(!show)
  }

  function logout() {
    setShow(false)
    logoutUser()
  }

  useEffect(() => {
    document.addEventListener('click', (e: any) => {
      if (!document.querySelector('.main-nav-user')?.contains(e.target)) {
        setShow(false)
      }
    })
  }, [])

  /**
   * exibe o menu quando "show" é true (toggle)
   */
  function showMenu() {
    if (!show) return ''
    return (
      <ul className="main-nav-user-drop">
        <li>
          <Link to="/dashboard" onClick={() => setShow(false)}>
            Minhas reservas
          </Link>
        </li>
        <li>
          <Link to="/dashboard/minha_conta" onClick={() => setShow(false)}>
            Meu perfil
          </Link>
        </li>
        <li>
          <Link to="#" onClick={logout}>
            Sair
          </Link>
        </li>
      </ul>
    )
  }

  /**
   * carrga o menú se estiver logado
   */
  function loadUserMenu() {
    if (isUserLogged()) {
      return (
        <li>
          <a href="#" onClick={onClick}>
            Minha conta
          </a>
          {showMenu()}
        </li>
      )
    } else {
      return (
        <li>
          <Link to={'/login'}>Login</Link>
        </li>
      )
    }
  }

  return <ul className="main-nav main-nav-user">{loadUserMenu()}</ul>
}

export default MainMenuDropDown
