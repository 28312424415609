import { TBookingPjDataApi } from 'aplication/apiServices/bookingPjApiService/TBookingPjDataApi'
import { TBookingPjData } from 'aplication/entities/TBookingPjData'

export function bookingPjDataParser(data: TBookingPjData): TBookingPjDataApi {
  if (!data) return {} as TBookingPjDataApi
  return {
    assinatura: data.plan,
    cnpj: data.cnpj,
    email: data.email,
    franquia_km: data.franquiaKm,
    grupos_interesse: data.groups,
    responsavel: data.responsible,
    telefone: data.phone,
    okEmail: data.okEmail,
    quantidade_veiculos: data.amountVehicles
  }
}
