import { TBookingDateBlockApi } from 'aplication/apiServices/bookingDateBlocks/TBookingDateBlockApi'
import { TBookingDateBlock } from './TBookingDateBlock'

function locationDataParser(data: any[]): number[] {
  if (!data) return [] as number[]
  return data.map(l => l.id)
}

export function bookingDateBlockDataParser(
  data: TBookingDateBlockApi
): TBookingDateBlock {
  if (!data) return {} as TBookingDateBlock
  return {
    locationId: locationDataParser(data.local_retirada),
    blockReturn: data.bloquear_devolucao,
    blockPickup: data.bloquear_retirada,
    start: data.inicio,
    final: data.fim,
    uuid: data.uuid,
    id: data.id
  }
}

export function bookingDateBlockArrayParser(
  data: TBookingDateBlockApi[]
): TBookingDateBlock[] {
  if (!data || data.length <= 0) return [] as TBookingDateBlock[]
  return data.map(d => bookingDateBlockDataParser(d))
}
