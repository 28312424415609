import { useRef } from 'react'
import { TDialogRef } from '../dialog'
import { TCaptchaRef } from './Captcha'

export type TUseCaptcha = {
  capchaIsInValid: (setSubmitDisabled: (value: boolean) => void) => boolean
  captchaRef: { current: TCaptchaRef }
}

export function useCapcha(dialogRef: { current: TDialogRef }): TUseCaptcha {
  const captchaRef = useRef<TCaptchaRef>({} as TCaptchaRef)
  function capchaIsInValid(setSubmitDisabled: (value: boolean) => void) {
    setSubmitDisabled(false)
    console.log(captchaRef)
    const { isValid, loaded } = captchaRef.current
    if (loaded && !isValid) {
      dialogRef.current?.changeMessage('Capcha inválido.', 'danger')
      return true
    } else if (!loaded) {
      dialogRef.current?.changeMessage('Verifique o capcha.', 'danger')
      return true
    }
    return false
  }

  return { capchaIsInValid, captchaRef }
}
