import React, { useContext } from 'react'
import { CarouselContext } from './carouselContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons'

const CarouselArrows: React.FC = () => {
  const { positions, currentPosition, updateCurrentposition } =
    useContext(CarouselContext)

  function arrowNavigation(direction: string) {
    let directionCalc: number | null = null
    if (Object.keys(currentPosition).length <= 0) {
      directionCalc = 1
    } else if (direction === 'next' || null) {
      if (currentPosition.id >= positions.length - 1) {
        directionCalc = 0
      } else {
        directionCalc = currentPosition.id + 1
      }
    } else if (direction === 'prev') {
      if (currentPosition.id === 0) {
        directionCalc = positions.length - 1
      } else {
        directionCalc = currentPosition.id - 1
      }
    }

    if (directionCalc || directionCalc === 0) {
      updateCurrentposition(directionCalc)
    }
  }

  function onClickPrev() {
    arrowNavigation('prev')
  }

  function onClickNext() {
    arrowNavigation('next')
  }

  // function disablePrevArrow() {
  //   const positionId = currentPosition.id
  //   if (positionId === 0) {
  //     return 'disabled'
  //   }
  //   return ''
  // }

  // function disableNextArrow() {
  //   const positionId = currentPosition.id
  //   if (positionId === positions.length - 1) {
  //     return 'disabled'
  //   }
  //   return ''
  // }

  function loadArrows() {
    if (!positions || positions.length <= 1) return <></>
    return (
      <div className="carousel-arrows-navigators">
        <button className={'carousel-scroll-prev'} onClick={onClickPrev}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <button className={'carousel-scroll-next'} onClick={onClickNext}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    )
  }

  return <>{loadArrows()}</>
}

export default CarouselArrows
