import React from 'react'
import { TStyleColor, TStyleSize } from 'view/viewTypes'
import './title.css'

type Props = {
  id?: string
  className?: string
  color?: TStyleColor
  size?: TStyleSize
}

const Title: React.FC<Props> = ({ children, color, size, className, id }) => {
  size = size || 'big'
  color = color || 'primary'

  return (
    <h2
      id={id}
      className={`${className} text-title color-${color} font-size-${size}`}
    >
      {children}
    </h2>
  )
}

export default Title
