const Hollydays = class {
  values: string[]

  constructor() {
    this.values = [
      '1-JAN',
      '21-ABR',
      '1-MAI',
      '24-JUN',
      '2-JUL',
      '7-SET',
      '12-OUT',
      '2-NOV',
      '15-NOV',
      '25-DEZ'
    ]
  }
}

export const hollydays = new Hollydays()
