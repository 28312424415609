import React from 'react'
import Card from 'view/components/card/card'
import './rentInfo.css'

type Component = {
  item: React.FC
  header: React.FC
  content: React.FC
}

const RentInfo: React.FC & Component = ({ children }) => {
  return (
    <div className="rent-info">
      <Card>
        <Card.content>{children}</Card.content>
      </Card>
    </div>
  )
}

const Item: React.FC = ({ children }) => {
  return <div className="rent-info-item">{children}</div>
}

const Header: React.FC = ({ children }) => {
  return <div className="rent-info-header">{children}</div>
}

const Content: React.FC = ({ children }) => {
  return <div className="rent-info-content">{children}</div>
}

RentInfo.header = Header
RentInfo.content = Content
RentInfo.item = Item

export default RentInfo
