import { objectIsEmpty } from 'aplication/utils/app/testValues'
import { TToken } from 'domain/entities/TToken'

type T = {
  setStorageToken: (token: TToken) => void
  getStorageToken: () => TToken
  removeStorageToken: () => void
  storageUserUuid: (userId: string) => void
  getUserUuid: () => string
}

export function useAuthToken(): T {
  return {
    setStorageToken(token: TToken) {
      if (!token || objectIsEmpty(token)) return
      localStorage.setItem('userToken', JSON.stringify(token))
    },

    getStorageToken() {
      if (typeof localStorage === 'undefined') return {} as TToken
      const tokenString = localStorage.getItem('userToken')
      if (!tokenString) return {} as TToken
      return JSON.parse(tokenString) as TToken
    },

    removeStorageToken() {
      localStorage.removeItem('userToken')
      localStorage.removeItem('userId')
    },

    storageUserUuid(userId: string) {
      localStorage.setItem('userId', userId)
    },

    getUserUuid() {
      return localStorage.getItem('userId') || ''
    }
  }
}
