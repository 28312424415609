import React from 'react'
import {
  Carousel,
  CarouselDotsNavigator,
  CarouselItens
} from 'view/components/carousel'
import './carouselCardImages.css'

type TProps = {
  images?: { image: string }[]
}

const CarouselCardImages: React.FC<TProps> = ({ images }) => {
  function loadImages() {
    return images?.map((img, index) => {
      return (
        <Carousel.item key={index} id={`carousel-item-${index}`}>
          <img src={img.image} />
        </Carousel.item>
      )
    })
  }

  function loadCarousel() {
    return (
      <Carousel className="carousel-card-images">
        <CarouselItens>
          <div className="null-item carousel-item"></div>
          {loadImages()}
          <div className="null-item carousel-item"></div>
        </CarouselItens>
        <CarouselDotsNavigator />
      </Carousel>
    )
  }

  return <>{loadCarousel()}</>
}

export default CarouselCardImages
