import React from 'react'
import Button from 'view/components/button/button'
import { useAppContext } from 'view/contexts/appContext/appContext'
import { SuccessPageTemplate } from 'view/template/successPageTemplate'

const ContactSuccess: React.FC = () => {
  const { modalContactRef } = useAppContext()

  return (
    <SuccessPageTemplate
      title="Mensagem enviada com sucesso!"
      buttons={
        <>
          <Button style="transparent" link={'/'}>
            Ir para página inicial
          </Button>
          <Button
            style="orange"
            onClick={() => {
              modalContactRef.current.toggleContactForm()
            }}
          >
            Fale conosco
          </Button>
        </>
      }
    >
      {`Obrigado pela preferência. A nossa central de atendimento entrará em
      contato nas próximas 24h, em horário comercial. Em caso de dúvidas, clique
      no botão "Fale Conosco" para iniciar uma conversa com nossos atendentes
      via Whatsapp.`}
    </SuccessPageTemplate>
  )
}

export default ContactSuccess
