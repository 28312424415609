import React, { useEffect, useRef } from 'react'
import BookingDateField from './bookingDateField'
import { useFormContext } from 'view/components/formComponent'
import { useDisabledController } from 'view/hooksPages'
import { bookingFormFieldListener } from './bookingFormFieldListener'
import ReactDatePicker from 'react-datepicker'

const BookingDatePickupField: React.FC = () => {
  const fieldRef = useRef<ReactDatePicker>()
  const { fields, updateField } = useFormContext()
  const { disabled } = useDisabledController(fields, 'pickupLocation')
  function onChange() {
    fields.forEach(field => {
      if (!['returnDate', 'returnTime'].includes(field.name)) return
      updateField({ ...field, value: '', isValid: true })
    })
  }

  // escura o valor de pickupLocation para abrir automatico
  const pickupLocation = bookingFormFieldListener('pickupLocation')
  useEffect(() => {
    if (!pickupLocation.change || pickupLocation.change === '0') return
    fieldRef.current?.setFocus()
  }, [pickupLocation.change])

  return (
    <BookingDateField
      name="pickupDate"
      listening="pickupLocation"
      disabled={disabled}
      onChange={onChange}
      fieldRef={fieldRef}
    />
  )
}

export default BookingDatePickupField
