import { LocationsApiService } from 'aplication/apiServices/locationsApiService/locationsApiService'
import { TLocation } from 'domain/entities/TLocation'
import { useEffect, useState } from 'react'
import { locationArrayDataParser } from './locationDataParser'

export type TUseLocations = {
  locations: TLocation[]
  getLocation: (locationId: string) => TLocation
}

export function useLocations(): TUseLocations {
  const [locations, setLocations] = useState<TLocation[]>([] as TLocation[])

  function loadData() {
    const apiService = new LocationsApiService()
    apiService.load().then(response => {
      if (
        !response ||
        response.statusCode !== 200 ||
        !response.body ||
        !response.body.results
      ) {
        console.error('location_load_error')
        return
      }
      const locationParsed = locationArrayDataParser(response.body.results)
      setLocations(locationParsed)
    })
  }

  function getLocation(locationId: string): TLocation {
    const found = locations.find(
      location => `${location.id}` === `${locationId}`
    )
    return found || ({} as TLocation)
  }

  useEffect(() => {
    loadData()
  }, [])

  return { locations, getLocation }
}
