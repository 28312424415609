import React from 'react'
import { CarouselItens } from 'view/components/carousel'
import Carousel from 'view/components/carousel/carousel'
import CarouselPageHeaderNavs from './carouselPageHeaderNavs/carouselPageHeaderNavs'
import './carouselPageHeader.css'
import { TCarGroupImage } from 'domain/entities/TCarGroup'
import { pushEmptyPositions } from 'aplication/utils/app/emptyContent'

type TProps = {
  images?: TCarGroupImage[]
}

const CarouselPageHeader: React.FC<TProps> = ({ images }) => {
  function makeImage(imagePath?: string | null) {
    return (
      <div className="image-wrapper">
        {imagePath ? <img src={imagePath} /> : <div className="uknow-image" />}
      </div>
    )
  }

  function loadImages() {
    images = pushEmptyPositions(images)
    return images.map((img, index) => {
      return (
        <Carousel.item key={index} id={`carousel-item-${index}`}>
          {makeImage(img.image)}
        </Carousel.item>
      )
    })
  }

  function loadCarousel() {
    return (
      <div className="carousel-gradient-box">
        <div className="carousel-gradient-left carousel-gradient"></div>
        <Carousel className="carousel-page-header">
          <CarouselItens>
            <div className="null-item carousel-item"></div>
            {loadImages()}
            <div className="null-item carousel-item"></div>
          </CarouselItens>
          <CarouselPageHeaderNavs />
        </Carousel>
        <div className="carousel-gradient-right carousel-gradient"></div>
      </div>
    )
  }

  return <>{loadCarousel()}</>
}

export default CarouselPageHeader
