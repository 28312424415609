import { useState } from 'react'
import { TBookingDateBlock } from './TBookingDateBlock'
import { BookingDateBlocksApiService } from 'aplication/apiServices/bookingDateBlocks/bookingDateBlocks'
import { bookingDateBlockArrayParser } from './bookingDateBlockDataParser'

export type TBookingDateBlockList = {
  data: TBookingDateBlock[]
  load: () => void
}

export function bookingDateBlockList(): TBookingDateBlockList {
  const [data, setData] = useState<TBookingDateBlock[]>(
    [] as TBookingDateBlock[]
  )

  function load() {
    const apiServices = new BookingDateBlocksApiService()
    apiServices.load().then(response => {
      if (!response || !response.body || response.statusCode !== 200) {
        return
      }
      const parsedData = bookingDateBlockArrayParser(response.body.results)
      setData(parsedData)
    })
  }

  return { data, load }
}
