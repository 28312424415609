import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useRentingPageContext } from 'view/pages/rentingPage/rentingPageContext'

const style = {
  fontSize: '20px',
  color: '#517280',
  cursor: 'pointer'
}

const LocationDateTimeEditBtn: React.FC = () => {
  const { modalBookingRef } = useRentingPageContext()

  function onClick() {
    console.log(modalBookingRef.current)
    modalBookingRef.current?.toggleBookingModal()
  }

  return (
    <span
      className="rent-info-edit"
      onClick={onClick}
      style={style}
      title="Editar"
    >
      <FontAwesomeIcon icon={['far', 'edit']} />
    </span>
  )
}

export default LocationDateTimeEditBtn
