import { useBookings } from 'aplication/useCases/booking/useBookings'
import { isoDateToAmericanDate } from 'aplication/utils/app/dateTimeCare/dateTimeCare'
import React, { useContext, useEffect, useState } from 'react'
import Button from 'view/components/button/button'
import {
  FormComponent,
  InputField,
  TField,
  useFormContext
} from 'view/components/formComponent'
import DateField from 'view/components/formComponent/fields/dateField/dateField'
import SelectCarGroup from 'view/componentsPages/customFields/selectCarGroup/selectCarGroup'
import LoadingContent from 'view/componentsPages/loadingContent/loadingContent'
import LoadMoreResults from 'view/componentsPages/loadMoreResults/loadMoreResults'
import { useAuthContext } from 'view/contexts/authContext'
import { BookingCard } from '../bookingCard'
import { DashboardBookingsContext } from './dashboardBookings'

const CleanerButton: React.FC = () => {
  const { resetFields } = useFormContext()
  return (
    <Button
      type="button"
      size="sm"
      style="transparent"
      onClick={() => resetFields()}
    >
      Limpar
    </Button>
  )
}

const DashboardBookingsClosed: React.FC = () => {
  const { loggedUser } = useAuthContext()
  const { reloading } = useContext(DashboardBookingsContext)
  const { bookings, loaded, loadInProgress, totalBookings, loadMoreBookings } =
    useBookings()
  const [formValues, setFormValues] = useState<{ [key: string]: any }>({})

  function renderBookings() {
    return bookings.map((booking, index) => {
      return <BookingCard rentingData={booking} key={index} />
    })
  }

  function loadMore() {
    loadMoreBookings(loggedUser?.person.uuid, false)
  }

  function onChangeForm(fields: any) {
    const values: { [key: string]: any } = {}
    fields.forEach((field: TField) => {
      if (field.value instanceof Date) {
        values[field.name] = isoDateToAmericanDate(field.value.toISOString())
        return
      }
      values[field.name] = field.value
    })
    setFormValues(values)
  }

  function showBookingHistory() {
    if (bookings.length <= 0) return <></>
    return (
      <div className="booking-list-itens">
        {renderBookings()}
        <LoadMoreResults
          data={bookings}
          totalData={totalBookings}
          loadMoreAction={loadMore}
        />
      </div>
    )
  }

  useEffect(() => {
    const timeDalay = setTimeout(() => {
      loadInProgress(loggedUser?.person.uuid, false, formValues)
    }, 1000)
    return () => {
      clearTimeout(timeDalay)
    }
  }, [loggedUser, formValues])

  useEffect(() => {
    if (!reloading) return
    loadInProgress(loggedUser?.person.uuid, false)
  }, [reloading])

  function renderContent() {
    if (!loaded) return <LoadingContent height={300} />
    return <>{showBookingHistory()}</>
  }

  return (
    <div className="booking-list">
      <h2 className="session-title">TODAS AS RESERVAS</h2>
      <FormComponent onFormChange={onChangeForm}>
        <div className="booking-list-form">
          <div className="form-secction">
            <InputField name="code" placeholder="Código da reserva" />
            <SelectCarGroup />
          </div>
          <div className="form-secction">
            <DateField name="data_inicio" placeholder="Data de retirada" />
            <DateField name="data_fim" placeholder="Data de devolução" />
          </div>
          <CleanerButton />
        </div>
      </FormComponent>
      {renderContent()}
    </div>
  )
}

export default DashboardBookingsClosed
