import React, { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'view/components/formComponent'
import { useDisabledController } from 'view/hooksPages'
import BookingTimeField from './bookingTimeField'
import ReactDatePicker from 'react-datepicker'
import { bookingFormFieldListener } from './bookingFormFieldListener'

const BookingTimeReturnField: React.FC = () => {
  const fieldRef = useRef<ReactDatePicker>()
  const { fields } = useFormContext()
  const { disabled } = useDisabledController(fields, 'returnDate')
  const [minTime, setMinTime] = useState<Date>()

  useEffect(() => {
    let pickcupDate = fields.find(field => field.name === 'pickupDate')?.value
    let returnDate = fields.find(field => field.name === 'returnDate')?.value
    const pickcupTime = fields.find(field => field.name === 'pickupTime')?.value
    if (!pickcupTime || !pickcupDate || !returnDate) return
    pickcupDate = new Date(pickcupDate).toISOString()
    returnDate = new Date(returnDate).toISOString()
    if (pickcupDate === returnDate) {
      const newTime = new Date(pickcupTime)
      newTime.setHours(newTime.getHours() + 2)
      setMinTime(newTime)
    } else {
      setMinTime(undefined)
    }
  }, [fields])

  // escura o valor de returnDate para abrir automatico
  const returnDate = bookingFormFieldListener('returnDate')
  useEffect(() => {
    if (!returnDate.change || returnDate.change === '0') return
    fieldRef.current?.setFocus()
  }, [returnDate.change])

  return (
    <BookingTimeField
      name="returnTime"
      listeningLocation="returnLocation"
      listeningDate="returnDate"
      disabled={disabled}
      minTime={minTime}
      fieldRef={fieldRef}
    />
  )
}

export default BookingTimeReturnField
