import React from 'react'
import PageContainerPromo from 'view/componentsPages/pageContainerPromo/pageContainerPromo'
import { DashboardSidebar } from '../dashboardSidebar'
import './dashboardSingleTemplate.css'

const DashboardSingleTemplate: React.FC = ({ children }) => {
  return (
    <div className="container">
      <div className="dashboard-template-page">
        <DashboardSidebar />
        <div className="dashboard-content">{children}</div>
      </div>

      <div className="dashboard-content-container-promo">
        <PageContainerPromo />
      </div>
    </div>
  )
}

export default DashboardSingleTemplate
