import React, { useEffect } from 'react'
import TitlePage from 'view/components/text/titlePage/titlePage'
import { useRentingDataValidation } from 'view/hooksPages'
import CarGroupComponent from './carGroup/carGroup'
import LoadMoreResults from 'view/componentsPages/loadMoreResults/loadMoreResults'
import LoadingContent from 'view/componentsPages/loadingContent/loadingContent'
import { useBookingDataContext } from 'view/contexts/bookingDataContext'
import { useBookingValuesContext } from 'view/contexts/bookingValuesContext'
import './carGroups.css'

const CarGroups: React.FC = () => {
  const { bookingData } = useBookingDataContext()
  const { carGroups, loaded, loadMoreCarGroups, totalGroups, loadData } =
    useBookingValuesContext().carGroup

  const { invalidData, goToInvalidPage } = useRentingDataValidation([
    'dateTimeValidator',
    'pickupValidation'
  ])

  function loadGroups() {
    return carGroups?.map((group, index) => {
      if (!group.price || group.price === 0) return <></>
      return <CarGroupComponent key={index} group={group} />
    })
  }

  function renderContent() {
    if (!loaded) {
      return (
        <div className="container">
          <LoadingContent />
        </div>
      )
    } else if (loaded && invalidData) {
      goToInvalidPage()
    } else {
      return (
        <div className="container">
          <TitlePage>Grupos de veículos disponíveis</TitlePage>
          <div className="car-group-list">{loadGroups()}</div>
          <LoadMoreResults
            data={carGroups}
            totalData={totalGroups}
            loadMoreAction={loadMoreCarGroups}
          />
        </div>
      )
    }
  }

  useEffect(() => {
    loadData()
  }, [bookingData])

  return <>{renderContent()}</>
}

export default CarGroups
