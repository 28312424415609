import React from 'react'
import { Link } from 'react-router-dom'
import './card.css'

type Props = {
  image?: string
  className?: string
  link?: string
  id?: string
}

type Component = {
  footer: React.FC
  content: React.FC
}

const Card: React.FC<Props> & Component = ({
  children,
  image,
  className,
  link,
  id
}) => {
  function hasImage() {
    if (image) {
      return (
        <div className="card-img">
          {link ? <Link to={link} className="card-link" /> : <></>}
          <img src={image} alt="" />
        </div>
      )
    }
    return ''
  }

  return (
    <div className={`card ${className || ''}`} id={id}>
      {hasImage()}
      {children}
    </div>
  )
}

const Content: React.FC = ({ children }) => {
  return <div className="card-content">{children}</div>
}

const Footer: React.FC = ({ children }) => {
  return <div className="card-footer">{children}</div>
}

Card.footer = Footer
Card.content = Content

export default Card
