import React, { useEffect } from 'react'
import { useCardBrand } from 'aplication/useCases/cardBrand/useCardBrand'
import { InputField, useFormContext } from 'view/components/formComponent'

type Props = {
  required?: boolean
}

const InputCardField: React.FC<Props> = ({ required }) => {
  const { brand, loadData } = useCardBrand()
  const { updateField } = useFormContext()

  function setInputMask(inputValue: string): string {
    const maskedValue = inputValue
      .replace(/\D/g, '')
      .replace(/(\d{4})(\d)/, '$1 $2')
      .replace(/(\d{4})(\d)/, '$1 $2')
      .replace(/(\d{4})(\d{1,2})/, '$1 $2')
      .replace(/( \d{4})\d+?$/, '$1')

    return maskedValue
  }

  function onChange(value: any) {
    value = value.replaceAll(' ', '')
    if (value.length >= 16) {
      loadData(value)
    }
  }

  useEffect(() => {
    if (!brand && brand !== '') {
      updateField({
        name: 'cardNumber',
        isValid: false,
        validationMessage: 'cartão inválido.'
      })
    }
    updateField({ name: 'cardBrand', value: brand })
  }, [brand])

  return (
    <InputField
      name="cardNumber"
      placeholder="Número do Cartão"
      regexMask={setInputMask}
      required={required}
      maxLength={50}
      // onChange={onChange}
      onBlur={onChange}
    />
  )
}

export default InputCardField
