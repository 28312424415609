import { useCarShopCarsRead } from 'aplication/useCases/carShop/useCarShopCarRead'
import { priceConverter } from 'aplication/utils/app/valueConverter'
import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import Card from 'view/components/card/card'
import TitlePage from 'view/components/text/titlePage/titlePage'
import { CarouselPageHeader } from 'view/componentsPages/carouselPageHeader'
import LoadingContent from 'view/componentsPages/loadingContent/loadingContent'
import PageContainerPromo from 'view/componentsPages/pageContainerPromo/pageContainerPromo'
import { useAppContext } from 'view/contexts/appContext/appContext'
import CarDescriptions from './carDescriptions/carDescriptions'
import CarResources from './carResources/carResources'
import CarShopCarContact from './carShopCarContact/carShopCarContact'
import CarouselSeminovosNormal from 'view/componentsPages/carouselSeminovosNormal/carouselSeminovosNormal'
import './carShopCar.css'

const CarShopCar: React.FC = () => {
  const { pageInfos } = useAppContext()
  const { car, loading, readCar } = useCarShopCarsRead()
  const params: { id: string } = useParams()
  const location = useLocation()

  function formatImages() {
    if (!car || !car.images) return []
    return car.images.map(image => {
      return { image }
    })
  }

  useEffect(() => {
    if (!params || !params.id) return
    readCar(params.id)
  }, [location])

  function renderContent() {
    if (!car) return <></>
    if (loading) {
      return (
        <div className="container">
          <LoadingContent />
        </div>
      )
    }
    return (
      <>
        <div className="container">
          <TitlePage>
            {car?.maker.name} {car?.model}
          </TitlePage>
        </div>
        <CarouselPageHeader images={formatImages()} />
        <div className="container">
          <div className="car-sections">
            <div className="car-content">
              <CarDescriptions car={car} />
              <CarResources features={car?.features} />
            </div>
            <div className="car-sidebar">
              <Card>
                <Card.content>
                  <h3
                    className="car-price font-emphasis font-size-bigger color-primary"
                    title="Valor do Veículo"
                  >
                    {priceConverter(car?.price)}
                  </h3>

                  <CarShopCarContact carId={car.id} />
                </Card.content>
              </Card>
            </div>
          </div>
        </div>

        <CarouselSeminovosNormal title="Você pode gostar" />

        <div className="container">
          <PageContainerPromo />
        </div>
      </>
    )
  }

  useEffect(() => {
    if (!car) return
    pageInfos.update({
      pageTitle: `${car?.maker.name} ${car?.model}`,
      pageDescription: car?.features.map(f => f.description).join('; '),
      pageImg: car?.images[0],
      pageImgWidth: '350',
      pageImgHeigth: '263'
    })
  }, [car])

  return <>{renderContent()}</>
}

export default CarShopCar
