import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
// import { useAppContext } from 'view/contexts/appContext/appContext'
import './whatsappButton.css'

const WhatsappButtonMobile: React.FC = () => {
  // const { organizationInfo } = useAppContext()
  // const whatsAppNumber = organizationInfo?.whatsapp
  //   ?.replace('(', '')
  //   .replace(')', '')
  //   .replace('-', '')
  //   .replace(' ', '')

  function onClick() {
    const targetButton = document.getElementById('rd-floating_button-ly4nl66c')
    if (targetButton) {
      targetButton.click()
    }
  }

  return (
    <a
      className="whatsapplink top-bar-nav-item btn-slave-whats"
      href={'#whats'}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={['fab', 'whatsapp']} />
    </a>
  )
}

export default WhatsappButtonMobile
