import { adminCredCard } from 'aplication/useCases/credCards/adminCredCard'
import { objectIsEmpty } from 'aplication/utils/app/testValues'
import { TCredCard } from 'domain/entities/TCredCard'
import React, { useContext, useRef } from 'react'
import Button from 'view/components/button/button'
import { Dialog, TDialogRef } from 'view/components/dialog'
import { FormComponent, InputField } from 'view/components/formComponent'
import PasswordField from 'view/components/formComponent/fields/passwordField/passwordField'
import { useModalContext } from 'view/components/modal/modalContext'
import { InputCardField } from 'view/componentsPages/customFields'
import InputMothYearField from 'view/componentsPages/customFields/inputMonthYear/inputMonthYear'
import { DashboardCredCardListContext } from './dashboardCredCardList'

const DashboardCredCardForm: React.FC = () => {
  const { refreshList, credCards } = useContext(DashboardCredCardListContext)
  const { toggleOpen } = useModalContext()
  const { createCredCard } = adminCredCard()
  const dialogFormRef = useRef<TDialogRef>({} as TDialogRef)

  function isMainCard() {
    const hasCards = credCards && credCards.length <= 0
    return hasCards
  }

  function onSubmit(data: TCredCard) {
    dialogFormRef.current.loadMessage('Enviando dados...')
    data.isMain = isMainCard()
    createCredCard(data).then(response => {
      if (response.statusCode !== 200 && response.statusCode !== 201) {
        dialogFormRef.current.changeMessage('Operação não realizada', 'danger')
        return
      }
      toggleOpen(false)
      setTimeout(() => refreshList(), 500)
    })
  }
  return (
    <>
      <h2>Novo Cartão de Crédito</h2>
      <Dialog ref={dialogFormRef} />
      <FormComponent onSubmit={onSubmit} className="cred-card-add-form">
        <div className="form-session">
          <InputField
            name="cardName"
            placeholder="Nome impresso no cartão"
            required
            maxLength={200}
          />
        </div>
        <div className="form-session">
          <InputCardField required />
        </div>
        <div className="form-session">
          <InputMothYearField required />
          <PasswordField
            name="cardSecurityCode"
            placeholder="Código"
            maxLength={3}
            required
          />
          <InputField type="hidden" name="cardBrand" />
        </div>
        <div className="form-session">
          <Button type="submit" size="bg" full>
            Salvar
          </Button>
        </div>
      </FormComponent>
    </>
  )
}

export default DashboardCredCardForm
