import React, { useContext } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import 'react-datepicker/dist/react-datepicker.css'
import { TFieldElement } from '../..'
import FieldProvider, { FieldContext } from '../fieldContext'

registerLocale('pt-BR', ptBR)

type TProps = TFieldElement & {
  filter?: (date: Date) => boolean
  onChange?: (value: Date) => void
  disableWrite?: boolean
  fieldRef?: any
}

const TimeComponent: React.FC = () => {
  const { updateField, stateValue, fieldProps } = useContext(FieldContext)
  const { name, onChange, filter, disabled, disableWrite, fieldRef } =
    fieldProps

  function handleChange(date: Date) {
    if (onChange) onChange(date)
    updateField({ name, value: date })
  }

  function disableWriteAction(e: any) {
    e.preventDefault()
    return false
  }

  return (
    <DatePicker
      selected={stateValue}
      onChange={handleChange}
      locale="pt-BR"
      placeholderText="Hora"
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={60}
      timeCaption="Hora"
      dateFormat={'HH:mm'}
      filterTime={filter}
      disabled={disabled}
      onKeyDown={disableWrite ? disableWriteAction : undefined}
      ref={fieldRef}
    />
  )
}

const TimeField: React.FC<TProps> = props => {
  return (
    <FieldProvider fieldProps={props}>
      <TimeComponent />
    </FieldProvider>
  )
}

export default TimeField
