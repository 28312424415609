import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './rentingStepsList.css'
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { useRentingPageContext } from 'view/pages/rentingPage/rentingPageContext'

const RentingStepsList: React.FC = () => {
  const [defaulStep, setDefaulStep] = useState<number>(0)

  const steps = ['retirada', 'grupos', 'reserva', 'pagamento']
  const { pathname } = useLocation()
  const { path } = useRouteMatch()
  const history = useHistory()

  const { modalBookingRef } = useRentingPageContext()

  function openBookingModal() {
    modalBookingRef.current.toggleBookingModal()
  }

  useEffect(() => {
    const location = pathname.split('/')[2]
    const stepIndex = steps.indexOf(location)
    setDefaulStep(stepIndex + 1)
  }, [history.location])

  function stepItem(stepNumber: number, stepText: string) {
    const isDefault = stepNumber === defaulStep
    const isChecked = stepNumber < defaulStep
    const theStepNumber = <span className="step-number">{stepNumber}</span>

    const stepIcon = (
      <span className="step-icon">
        <FontAwesomeIcon icon="check" />
      </span>
    )

    function stepLink() {
      return (
        <Link to={`${path}/${steps[stepNumber - 1]}`} className="step-label">
          {stepText}
        </Link>
      )
    }

    function stepSpan() {
      return <span className="step-label">{stepText}</span>
    }

    function stepBooking() {
      return (
        <>
          <span className="step-label step-booking" onClick={openBookingModal}>
            {stepText}
          </span>
        </>
      )
    }

    function activateLink() {
      if (!isChecked) {
        return stepSpan()
      } else if (stepText === 'Retirada') {
        return stepBooking()
      } else {
        return stepLink()
      }
    }

    return (
      <li className={`step ${isDefault ? 'default' : ''}`}>
        {isChecked ? stepIcon : theStepNumber}
        {activateLink()}
      </li>
    )
  }

  function renderStepList() {
    if (location.pathname.includes('reserva_concluida')) return <></>
    return (
      <div className="renting-steps-list">
        <div className="steps">
          {stepItem(1, 'Retirada')}
          <li className="steps-separator"></li>
          {stepItem(2, 'Grupos')}
          <li className="steps-separator"></li>
          {stepItem(3, 'Reserva')}
          <li className="steps-separator"></li>
          {stepItem(4, 'Pagamento')}
        </div>
      </div>
    )
  }

  return <>{renderStepList()}</>
}

export default RentingStepsList
