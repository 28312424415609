import { TResourceApi } from 'domain/entities/api/TResourceApi'
import { TResource } from 'domain/entities/TResource'

export function resourcesDataParser(data: TResourceApi): TResource {
  if (!data) return {} as TResource
  return {
    description: data.nome,
    id: data.id,
    price: parseFloat(data.valor),
    title: data.nome,
    quantity: data.quantidade
  }
}

export function resourceArrayDataParser(data: TResourceApi[]): TResource[] {
  if (!data || data.length <= 0) return [] as TResource[]
  return data.map(resourceApi => resourcesDataParser(resourceApi))
}
