import React, { createContext, useEffect, useState } from 'react'
import { TDriver } from 'domain/entities/TRentingData'
import { useBookingDataContext } from 'view/contexts/bookingDataContext'
import { useBookingValuesContext } from 'view/contexts/bookingValuesContext'
import BookingDriversForm from './bookingDriversForm'
import './drivers.css'

export type TDriversContext = {
  drivers: TDriver[]
  updateDrivers: (driver: TDriver) => void
  removeDriver: (index: string) => void
}

export const DriversContext = createContext<TDriversContext>(
  {} as TDriversContext
)

export const BookingDriversList: React.FC = () => {
  const { bookingData, updateBookingData } = useBookingDataContext()
  const { driversCalc } = useBookingValuesContext()
  const [drivers, setDrivers] = useState<TDriver[]>([] as TDriver[])
  const { driversTotalTax } = driversCalc.driversValues

  function updateDrivers(driver: TDriver) {
    setDrivers([...drivers, driver])
    driversCalc.addDriver()
  }

  function removeDriver(index: string) {
    const newDrivers = drivers.filter((driver, i) => {
      return i !== parseInt(index)
    })
    setDrivers(newDrivers)
    driversCalc.removeDriver()
  }

  useEffect(() => {
    if (!drivers && drivers !== 0) return
    updateBookingData({ drivers, driversTotalTax })
  }, [drivers])

  useEffect(() => {
    if (!driversTotalTax && driversTotalTax !== 0) return
    updateBookingData({ driversTotalTax })
  }, [driversTotalTax])

  useEffect(() => {
    if (!bookingData.drivers || bookingData.drivers.length <= 0) return
    setDrivers(bookingData.drivers)
  }, [])

  return (
    <DriversContext.Provider value={{ drivers, updateDrivers, removeDriver }}>
      <BookingDriversForm />
    </DriversContext.Provider>
  )
}
