import { CarBrandApiService } from 'aplication/apiServices/carBrandApiService/carBrandApiService'
import { TCarBrandApi } from 'aplication/apiServices/carBrandApiService/carBrandApiTypes'
import { useEffect, useState } from 'react'
import { TCarBrand } from './useCarBrandType'

type TUseCarBrand = {
  carBrands: TCarBrand[]
}

export function useCarBrand(): TUseCarBrand {
  const [data, setData] = useState<TCarBrand[]>([] as TCarBrand[])

  function dataTypeParser(apiData: TCarBrandApi): TCarBrand {
    return {
      id: apiData.uuid,
      name: apiData.nome,
      image: apiData.image,
      slug: apiData.slug
    }
  }

  function loadData() {
    const apiService = new CarBrandApiService()
    apiService.load().then(response => {
      if (
        !response ||
        response.statusCode !== 200 ||
        !response.body ||
        !response.body.results
      ) {
        console.error('carbrand_service_error')
        return
      }
      setData(response.body.results.map(i => dataTypeParser(i)))
    })
  }

  useEffect(() => loadData(), [])

  return { carBrands: data }
}
