import React, { useEffect, useState } from 'react'
import Button from 'view/components/button/button'
import IconLoading from 'view/components/icons/iconLoading/iconLoading'

type TProps = {
  data: any[]
  totalData: number
  loadMoreAction: () => void
}

const LoadMoreResults: React.FC<TProps> = ({
  loadMoreAction,
  data,
  totalData
}) => {
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)

  function renderLoadIcon() {
    if (!dataLoaded) return <IconLoading />
    return <></>
  }

  function onClickLoadMore() {
    setDataLoaded(false)
    loadMoreAction()
  }

  function renderButton() {
    if (data.length >= totalData) return <></>
    return (
      <div className="car-group-more">
        <Button style="link" onClick={onClickLoadMore}>
          {renderLoadIcon()} Exibir mais resultados
        </Button>
      </div>
    )
  }

  useEffect(() => {
    setDataLoaded(true)
  }, [data])

  return <>{renderButton()}</>
}

export default LoadMoreResults
