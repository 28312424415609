import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'

export class UserUpdateActivatioConde extends ApiService {
  async load(clientUuid: string): Promise<HttpResponse> {
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/new-account-activation-code/`,
      method: 'post',
      body: {
        cliente_uuid: clientUuid
      },
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
