import { useAddressByCep } from 'aplication/useCases/cep/useAddressByCep'
import { useCityList } from 'aplication/useCases/city/useCityList'
import { objectIsEmpty } from 'aplication/utils/app/testValues'
import { TAddressByCep } from 'domain/entities/TAddressByCep'
import { TCity } from 'domain/entities/TCity'
import React, { useEffect, useState } from 'react'
import { InputField, useFormContext } from 'view/components/formComponent'
import IconLoading from 'view/components/icons/iconLoading/iconLoading'
import LoadAnimation from 'view/components/loadAnimation/loadAnimation'
import { useAppContext } from 'view/contexts/appContext/appContext'

type Props = {
  required?: boolean
}

const InputCepField: React.FC<Props> = ({ required }) => {
  const { modalDialogRef } = useAppContext()
  const modalDialog = modalDialogRef.current.modal
  const { addressByCep, loadedAddress, loadAddress } = useAddressByCep()
  const { cities, loadedCities, loadCities } = useCityList()
  const [citySelected, setCitySelected] = useState<string>('')
  const { updateField } = useFormContext()

  function setInputMask(inputValue: string): string {
    const maskedValue = inputValue
      .replace(/\D/g, '')
      .replace(/(\d{5})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1')

    return maskedValue
  }

  function onBlur(value: string) {
    if (!value || value === '') return
    loadAddress(value.replace('-', ''))
    modalDialogRef.current.setClassName('modal-loading-address')
    modalDialogRef.current.setContent(
      <>
        <IconLoading /> Pesquisando endereço pelo CEP...
      </>
    )
    modalDialog.current.toggleOpen(true)
  }

  function selectCityByAddress(
    addressByCep: TAddressByCep,
    cities: TCity[]
  ): void {
    const foundCity = cities.find(
      city => city.description === addressByCep.city
    )
    if (foundCity) setCitySelected(`${foundCity.id}`)
  }

  function updateFields(citySelected: string) {
    if (!cities || cities.length <= 0 || objectIsEmpty(addressByCep)) return
    const address: { [key: string]: any } = addressByCep
    address.city = citySelected
    const fields = ['address', 'addressDistrict', 'uf', 'city']
    fields.forEach(field => {
      updateField({ name: field, value: address[field] })
    })
  }

  useEffect(() => {
    if (!addressByCep || !addressByCep.uf) return
    loadCities(addressByCep.uf)
  }, [addressByCep])

  useEffect(() => {
    selectCityByAddress(addressByCep, cities)
  }, [cities])

  useEffect(() => {
    if (!citySelected || citySelected === '') return
    updateFields(citySelected)
  }, [citySelected])

  useEffect(() => {
    if (loadedAddress) {
      modalDialog.current.toggleOpen(false)
    }
  }, [loadedAddress, loadedCities])

  return (
    <InputField
      name="cep"
      placeholder="CEP"
      regexMask={setInputMask}
      required={required}
      maxLength={20}
      onBlur={onBlur}
    />
  )
}

export default InputCepField
