import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { TStyleColor } from 'view/viewTypes'

type Props = {
  color?: TStyleColor
  type?: 'circle' | 'normal'
  onClick?: () => void
}

const IconClose: React.FC<Props> = ({ color, type, onClick }) => {
  type = type || 'normal'
  const types: { [key: string]: IconProp } = {
    normal: ['fas', 'times'],
    circle: ['far', 'times-circle']
  }

  function handleClick() {
    if (onClick) onClick()
  }
  return (
    <div
      className={`icon-close color-${color}`}
      style={{ cursor: 'pointer' }}
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={types[type]} />
    </div>
  )
}

export default IconClose
