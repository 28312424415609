import { CarShopCarsApiService } from 'aplication/apiServices/carShopCars/carShopCarsApiService'
import { useState } from 'react'
import { carShopDataTypeParser } from './carShopDataParsers'
import { TCarShopCar } from './carShopTypes'

type TUseCarShopCars = {
  car: TCarShopCar
  loading: boolean
  readCar: (id: string) => void
}

export function useCarShopCarsRead(): TUseCarShopCars {
  const [car, setCar] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)

  function readCar(id: string) {
    setLoading(true)
    const apiService = new CarShopCarsApiService()
    apiService.read(id).then(response => {
      if (!response || response.statusCode !== 200 || !response.body) {
        console.error('car_shop_car_read_error')
        return
      }
      const newCar = carShopDataTypeParser(response.body)
      setCar(newCar)
      setTimeout(() => {
        setLoading(false)
      }, 500)
    })
  }

  return { car, readCar, loading }
}
