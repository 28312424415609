import React, { createContext, useState } from 'react'
import TitlePage from 'view/components/text/titlePage/titlePage'
import { DashboardSingleTemplate } from '../dashboardSingleTemplate'
import DashboardBookingsClosed from './dashboardBookingsClosed'
import DashboardBookingsOpen from './dashboardBookingsOpen'
import './dashboardBookings.css'
import DashboarInfoPjUser from './dashboardInfoPjUser'

type TContext = {
  reloading: boolean
  reloadBookings: () => void
}

export const DashboardBookingsContext = createContext<TContext>({} as TContext)

const DashboardBookings: React.FC = () => {
  const [reloading, setReloading] = useState<boolean>(false)

  function reloadBookings() {
    setReloading(true)
  }

  return (
    <DashboardBookingsContext.Provider value={{ reloading, reloadBookings }}>
      <DashboardSingleTemplate>
        <TitlePage>Minhas reservas</TitlePage>
        <DashboarInfoPjUser />

        <div className="booking-open">
          <h2 className="session-title">RESERVAS EM ABERTO</h2>
          <div className="booking-list-itens">
            <DashboardBookingsOpen />
          </div>
        </div>

        <DashboardBookingsClosed />
      </DashboardSingleTemplate>
    </DashboardBookingsContext.Provider>
  )
}

export default DashboardBookings
