import React, { useEffect, useState } from 'react'
import TitlePage from 'view/components/text/titlePage/titlePage'
import './aboutUs.css'
import AboutusMap from './aboutusMap/aboutusMap'
import AboutusServices from '../../componentsPages/aboutusServices/aboutusServices'
import { useAppContext } from 'view/contexts/appContext/appContext'
import {
  TUseHtmlContentProps,
  useHtmlContent
} from 'aplication/useCases/htmlContent/useHtmlContent'
import { AboutUsDataService } from 'aplication/apiServices/aboutUsDataService/aboutUsDataService'
import LoadingContent from 'view/componentsPages/loadingContent/loadingContent'
import PageBannerTop from 'view/componentsPages/pageBannerTop/pageBannerTop'

const AboutUs: React.FC = () => {
  const useHtmlContentProps: TUseHtmlContentProps = {
    ApiService: AboutUsDataService
  }

  const { data, loaded } = useHtmlContent(useHtmlContentProps)

  const { organizationInfo, pageInfos } = useAppContext()
  const [image, setImage] = useState<string>('')

  useEffect(() => {
    if (!organizationInfo || !organizationInfo.imgFachadaRealiza) {
      setImage('')
      return
    }
    setImage(organizationInfo.imgFachadaRealiza)
  }, [organizationInfo])

  function renderContent() {
    if (!loaded || !organizationInfo?.loaded) {
      return (
        <div className="container">
          <LoadingContent />
        </div>
      )
    } else {
      return (
        <div id="aboutus-page" className="aboutus-page">
          <div className="container">
            <TitlePage>{data?.title}</TitlePage>

            {/* <div className="aboutus-img">
              <img src={image} />
            </div> */}

            <PageBannerTop bannerLocation="quemsomos_topo" />

            <div
              className="aboutus-content"
              dangerouslySetInnerHTML={{ __html: data.content }}
            />
          </div>

          <AboutusMap />

          <AboutusServices />
        </div>
      )
    }
  }

  useEffect(() => {
    if (!data) return
    pageInfos.update({
      pageTitle: data?.title,
      pageDescription: data?.brief
    })
  }, [data])

  return <>{renderContent()}</>
}

export default AboutUs
