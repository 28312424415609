import React from 'react'
import { useFormContext } from 'view/components/formComponent'
import SelectFieldLocations from '../selectFieldLocations/selectFieldLocations'

const PickupLocationField: React.FC = () => {
  const { resetFields } = useFormContext()

  function onChange() {
    resetFields(['hasReturnLocation'])
  }

  return (
    <SelectFieldLocations
      name="pickupLocation"
      placeholder="Local de retirada"
      onChange={onChange}
    />
  )
}

export default PickupLocationField
