import { ICarGroup } from 'domain/entities/ICarGroup'
import { TRentingData } from 'domain/entities/TRentingData'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from 'view/components/button/button'
import Card from 'view/components/card/card'
import CarouselCardImages from 'view/componentsPages/carouselCardImages/carouselCardImages'
import { useBookingDataContext } from 'view/contexts/bookingDataContext'
import { useBookingValuesContext } from 'view/contexts/bookingValuesContext'
import IconLoading from 'view/components/icons/iconLoading/iconLoading'
import CarFeaturesTags from 'view/componentsPages/carFeaturesTags/carFeaturesTags'
import './carGroup.css'
import CarGroupQueueBtn from './carGroupQueueBtn'

type Props = {
  group: ICarGroup
}

const CarGroupComponent: React.FC<Props> = ({ group }) => {
  const { name, price } = group
  const { updateBookingData } = useBookingDataContext()
  const { carGroupPriceRule, priceRule } =
    useBookingValuesContext().carGroupPriceRules
  const history = useHistory()
  const [thePrice, setThePrice] = useState<JSX.Element | string>('0')

  function selectCarGroup() {
    if (!price) return
    const dailyValue = carGroupPriceRule(price)
    const rentingData: TRentingData = { carGroup: group, dailyValue }
    updateBookingData(rentingData)
    const path = history.location.pathname.split('/')
    history.push(`/${path[1]}/reserva`)
  }

  function priceConversor(price?: number | null) {
    if (!price) return <></>
    const priceRule = carGroupPriceRule(price)
    const stringPrice = priceRule.toFixed(2)
    const arrayPrice = stringPrice.split('.')
    const text = (
      <>
        R$ {arrayPrice[0]}
        <span> ,{arrayPrice[1] ? arrayPrice[1] : '00'}</span>
      </>
    )
    setThePrice(text)
  }

  function showButtonIfAvailable() {
    if (!group.available) {
      return <CarGroupQueueBtn groupName={group.name} />
    }
    return (
      <Button size="md" onClick={selectCarGroup}>
        Reservar
      </Button>
    )
  }

  useEffect(() => {
    if (!priceRule) return
    priceConversor(price)
  }, [priceRule])

  function renderContent() {
    if (!thePrice || thePrice === '0') {
      return (
        <Card className="car-group car-group-empty">
          <Card.content>
            <IconLoading />
          </Card.content>
        </Card>
      )
    }
    return (
      <Card className={`car-group${!group.available ? ' unavalable' : ''}`}>
        <Card.content>
          <CarouselCardImages images={group.img} />
          <h3 className="title">
            {name} {!group.available ? <small>{'(Indisponível)'}</small> : ''}
          </h3>
          <CarFeaturesTags features={group.features} />
          <div className="info-row">
            <div className="price">
              {thePrice}
              <span className="text">/DIÁRIA</span>
            </div>
            {showButtonIfAvailable()}
          </div>
        </Card.content>
      </Card>
    )
  }

  return <>{renderContent()}</>
}

export default CarGroupComponent
