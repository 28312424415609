import { bookingPjDataPost } from 'aplication/useCases/bookingPj/bookingPjDataPost'
import React, { useRef, useState } from 'react'
import Button from 'view/components/button/button'
import { Dialog, TDialogRef } from 'view/components/dialog'
import {
  CheckboxField,
  FormComponent,
  InputField,
  SelectField
} from 'view/components/formComponent'
import { useModalContext } from 'view/components/modal/modalContext'
import {
  InputEmailField,
  InputPhoneField
} from 'view/componentsPages/customFields'
import InputCnpjField from 'view/componentsPages/customFields/inputCnpjField/inputCnpjField'
import BookingPjCarGroupsSelect from '../../pages/bookingPJ/bookingPjCarGroupsSelect'
import './bookingPjForm.css'
import { useHistory } from 'react-router-dom'
import { Captcha, useCapcha } from 'view/components'

const BookingPjForm: React.FC = () => {
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false)

  const { toggleOpen } = useModalContext()
  const dialogRef = useRef<TDialogRef>({} as TDialogRef)
  const history = useHistory()

  const { captchaRef, capchaIsInValid } = useCapcha(dialogRef)

  function onSubmit(values: any) {
    setSubmitDisabled(true)
    if (capchaIsInValid(setSubmitDisabled)) return
    dialogRef.current.loadMessage('Enviando dados...')
    bookingPjDataPost(values).then(result => {
      if (!result.success) {
        dialogRef.current.changeMessage(
          'Não foi possível enviar os dados',
          'danger'
        )
      } else {
        dialogRef.current.changeMessage(
          'Dados enviados com sucesso.',
          'success'
        )
        setTimeout(() => {
          toggleOpen(false)
          history.push('/mensagem-enviada-com-sucesso')
        }, 1000)
      }
    })
  }

  return (
    <>
      <Dialog ref={dialogRef} />
      <h2 className="font-emphasis">Terceirização de frota</h2>
      <p className="color-gray1">
        Após preencher o formulário, iremos elaborar uma proposta alinhada com
        os seus interesses e entraremos em contato.
      </p>
      <FormComponent className="pj-form" onSubmit={onSubmit}>
        <InputCnpjField required />
        <InputField
          name="responsible"
          placeholder="Nome do Responsável"
          required
        />
        <InputEmailField required />
        <InputPhoneField required />
        <BookingPjCarGroupsSelect />
        <InputField
          name="amountVehicles"
          placeholder="Quantidade de Veículos"
          required
        />
        <SelectField
          name="plan"
          placeholder="Tempo de Contrato"
          required
          options={[
            { label: '1 mês', value: '1 mês' },
            { label: '3 meses', value: '3 meses' },
            { label: '6 meses', value: '6 meses' },
            { label: '1 ano', value: '1 ano' },
            { label: '2 anos', value: '2 anos' },
            { label: '3 anos', value: '3 anos' },
            { label: '5 anos', value: '5 anos' }
          ]}
        />
        <SelectField
          name="franquiaKm"
          placeholder="Franquia de km/mês"
          required
          options={[
            { label: '1.000 km/mês', value: '1.000 km/mês' },
            { label: '2.000 km/mês', value: '2.000 km/mês' },
            { label: '3.000 km/mês', value: '3.000 km/mês' },
            { label: '4.000 km/mês', value: '4.000 km/mês' },
            { label: '5.000 km/mês', value: '5.000 km/mês' },
            { label: '6.000 km/mês', value: '6.000 km/mês' }
          ]}
        />
        <CheckboxField
          name="okEmail"
          label="Autorizo o envio de e-mails promocionais da Realiza."
        />
        <div style={{ width: '100%', padding: '20px 0' }}>
          <Captcha ref={captchaRef} />
        </div>
        <Button type="submit" disabled={submitDisabled}>
          Enviar
        </Button>
      </FormComponent>
    </>
  )
}

export default BookingPjForm
