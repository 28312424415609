import React, { useContext } from 'react'
import { CarouselContext } from './carouselContext'
import { carouselMouseActions } from './carouselMouseActions'

const CarouselElement: React.FC = ({ children }) => {
  const { scrollRef, currentPosition, runAutoScroll, scrollInterval } =
    useContext(CarouselContext)

  carouselMouseActions()

  function onMouseLeave() {
    runAutoScroll(currentPosition.id)
  }

  function onMouseEnter() {
    clearInterval(scrollInterval)
  }

  return (
    <div
      className="carousel"
      ref={scrollRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  )
}

export default CarouselElement
