import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { priceConverter } from 'aplication/utils/app/valueConverter'
import React from 'react'
import ClientInforBar from 'view/componentsPages/clientInfoBar/clientInfoBar'
import { useAuthContext } from 'view/contexts/authContext'

const RentingClientInfoBar: React.FC = () => {
  const { loggedUser } = useAuthContext()

  function renderInfoBar() {
    if (
      !loggedUser ||
      !loggedUser.person ||
      !loggedUser.person.clientType ||
      loggedUser.person.clientType !== 'PJ'
    ) {
      return <></>
    } else {
      return (
        <ClientInforBar>
          <span title="Usuário logado">
            <FontAwesomeIcon icon={['fas', 'user']} /> {loggedUser?.person.name}
          </span>
          <span title="Crédito para locação">
            <FontAwesomeIcon icon={['fas', 'coins']} />{' '}
            {priceConverter(loggedUser?.person.clientCreditNow)}
          </span>
          <span title="Limite de carros para locação simultênea">
            <FontAwesomeIcon icon={['fas', 'car']} />{' '}
            {loggedUser?.person.clientCarLimitNow}
          </span>
        </ClientInforBar>
      )
    }
  }

  return <>{renderInfoBar()}</>
}

export default RentingClientInfoBar
