import React from 'react'
import { Checkout } from 'view/componentsPages/checkout'
import { useBookingDataContext } from 'view/contexts/bookingDataContext'
import { useBookingValuesContext } from 'view/contexts/bookingValuesContext'
import './bookingPaymentCheckout.css'

const BookingPaymentCheckout: React.FC = () => {
  const { bookingData } = useBookingDataContext()
  const { driversValues } = useBookingValuesContext().driversCalc

  const params = {
    bookingData,
    driversTax: driversValues.driversTax
  }

  return <Checkout {...params} />
}

export default BookingPaymentCheckout
