import React, { useContext, useRef } from 'react'
import { TFieldElement } from 'view/components/formComponent'
import FieldProvider, {
  FieldContext
} from 'view/components/formComponent/fields/fieldContext'
import CheckboxStyled from '../checkBoxStyled/checkBoxStyled'
import './checkboxGroupField.css'

type TOptionCheckbox = { label: string; value: string }

const CheckboxGroupComponent: React.FC = () => {
  const { updateField, fieldProps, stateValue } = useContext(FieldContext)
  const { options } = fieldProps
  const groupRef = useRef<HTMLDivElement>({} as HTMLDivElement)

  function onChange() {
    const value: string[] = []
    const checkeds =
      groupRef.current.querySelectorAll<HTMLInputElement>('input:checked')
    checkeds.forEach(option => {
      value.push(option.value)
    })
    updateField({ name: fieldProps.name, value })
  }

  function isChecked(value: string) {
    return stateValue === value
  }

  function renderOptions() {
    return options.map((option: TOptionCheckbox, index: number) => {
      return (
        <CheckboxStyled
          key={index}
          id={option.value}
          label={option.label}
          value={option.value}
          isChecked={isChecked}
          name={fieldProps.name}
          onChange={onChange}
        />
      )
    })
  }

  return (
    <div className="checkbox-group-fields" ref={groupRef}>
      {renderOptions()}
    </div>
  )
}

type TProps = TFieldElement & {
  options: TOptionCheckbox[]
}

const CheckboxGroupField: React.FC<TProps> = props => {
  return (
    <div className="checkbox-group">
      <FieldProvider fieldProps={props}>
        <label>{props.label}</label>
        <CheckboxGroupComponent />
      </FieldProvider>
    </div>
  )
}

export default CheckboxGroupField
