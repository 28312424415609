import { useCarGroupCombo } from 'aplication/useCases/carGroup/useCarGroupCombo'
import React, { useEffect } from 'react'
import { SelectField } from 'view/components/formComponent'

const SelectCarGroup: React.FC = () => {
  const carGroupCombo = useCarGroupCombo()

  useEffect(() => {
    carGroupCombo.loadData()
  }, [])

  return (
    <SelectField
      name="grupo"
      placeholder="Grupo de veículo"
      options={carGroupCombo.data}
    />
  )
}

export default SelectCarGroup
