import { HttpResponse } from 'aplication/apiServices/protocols/IHttpClient'
import { UserUpdatePassword } from 'aplication/apiServices/userUpdatePassword/userUpdatePassword'
import { TUserPasswordPost } from 'aplication/entities/TUserPasswordPost'
import { userUpdatePasswordPostParser } from './userUpdatePasswordDataParser'

export async function userUpdatePassword(
  body: TUserPasswordPost
): Promise<HttpResponse> {
  const serviceData = new UserUpdatePassword()
  console.log(body)
  const data = userUpdatePasswordPostParser(body)
  console.log(data)
  return await serviceData.load(data)
}
