import React from 'react'
import { InputField, TFieldElement } from 'view/components/formComponent'
import './inputConfirmCodeField.css'

const InputConfirmCodeField: React.FC<TFieldElement> = ({
  name,
  placeholder
}) => {
  // function setMask(inputValue: string): string {
  //   const maskedValue = inputValue
  //     .replace(/\D/g, '')
  //     .replace(/(\d{2})(\d)/, '$1 $2')
  //     .replace(/(\d{2})(\d{1,2})/, '$1 $2')
  //     .replace(/(\d{2})(\d{1,2})/, '$1')

  //   return maskedValue
  // }

  return (
    <InputField
      name={name}
      placeholder={placeholder}
      maxLength={6}
      required
      className="input-confirm-code-field"
    />
  )
}

export default InputConfirmCodeField
