import React from 'react'
import { TBooking } from 'domain/entities/TBooking'
import { priceConverter } from 'aplication/utils/app/valueConverter'
import { useAppContext } from 'view/contexts/appContext/appContext'
import {
  isoDateToDateDisplay,
  isoDateToTimeDisplay
} from 'aplication/utils/app/dateTimeCare/dateTimeCare'
import CheckoutLocationTax from 'view/componentsPages/checkout/checkoutLocationTax'

type Props = {
  booking: TBooking
}

const DashboardBookingCheckout: React.FC<Props> = ({ booking }) => {
  const { organizationInfo } = useAppContext()

  function totalWithoutDiscount() {
    if (!booking.discountTotal || !booking.dailyTotal) return <></>
    return priceConverter(booking?.discountTotal + booking?.dailyTotal)
  }

  function formatExtraHours(extraHours: number, totalValue: number) {
    const time = extraHours > 5 ? 1 : extraHours
    const timeValue = totalValue / time
    return `${time} x ${priceConverter(timeValue)}`
  }

  function renderExtraHours() {
    if (!booking.extraHour || !booking?.extraHourValue) return <></>
    return (
      <div className="checkout-values-item">
        <div className="checkout-values-item-info">
          <h3>Horas extras</h3>
          <span>
            {formatExtraHours(booking.extraHour, booking.extraHourValue)}
          </span>
        </div>
        <div className="checkout-values-item-value">
          {priceConverter(booking?.extraHourValue)}
        </div>
      </div>
    )
  }

  function renderDrivers() {
    if (!booking?.driversTax || !booking?.driversTotalTax) return <></>
    return (
      <div className="checkout-values-item">
        <div className="checkout-values-item-info">
          <h3>Condutores</h3>
          <span>
            {booking.daily} x {priceConverter(booking?.driversTax)}
          </span>
        </div>
        <div className="checkout-values-item-value">
          {priceConverter(booking?.driversTotalTax)}
        </div>
      </div>
    )
  }

  function renderResources() {
    if (!booking?.daily || !booking?.resourcesTotal) return <></>
    return (
      <div className="checkout-values-item">
        <div className="checkout-values-item-info">
          <h3>Acessórios</h3>
          <span>
            {booking?.daily || 0} x {priceConverter(booking?.resourcesTotal)}
          </span>
        </div>
        <div className="checkout-values-item-value">
          {priceConverter(booking?.resourcesTotal)}
        </div>
      </div>
    )
  }

  return (
    <div className="checkout">
      <div className="checkout-booking">
        <h3>{booking.carGroup?.name}</h3>
        <span>{booking?.daily} dias</span>
        <div className="checkout-booking-item">
          <span>{booking.pickup?.local}</span>
          <span>
            {isoDateToDateDisplay(booking?.pickup?.date)} -{' '}
            {isoDateToTimeDisplay(booking?.pickup?.hour)}
          </span>
        </div>
        <div className="checkout-booking-item">
          <span>{booking.return?.local}</span>
          <span>
            {isoDateToDateDisplay(booking?.return?.date)} -{' '}
            {isoDateToTimeDisplay(booking?.return?.hour)}
          </span>
        </div>
      </div>

      <div className="checkout-values">
        <div className="checkout-values-item">
          <div className="checkout-values-item-info">
            <h3>Diárias</h3>
            <span>
              {booking?.daily} x {priceConverter(booking?.dailyValue)}
            </span>
          </div>
          <div className="checkout-values-item-value">
            <small className="discount">{totalWithoutDiscount()}</small>
            <br />
            {priceConverter(booking?.dailyTotal)}
          </div>
        </div>

        {renderExtraHours()}

        <div className="checkout-values-item">
          <div className="checkout-values-item-info">
            <h3>Proteção</h3>
            <span>
              {booking?.daily} x {priceConverter(booking?.protection?.price)}
            </span>
          </div>
          <div className="checkout-values-item-value">
            {priceConverter(booking?.protectionTotal)}
          </div>
        </div>

        {renderDrivers()}

        {renderResources()}

        <CheckoutLocationTax bookingData={booking} />
      </div>

      <div className="checkout-final">
        <div className="checkout-total">
          <span>Total</span> <span>{priceConverter(booking?.totalToPay)}</span>
        </div>
      </div>
    </div>
  )
}

export default DashboardBookingCheckout
