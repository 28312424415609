import { useState } from 'react'

export type TEventFunction = {
  id: string
  name: string
  action: (values: { [key: string]: any } | null) => any
}

export type TUseEventController = {
  subscribeEvent: (eventFunction: TEventFunction) => void
  triggerEvent: (
    name: string,
    values: { [key: string]: any } | null,
    callBack?: (result: { [key: string]: any } | null) => void
  ) => void
}

export function useEventController(): TUseEventController {
  const [events, setEvents] = useState<TEventFunction[]>([] as TEventFunction[])

  function subscribeEvent(eventFunction: TEventFunction) {
    setEvents((oldEvents: TEventFunction[]) => {
      const foundEvent = oldEvents.find(evt => evt.id === eventFunction.id)
      if (!foundEvent || Object.keys(foundEvent).length <= 0) {
        oldEvents.push(eventFunction)
      }
      return oldEvents
    })
  }

  function triggerEvent(
    name: string,
    values: { [key: string]: any } | null,
    callBack?: (result: { [key: string]: any } | null) => void
  ) {
    events.forEach(event => {
      if (event.name === name) {
        const result = event.action(values)
        if (callBack) callBack(result)
      }
    })
  }

  return {
    subscribeEvent,
    triggerEvent
  }
}
