import { TCarStockFeatureApi } from 'aplication/apiServices/carStockApiService/TCarStockApi'
import { TCarStockFeature } from './TCarStock'

export function carStockFeaturesDataParser(
  data: TCarStockFeatureApi
): TCarStockFeature {
  if (!data) return {} as TCarStockFeature
  return {
    description: data.descricao,
    id: data.id,
    image: data.image,
    slug: data.slug,
    uuid: data.uuid
  }
}

export function carStockFeaturesArrayParser(
  data: TCarStockFeatureApi[]
): TCarStockFeature[] {
  if (!data || data.length <= 0) return [] as TCarStockFeature[]
  return data.map(d => carStockFeaturesDataParser(d))
}
