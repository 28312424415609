import React, { useEffect } from 'react'
import { AboutUsEthicDataService } from 'aplication/apiServices/aboutUsEthicDataService/aboutUsEthicDataService'
import {
  TUseHtmlContentProps,
  useHtmlContent
} from 'aplication/useCases/htmlContent/useHtmlContent'
import TitlePage from 'view/components/text/titlePage/titlePage'
import LoadingContent from 'view/componentsPages/loadingContent/loadingContent'
import { useAppContext } from 'view/contexts/appContext/appContext'

const AboutUsEthic: React.FC = () => {
  const useHtmlContentProps: TUseHtmlContentProps = {
    ApiService: AboutUsEthicDataService
  }
  const { pageInfos } = useAppContext()
  const { data, loaded } = useHtmlContent(useHtmlContentProps)

  function renderContent() {
    if (!loaded) {
      return (
        <div className="container">
          <LoadingContent />
        </div>
      )
    } else {
      return (
        <div id="aboutus-ethic-page" className="aboutus-page">
          <div className="container">
            <TitlePage>{data?.title}</TitlePage>
            <div
              className="aboutus-content-2"
              dangerouslySetInnerHTML={{ __html: data?.content }}
            />
          </div>
        </div>
      )
    }
  }

  useEffect(() => {
    if (!data) return
    pageInfos.update({
      pageTitle: data?.title,
      pageDescription: data?.brief
    })
  }, [data])

  return <>{renderContent()}</>
}

export default AboutUsEthic
