import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Dialog, useDialog } from 'view/components/dialog'
import { Modal, useModal } from 'view/components/modal'
import { Text, Title } from 'view/components/text'
import UserActivationForm from './userActivationForm/userActivationForm'
import './modalUserActivation.css'
import Button from 'view/components/button/button'
import { userUpdateActivatioConde } from 'aplication/useCases/userUpdateActivatioConde/userUpdateActivatioConde'
import { useAuthContext } from 'view/contexts/authContext'

export type TModalUserActivation = {
  toggle: () => void
}

export type TModalUserActivationContext = {
  modal: any
  dialog: any
}

export const ModalUserActivationContext =
  React.createContext<TModalUserActivationContext>(
    {} as TModalUserActivationContext
  )

const ModalUserActivation: React.ForwardRefRenderFunction<
  TModalUserActivation
> = (props, ref) => {
  const [onSuccess, setOnSuccess] = useState<any>()
  const modal = useModal()
  const dialog = useDialog()
  const { userUuid } = useAuthContext()
  const [resendDisabled, setResendDisabled] = useState<boolean>(false)

  function toggle() {
    modal.use.toggleOpen()
  }

  function buildOnSuccess(callback: () => void) {
    return setOnSuccess(callback)
  }

  function resendCode() {
    userUpdateActivatioConde(userUuid).then(response => {
      setResendDisabled(true)
      const responseStatus = response.statusCode
      const msgColor = responseStatus === 200 ? 'success' : 'warning'
      if (responseStatus !== 200) {
        setTimeout(() => {
          setResendDisabled(false)
        }, 500)
      }
      dialog.ref.current?.changeMessage(response?.body?.msg, msgColor)
    })
  }

  useImperativeHandle(ref, () => {
    return { toggle, buildOnSuccess }
  })

  return (
    <Modal
      className="modal-user-activation"
      ref={modal.ref}
      content={
        <>
          <Dialog ref={dialog.ref} />
          <Title size="bigger">Ativação de conta</Title>
          <Text span color="gray2">
            Para realizar a ativação da sua conta, digite abaixo o código que
            foi enviado para o e-mail cadastrado.
          </Text>
          <ModalUserActivationContext.Provider value={{ modal, dialog }}>
            <UserActivationForm onSuccess={onSuccess} />
          </ModalUserActivationContext.Provider>
          <div className="resend-link">
            <Text span color="gray2">
              Não recebeu o código?{' '}
              <Button
                style="link"
                onClick={resendCode}
                disabled={resendDisabled}
              >
                Reenviar
              </Button>
            </Text>
          </div>
        </>
      }
    />
  )
}

export default forwardRef(ModalUserActivation)
