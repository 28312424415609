import { CredCardApiService } from 'aplication/apiServices/credCardApiService/credCardApiService'
import { TCredCard } from 'domain/entities/TCredCard'
import { useState } from 'react'
import { useAuthContext } from 'view/contexts/authContext'
import { credCardArrayParser } from './credCardsDataParser'

export type TUseCredCards = {
  credCards: TCredCard[]
  loaded: boolean
  totalCards: number
  loadMoreCards: () => void
  loadCards: () => void
}

export function useCredCards(): TUseCredCards {
  const { loggedUser } = useAuthContext()
  const [credCards, setCredCards] = useState<TCredCard[]>([] as TCredCard[])
  const [loaded, setLoaded] = useState<boolean>(false)
  const [totalCards, setTotalCards] = useState<number>(0)
  const [nextPage, setNextPage] = useState<number>(1)
  const apiService = new CredCardApiService()

  function loadCards() {
    setLoaded(false)
    apiService.load(loggedUser?.person.uuid).then(response => {
      if (
        !response ||
        response.statusCode !== 200 ||
        !response.body ||
        !response.body.results
      ) {
        console.error('cred_cards_load_error')
        return
      }
      const allCards = credCardArrayParser(response.body.results)
      setNextPage(2)
      setCredCards(allCards)
      setTotalCards(response.body.count)

      setTimeout(() => {
        setLoaded(true)
      }, 500)
    })
  }

  function loadNextPage(page: number) {
    setLoaded(false)
    apiService.load(loggedUser?.person.uuid, page).then(response => {
      if (
        !response ||
        response.statusCode !== 200 ||
        !response.body ||
        !response.body.results
      ) {
        console.error('cred_cards_load_error')
        return
      }
      const newCards = credCardArrayParser(response.body.results)
      setTimeout(() => {
        setCredCards([...credCards, ...newCards])
      }, 500)
    })
  }

  function hasMoreCars() {
    return credCards.length >= totalCards
  }

  function loadMoreCards() {
    if (!hasMoreCars) return
    loadNextPage(nextPage)
    setNextPage(nextPage + 1)
  }

  return { credCards, loaded, totalCards, loadCards, loadMoreCards }
}
