import { ClientUpdateDataService } from 'aplication/apiServices/clientUpdateDataService/clientUpdateDataService'
import { HttpResponse } from 'aplication/apiServices/protocols/IHttpClient'
import { TApiUser } from 'domain/entities/api/TApiUser'
import { TClient } from 'domain/entities/TClient'

export async function clientUpdate(
  clientData: TClient
): Promise<HttpResponse<TApiUser | any>> {
  const serviceData = new ClientUpdateDataService()
  return await serviceData.load(clientData)
}
