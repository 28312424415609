import React, { createContext, useContext, useEffect, useState } from 'react'
import { User } from 'aplication/entities/User'
import { TEmpty } from 'domain/entities/TEmpty'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'aplication/useCases/auth/useAuth'
import LoadingContent from 'view/componentsPages/loadingContent/loadingContent'
import { TLoginResolve } from 'aplication/entities/TLoginResolve'
import {
  deleteRentingData,
  readRentingData,
  saveRentingData
} from 'domain/useCases/rentingStepsDataControl/rentingStepsDataControl'
import { objectIsEmpty } from 'aplication/utils/app/testValues'

type TAuthContext = {
  loggedUser: User | TEmpty | null
  userUuid: string
  updateUserUuid: (value: string) => void
  logoutUser: () => void
  isUserLogged: () => boolean
  loadCurrentUser: () => void
  loginUser: (params: {
    username: string
    password: string
  }) => Promise<TLoginResolve>
}

const AuthContext = createContext<TAuthContext>({} as TAuthContext)

export function useAuthContext(): TAuthContext {
  return useContext(AuthContext)
}

export const AuthContextProvider: React.FC = ({ children }) => {
  const { login, logout, firstAppToken, loadCurrentUser, loaded, loggedUser } =
    useAuth()
  const history = useHistory()
  const [userUuid, setUserUuid] = useState<string>('')

  function logoutUser() {
    logout()
    history.replace('/login')
  }

  function loginUser(params: {
    username: string
    password: string
  }): Promise<TLoginResolve> {
    return new Promise(resolve => {
      const { username, password } = params
      login({ username, password }).then(result => {
        setTimeout(() => {
          resolve(result)
        }, 1000)
      })
    })
  }

  function updateUserUuid(value: string) {
    if (!value) return
    setUserUuid(value)
  }

  function isUserLogged(): boolean {
    if (!loggedUser) return false
    return Object.keys(loggedUser).length > 0 && loggedUser.isActivated
  }

  function bookingDataCheking() {
    const storageData = readRentingData()
    const hasLoggedUser = loggedUser && loggedUser.person
    if (
      !storageData ||
      objectIsEmpty(storageData) ||
      (!storageData.client && !hasLoggedUser)
    ) {
      return
    }
    if (!storageData.client && hasLoggedUser) {
      saveRentingData({ ...storageData, client: loggedUser.person.id })
    } else if (
      (storageData.client && !hasLoggedUser) ||
      (hasLoggedUser && storageData.client !== loggedUser?.person.id)
    ) {
      deleteRentingData()
    }
  }

  function renderContent() {
    if (!loaded) {
      return (
        <div className="container">
          <LoadingContent />
        </div>
      )
    } else {
      return (
        <AuthContext.Provider
          value={{
            loggedUser,
            isUserLogged,
            loginUser,
            logoutUser,
            updateUserUuid,
            userUuid,
            loadCurrentUser
          }}
        >
          {children}
        </AuthContext.Provider>
      )
    }
  }

  useEffect(() => {
    firstAppToken()
    loadCurrentUser()
  }, [])

  useEffect(() => {
    bookingDataCheking()
  }, [loggedUser])

  return <>{renderContent()}</>
}
