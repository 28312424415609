import { createContext } from 'react'
import { TPosition } from './carouselTypes'

type TCarouselContext = {
  scrollRef: any
  positions: TPosition[]
  currentPosition: TPosition
  scrollInterval: any
  runAutoScroll: (currentPositionId: number) => void
  updateCurrentposition: (positionId: number) => void
  scrollToPosition: (position: TPosition) => void
}

export const CarouselContext = createContext<TCarouselContext>(
  {} as TCarouselContext
)
