import React, { useEffect, useState } from 'react'
import { slideImagesLoader } from 'aplication/useCases/slideImages/slideImagesLoader'
import './pageBannerContainer.css'

type TProps = {
  onlyMobile?: boolean
  bannerLocation?: string
}

const PageBannerContainer: React.FC<TProps> = ({
  onlyMobile,
  bannerLocation
}) => {
  if (!bannerLocation) return <></>

  const { slideImages, loadSlideImages } = slideImagesLoader()
  const [images, setImages] = useState<{ desktop?: string; mobile?: string }>()
  const randonSelect = Math.floor(Math.random() * slideImages.length)

  useEffect(() => {
    loadSlideImages(bannerLocation)
  }, [])

  useEffect(() => {
    if (!slideImages || slideImages.length <= 0 || !slideImages[0]) return
    setImages(slideImages[randonSelect])
  }, [slideImages])

  function renderMobileOnly() {
    if (!images || !images.mobile) return <></>
    return (
      <img
        src={images?.mobile || ''}
        className="page-banner-container-img-mobile_size"
      />
    )
  }

  function renderContent() {
    if (!images) return <></>
    return (
      <div className="page-banner-container">
        <img src={images.desktop || ''} className="page-banner-container-img" />
        <img
          src={images.mobile || ''}
          className="page-banner-container-img-mobile"
        />
      </div>
    )
  }

  return <>{onlyMobile ? renderMobileOnly() : renderContent()}</>
}

export default PageBannerContainer
