import appTexts from 'aplication/localData/appText/appText'
import { createRenting } from 'aplication/useCases/createRenting/createRenting'
import { deleteRentingData } from 'domain/useCases/rentingStepsDataControl/rentingStepsDataControl'
import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Dialog, TDialogRef } from 'view/components/dialog'
import BookingPaymentModalMessage, {
  TBookingPaymentModalMessageRef
} from 'view/componentsPages/bookingPaymentModalMessage/bookingPaymentModalMessage'
import BookingPaymentPage from 'view/componentsPages/bookingPaymentPage/bookingPaymentPage'
import PaymentUserPj from 'view/componentsPages/bookingPaymentUserPj/bookingPaymentUserPj'
import { useAuthContext } from 'view/contexts/authContext'
import { useBookingDataContext } from 'view/contexts/bookingDataContext'
import PaymentUserPf, { renderCustomMessagePf } from './paymentUserPf'

const PaymentPageForm: React.FC = () => {
  const history = useHistory()
  const { loggedUser } = useAuthContext()
  const { bookingData, updateBookingData } = useBookingDataContext()
  const dialogRef = useRef<TDialogRef>({} as TDialogRef)
  const modalMessageRef = useRef<TBookingPaymentModalMessageRef>(
    {} as TBookingPaymentModalMessageRef
  )

  function onSubmit(values: any) {
    modalMessageRef.current.toggleModal(values)
  }

  function postAction(values: any) {
    dialogRef.current.loadMessage('Enviando dados da reserva...')
    createRenting(bookingData, values).then(response => {
      if (response.statusCode === 201) {
        const bookingId = response.body.uuid
        const path = history.location.pathname.split('/')[1]
        history.push(`/${path}/reserva_concluida?id=${bookingId}`)
        deleteRentingData()
      } else {
        const message = appTexts[response.body?.detail]
        const defaultMsg = appTexts.default_reserva_post_error
        dialogRef.current.changeMessage(message || defaultMsg, 'danger')
      }
    })
  }

  function renderCustomModalMessage() {
    const userType = loggedUser?.person.clientType

    if (userType !== 'PJ') {
      return renderCustomMessagePf()
    } else {
      return <></>
    }
  }

  function selectPaymentByUser() {
    const userType = loggedUser?.person.clientType

    if (userType !== 'PJ') {
      return <PaymentUserPf onSubmit={onSubmit} />
    } else {
      return <PaymentUserPj onSubmit={onSubmit} dialogRef={dialogRef} />
    }
  }

  useEffect(() => {
    if (!loggedUser || !loggedUser.person) return
    updateBookingData({ client: loggedUser?.person.id })
  }, [loggedUser])

  return (
    <BookingPaymentPage>
      <Dialog ref={dialogRef} />
      {selectPaymentByUser()}
      <BookingPaymentModalMessage
        ref={modalMessageRef}
        onConfirm={postAction}
        customMessage={renderCustomModalMessage()}
      />
    </BookingPaymentPage>
  )
}

export default PaymentPageForm
