import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { TFieldElement } from '../..'
import FieldProvider, { FieldContext } from '../fieldContext'
import './passwordField.css'

type TProps = TFieldElement & {
  maxLength?: number
}

const PasswordComponent: React.FC = () => {
  const { updateField, fieldProps, stateValue } = useContext(FieldContext)
  const { name, placeholder, maxLength } = fieldProps
  const [show, setShow] = useState(false)
  const fieldRef = useRef<HTMLInputElement>({} as HTMLInputElement)
  let timeoutToSetInputValue: any = {}

  function toggleIcon() {
    if (show) return <FontAwesomeIcon icon={['far', 'eye-slash']} />
    return <FontAwesomeIcon icon={['far', 'eye']} />
  }

  function toggleType() {
    if (show) return 'text'
    return 'password'
  }

  function toggleShow() {
    setShow(!show)
  }

  function handleChange(event: any) {
    const value = event.target.value
    updateField({ name, value: value })
  }

  /**
   * evita erro de acentuação no input
   */
  function delayToSetInputValueByStateValue() {
    timeoutToSetInputValue = setTimeout(() => {
      fieldRef.current.value = stateValue
    }, 100)
    return () => {
      clearTimeout(timeoutToSetInputValue)
    }
  }

  useEffect(() => delayToSetInputValueByStateValue(), [stateValue])

  return (
    <>
      <span className="hide-show" onClick={toggleShow}>
        {toggleIcon()}
      </span>
      <input
        type={toggleType()}
        name={name}
        placeholder={placeholder}
        onChange={handleChange}
        maxLength={maxLength || 20}
        defaultValue={stateValue || ''}
        ref={fieldRef}
      />
    </>
  )
}

const PasswordField: React.FC<TProps> = props => {
  return (
    <FieldProvider fieldProps={{ ...props, className: 'password-field' }}>
      <PasswordComponent />
    </FieldProvider>
  )
}

export default PasswordField
