import { useEffect, useState } from 'react'
import { OrganizationInfoDataService } from 'aplication/apiServices/organizationInfoDataService/organizationInfoDataService'

import {
  TOrganizationInfo,
  TOrganizationInfoImage
} from '../../../domain/entities/TOrganizationInfo'
import { TOrganizationInfoApiData } from 'domain/entities/api/TOrganizationInfoApiData'

export type TUseOrganizationInfo = {
  organizationInfo?: TOrganizationInfo & {
    loaded: boolean
  }
}

export function useOrganizationInfo(): TUseOrganizationInfo {
  const dataService = new OrganizationInfoDataService()
  const [data, setData] = useState<TOrganizationInfo>({} as TOrganizationInfo)
  const [loaded, setLoaded] = useState<boolean>(false)

  function dataTypeParser(
    apiData: TOrganizationInfoApiData
  ): TOrganizationInfo {
    return {
      id: apiData.id,
      address: apiData.endereco,
      adminUrl: apiData.url_admin,
      logo: apiData.logomarca,
      imgBackgroundLogin: apiData.img_background_login,
      imgFachadaRealiza: apiData.img_fachada_realiza,
      imgRodape: apiData.img_rodape,
      imgRodapeMb: apiData.img_rodape_mb,
      linkImgRodape: apiData.link_img_rodape,
      mainEmail: apiData.email_contato_principal,
      siteDescription: apiData.descricao_site,
      siteFooterText: apiData.texto_rodape_site,
      siteName: apiData.name_site,
      siteTitle: apiData.titulo_site,
      siteUrl: apiData.url_site,
      timeOpen: apiData.horario_funcionamento,
      whatsapp: apiData.whatsapp,
      phone: apiData.telefone,
      cnpj: apiData.cnpj,
      driverTax: apiData.custo_condutor
    }
  }

  function loadData() {
    setLoaded(false)

    dataService.load().then(response => {
      if (
        !response ||
        response.statusCode !== 200 ||
        !response.body ||
        !response.body.results
      ) {
        console.error('organization_info_service_error')
        return
      }
      setData(dataTypeParser(response.body.results[0]))
      setTimeout(() => setLoaded(true), 200)
    })
  }

  useEffect(() => loadData(), [])

  return { organizationInfo: { ...data, loaded } }
}
