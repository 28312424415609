import {
  dateToIsoDate,
  isoDateToDate
} from 'aplication/utils/app/dateTimeCare/dateTimeCare'
import { TBookingEvent } from 'domain/entities/TBooking'
import { TLocation } from 'domain/entities/TLocation'
import { TRentingData } from 'domain/entities/TRentingData'
import {
  readRentingData,
  saveRentingData
} from 'domain/useCases/rentingStepsDataControl/rentingStepsDataControl'
import { useEffect, useState } from 'react'
import { TField } from 'view/components/formComponent'
import { useAppContext } from 'view/contexts/appContext/appContext'
import { useBookingDataContext } from 'view/contexts/bookingDataContext'

type TFieldValues = {
  pickupLocation?: string
  pickupDate?: Date
  pickupTime?: Date
  returnLocation?: string
  returnDate?: Date
  returnTime?: Date
  hasReturnLocation?: boolean
}

type TBookingFormRentingData = {
  fieldValues: TFieldValues
  buildRentingData: (fields: TField[]) => void
}

function selectRentingDataState() {
  const bookinfDataContext = useBookingDataContext()
  if (Object.keys(bookinfDataContext).length > 0) {
    return bookinfDataContext
  }
  return {
    bookingData: readRentingData(),
    updateBookingData: saveRentingData
  }
}

export function bookingFormRentingData(): TBookingFormRentingData {
  const { bookingData, updateBookingData } = selectRentingDataState()
  const { locations } = useAppContext().locations

  const [fieldValues, setFieldValues] = useState<TFieldValues>(
    {} as TFieldValues
  )

  function getLocation(locationId: string): TLocation {
    const found = locations.find(
      location => `${location.id}` === `${locationId}`
    )
    return found || ({} as TLocation)
  }

  function getBookingFields(
    fields: TField[],
    fieldsNames: { location: string; date: string; time: string }
  ) {
    const { location, date, time } = fieldsNames
    const fieldLocation = fields.find(f => f.name === location)
    const fieldDate = fields.find(f => f.name === date)
    const fieldTime = fields.find(f => f.name === time)

    return {
      location: getLocation(fieldLocation?.value),
      local: fieldLocation?.value,
      date: fieldDate?.value,
      hour: fieldTime?.value
    }
  }

  function formatDateToSave(bookingEvent: TBookingEvent) {
    let { date, hour } = bookingEvent
    date = dateToIsoDate(date)
    hour = dateToIsoDate(hour)
    return { ...bookingEvent, date, hour }
  }

  function buildRentingData(fields: TField[]): void {
    let rentingData: TRentingData = {} as TRentingData

    const pickupEvent = getBookingFields(fields, {
      location: 'pickupLocation',
      date: 'pickupDate',
      time: 'pickupTime'
    })

    const returnEvent = getBookingFields(fields, {
      location: 'returnLocation',
      date: 'returnDate',
      time: 'returnTime'
    })

    rentingData = {
      ...rentingData,
      pickup: formatDateToSave(pickupEvent),
      return: formatDateToSave(returnEvent)
    }

    updateBookingData(rentingData)
  }

  useEffect(() => {
    if (!bookingData) return
    const rentingToFieldValues: TFieldValues = {
      pickupLocation: bookingData.pickup?.local,
      pickupDate: isoDateToDate(bookingData.pickup?.date),
      pickupTime: isoDateToDate(bookingData.pickup?.hour),
      returnLocation: bookingData.return?.local,
      returnDate: isoDateToDate(bookingData.return?.date),
      returnTime: isoDateToDate(bookingData.return?.hour),
      hasReturnLocation: bookingData.pickup?.local !== bookingData.return?.local
    }

    setFieldValues(rentingToFieldValues)
  }, [])

  return { buildRentingData, fieldValues }
}
