import { ResourcesDataService } from 'aplication/apiServices/resourcesDataService/resourcesDataService'

export async function resourcesList(
  resourceId?: number | null
): Promise<any | null> {
  const dataService = new ResourcesDataService()
  const response = await dataService.load(resourceId)

  if (response.statusCode === 200) {
    return response.body
  }

  return null
}
