import React, { useEffect, useState } from 'react'
import { FormComponent, TField } from 'view/components/formComponent'
import RentInfo from '../rentInfo/rentInfo'
import Resource from '../../../../componentsPages/resource/resource'
import { useBookingDataContext } from 'view/contexts/bookingDataContext'
import { useBookingValuesContext } from 'view/contexts/bookingValuesContext'

const Additional: React.FC = () => {
  const { updateBookingData } = useBookingDataContext()

  const {
    resources,
    resourcesValue,
    resourcesDetail,
    countResources,
    resourcesToRentingData,
    updateResourcesValue
  } = useBookingValuesContext().resources

  const [fieldValues, setFieldValues] = useState<any>()

  function loadResources() {
    return resources.map((resource, index) => {
      return <Resource canEdit key={index} resource={resource} />
    })
  }

  function onFormChange(fields: TField[]) {
    const resourcesData = resourcesToRentingData(fields)
    updateResourcesValue(resourcesData)
  }

  function renderResources() {
    if (!resources || resources.length <= 0) return <></>
    return (
      <FormComponent onFormChange={onFormChange} fieldValues={fieldValues}>
        <RentInfo>
          <RentInfo.header>
            <h3>Acessórios</h3>
          </RentInfo.header>
          {loadResources()}
        </RentInfo>
      </FormComponent>
    )
  }

  useEffect(() => {
    const values = countResources()
    setFieldValues(values)
  }, [])

  useEffect(() => {
    updateBookingData({ resources: resourcesValue, resourcesDetail })
  }, [resourcesValue])

  return <>{renderResources()}</>
}

export default Additional
