import React from 'react'
import './carouselConteudoWithBox.css'
import CarouselConteudo from 'view/componentsPages/carouselConteudo/carouselConteudo'

type TProps = {
  title?: string
  link?: string
  textLink?: string
}

const CarouselConteudoWithBox: React.FC<TProps> = props => {
  return (
    <div className="conteudo-list-wrapper">
      <CarouselConteudo {...props} />
    </div>
  )
}

export default CarouselConteudoWithBox
