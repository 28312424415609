import { TFaqApi } from 'domain/entities/api/TFaqApi'
import { TFaq } from 'domain/entities/TFaq'

export function faqDataParser(data: TFaqApi): TFaq {
  if (!data) return {} as TFaq
  return {
    answer: data.resposta,
    content: data.content,
    id: data.id,
    question: data.pergunta,
    slug: data.slug,
    title: data.title,
    uuid: data.uuid
  }
}

export function faqArrayParser(data: TFaqApi[]): TFaq[] {
  if (!data || data.length <= 0) return [] as TFaq[]
  return data.map(p => faqDataParser(p))
}
