import React, { createContext, useRef } from 'react'
import {
  isoDateToDateDisplay,
  isoDateToTimeDisplay
} from 'aplication/utils/app/dateTimeCare/dateTimeCare'
import { Link } from 'react-router-dom'
import Card from 'view/components/card/card'
import { BookingStatus } from 'view/componentsPages/bookingStatus'
import './bookingCard.css'
import BookingCardOptions from './bookingCardOptions/bookingCardOptions'
import { TBooking } from 'domain/entities/TBooking'
import { Dialog, TDialogRef } from 'view/components/dialog'
import { priceConverter } from 'aplication/utils/app/valueConverter'
import BookingCardBookingCode from '../components/bookingCardBookingCode/bookingCardBookingCode'

type Props = {
  rentingData: TBooking
}

type TContext = {
  bookingData: TBooking
  dialogRef: { current: TDialogRef }
}

export const BookingCardContext = createContext<TContext>({} as TContext)

const BookingCard: React.FC<Props> = ({ rentingData }) => {
  const dialogRef = useRef<TDialogRef>({} as TDialogRef)

  function firstImage(rentingData: TBooking) {
    const images = rentingData.carGroup?.img
    if (!images || images.length <= 0) return '/media/empty.png'
    return images[0].image
  }

  function buildPrice() {
    const price = priceConverter(rentingData.totalToPay, true)
    return (
      <>
        {price[0]}
        <small>{price[1]}</small>
      </>
    )
  }

  return (
    <BookingCardContext.Provider
      value={{ bookingData: rentingData, dialogRef }}
    >
      <div className="booking">
        <Card>
          <Dialog ref={dialogRef} />
          <Card.content>
            <div className="booking-card">
              <div className="booking-img-secction">
                <Link to={`/dashboard/reserva/${rentingData?.id}`}>
                  <img src={firstImage(rentingData)} />
                </Link>
              </div>
              <div className="booking-info-secction">
                <BookingCardBookingCode booking={rentingData} />
                <Link to={`/dashboard/reserva/${rentingData?.id}`}>
                  <h3 className="booking-group-name">
                    {rentingData.carGroup?.name}
                  </h3>
                </Link>
                <div className="booking-date-time">
                  <span>
                    {`${isoDateToDateDisplay(
                      rentingData.pickup?.date
                    )} - ${isoDateToTimeDisplay(rentingData.pickup?.hour)}`}
                  </span>
                  <span>{`${isoDateToDateDisplay(
                    rentingData.return?.date
                  )} - ${isoDateToTimeDisplay(
                    rentingData.return?.hour
                  )}`}</span>
                </div>
              </div>
              <div className="booking-status-secction">
                <div>
                  <BookingStatus status={rentingData?.status} />
                </div>
              </div>
              <div className="booking-price-secction">
                <div>
                  <div className="booking-price">{buildPrice()}</div>
                </div>
              </div>
              <BookingCardOptions
                bookingId={rentingData?.id}
                bookingStatus={rentingData?.status}
              />
            </div>
          </Card.content>
        </Card>
      </div>
    </BookingCardContext.Provider>
  )
}

export default BookingCard
