import { HttpResponse } from 'aplication/apiServices/protocols/IHttpClient'
import { ApiService } from '../apiService'
import { TCarStockApi } from './TCarStockApi'

type TResponseData = {
  results: TCarStockApi[]
  count: number
}

export class CarStockApiService extends ApiService {
  async load(
    cityId: number,
    page?: number
  ): Promise<HttpResponse<TResponseData>> {
    if (!cityId) throw new Error('cityId undefined')
    const city = `cidade_id=${cityId}`
    const paged = page ? `page=${page}` : ''
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/estoque-veiculos/?${city}&${paged}`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
