import { TCredCardApi } from 'domain/entities/api/TCredCardApi'
import { TCredCard } from 'domain/entities/TCredCard'

export function credCardDataParser(data: TCredCardApi): TCredCard {
  if (!data) return {} as TCredCard
  return {
    cardId: data.card_id,
    cardNumber: data.card_number,
    client: data.cliente,
    id: data.id,
    isDeleted: data.is_deleted,
    isMain: data.is_main_card,
    uuid: data.uuid,
    cardBrand: data.card_brand,
    cardExpirationDate: data.card_expiration_date,
    cardName: data.card_name,
    cardSecurityCode: data.card_security_code
  }
}

export function credCardArrayParser(data: TCredCardApi[]): TCredCard[] {
  if (!data || data.length <= 0) return [] as TCredCard[]
  return data.map(card => credCardDataParser(card))
}

export function credCardDataParserToApi(data: TCredCard): TCredCardApi {
  if (!data) return {} as TCredCardApi
  return {
    card_brand: data.cardBrand,
    card_expiration_date: data.cardExpirationDate,
    card_id: data.cardId,
    card_name: data.cardName,
    card_number: data.cardNumber,
    card_security_code: data.cardSecurityCode,
    cliente: data?.client,
    id: data.id,
    is_deleted: data.isDeleted,
    is_main_card: data.isMain,
    uuid: data.uuid
  }
}
