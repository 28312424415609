import React, { useEffect, useRef, useState } from 'react'
import {
  carShopPostContact,
  TCarShopContact
} from 'aplication/useCases/carShopPostContact/carShopPostContact'
import Button from 'view/components/button/button'
import { Dialog, TDialogRef } from 'view/components/dialog'
import {
  FormComponent,
  InputField,
  TextAreaField
} from 'view/components/formComponent'
import {
  InputEmailField,
  InputPhoneField
} from 'view/componentsPages/customFields'
import PhoneNumber from 'view/componentsPages/phoneNumber/phoneNumber'
import { useHistory } from 'react-router-dom'
import { Captcha, useCapcha } from 'view/components'

type TProps = {
  carId: number
}

const CarShopCarContact: React.FC<TProps> = ({ carId }) => {
  const history = useHistory()
  const dialogRef = useRef<TDialogRef>({} as TDialogRef)
  const [fieldValues, setFieldValues] = useState<any>({})

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false)
  const { captchaRef, capchaIsInValid } = useCapcha(dialogRef)

  function onFormSubmit(data: TCarShopContact, resetFields: () => void) {
    setSubmitDisabled(true)
    if (capchaIsInValid(setSubmitDisabled)) return
    if (!data) return
    let msg = 'Enviando dados...'
    dialogRef.current?.loadMessage(msg)
    carShopPostContact(data).then(response => {
      if (
        !response ||
        (response.statusCode !== 200 && response.statusCode !== 201)
      ) {
        console.error('car_shop_cars_load_error')
        msg = 'Não foi possível enviar a proposta.'
        dialogRef.current?.changeMessage(msg, 'danger')
        return
      }
      msg = 'Proposta enviada com sucesso.'
      dialogRef.current?.changeMessage(msg, 'success')
      resetFields()
      history.push('/mensagem-enviada-com-sucesso')
    })
  }

  useEffect(() => {
    if (!carId) return
    setFieldValues({ car: carId })
  }, [carId])

  return (
    <>
      <div className="car-contact-form">
        <h3 className="font-size-medium">Ficou interessado?</h3>
        <p>Preencha os dados abaixo e nos envie uma proposta.</p>
        <Dialog ref={dialogRef} />
        <FormComponent
          onSubmit={onFormSubmit}
          fieldValues={fieldValues}
          onFormChange={values => console.log(values)}
        >
          <InputField name="name" placeholder="Nome" required maxLength={200} />
          <InputEmailField required />
          <InputPhoneField required />
          <TextAreaField
            name="text"
            placeholder="Proposta"
            required
            maxLength={400}
          />
          <InputField type="hidden" name="car" />
          <div
            style={{ textAlign: 'center', width: '100%', padding: '20px 0' }}
          >
            <Captcha ref={captchaRef} />
          </div>

          <Button type="submit" full disabled={submitDisabled}>
            Enviar Proposta
          </Button>
        </FormComponent>
      </div>

      <div className="car-contacts">
        <h3>Entre em contato para mais informações.</h3>
        <PhoneNumber className="font-size-big color-secondary" />
      </div>
    </>
  )
}

export default CarShopCarContact
