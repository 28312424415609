import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import './modal.css'
import { ModalContext } from './modalContext'

export type TModalRef = {
  toggleOpen: (value?: boolean) => void
}

type Props = {
  transparent?: boolean
  content: any
  className?: string
  startOpen?: boolean
  onChange?: (open: boolean) => void
}

const Modal: React.ForwardRefRenderFunction<TModalRef, Props> = (
  { transparent, content, className, startOpen, onChange },
  ref
) => {
  const [open, setOpen] = useState<boolean>(startOpen || false)

  function toggleOpen(value?: boolean) {
    if (value === undefined) value = !open
    setOpen(value)
  }

  function setClassName() {
    let classStyle = ''
    if (transparent) classStyle += ' modal-transparent'
    return classStyle
  }

  useImperativeHandle(ref, () => {
    return { toggleOpen }
  })

  useEffect(() => {
    if (onChange) onChange(open)
  }, [open])

  function showModal() {
    if (open) {
      return (
        <ModalContext.Provider value={{ open, toggleOpen }}>
          <div className="modal-wrapper">
            <div className={`modal${setClassName()} ${className || ''}`}>
              <div className="modal-close" onClick={() => toggleOpen(false)}>
                <FontAwesomeIcon icon={['far', 'times-circle']} />
              </div>
              <div className="modal-content">{content}</div>
            </div>
          </div>
        </ModalContext.Provider>
      )
    } else {
      return ''
    }
  }

  return <>{showModal()}</>
}

export default forwardRef(Modal)
