import { CarShopFiltersFeatures } from 'aplication/apiServices/carShopFilters/carShopFiltersFeatures'
import { TCarShopFeatureApi } from 'aplication/apiServices/carShopFilters/carShopFiltersTypes'
import { useEffect, useState } from 'react'
import { TCarShopFeature } from './carShopTypes'

export type TUseCarShopFilters = {
  features: TCarShopFeature[]
}

export function useCarShopFiltersFeatures(): TUseCarShopFilters {
  const [features, setFeatures] = useState<TCarShopFeature[]>(
    [] as TCarShopFeature[]
  )

  function dataTypeParser(apiData: TCarShopFeatureApi): TCarShopFeature {
    if (!apiData) return {} as TCarShopFeature
    return {
      id: apiData.id,
      slug: apiData.slug,
      description: apiData.descricao
    }
  }

  function dataTypeParserArray(
    apiFeatures: TCarShopFeatureApi[]
  ): TCarShopFeature[] {
    return apiFeatures.map(feature => {
      return dataTypeParser(feature)
    })
  }

  function loadFeatures() {
    const apiData = new CarShopFiltersFeatures()
    apiData.load().then(response => {
      if (
        !response ||
        response.statusCode !== 200 ||
        !response.body ||
        !response.body.results
      ) {
        console.error('car_shop_features_load_error')
        return
      }
      const allFeatures = dataTypeParserArray(response.body.results)
      setFeatures(allFeatures)
    })
  }

  useEffect(() => loadFeatures(), [])

  return { features }
}
