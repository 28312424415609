import { CurrentUserDataService } from 'aplication/apiServices/currentUserDataService/currentUserDataService'
import { User } from 'aplication/entities/User'
import { TEmpty } from 'domain/entities/TEmpty'
import { userIsActivated } from '../userIsActivated/userIsActivated'

export async function currentUser(id: string): Promise<User | TEmpty> {
  const dataService = new CurrentUserDataService()
  const response = await dataService.load(id)
  if (response.statusCode === 200) {
    const user = new User(response.body)
    user.isActivated = await userIsActivated(user.person.uuid)
    return user
  }

  return {}
}
