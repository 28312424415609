import React, { useEffect, useRef, useState } from 'react'
import NotificationButton from './notificationButton'
import { useAuthContext } from 'view/contexts/authContext'
import './mainTopNotifications.css'
import MainTopNotificationsList from './mainTopNotificationsList'

const MainTopNotifications: React.FC = () => {
  const { isUserLogged } = useAuthContext()
  const [open, setOpen] = useState<boolean>(false)

  const elementRef = useRef<any>()

  function toggleDropdown() {
    setOpen(!open)
  }

  function detectOutClick() {
    document.addEventListener('mousedown', (e: any) => {
      if (elementRef.current && !elementRef.current.contains(e.target)) {
        setOpen(false)
      }
    })
  }

  useEffect(() => {
    detectOutClick()
  }, [])

  function renderComponent() {
    if (!isUserLogged()) return <></>
    return (
      <div className="notifications-wrap" ref={elementRef}>
        <NotificationButton onClick={toggleDropdown} />
        <div className={`notifications-dropdown ${open ? 'open' : ''}`}>
          <div className="notifications-title">Notificações</div>
          <div className="notifications-scroll">
            <MainTopNotificationsList open={open} />
          </div>
        </div>
      </div>
    )
  }

  return <>{renderComponent()}</>
}

export default MainTopNotifications
