import React, { useEffect } from 'react'
import BookingForm from 'view/componentsPages/bookingForm/bookingForm'
import CarouselSeminovos from 'view/componentsPages/carouselSeminovos/carouselSeminovos'
import { FaqBlock } from 'view/componentsPages/faqBlock'
import HomePromo from './homePromo/homePromo'
import FullBgGray from 'view/components/fullBgGray/fullBgGray'
import TerceirizacaoInfo from './terceirizacaoInfo/terceirizacaoInfo'
import { useHistory } from 'react-router-dom'
import { useAppContext } from 'view/contexts/appContext/appContext'
import CarouselConteudoWithBox from '../../componentsPages/carouselConteudo/carouselConteudoWithBox/carouselConteudoWithBox'
import PageBannerContainer from 'view/componentsPages/pageBannerContainer/pageBannerContainer'
import './homePage.css'

const HomePage: React.FC = () => {
  const { modalContactRef } = useAppContext()
  const history = useHistory()

  useEffect(() => {
    if (history.location.pathname === '/contato') {
      modalContactRef.current.toggleContactForm()
    }
  }, [])

  return (
    <div className="home-page">
      <HomePromo />
      <FullBgGray>
        <div className="container">
          <BookingForm />
        </div>
        <TerceirizacaoInfo />
      </FullBgGray>

      <CarouselSeminovos />

      <div className="container">
        <PageBannerContainer bannerLocation="home_rodape" />
        <FaqBlock />
      </div>
      <CarouselConteudoWithBox link="/conteudo" textLink="Veja todos" />
    </div>
  )
}

export default HomePage
