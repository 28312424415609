import { TCarStockApi } from 'aplication/apiServices/carStockApiService/TCarStockApi'
import { carStockFeaturesArrayParser } from './carStockFeaturesDataParser'
import { carStockImageArrayParser } from './carStockImageDataParser'
import { carStockProtectionArrayParser } from './carStockProtectionDataParser'
import { ICarGroup } from 'domain/entities/ICarGroup'

export function carStockDataParser(data: TCarStockApi): ICarGroup {
  if (!data) return {} as ICarGroup
  return {
    caucao: data.grupo.taxa_caucao,
    city: data.cidade.id,
    description: data.grupo.descricao,
    enabled: data.grupo.enabled,
    features: carStockFeaturesArrayParser(data.grupo.caracteristicas),
    id: data.grupo.id,
    img: carStockImageArrayParser(data.grupo.imagens),
    name: data.grupo.nome,
    price: data.grupo.valor_diaria,
    protection: carStockProtectionArrayParser(data.grupo.protecoes)[0],
    resources: data.grupo.acessorios,
    slug: data.grupo.slug,
    stock: data.estoque,
    available: data.estoque,
    uuid: data.grupo.uuid
  }
}

export function carStockArrayParser(data: TCarStockApi[]): ICarGroup[] {
  if (!data || data.length <= 0) return [] as ICarGroup[]
  return data.map(d => carStockDataParser(d))
}
