import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TCredCard } from 'domain/entities/TCredCard'
import Button from 'view/components/button/button'
import Card from 'view/components/card/card'
import { DashboardCredCardListContext } from './dashboardCredCardList'
import { adminCredCard } from 'aplication/useCases/credCards/adminCredCard'

type TProps = {
  credCard: TCredCard
}

const DashboardCredCard: React.FC<TProps> = ({ credCard }) => {
  const { dialogRef, refreshList } = useContext(DashboardCredCardListContext)
  const { setMainCredCard, deleteCredCard } = adminCredCard()

  function setMain() {
    dialogRef.current.loadMessage('Definindo como padrão...')
    setMainCredCard(credCard.uuid).then(response => {
      setTimeout(() => {
        if (response.statusCode === 200) {
          refreshList()
        } else {
          dialogRef.current.changeMessage(
            `Operação não pode ser realizada: ${response.body.detail}`,
            'danger'
          )
        }
      }, 500)
    })
  }

  function deleteCard() {
    dialogRef.current.loadMessage('Excluido...')
    deleteCredCard(credCard.uuid).then(response => {
      setTimeout(() => {
        if (response.statusCode === 200) {
          refreshList()
        } else {
          dialogRef.current.changeMessage(
            `Operação não pode ser realizada: ${response.body.detail}`,
            'danger'
          )
        }
      }, 500)
    })
  }

  function isMain() {
    if (!credCard.isMain) {
      return (
        <div className="cred-card-btns">
          <Button
            className="cred-card-set-main"
            size="sm-x"
            onClick={setMain}
            title="Definir como principal"
          >
            <FontAwesomeIcon icon={['fas', 'check']} />
          </Button>
          <Button
            className="cred-card-delete"
            size="sm-x"
            onClick={deleteCard}
            title="Excluir"
          >
            <FontAwesomeIcon icon={['fas', 'times']} />
          </Button>
        </div>
      )
    }
    return (
      <div className="cred-card-main">
        <FontAwesomeIcon icon={['fas', 'check']} />
      </div>
    )
  }

  return (
    <Card className="cred-card">
      <Card.content>
        <div className="cred-card-brand">{credCard.cardBrand}</div>
        <div className="cred-card-number">{credCard.cardNumber}</div>
        {isMain()}
      </Card.content>
    </Card>
  )
}

export default DashboardCredCard
