import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import Button from 'view/components/button/button'
import Card from 'view/components/card/card'
import TitlePage from 'view/components/text/titlePage/titlePage'
import { useAuthContext } from 'view/contexts/authContext'
import { DashboardSingleTemplate } from '../../dashboardSingleTemplate'
import './accountShow.css'

const AccountShow: React.FC = () => {
  const { path } = useRouteMatch()
  const { loggedUser } = useAuthContext()
  const history = useHistory()

  return (
    <DashboardSingleTemplate>
      <div className="account-content">
        <TitlePage>{loggedUser?.person.name}</TitlePage>

        <Card>
          <Card.content>
            <Button
              style="orange"
              onClick={() => history.push(`${path}/editar`)}
            >
              Editar meus dados
            </Button>
            <div className="account-user-data">
              <span>
                <b>Nome:</b> {loggedUser?.person?.name}{' '}
                {loggedUser?.person?.lastName}
              </span>

              <span>
                <b>Email:</b> {loggedUser?.email}
              </span>

              <span>
                <b>Tipo de Documento:</b> {loggedUser?.person.documentType}
              </span>

              <span>
                <b>Documento:</b> {loggedUser?.person.documento}
              </span>

              <span>
                <b>Telefone: </b>
                {loggedUser?.person.phone}
              </span>

              <span>
                <b>Endereço:</b> {loggedUser?.person.address}{' '}
                {loggedUser?.person.addressDistrict}{' '}
                {loggedUser?.person.complement}
              </span>
            </div>
          </Card.content>
        </Card>
      </div>
    </DashboardSingleTemplate>
  )
}

export default AccountShow
