import React from 'react'
import { TStyleColor, TStyleSize } from 'view/viewTypes'

type Props = {
  id?: string
  className?: string
  color?: TStyleColor
  size?: TStyleSize
  span: boolean
}

const Text: React.FC<Props> = ({ children, ...props }) => {
  let { color, size, className, span, id } = props
  color = color || 'gray1'
  size = size || 'small'

  function makeClassName() {
    return `${className || ''} color-${color} font-size-${size}`
  }

  function selectTag() {
    if (span) {
      return (
        <span id={id} className={makeClassName()}>
          {children}
        </span>
      )
    } else {
      return (
        <div id={id} className={makeClassName()}>
          {children}
        </div>
      )
    }
  }

  return selectTag()
}

export default Text
