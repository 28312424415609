import { useState } from 'react'
import { BookingApiSevice } from 'aplication/apiServices/bookingApiService/bookingApiService'
import { TBooking } from 'domain/entities/TBooking'
import { bookingArrayParser } from './bookingDataParser'

export type TUseBookings = {
  bookings: TBooking[]
  totalBookings: number
  loaded: boolean
  loadInProgress: (
    clientId: string,
    inProgress: boolean,
    filters?: { [key: string]: any }
  ) => void
  loadMoreBookings: (clientId: string, inProgress: boolean) => void
}

export function useBookings(): TUseBookings {
  const [bookings, setBookings] = useState<TBooking[]>([{}] as TBooking[])
  const [totalBookings, setTotalBookings] = useState<number>(0)
  const [nextPage, setNextPage] = useState<number>(1)
  const [loaded, setLoaded] = useState<boolean>(false)
  const apiService = new BookingApiSevice()

  function loadInProgress(
    clientId: string,
    inProgress: boolean,
    filters?: { [key: string]: any }
  ) {
    setLoaded(false)
    apiService.load(clientId, inProgress, undefined, filters).then(response => {
      if (
        !response ||
        response.statusCode !== 200 ||
        !response.body ||
        !response.body.results
      ) {
        console.error('booking_inprogress_load_error')
        return
      }
      const bookingsParsed = bookingArrayParser(response.body.results)
      setNextPage(2)
      setBookings(bookingsParsed)
      setTotalBookings(response.body.count)
      setLoaded(true)
    })
  }

  function bookingsNextPage(
    clientId: string,
    inProgress: boolean,
    page: number
  ) {
    apiService.load(clientId, inProgress, page).then(response => {
      if (
        !response ||
        response.statusCode !== 200 ||
        !response.body ||
        !response.body.results
      ) {
        console.error('booking_inprogress_load_error')
        return
      }
      const bookingsParsed = bookingArrayParser(response.body.results)
      setTimeout(() => {
        setBookings([...bookings, ...bookingsParsed])
      }, 500)
    })
  }

  function hasMoreBookings() {
    return bookings.length >= totalBookings
  }

  function loadMoreBookings(clientId: string, inProgress: boolean) {
    if (!hasMoreBookings) return
    bookingsNextPage(clientId, inProgress, nextPage)
    setNextPage(nextPage + 1)
  }

  return {
    bookings,
    loadInProgress,
    loadMoreBookings,
    totalBookings,
    loaded
  }
}
