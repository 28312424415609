import { useCredCards } from 'aplication/useCases/credCards/useCredCards'
import { TCredCard } from 'domain/entities/TCredCard'
import React, { createContext, useEffect, useRef, useState } from 'react'
import { Dialog, TDialogRef } from 'view/components/dialog'
import LoadingContent from 'view/componentsPages/loadingContent/loadingContent'
import DashboardCredCard from './dashboardCredCard'
import DashboardCredCardAdd from './dashboardCredCardAdd'

type TContext = {
  refreshList: () => void
  credCards: TCredCard[]
  dialogRef: {
    current: TDialogRef
  }
}

export const DashboardCredCardListContext = createContext<TContext>(
  {} as TContext
)

const DashboardCredCardList: React.FC = () => {
  const { credCards, loadCards, loaded } = useCredCards()
  const dialogRef = useRef<TDialogRef>({} as TDialogRef)
  const [refresh, setRefresh] = useState<boolean>(false)

  function renderCards() {
    if (!credCards || credCards.length <= 0) {
      return <div>Não há cartões de crédito cadastrados</div>
    }
    return credCards.map((card, index) => {
      return <DashboardCredCard key={index} credCard={card} />
    })
  }

  function refreshList() {
    setRefresh(true)
  }

  function renderContent() {
    if (!loaded) {
      return <LoadingContent height={200} />
    } else {
      return (
        <DashboardCredCardListContext.Provider
          value={{ dialogRef, credCards, refreshList }}
        >
          <DashboardCredCardAdd />
          <Dialog ref={dialogRef} />
          {renderCards()}
        </DashboardCredCardListContext.Provider>
      )
    }
  }

  useEffect(() => loadCards(), [])

  useEffect(() => {
    if (!refresh) return
    loadCards()
    setRefresh(false)
  }, [refresh])

  return <>{renderContent()}</>
}

export default DashboardCredCardList
