import React, { useContext } from 'react'
import { TFieldElement } from '../..'
import FieldProvider, { FieldContext } from '../fieldContext'
import './inputFile.css'

type TProps = TFieldElement

const InputFileComponent: React.FC = () => {
  const { updateField, fieldProps, stateValue } = useContext(FieldContext)

  function handleChange(event: any) {
    const { name, onChange } = fieldProps
    const value = event.target.files[0]
    if (onChange) onChange(value)
    updateField({ name, value })
  }

  function renderLabel() {
    if (!stateValue || !stateValue.name) return fieldProps.placeholder
    return stateValue.name
  }

  return (
    <>
      <label htmlFor={fieldProps.name} className="input-file-box">
        {renderLabel()}
      </label>
      <input
        id={fieldProps.name}
        type={'file'}
        name={fieldProps?.name}
        placeholder={fieldProps?.placeholder}
        onChange={handleChange}
        disabled={fieldProps?.disabled}
        className={`input-file ${fieldProps?.className}`}
      />
    </>
  )
}

const InputFileField: React.FC<TProps> = props => {
  return (
    <FieldProvider fieldProps={{ ...props }}>
      <InputFileComponent />
    </FieldProvider>
  )
}

export default InputFileField
