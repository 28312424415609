import React, { createContext } from 'react'

export type TOption = { label: string; value: string }

export type TSelected = {
  value: string
  label: string
}

type TContext = {
  open: boolean
  selected: TSelected
  options: TOption[]
  setOptions: (value: TOption[]) => void
  setOpen: (value: boolean) => void
  setSelected: (value: TSelected) => void
}

export const SelectFieldContext = createContext<TContext>({} as TContext)
