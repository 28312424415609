import React, { useEffect } from 'react'
import NotificationsList from 'view/componentsPages/notificationsList/notificationsList'
import { useAppContext } from 'view/contexts/appContext/appContext'

type TProps = {
  open: boolean
}

const MainTopNotificationsList: React.FC<TProps> = ({ open }) => {
  const { notifications, removeNotification, loadNotifications } =
    useAppContext().notifications

  useEffect(() => {
    loadNotifications()
  }, [open])

  return (
    <NotificationsList
      notifications={notifications}
      removeNotification={removeNotification}
    />
  )
}

export default MainTopNotificationsList
