import { TUserPasswordPostApi } from 'aplication/apiServices/userUpdatePassword/TUserPasswordPostApi'
import { TUserPasswordPost } from 'aplication/entities/TUserPasswordPost'

export function userUpdatePasswordPostParser(
  data: TUserPasswordPost
): TUserPasswordPostApi {
  if (!data) return {} as TUserPasswordPostApi
  return {
    cliente_uuid: data.uuid,
    old_password: data.oldPassword,
    password: data.password
  }
}
