import { useBookings } from 'aplication/useCases/booking/useBookings'
import React, { useContext, useEffect } from 'react'
import LoadingContent from 'view/componentsPages/loadingContent/loadingContent'
import { useAuthContext } from 'view/contexts/authContext'
import { BookingCard } from '../bookingCard'
import { DashboardBookingsContext } from './dashboardBookings'

const DashboardBookingsOpen: React.FC = () => {
  const { reloading } = useContext(DashboardBookingsContext)
  const { loggedUser } = useAuthContext()
  const { bookings, loadInProgress, loaded } = useBookings()

  function renderOpenBooking() {
    if (!bookings || bookings.length <= 0) {
      return (
        <div className="empty-booking-list">
          Você ainda não possui reservas em aberto
        </div>
      )
    }
    return bookings.map((booking, index) => {
      return <BookingCard rentingData={booking} key={index} />
    })
  }

  useEffect(() => {
    loadInProgress(loggedUser?.person.uuid, true)
  }, [loggedUser])

  useEffect(() => {
    if (!reloading) return
    loadInProgress(loggedUser?.person.uuid, true)
  }, [reloading])

  function renderContent() {
    if (!loaded) return <LoadingContent height={200} />
    return <>{renderOpenBooking()}</>
  }

  return <>{renderContent()}</>
}

export default DashboardBookingsOpen
