import React from 'react'
import { InputField } from 'view/components/formComponent'
import CnhSecuritynumberField from 'view/components/formComponent/fields/cnhSecuritynumberField/cnhSecuritynumberField'
import InputCnhField from 'view/componentsPages/customFields/inputCnhField/inputCnhField'
import SelectCityField from 'view/componentsPages/customFields/selectCityField/selectCityField'

const AccountEditPersonPJFields: React.FC = () => {
  return (
    <div className="form-session">
      <InputField
        name="documento"
        placeholder="Documento"
        disabled
        title="Documento"
      />
      <InputField
        name="name"
        placeholder="Razão Social"
        maxLength={200}
        title="Razão Social"
      />
      <InputField
        name="email"
        placeholder="Email da empresa"
        title="Email da empresa"
      />
      {/* <InputField name="cep" placeholder="CEP" title="CEP" />
      <InputField name="address" placeholder="Endereço" title="Endereço" />
      <InputField name="addressDistrict" placeholder="Bairro" title="Bairro" />
      <InputField
        name="complement"
        placeholder="Complemento"
        title="Complemento"
      /> */}
      <InputField
        name="phone"
        placeholder="Telefone da empresa"
        title="Telefone da empresa"
      />
      <InputField
        name="pJResponsible"
        placeholder="Nome do Responsável"
        title="Nome do Responsável"
      />
      <InputField
        name="pJResponsiblePhone"
        placeholder="Telefone do Responsável"
        title="Telefone do Responsável"
      />
      {/* <SelectCityField /> */}
      {/* <InputCnhField /> */}
      {/* <CnhSecuritynumberField /> */}
      <InputField type="hidden" name="uuid" placeholder="" />
    </div>
  )
}

export default AccountEditPersonPJFields
