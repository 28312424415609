import React, { useEffect, useState } from 'react'
import { InputField, useFormContext } from 'view/components/formComponent'
import { isValidCpf } from 'view/components/formComponent/formComponentValidation/formComponentValidators'

const InputDocumentField: React.FC = () => {
  const [type, setType] = useState<string>('')
  const { fields, updateField } = useFormContext()

  useEffect(() => {
    if (Object.keys(fields).length < 0) return
    const documentTypeField = fields.find(field => {
      return field.name === 'documentType' ? field : null
    })
    if (documentTypeField && documentTypeField.value) {
      setType(`${documentTypeField.value}`)
    }
  }, [fields])

  function setMask(inputValue: string): string {
    const maskedValue = inputValue
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')

    return maskedValue
  }

  function setInputMask() {
    return type === 'CPF' ? setMask : undefined
  }

  // function selectInputType() {
  //   return type === 'passaporte' ? 'number' : 'text'
  // }

  function selectPlaceholder() {
    return type === 'CPF' ? 'CPF' : 'Passaporte'
  }

  useEffect(() => {
    if (type === 'CPF') {
      updateField({ name: 'document', validators: [isValidCpf] })
    } else {
      updateField({ name: 'document', validators: [] })
    }
  }, [type])

  return (
    <InputField
      type="text"
      name="document"
      placeholder={selectPlaceholder()}
      regexMask={setInputMask()}
      disabled={type === ''}
      required
    />
  )
}

export default InputDocumentField
