import React from 'react'
import './bookingStatus.css'

type Props = {
  status?: string
}

const BookingStatus: React.FC<Props> = ({ status }) => {
  const statusTypes: { [key: string]: string } = {
    analise_cliente_andamento: 'Em análise',
    aguardando_analise_manual: 'Em análise',
    aguardando_retirada: 'Aguardando retirada',
    em_andamento: 'Em andamento',
    devolucao_veiculo_em_atraso: 'Devolução em atraso',
    pagamento_em_processamento: 'Pagamento em processamento',
    pagamento_nao_autorizado: 'Pagamento não autorizado',
    pagamento_cancelado: 'Pagamento cancelado',
    cliente_reprovado: 'Cliente reprovado',
    finalizada: 'Concluída',
    expirada: 'Expirada',
    cancelada: 'Cancelada'
  }

  return (
    <div className={`booking-status ${status}`}>
      <span className="icon">!</span>
      <span>{statusTypes[status || '']}</span>
    </div>
  )
}

export default BookingStatus
