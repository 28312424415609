import React, { useContext } from 'react'
import Button from 'view/components/button/button'
import { TDriver } from 'domain/entities/TRentingData'
import { FormComponent, InputField } from 'view/components/formComponent'
import CnhSecuritynumberField from 'view/components/formComponent/fields/cnhSecuritynumberField/cnhSecuritynumberField'
import { DriversContext } from '../bookingDriversList'
import './addDriverForm.css'

const AddDriverForm: React.FC = () => {
  const { updateDrivers } = useContext(DriversContext)

  function onSubmit(driver: TDriver, resetForm: () => void) {
    if (!driver) return
    updateDrivers(driver)
    resetForm()
  }

  function setInputNumberMask(inputValue: string): string {
    return inputValue.match(/\d+/g)?.join('') || ''
  }

  return (
    <FormComponent onSubmit={onSubmit} className="add-driver-form">
      <InputField name="name" placeholder="Nome" maxLength={200} required />
      <InputField
        name="cnh"
        placeholder="CNH"
        required
        maxLength={11}
        regexMask={setInputNumberMask}
      />
      <CnhSecuritynumberField required />
      <Button type="submit">Adicionar</Button>
    </FormComponent>
  )
}

export default AddDriverForm
