import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import CarShopCar from './carShopCar/carShopCar'
import CarShopList from './carShopList/carShopList'

const CarShop: React.FC = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path} component={CarShopList} />
      <Route exact path={`${path}/carro/:id`} component={CarShopCar} />
    </Switch>
  )
}

export default CarShop
