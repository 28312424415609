import { parseToApiClient } from 'aplication/useCases/clientFactory/clientFactory'
import { TClient } from 'domain/entities/TClient'
import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'

export class ClientUpdateDataService extends ApiService {
  async load(client: TClient): Promise<HttpResponse> {
    const body = parseToApiClient(client)

    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/cliente/${client.uuid}/`,
      method: 'put',
      body,
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
