import { UserNotificationsApiService } from 'aplication/apiServices/userNotificationsApiService/userNotificationsApiService'
import { TUserNotificationApi } from 'aplication/apiServices/userNotificationsApiService/userNotificationsApiServiceTypes'
import { useEffect, useState } from 'react'
import { useAuthContext } from 'view/contexts/authContext'
import { TUserNotification } from './useUserNotificationsTypes'

export type TUseUserNotifications = {
  notifications: TUserNotification[]
  totalNotifications: number
  loaded: boolean
  removeNotification: (notificationIndex: string) => void
  loadNotifications: (read?: boolean) => void
  loadMoreNotifications: (read?: boolean) => void
}

export function useUserNotifications(): TUseUserNotifications {
  const apiService = new UserNotificationsApiService()
  const { loggedUser } = useAuthContext()
  const [loaded, setLoaded] = useState<boolean>(true)
  const [notifications, setNotifications] = useState<TUserNotification[]>(
    [] as TUserNotification[]
  )
  const [totalNotifications, setTotalNotifications] = useState<number>(0)
  const [nextPage, setNextPage] = useState<number>(1)

  function dataTypeParser(data: TUserNotificationApi[]): TUserNotification[] {
    return data.map(d => {
      return {
        id: d.id,
        cliente: d.cliente,
        createdAt: d.created_at,
        description: d.description,
        disabled: d.disabled,
        imgUrl: d.img_url,
        read: d.read,
        title: d.title,
        url: d.url
      }
    })
  }

  function loadNotifications(read?: boolean) {
    setLoaded(false)
    if (!loggedUser || !loggedUser?.person || !loggedUser?.person.uuid) return
    apiService.load(loggedUser?.person.uuid, read).then(response => {
      if (
        !response ||
        response.statusCode !== 200 ||
        !response.body ||
        !response.body.results
      ) {
        console.error('user_notifications_load_error')
        return
      }
      const allNotifications = dataTypeParser(response.body.results).reverse()
      setNextPage(2)
      setNotifications(allNotifications)
      setTotalNotifications(response.body.count)
      setTimeout(() => setLoaded(true), 200)
    })
  }

  function notificationsNextPage(read?: boolean, page?: number) {
    if (!loggedUser) return
    apiService.load(loggedUser?.person.uuid, read, page).then(response => {
      if (
        !response ||
        response.statusCode !== 200 ||
        !response.body ||
        !response.body.results
      ) {
        console.error('user_notifications_load_error')
        return
      }
      const allNotifications = dataTypeParser(response.body.results).reverse()
      setTimeout(() => {
        setNotifications([...notifications, ...allNotifications])
      }, 500)
    })
  }

  function hasMoreNotifications() {
    return notifications.length >= totalNotifications
  }

  function loadMoreNotifications(read?: boolean) {
    if (!hasMoreNotifications) return
    notificationsNextPage(read, nextPage)
    setNextPage(nextPage + 1)
  }

  function setRead(notificationId: string) {
    apiService
      .setRead(notificationId, loggedUser?.person.uuid)
      .then(response => {
        if (
          !response ||
          response.statusCode !== 200 ||
          !response.body ||
          !response.body.read
        ) {
          console.error('user_notifications_set_read_error')
        }
      })
  }

  function removeNotification(notificationId: string) {
    setNotifications(old => {
      const newNotifications = old.filter(
        i => i.id !== parseInt(notificationId)
      )
      return newNotifications
    })
    setRead(notificationId)
  }

  return {
    notifications,
    totalNotifications,
    loaded,
    removeNotification,
    loadNotifications,
    loadMoreNotifications
  }
}
