import { CepApiService } from 'aplication/apiServices/cepApiService/cepApiService'
import { TAddressByCep } from 'domain/entities/TAddressByCep'
import { useState } from 'react'
import { addressByCepDataParser } from './addressByCepDataParser'

type T = {
  addressByCep: TAddressByCep
  loadedAddress: boolean
  loadAddress: (cep: string) => void
}

export function useAddressByCep(): T {
  const apiService = new CepApiService()
  const [loaded, setLoaded] = useState<boolean>(false)
  const [addressByCep, setAddressByCep] = useState<TAddressByCep>(
    {} as TAddressByCep
  )

  function loadAddress(cep: string): void {
    setLoaded(false)
    if (!cep) return
    apiService.load(cep).then(response => {
      if (!response || response.statusCode !== 200 || !response.body) {
        console.error('car_shop_cars_load_error')
        return
      }
      setAddressByCep(addressByCepDataParser(response.body))
      setTimeout(() => setLoaded(true), 500)
    })
  }

  return { addressByCep, loadedAddress: loaded, loadAddress }
}
