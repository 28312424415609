import React, { useContext } from 'react'
import { TFieldElement } from '../../formComponentsTypes'
import FieldProvider, { FieldContext } from '../fieldContext'

type TProps = TFieldElement & {
  width?: string
  maxLength?: number
}

const TextAreaComponent: React.FC = () => {
  const { updateField, stateValue, fieldProps } = useContext(FieldContext)
  const { name, placeholder, maxLength } = fieldProps

  function handleChange(event: any) {
    const value = event.target.value
    updateField({ name, value: value })
  }

  return (
    <textarea
      name={name}
      placeholder={placeholder}
      value={stateValue || ''}
      onChange={handleChange}
      maxLength={maxLength}
      style={{ resize: 'none' }}
    />
  )
}

const TextAreaField: React.FC<TProps> = props => {
  return (
    <FieldProvider fieldProps={props}>
      <TextAreaComponent />
    </FieldProvider>
  )
}

export default TextAreaField
