import { useState } from 'react'
import { TCountry } from './TCountry'
import { CountriesApiService } from 'aplication/apiServices/countriesApiservice/countriesApiService'
import { countryArrayParser } from './countryDataParser'

type TCountryList = {
  data: TCountry[]
  loaded: boolean
  load: () => void
}

export function countryList(): TCountryList {
  const apiService = new CountriesApiService()
  const [data, setData] = useState<TCountry[]>([] as TCountry[])
  const [loaded, setLoaded] = useState<boolean>(false)

  function load() {
    setLoaded(false)
    apiService.load().then(response => {
      if (!response || response.statusCode !== 200 || !response.body) {
        console.error('car_shop_cars_load_error')
        return
      }

      setData(countryArrayParser(response.body.results))
      setTimeout(() => setLoaded(true), 500)
    })
  }

  return { data, loaded, load }
}
