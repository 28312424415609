/* eslint-disable camelcase */
import { RecoveryAccountCode } from 'aplication/apiServices/recoveryAccountCode/recoveryAccountCode'
import { userUpdateActivatioConde } from 'aplication/useCases/userUpdateActivatioConde/userUpdateActivatioConde'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Button from 'view/components/button/button'
import { Dialog, TDialogRef } from 'view/components/dialog'
import { FormComponent, InputField } from 'view/components/formComponent'
import { Text } from 'view/components/text'
import TitlePage from 'view/components/text/titlePage/titlePage'
import InputConfirmCodeField from 'view/componentsPages/customFields/inputConfirmCodeField/inputConfirmCodeField'
import { useAuthContext } from 'view/contexts/authContext'

const RequestAccountCodeForm: React.FC = () => {
  const dialogRef = useRef<TDialogRef>({} as TDialogRef)
  const history = useHistory()
  const { userUuid } = useAuthContext()
  const [formValues, setFormValues] = useState<any>({})
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(false)
  const [disabledNewCode, setDisabledNewCode] = useState<boolean>(false)

  function errorAction(response: any) {
    dialogRef.current?.changeMessage(response.body.msg, 'danger')
    setDisabledSubmit(false)
  }

  function successAction(response: any) {
    dialogRef.current?.changeMessage(response.body.msg, undefined, true)
    setTimeout(() => {
      history.replace('/login/recuperar_conta_senha')
    }, 5000)
  }

  function onSubmit(value: any) {
    setDisabledSubmit(true)
    const { cliente_uuid, recovery_account_code } = value
    const code = recovery_account_code.replaceAll(' ', '')
    dialogRef.current?.loadMessage('Enviando dados...')
    const apiService = new RecoveryAccountCode()
    apiService
      .load({ cliente_uuid, recovery_account_code: code })
      .then(response => {
        if (!response) return
        setTimeout(() => {
          if (response.statusCode !== 200) {
            errorAction(response)
          } else {
            successAction(response)
          }
        }, 500)
      })
  }

  function resendCode() {
    setDisabledNewCode(true)
    dialogRef.current?.loadMessage('Reenviando código de ativação...')
    userUpdateActivatioConde(userUuid).then(response => {
      const responseStatus = response.statusCode
      const msgColor = responseStatus === 200 ? 'success' : 'warning'
      if (responseStatus !== 200) {
        setTimeout(() => {
          setDisabledNewCode(false)
        }, 500)
      }
      dialogRef.current?.changeMessage(response?.body?.msg, msgColor)
    })
  }

  useEffect(() => {
    if (!userUuid) {
      history.replace('/login')
    }
    setFormValues({ cliente_uuid: userUuid })
  }, [])

  return (
    <>
      <Dialog ref={dialogRef} />
      <TitlePage>Código de recuperação</TitlePage>
      <FormComponent onSubmit={onSubmit} fieldValues={formValues}>
        <span>Adicone abaixo o código de confirmação recebido por email.</span>
        <div className="form-session">
          <InputConfirmCodeField
            name="recovery_account_code"
            placeholder="Código"
          />
          <InputField name="cliente_uuid" type="hidden" maxLength={200} />
        </div>
        <Button type="submit" full disabled={disabledSubmit}>
          Enviar
        </Button>
        <div className="login-new-account">
          <Link to="#" onClick={resendCode} hidden={disabledNewCode}>
            Reenviar código de ativação
          </Link>
        </div>
      </FormComponent>
    </>
  )
}

export default RequestAccountCodeForm
