import React from 'react'
import { Route, Switch } from 'react-router-dom'
import BookingPjPage from './bookingPjPage'

const BookingPj: React.FC = () => {
  return (
    <Switch>
      <Route path="/" component={BookingPjPage} />
    </Switch>
  )
}

export default BookingPj
