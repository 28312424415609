import { HcapchaValidateApiService } from 'aplication/apiServices/hcapchaValidateApiService/hcapchaValidateApiService'
import { useState } from 'react'

export type TCaptchaValidator = {
  load: (token: string) => void
  isValid: boolean
  loaded: boolean
}

export function hCapchaValidator(): TCaptchaValidator {
  const [isValid, setIsValid] = useState<boolean>(false)
  const [loaded, setLoaded] = useState<boolean>(false)

  function load(token: string) {
    setLoaded(false)
    if (!token) return
    const apiService = new HcapchaValidateApiService()
    apiService.load(token).then(response => {
      if (!response || !response.body) setIsValid(false)
      setIsValid(response.body.success)
      setLoaded(true)
    })
  }

  return { load, isValid, loaded }
}
