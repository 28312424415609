import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import TitlePage from 'view/components/text/titlePage/titlePage'
import LoadingContent from 'view/componentsPages/loadingContent/loadingContent'
import { useAppContext } from 'view/contexts/appContext/appContext'
import { useFaqReader } from 'aplication/useCases/faq/useFaqReader'
import './faqSingle.css'

const FaqSingle: React.FC = () => {
  const { pageInfos } = useAppContext()
  const params: { slug: string } = useParams()
  const { data, loading, read } = useFaqReader()

  function renderContent() {
    if (loading) return <LoadingContent />
    return (
      <>
        <TitlePage>Perguntas frequentes</TitlePage>
        <h2 className="faq-title">{data?.title}</h2>
        <div
          className="faq-content"
          dangerouslySetInnerHTML={{ __html: data?.content }}
        />
      </>
    )
  }

  useEffect(() => {
    read(params.slug)
  }, [])

  useEffect(() => {
    if (!data) return
    pageInfos.update({
      pageTitle: data?.title,
      pageDescription: data?.content
    })
  }, [data])

  return (
    <div className="faq-single">
      <div className="container">{renderContent()}</div>
    </div>
  )
}

export default FaqSingle
