import { objectIsEmpty } from 'aplication/utils/app/testValues'
import React, { useEffect } from 'react'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import LoadingContent from 'view/componentsPages/loadingContent/loadingContent'
import { useAuthContext } from 'view/contexts/authContext'
import { usePageLoader } from 'view/hooksPages/usePageLoader/usePageLoader'
import { Account } from './account'
import DashboardBooking from './dashboardBooking/dashboardBooking'
import { DashboardBookings } from './dashboardBookings'
import DashboardCredCards from './dashboardCredCards/dashboardCredCards'
import DashboardNotificationsPage from './dashboardNotificationsPage/dashboardNotificationsPage'

const Dashboard: React.FC = () => {
  const history = useHistory()
  const { path } = useRouteMatch()
  const { loaded } = usePageLoader()
  const { loggedUser, loadCurrentUser } = useAuthContext()

  useEffect(() => {
    loadCurrentUser()
  }, [])

  useEffect(() => {
    if (!loggedUser || objectIsEmpty(loggedUser)) {
      history.push('/login?status=unlogged')
    } else if (!loggedUser.isActivated) {
      history.replace('/login?status="inactive"')
    }
  }, [loggedUser])
  function renderContent() {
    if (loaded) {
      return (
        <Switch>
          <Route exact path={`${path}/`} component={DashboardBookings} />
          <Route path={`${path}/reserva/:id`} component={DashboardBooking} />
          <Route path={`${path}/minha_conta`} component={Account} />
          <Route
            path={`${path}/cartoes-de-credito`}
            component={DashboardCredCards}
          />
          <Route
            path={`${path}/notificacoes`}
            component={DashboardNotificationsPage}
          />
        </Switch>
      )
    }

    return (
      <div className="container">
        <LoadingContent />
      </div>
    )
  }

  return <>{renderContent()}</>
}

export default Dashboard
