import { useQuery } from 'aplication/utils/app/useQuery'
import React from 'react'
import Button from 'view/components/button/button'
import { SuccessPageTemplate } from 'view/template/successPageTemplate'

const DashboardBookingPaymentSuccess: React.FC = () => {
  const query = useQuery()
  const bookingId = query.get('id')

  return (
    <SuccessPageTemplate
      className="booking-done"
      title="Pagamento da reserva concluído com sucesso!"
      buttons={
        <>
          <Button style="transparent" link={`/dashboard/reserva/${bookingId}`}>
            Ver detalhes da reserva
          </Button>
          <Button style="orange" link="/dashboard">
            Ver minhas reservas
          </Button>
          <Button style="link" link="/">
            Ir para página inicial
          </Button>
        </>
      }
    />
  )
}

export default DashboardBookingPaymentSuccess
