import { useEffect, useState } from 'react'
import { useFormContext } from 'view/components/formComponent'

type TBookingFormFieldListener = {
  change: any
}

export function bookingFormFieldListener(
  fieldName: string
): TBookingFormFieldListener {
  const { fields, isRegister, isDataBind } = useFormContext()
  const [value, setValue] = useState<any>()
  const [change, setChange] = useState<any>()

  useEffect(() => {
    const fieldValue = fields.find(f => f.name === fieldName)?.value
    setValue(fieldValue)
    if (isRegister || isDataBind || fieldValue === value) return
    setChange(fieldValue)
  }, [fields])

  return {
    change
  }
}
