import React, { useContext, useEffect, useRef } from 'react'
import { TFieldElement } from '../..'
import FieldProvider, { FieldContext } from '../fieldContext'

type TProps = TFieldElement & {
  type?: string
  defaultValue?: any
  maxLength?: number
  min?: number
  max?: number
  regexMask?: (value: string) => string
}

const InputComponent: React.FC = () => {
  const { updateField, stateValue, fieldProps } = useContext(FieldContext)
  const fieldRef = useRef<HTMLInputElement>({} as HTMLInputElement)
  let timeoutToSetInputValue: any = {}

  function handleChange(event: any) {
    const { name, regexMask, onChange } = fieldProps
    let value = event.target.value
    if (regexMask) {
      value = regexMask(value)
    }
    if (onChange) onChange(value)
    fieldRef.current.value = value
    updateField({ name, value: value })
  }

  function handleBlur(event: any) {
    const { onBlur } = fieldProps
    const value = event.target.value
    if (onBlur) onBlur(value)
  }

  /**
   * evita erro de acentuação no input
   */
  function delayToSetInputValueByStateValue() {
    timeoutToSetInputValue = setTimeout(() => {
      fieldRef.current.value = stateValue
    }, 100)
    return () => {
      clearTimeout(timeoutToSetInputValue)
    }
  }

  function cleanStateValue(stateValue: any) {
    if (!stateValue || stateValue === 'undefined') {
      return ''
    }
    return stateValue
  }

  useEffect(() => delayToSetInputValueByStateValue(), [stateValue])

  return (
    <input
      type={fieldProps?.type || 'text'}
      name={fieldProps?.name}
      placeholder={fieldProps?.placeholder}
      defaultValue={cleanStateValue(stateValue) || ''}
      onChange={handleChange}
      disabled={fieldProps?.disabled}
      maxLength={fieldProps?.maxLength}
      min={fieldProps?.min}
      max={fieldProps?.max}
      className={fieldProps?.className}
      onBlur={handleBlur}
      autoComplete={fieldProps?.autoComplete}
      ref={fieldRef}
      title={fieldProps?.title}
    />
  )
}

const InputField: React.FC<TProps> = props => {
  const { type, className } = props
  const hiddenClass = type === 'hidden' ? 'typeHidden' : ''
  const newClass = `${className || ''} ${hiddenClass}`
  return (
    <FieldProvider fieldProps={{ ...props, className: newClass }}>
      <InputComponent />
    </FieldProvider>
  )
}

export default InputField
