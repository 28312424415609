import { CarFeaturesApiService } from 'aplication/apiServices/carFeaturesApiService/carFeaturesApiService'
import { useState } from 'react'
import { carFeaturesDataTypeParserArray } from './carFeatures'
import { TCarFeature } from './carFeaturesTypes'

type TUseCarFeatures = {
  features: TCarFeature[]
  loadFeatures: () => void
}

export function useCarFeatures(): TUseCarFeatures {
  const [features, setFeatures] = useState<TCarFeature[]>([] as TCarFeature[])

  function loadFeatures() {
    const apiService = new CarFeaturesApiService()
    apiService.load().then(response => {
      if (
        !response ||
        response.statusCode !== 200 ||
        !response.body ||
        !response.body.results
      ) {
        console.error('car_shop_cars_load_error')
        return
      }
      const featuresArray = carFeaturesDataTypeParserArray(
        response.body.results
      )
      setFeatures(featuresArray)
    })
  }

  return { features, loadFeatures }
}
