import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ContactTime } from 'view/componentsPages/contactTime'
import PhoneNumber from 'view/componentsPages/phoneNumber/phoneNumber'
import { useAppValues } from 'view/contexts/appContext/appContext'
import { useAuthContext } from 'view/contexts/authContext'
import './mainMenuMobile.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'

type TMenuLink = { link?: string; label: string; onClick?: () => void }

type Props = {
  toggle: () => void
}

const MainMenuMobile: React.FC<Props> = ({ toggle }) => {
  const { modalContactRef } = useAppValues()
  const { isUserLogged, logoutUser } = useAuthContext()

  const mainMenu: TMenuLink[] = [
    { link: '/', label: 'Início' },
    { link: '/locacao/grupos', label: 'Locação de veículo' },
    { label: 'Seminovos', link: '/seminovos' },
    { link: '/quem-somos', label: 'Quem somos' },
    {
      label: 'Contato',
      onClick: () => {
        modalContactRef.current.toggleContactForm()
      }
    },
    { link: '/faq', label: 'FAQ' }
  ]

  const userMenu: TMenuLink[] = [
    { link: '/dashboard', label: 'Minhas Reservas' },
    { link: '/dashboard/minha_conta', label: 'Minha Conta' },
    { link: '/dashboard/cartoes-de-credito', label: 'Cartões de crédito' },
    { link: '/login', label: 'Sair', onClick: logoutUser }
  ]

  const secoundMenu: TMenuLink[] = [
    { link: '/codigo-de-etica', label: 'Código de ética e conduta' },
    { link: '/politica-de-privacidade', label: 'Política de privacidade' }
  ]

  function loadMenu(menuItens: TMenuLink[]) {
    return menuItens.map((item, index) => {
      return (
        <li key={index}>
          <Link
            to={item.link || '#'}
            onClick={() => {
              toggle()
              if (item.onClick) item.onClick()
            }}
          >
            {item.label}
          </Link>
        </li>
      )
    })
  }

  function loadUserMenu() {
    if (isUserLogged()) {
      return loadMenu(userMenu)
    } else {
      return (
        <li>
          <Link
            to={'/login'}
            onClick={() => {
              toggle()
            }}
          >
            Login
          </Link>
        </li>
      )
    }
  }

  return (
    <div className="main-menu-mobile">
      <button onClick={toggle} className="main-menu-mobile-close">
        <FontAwesomeIcon icon={faTimesCircle} />
      </button>
      <ul className="main-nav-mobile">{loadMenu(mainMenu)}</ul>

      <ul className="main-nav-mobile user">{loadUserMenu()}</ul>

      <div className="main-menu-mobile-footer">
        <PhoneNumber />
        <div className="hour">
          <ContactTime />
        </div>
        <ul className="links">{loadMenu(secoundMenu)}</ul>
      </div>
    </div>
  )
}

export function useMainMenu(): any {
  const [mainMenuOpen, setMainMenuOpen] = useState(false)
  const [bodyTop, setBodyTop] = useState(0)

  function toggleMainMenuMobile() {
    setMainMenuOpen(!mainMenuOpen)
  }

  function returnMainMenu() {
    if (mainMenuOpen) {
      return <MainMenuMobile toggle={toggleMainMenuMobile} />
    }

    return ''
  }

  useEffect(() => {
    const body = document.body
    if (mainMenuOpen) {
      setBodyTop(window.scrollY)
      body.style.position = 'fixed'
    } else {
      body.style.position = ''
      window.scrollTo(0, bodyTop || 0 * -1)
    }
  }, [mainMenuOpen])

  return { returnMainMenu, toggleMainMenuMobile }
}
