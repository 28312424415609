import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { InvalidRentingDataMessage } from 'view/componentsPages/invalidRentingDataMessage'
import { usePageLoader, useRentingDataValidation } from 'view/hooksPages'
import { useAuthContext } from 'view/contexts/authContext'
import PaymentPageForm from './paymentPageForm'
import LoadingContent from 'view/componentsPages/loadingContent/loadingContent'
import './paymentPage.css'

const PaymentPage: React.FC = () => {
  const history = useHistory()
  const { loggedUser } = useAuthContext()
  const { loaded } = usePageLoader()
  const { invalidData, goToInvalidPage } = useRentingDataValidation([
    'dateTimeValidator',
    'carGroupValidator',
    'pickupValidation'
  ])

  useEffect(() => {
    if (!loggedUser || !loggedUser.person) {
      history.replace('/login?status=unlogged&by_checkout=1')
    }
  }, [loggedUser])

  function renderContent() {
    if (!loaded) {
      return (
        <>
          <div className="container">
            <LoadingContent />
          </div>
        </>
      )
    } else if (loaded && invalidData) {
      goToInvalidPage()
    } else {
      return <PaymentPageForm />
    }
  }

  return <>{renderContent()}</>
}

export default PaymentPage
