import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'

export class PriceRulesDataSevice extends ApiService {
  async load(ruleName: string): Promise<HttpResponse> {
    const rules: { [key: string]: string } = {
      sevenDays: 'descontos-antecedencia1a6dias',
      fiffteenDays: 'descontos-antecedencia7a14dias',
      fiffteenDaysBeyond: 'descontos-atecedencia15diasoumais',
      hollydays: 'descontos-periodoferiado'
    }

    return this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/${rules[ruleName]}/`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
