import React, { useEffect } from 'react'
import LoadAnimation from '../loadAnimation/loadAnimation'

type Props = {
  loaded?: boolean
  onLoading?: () => void
}

const Page: React.FC<Props> = ({ loaded = true, onLoading, children }) => {
  function loadContent() {
    if (!loaded) {
      return <LoadAnimation />
    } else {
      return children
    }
  }

  useEffect(() => {
    if (!loaded) {
      setTimeout(() => {
        if (onLoading) onLoading()
      }, 700)
    }
  }, [])

  return <div className="page-content">{loadContent()}</div>
}

export default Page
