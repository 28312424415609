import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from 'view/components/button/button'
import { useAppContext } from 'view/contexts/appContext/appContext'
import { SuccessPageTemplate } from 'view/template/successPageTemplate'

const AccountCreateSuccess: React.FC = () => {
  const { modalUserActivationRef } = useAppContext()
  const history = useHistory()

  function successAction() {
    setTimeout(() => {
      history.replace('/ativacao-de-usuario-concluida-com-sucesso')
    }, 1000)
  }

  function openActivation() {
    modalUserActivationRef.current.buildOnSuccess(() => successAction)
    modalUserActivationRef.current.toggle()
  }

  return (
    <SuccessPageTemplate
      title="Cadastro realizado com sucesso!"
      buttons={
        <Button style="orange" onClick={openActivation}>
          Ativar usuário
        </Button>
      }
    >
      Agora, vamos iniciar o processo de validação da sua conta. Ao clicar no
      botão abaixo, será enviado um código de verificação para o e-mail
      cadastrado. Copie este código e preencha a seguir.
    </SuccessPageTemplate>
  )
}

export default AccountCreateSuccess
