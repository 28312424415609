import { UseCarShopCars } from 'aplication/useCases/carShop/useCarShopCars'
import React, { useEffect } from 'react'
import Carousel from '../../components/carousel/carousel'
import CarCard from '../carCard/carCard'
import LoadingContent from '../loadingContent/loadingContent'
import { CarouselItens } from 'view/components/carousel'
import CarouselHeader from 'view/components/carousel/carouselHeader'
import './carouselSeminovosNormal.css'
import { TCarShopCar } from 'aplication/useCases/carShop/carShopTypes'

type TProps = {
  title?: string
  link?: string
  linkText?: string
  autoScroll?: boolean
}

const CarouselSeminovosNormal: React.FC<TProps> = ({
  link,
  linkText,
  title,
  autoScroll
}) => {
  const { cars, loadCars, loading } = UseCarShopCars()

  function comparePrice(carA: TCarShopCar, carB: TCarShopCar) {
    if (carA.price < carB.price) {
      return -1
    }
    if (carA.price > carB.price) {
      return 1
    }
    return 0
  }

  function renderCarousel(data: any, index: number): any {
    return (
      <Carousel.item id={`carousel-item-${index}`} key={index}>
        <CarCard car={data} />
      </Carousel.item>
    )
  }

  function renderItem(): any {
    const list = cars.sort(comparePrice)
    return list.map((item: any, index: number) => {
      return renderCarousel(item, index)
    })
  }

  function renderContent() {
    if (!cars || cars.length <= 0) return <></>
    if (loading) {
      return (
        <div className="container">
          <LoadingContent />
        </div>
      )
    } else {
      return (
        <div className="carousel-seminovos-normal">
          <div className="container">
            <Carousel autoScroll={autoScroll} autoScrollTimeOut={10000}>
              <CarouselHeader
                title={title || ''}
                link={link}
                linkText={linkText}
              />
              <CarouselItens>{renderItem()}</CarouselItens>
            </Carousel>
          </div>
        </div>
      )
    }
  }

  useEffect(() => loadCars(), [])

  return <>{renderContent()}</>
}

export default CarouselSeminovosNormal
