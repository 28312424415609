/**
 * Controlador do registro de token de usuários no localStorage
 */
export class UserToken {
  readonly localName: string = ''
  value?: string
  refresh?: string
  validate = 23
  endValidate?: Date

  /**
   * @param localName LocalStorage name
   * @param newToken Token
   */
  constructor(localName: string, newToken: string | null = null) {
    this.localName = localName
    this.value = newToken || ''
    this.load()
  }

  load(): void {
    this.value = localStorage.getItem(this.localName) || undefined
    this.refresh = localStorage.getItem(this.localName + 'refresh') || undefined
    const endValidate = localStorage.getItem(this.localName + 'createAt')
    if (endValidate) {
      this.endValidate = new Date(endValidate)
    }
  }

  get(): string | null {
    if (!this.value || this.value === '') {
      return localStorage.getItem(this.localName)
    }
    return this.value
  }

  getRefresh(): string | null {
    if (!this.refresh || this.refresh === '') {
      return localStorage.getItem(this.localName + 'refresh')
    }
    return this.refresh
  }

  isInValid(): boolean {
    if (!this.endValidate) return true
    const currentDate = new Date()
    const currentTime = currentDate.getTime()
    const validateTime = this.endValidate?.getTime()
    return validateTime <= currentTime
  }

  delete(): void {
    localStorage.removeItem(this.localName)
  }
}
