import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import Button from 'view/components/button/button'
import Faq from 'view/components/faq/faq'
import { FormComponent, InputField } from 'view/components/formComponent'
import TitlePage from 'view/components/text/titlePage/titlePage'
import CarouselConteudo from 'view/componentsPages/carouselConteudo/carouselConteudo'
import LoadingContent from 'view/componentsPages/loadingContent/loadingContent'
import PageContainerPromo from 'view/componentsPages/pageContainerPromo/pageContainerPromo'
import { useAppContext } from 'view/contexts/appContext/appContext'
import './faqPage.css'

const FaqPage: React.FC = () => {
  const { faq } = useAppContext()
  const [search, setSearch] = useState<string>('')

  function onSubmit(value: any) {
    setSearch(value.query)
  }

  function renderContent() {
    if (!faq.loaded) {
      return (
        <div className="container">
          <LoadingContent />
        </div>
      )
    } else {
      return (
        <>
          <div className="container">
            <TitlePage>Perguntas frequentes</TitlePage>
            <FormComponent onSubmit={onSubmit} className="faq-form">
              <InputField name="query" placeholder="Pesquise por um termo" />
              <Button type="submit">
                <FontAwesomeIcon icon={['fas', 'search']} className="icon" />
              </Button>
            </FormComponent>
            <div className="faq-page-list">
              <Faq search={search} />
            </div>

            <PageContainerPromo />
          </div>
          <CarouselConteudo />
        </>
      )
    }
  }

  return <>{renderContent()}</>
}

export default FaqPage
