import { THtmlContentApi } from 'domain/entities/api/THtmlContentApi'
import { IDataService } from 'domain/entities/IDataService'
import { THtmlContent } from 'domain/entities/THtmlContent'
import { useEffect, useState } from 'react'
import { htmlContentdataParser } from '../htmlContent/htmlContent'

type TUseData<TData = any> = {
  data: TData
  loaded: boolean
}

export type TUseHtmlContentProps = {
  ApiService: new (props?: any) => IDataService
}

export function useHtmlContent(props: TUseHtmlContentProps): TUseData {
  const { ApiService } = props
  const [data, setData] = useState<TUseData['data']>({} as TUseData['data'])
  const [loaded, setLoaded] = useState<boolean>(true)

  function dataTypeParser(data: THtmlContentApi[]): THtmlContent {
    const d = data[0]
    return htmlContentdataParser(d)
  }

  function loadData() {
    setLoaded(false)
    const apiService = new ApiService()

    apiService.load().then(response => {
      if (
        !response ||
        response.statusCode !== 200 ||
        !response.body ||
        !response.body.results
      ) {
        console.error('html_content_service_error')
        return
      }
      setData(dataTypeParser(response.body.results))
      setTimeout(() => setLoaded(true), 100)
    })
  }

  useEffect(() => loadData(), [])

  return { data, loaded }
}
