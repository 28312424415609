import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'

export class UserIsActivated extends ApiService {
  async load(clientUuid: string): Promise<HttpResponse> {
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/check-account-activation/?cliente_uuid=${clientUuid}`,
      method: 'get'
    })
  }
}
