import React, { createContext, useContext, useEffect } from 'react'
import { TCarFeature } from 'aplication/useCases/carFeatures/carFeaturesTypes'
import { useCarFeatures } from 'aplication/useCases/carFeatures/useCarFeatures'
import { TUseResources, useResources } from 'view/hooksPages'
import {
  TUseBookingDaily,
  useBookingDaily
} from 'view/hooksPages/useBookingDaily/useBookingDaily'
import {
  TUseProtections,
  useProtections
} from 'view/hooksPages/useProtections/useProtections'
import {
  TUseCarGroupPriceRules,
  useCarGroupPriceRules
} from 'aplication/useCases/carGroup/useCarGroupPriceRules'
import {
  TUseCalcDrivers,
  useCalcDrivers
} from 'aplication/useCases/drivers/useCalcDrivers'
import {
  TCarStockList,
  carStockList
} from 'aplication/useCases/carStock/carStockList'

export type TBookingValuesContext = {
  resources: TUseResources
  carFeatures: TCarFeature[]
  driversCalc: TUseCalcDrivers
  bookingDaily: TUseBookingDaily
  protections: TUseProtections
  carGroup: TCarStockList
  carGroupPriceRules: TUseCarGroupPriceRules
}

const BookingValuesContext = createContext<TBookingValuesContext>(
  {} as TBookingValuesContext
)

const BookingValuesProvider: React.FC = ({ children }) => {
  const { features, loadFeatures } = useCarFeatures()

  const contextValue = {
    carFeatures: features,
    carGroupPriceRules: useCarGroupPriceRules(),
    carGroup: carStockList(),
    protections: useProtections(),
    bookingDaily: useBookingDaily(),
    resources: useResources(),
    driversCalc: useCalcDrivers()
  }

  useEffect(() => {
    loadFeatures()
  }, [])

  return (
    <BookingValuesContext.Provider value={{ ...contextValue }}>
      {children}
    </BookingValuesContext.Provider>
  )
}

export default BookingValuesProvider

export function useBookingValuesContext(): TBookingValuesContext {
  return useContext(BookingValuesContext)
}
