import React, { useEffect, useState } from 'react'
import {
  InputField,
  TValidator,
  useFormContext
} from 'view/components/formComponent'
import { isValidCpf } from 'view/components/formComponent/formComponentValidation/formComponentValidators'
import SelectFieldLoginType from './selectLoginType'
import './inputLoginField.css'

type TProps = {
  name?: string
}

const InputLoginField: React.FC<TProps> = ({ name }) => {
  const [loginType, setLoginType] = useState<string>('cpf')
  const { updateField } = useFormContext()

  const options: {
    [key: string]: {
      placeholder: string
      mask: (value: string) => string
    }
  } = {
    cpf: {
      placeholder: '00.000.000-00',
      mask: (value: string): string => {
        return value
          .replace(/\D/g, '')
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d{1,2})/, '$1-$2')
          .replace(/(-\d{2})\d+?$/, '$1')
      }
    },
    cnpj: {
      placeholder: '00.000.000/0000-00',
      mask: (value: string): string => {
        return value
          .replace(/\D/g, '')
          .replace(/(\d{2})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d)/, '$1/$2')
          .replace(/(\d{4})(\d{1,2})/, '$1-$2')
          .replace(/(-\d{2})\d+?$/, '$1')
      }
    },
    noMask: {
      placeholder: 'Passaporte',
      mask: (value: string): string => {
        return value
      }
    }
  }

  function setInputMask() {
    return options[loginType].mask
  }

  function setPlaceholder() {
    return options[loginType].placeholder
  }

  useEffect(() => {
    let validators: TValidator[] = []
    if (loginType === 'cpf') validators = [isValidCpf]
    updateField({ name: 'username', validators, value: '' })
  }, [loginType])

  return (
    <>
      <SelectFieldLoginType
        loginType={loginType}
        setLoginType={(value: string) => setLoginType(value)}
      />
      <InputField
        name={name || 'username'}
        placeholder={setPlaceholder()}
        regexMask={setInputMask()}
        maxLength={100}
      />
    </>
  )
}

export default InputLoginField
