import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'
import Error401 from './error401/error401'

const ErrorPages: React.FC = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${path}/401`} component={Error401} />
    </Switch>
  )
}

export default ErrorPages
