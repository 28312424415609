import { CityApiService } from 'aplication/apiServices/cityApiService/cityApiService'
import { TCity } from 'domain/entities/TCity'
import { useState } from 'react'
import { cityArrayParser } from './cityDataParser'

type T = {
  cities: TCity[]
  loadedCities: boolean
  loadCities: (uf: string) => void
}

export function useCityList(): T {
  const apiService = new CityApiService()
  const [cities, setCities] = useState<TCity[]>([] as TCity[])
  const [loaded, setLoaded] = useState<boolean>(false)

  function loadCities(uf: string) {
    setLoaded(false)
    if (!uf) return
    apiService.load(uf).then(response => {
      if (!response || response.statusCode !== 200 || !response.body) {
        console.error('car_shop_cars_load_error')
        return
      }

      setCities(cityArrayParser(response.body))
      setTimeout(() => setLoaded(true), 500)
    })
  }

  return { cities, loadedCities: loaded, loadCities }
}
