import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'

export class HcapchaValidateApiService extends ApiService {
  async load(token: string): Promise<HttpResponse> {
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/hcaptcha-validate/`,
      method: 'post',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      },
      body: {
        'h-captcha-response': token
      }
    })
  }
}
