import React, { useEffect, useRef, useState } from 'react'
import { Modal, TModalRef } from 'view/components/modal'
import TitlePage from 'view/components/text/titlePage/titlePage'
import BookingPjForm from '../../componentsPages/pJModalFormButton/bookingPjForm'
import { useOrganizationServices } from 'aplication/useCases/organizationServices/organizationServices'
import { TOrganizationService } from 'aplication/useCases/organizationServices/organizationServicesTypes'
import PJModalFormButton from 'view/componentsPages/pJModalFormButton/pJModalFormButton'
import DinamicContent from 'view/components/dinamicContent/dinamicContent'
import ServicePageTemplate from 'view/componentsPages/servicePageTemplate/servicePageTemplate'
import PageBannerContainer from 'view/componentsPages/pageBannerContainer/pageBannerContainer'
import PageBannerTop from 'view/componentsPages/pageBannerTop/pageBannerTop'
import './bookingPj.css'
import { useQuery } from 'aplication/utils/app/useQuery'

const BookingPjPage: React.FC = () => {
  const modalRef = useRef<TModalRef>({} as TModalRef)
  const { services, filterData } = useOrganizationServices()
  const [service, setService] = useState<TOrganizationService>(
    {} as TOrganizationService
  )
  const query = useQuery()

  function openModalWithQueryParam() {
    const modalPjOpen = query.get('modalpjopen')
    if (modalPjOpen) {
      modalRef.current.toggleOpen(true)
    }
  }

  useEffect(() => {
    setService(filterData('terceirizacao-de-frotas'))
  }, [services])

  useEffect(() => {
    const btns = document.querySelectorAll('.modal-formpj-open')
    btns.forEach(btn => {
      btn.addEventListener('click', () => {
        modalRef.current.toggleOpen(true)
      })
    })
  }, [service])

  useEffect(() => {
    openModalWithQueryParam()
  }, [])

  return (
    <div id="booking-pj-page">
      <PageBannerTop bannerLocation="terceirizacaodefrotas_topo" />
      <ServicePageTemplate>
        <TitlePage>Terceirização de frota</TitlePage>

        <DinamicContent content={service?.content} />

        <div className="button-line">
          <PJModalFormButton />
        </div>
        <PageBannerContainer bannerLocation="terceirizacaodefrotas_rodape" />
      </ServicePageTemplate>
      <Modal
        ref={modalRef}
        content={<BookingPjForm />}
        className="booking-pj-form"
      />
    </div>
  )
}

export default BookingPjPage
