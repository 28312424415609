import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import { TStyleColor } from 'view/viewTypes'
import IconClose from '../icons/iconClose/iconClose'
import IconLoading from '../icons/iconLoading/iconLoading'
import './dialog.css'

export type TDialogRef = {
  toggle: (value: boolean) => void
  changeMessage: (
    message: string,
    type?: TStyleColor,
    loading?: boolean
  ) => void
  loadMessage: (message: string) => void
}

type TProps = {
  scroll?: boolean
}

const Dialog: React.ForwardRefRenderFunction<TDialogRef, TProps> = (
  { scroll },
  ref
) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [show, setShow] = useState<boolean>(false)
  const [color, setColor] = useState<TStyleColor>('neutral')
  const [text, setText] = useState<string>('')
  const elementRef = useRef<HTMLDivElement>({} as HTMLDivElement)

  function toggle(value?: boolean) {
    setShow(value || !show)
  }

  function loadMessage(message: string) {
    setShow(true)
    setLoading(true)
    setText(message)
    setColor('neutral')
  }

  function changeMessage(
    message: string,
    type?: TStyleColor,
    loading?: boolean
  ) {
    setShow(false)
    setTimeout(() => {
      setShow(true)
      setText(message)
      setColor(type || 'neutral')
      setLoading(loading || false)
    }, 200)
  }

  useImperativeHandle(ref, () => {
    return { toggle, changeMessage, loadMessage }
  })

  useEffect(() => {
    if (!show) return
    if (scroll) {
      window.scrollTo(0, elementRef.current.offsetTop + -250)
    }
  }, [show])

  function loadDialog() {
    if (!show) return <></>
    return (
      <div className={'dialog'} ref={elementRef}>
        <div className={`dialog-content bgt-${color}`}>
          {loading ? <IconLoading /> : ''}
          {text}
          <IconClose color={color} onClick={() => setShow(false)} />
        </div>
      </div>
    )
  }

  return loadDialog()
}

export default forwardRef(Dialog)
