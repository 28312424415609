import { TSlideImageApi } from 'aplication/apiServices/slideImagesApiService/TSlideImageApi'
import { TSlideImage } from 'aplication/entities/TSlideImage'

export function slideImagesDataParser(data: TSlideImageApi): TSlideImage {
  if (!data) return {} as TSlideImage
  return {
    createdAt: data.created_at,
    deletedAt: data.deleted_at,
    desktop: data.desktop,
    enabled: data.enabled,
    id: data.id,
    isDeleted: data.is_deleted,
    link: data.link,
    metadata: data.metadata,
    mobile: data.mobile,
    name: data.name,
    position: data.position,
    thumbnail: data.thumbnail,
    updatedAt: data.updated_at,
    uuid: data.uuid
  }
}

export function slideImagesArrayParser(data: TSlideImageApi[]): TSlideImage[] {
  if (!data || data.length <= 0) return [] as TSlideImage[]
  return data.map(blogPost => slideImagesDataParser(blogPost))
}
