import { BookingPjApiService } from 'aplication/apiServices/bookingPjApiService/bookingPjApiService'
import { TBookingPjData } from 'aplication/entities/TBookingPjData'
import { TServiceResult } from 'aplication/entities/TServiceResult'
import { bookingPjDataParser } from './bookingPjDataParser'

export function bookingPjDataPost(
  body: TBookingPjData
): Promise<TServiceResult> {
  return new Promise(resolve => {
    const apiService = new BookingPjApiService()
    const bodyParsed = bookingPjDataParser(body)
    apiService.postData(bodyParsed).then(response => {
      if (response.statusCode !== 200 && response.statusCode !== 201) {
        resolve({ success: false })
      } else {
        resolve({ success: true })
      }
    })
  })
}
