import { TResource } from 'domain/entities/TResource'
import React from 'react'
import RentInfo from 'view/pages/rentingPage/booking/rentInfo/rentInfo'
import Resource from '../resource/resource'

type TProps = {
  resources?: TResource[] | null
}

const ResourcesList: React.FC<TProps> = ({ resources }) => {
  function loadResources() {
    if (!resources || resources.length <= 0) return <></>
    return resources.map((resource, index) => {
      return <Resource key={index} resource={resource} />
    })
  }

  function renderList() {
    if (!resources || resources.length <= 0) return <></>
    return (
      <RentInfo>
        <RentInfo.header>
          <h3>Acessórios</h3>
        </RentInfo.header>
        {loadResources()}
      </RentInfo>
    )
  }

  return <>{renderList()}</>
}

export default ResourcesList
