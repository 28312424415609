import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'

export type TActivationData = {
  clientUuid: string
  activationCode: string
}

export class UserActivation extends ApiService {
  async load(activationData: TActivationData): Promise<HttpResponse> {
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/activate-account/`,
      method: 'post',
      body: {
        cliente_uuid: activationData.clientUuid,
        activation_account_code: activationData.activationCode
      },
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
