import React from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { useAppContext } from 'view/contexts/appContext/appContext'
import './mainFooterMenu.css'

const MainFooterMenu: React.FC = () => {
  const { modalContactRef } = useAppContext()

  function scrollTo(el: any) {
    setTimeout(() => {
      el.scrollIntoView({ behavior: 'smooth' })
    }, 300)
  }

  return (
    <ul className="footer-menu">
      <li>
        <Link to="/quem-somos#aboutus-page">Quem somos</Link>
      </li>
      <li>
        <HashLink to="/quem-somos#aboutus-map" scroll={scrollTo}>
          Localização
        </HashLink>
      </li>
      <li>
        <Link
          to="#"
          onClick={e => {
            e.preventDefault()
            modalContactRef.current.toggleContactForm()
          }}
        >
          Contato
        </Link>
      </li>
      <li>
        <Link to="/faq">FAQ</Link>
      </li>
    </ul>
  )
}

export default MainFooterMenu
