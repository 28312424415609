import { TDriver } from 'domain/entities/TRentingData'
import React from 'react'
import Card from 'view/components/card/card'
import Driver from 'view/componentsPages/driver/driver'

type TProps = {
  drivers?: TDriver[]
}

const DashboardBookingDrivers: React.FC<TProps> = ({ drivers }) => {
  function renderDrivers() {
    if (!drivers || drivers.length <= 0) return <></>
    return (
      <div className="drivers-list">
        {drivers.map((driver, index) => {
          return <Driver key={index} driver={driver} id={`${index}`} />
        })}
      </div>
    )
  }

  function renderContent() {
    if (!drivers || drivers.length <= 0) return <></>
    return (
      <Card className="booking-drivers">
        <Card.content>
          <h3>Condutores</h3>
          {renderDrivers()}
        </Card.content>
      </Card>
    )
  }

  return <>{renderContent()}</>
}

export default DashboardBookingDrivers
