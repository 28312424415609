import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'
import { TSiteContactSubjectApi } from './TSiteContactSubject'

type TResponse = {
  results: TSiteContactSubjectApi[]
}

export class SiteContactSubjectApiService extends ApiService {
  async load(): Promise<HttpResponse<TResponse>> {
    return this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/fale-conosco-assuntos/`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
