import React from 'react'
import { TCarShopCar } from 'aplication/useCases/carShop/carShopTypes'
import { Link } from 'react-router-dom'
import Card from 'view/components/card/card'
import './carCard.css'
import CarFeaturesTags from '../carFeaturesTags/carFeaturesTags'
import { priceConverter } from 'aplication/utils/app/valueConverter'

type TProps = {
  car: TCarShopCar
  features?: boolean
}

const CarCard: React.FC<TProps> = ({ car, features }) => {
  function selectFirstImage() {
    let carImage = '/media/empty.png'
    if (car.images && car.images.length > 0) {
      carImage = car.images[0]
    }
    return carImage
  }

  return (
    <div className="car-card">
      <Card image={selectFirstImage()} link={`/seminovos/carro/${car.uuid}/`}>
        <Card.content>
          <div className="car-card-title">
            <Link to={`/seminovos/carro/${car.uuid}/`}>
              {car.maker?.name} {car.model}
            </Link>
          </div>
          {!features ? <></> : <CarFeaturesTags features={car.features} />}
          <span className="car-card-price">
            {priceConverter(car.price, true)[0].replace(',', '')}
          </span>
        </Card.content>
        <Card.footer>
          <div className="car-card-footer">
            <span>{car.km}km</span>
            <span>{car.year}</span>
            <span>{car.fuel}</span>
          </div>
        </Card.footer>
      </Card>
    </div>
  )
}

export default CarCard
