import React from 'react'
import AppRouting from 'view/appRouting/appRouting'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCheck,
  faCheckCircle,
  faSearch,
  faExclamationCircle,
  faTimes,
  faSpinner,
  faInfoCircle,
  faAngleRight,
  faAngleLeft,
  faEllipsisH,
  faCoins,
  faUser,
  faCar
} from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import {
  faEdit,
  faTimesCircle,
  faEye,
  faEyeSlash,
  faBell,
  faSquare,
  faCheckSquare
} from '@fortawesome/free-regular-svg-icons'

library.add(
  faCheck,
  faCheckCircle,
  faEdit,
  faTimesCircle,
  faEyeSlash,
  faEye,
  faSearch,
  faBell,
  faWhatsapp,
  faInstagram,
  faExclamationCircle,
  faSpinner,
  faTimes,
  faInfoCircle,
  faAngleLeft,
  faAngleRight,
  faEllipsisH,
  faCoins,
  faUser,
  faCar,
  faSquare,
  faCheckSquare
)

const App: React.FC = () => {
  return <AppRouting />
}

export default App
