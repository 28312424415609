// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TEmpty = { [key: string]: any }

export function objectIsEmpty(value: TEmpty): boolean {
  return Object.keys(value).length <= 0
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function arrayIsEmpty(value: any[]): boolean {
  return value.length <= 0
}

export function objectIncludes(value: TEmpty, key: string): boolean {
  return Object.keys(value).includes(key)
}
