import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'
import { TUserNotificationApi } from './userNotificationsApiServiceTypes'

type TLoadResponseBody = {
  results?: TUserNotificationApi[]
  count: number
}

type TSetReadResponseBody = {
  read: boolean
}

export class UserNotificationsApiService extends ApiService {
  async load(
    uuid: string,
    read?: boolean,
    page?: number
  ): Promise<HttpResponse<TLoadResponseBody>> {
    const readStatus = read ? '&read=true' : ''
    const paged = page ? `&page=${page}` : ''
    return this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/user-notifications/?uuid=${uuid}${readStatus}${paged}`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }

  /**
   * Atualiza notificação como lida
   * @param notificationId id da notificação
   * @returns
   */
  async setRead(
    notificationId: string,
    uuid: string
  ): Promise<HttpResponse<TSetReadResponseBody>> {
    return this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/user-notifications/${notificationId}/?uuid=${uuid}`,
      method: 'patch',
      body: {
        read: true
      },
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
