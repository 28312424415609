import React from 'react'
import { Title } from 'view/components/text'
import './contentMessage.css'

type Props = {
  title: string
  buttons?: JSX.Element
}

const ContentMessage: React.FC<Props> = ({ children, title, buttons }) => {
  return (
    <div className="container">
      <div className="content-message">
        <Title>{title}</Title>
        {children}
        {buttons}
      </div>
    </div>
  )
}

export default ContentMessage
