import React from 'react'
import { ContactTime } from 'view/componentsPages/contactTime'
import PhoneNumber from 'view/componentsPages/phoneNumber/phoneNumber'
import { WhatsappButton } from 'view/componentsPages/whatsappButton'
import { useAppContext } from 'view/contexts/appContext/appContext'
import './mainFooter.css'
import MainFooterLastMenu from './mainFooterLastMenu/mainFooterLastMenu'
import MainFooterMenu from './mainFooterMenu/mainFooterMenu'

const MainFooter: React.FC = () => {
  const { organizationInfo } = useAppContext()

  return (
    <div className="main-footer">
      <div className="container">
        <div className="main-footer-content">
          <div className="footer-column">
            <h2>Central de atendimento</h2>
            <PhoneNumber />
            <ContactTime />
            <WhatsappButton />
          </div>
          <div className="footer-column">
            <MainFooterMenu />
          </div>
          <div className="footer-column">
            <MainFooterLastMenu />
          </div>
          <div className="law-text">
            <div className="text">
              {organizationInfo?.siteFooterText} <br />
              <b>CNPJ:</b> {organizationInfo?.cnpj} | <b>Endereço:</b>{' '}
              {organizationInfo?.address}
            </div>
            <div className="logo">
              <img src={organizationInfo?.logo} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainFooter
