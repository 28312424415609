import { TApiProtection } from 'domain/entities/api/TApiProtection'
import { TProtection } from 'domain/entities/TProtection'

export function protectionDataParser(data: TApiProtection): TProtection {
  if (!data) return {} as TProtection
  return {
    id: data.id,
    uuid: data.uuid,
    price: parseFloat(data.valor),
    title: data.tipo,
    description: data.descricao
  }
}

export function protectionArrayParser(data: TApiProtection[]): TProtection[] {
  if (!data || data.length <= 0) return [] as TProtection[]
  return data.map(protection => protectionDataParser(protection))
}
