import { TTokenApi } from 'domain/entities/api/TTokenApi'
import { TToken } from 'domain/entities/TToken'

export function authDataParser(data: TTokenApi): TToken {
  if (!data) return {} as TToken
  return {
    access: data.access,
    refresh: data.refresh
  }
}
