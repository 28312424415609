import { HttpResponse } from 'aplication/apiServices/protocols/IHttpClient'
import { TCarShopFeatureApi } from './carShopFiltersTypes'
import { ApiService } from '../apiService'

type TResponseData = {
  results: TCarShopFeatureApi[]
}

export class CarShopFiltersFeatures extends ApiService {
  async load(): Promise<HttpResponse<TResponseData>> {
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/caracteristicas-veiculos/`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
