import React, { useEffect, useState } from 'react'
import Button from 'view/components/button/button'
import { CheckboxField, FormComponent } from 'view/components/formComponent'
import { useAuthContext } from 'view/contexts/authContext'
import { useBookingValuesContext } from 'view/contexts/bookingValuesContext'

type TProps = {
  title?: string
  onSubmit: (values: any) => void
}

const BookingAfterPaymentCondition: React.FC<TProps> = ({
  title,
  onSubmit,
  children
}) => {
  const { loggedUser } = useAuthContext()
  const { daily } = useBookingValuesContext().bookingDaily
  const [checked, setChecked] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)

  function loadReturnPaymentButton() {
    if (!checked) return <></>
    return (
      <Button type="submit" size="bg" full>
        Confirmar
      </Button>
    )
  }

  function handleSubmit(values: any) {
    onSubmit(null)
  }

  function onChange(values: any) {
    const fieldReturnPayment = values.find(
      (f: any) => f.name === 'return_payment'
    )
    setChecked(fieldReturnPayment.value)
  }

  function renderCardForm() {
    if (checked) return <></>
    return children
  }

  useEffect(() => {
    if (!loggedUser || !loggedUser.person) return
    if (
      loggedUser.person.documentType !== 'cpf'.toUpperCase() ||
      (daily && daily >= 30)
    ) {
      setChecked(true)
      setDisabled(true)
    }
  }, [loggedUser])

  return (
    <>
      <div className="payment-card-form">
        <FormComponent onFormChange={onChange} onSubmit={handleSubmit}>
          <div className="form-session">
            <CheckboxField
              name="return_payment"
              label={title || 'Pagar depois'}
              checked={checked}
              disabled={disabled}
            />
          </div>
          <div className="form-session"></div>
          <div className="form-session">{loadReturnPaymentButton()}</div>
        </FormComponent>
      </div>
      {renderCardForm()}
    </>
  )
}

export default BookingAfterPaymentCondition
