import { TProtection } from 'domain/entities/TProtection'
import React from 'react'
import RentInfo from 'view/pages/rentingPage/booking/rentInfo/rentInfo'

type TProps = {
  protection?: TProtection | null
}

const ProtectionInfo: React.FC<TProps> = ({ protection }) => {
  return (
    <RentInfo.item>
      <RentInfo.header>
        <h3>Proteção</h3>
      </RentInfo.header>
      <RentInfo.content>
        <div className="rent-info-extra">
          <h3>{protection?.title}</h3>
          <div
            dangerouslySetInnerHTML={{ __html: protection?.description || '' }}
          />
        </div>
      </RentInfo.content>
    </RentInfo.item>
  )
}

export default ProtectionInfo
