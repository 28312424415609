import { CarShopFilters } from 'aplication/apiServices/carShopFilters/carShopFilters'
import { TCarShopFiltersApi } from 'aplication/apiServices/carShopFilters/carShopFiltersTypes'
import { useEffect, useState } from 'react'
import { TCarShopFiltersOptions } from './carShopTypes'

export type TUseCarShopFilters = {
  filtersOptions: TCarShopFiltersOptions
}

export function useCarShopFiltersOptions(): TUseCarShopFilters {
  const [filtersOptions, setFiltersOptions] = useState<TCarShopFiltersOptions>(
    {} as TCarShopFiltersOptions
  )

  function dataTypeParser(apiData: TCarShopFiltersApi): TCarShopFiltersOptions {
    if (!apiData) return {} as TCarShopFiltersOptions
    return {
      color: apiData.cor,
      fuel: apiData.tipo_combustivel,
      years: apiData.ano_model_fabricacao
    }
  }

  function loadFiltersOptions() {
    const apiData = new CarShopFilters()
    apiData.load().then(response => {
      if (!response || response.statusCode !== 200 || !response.body) {
        console.error('car_shop_filters_options_load_error')
        return
      }
      const allFeatures = dataTypeParser(response.body)
      setFiltersOptions(allFeatures)
    })
  }

  useEffect(() => loadFiltersOptions(), [])

  return { filtersOptions }
}
