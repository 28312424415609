import React, { createContext, useContext, useEffect, useState } from 'react'
import Button from '../button/button'
import './tabs.css'

type TTab = {
  id: string
  title: string
  content: JSX.Element
}

type TProps = {
  tabs: TTab[]
}

type TTabContext = {
  activeTab: TTab | null
}

const TabContext = createContext<TTabContext>({} as TTabContext)

const Tabs: React.FC<TProps> = ({ tabs }) => {
  const [tabsList, setTabsList] = useState<TTab[]>([] as TTab[])
  const [activeTab, setActiveTab] = useState<TTab | null>(null)

  function setTabActive(event: any) {
    const tabId = event.target.id
    if (!tabId) return
    const tab = tabsList.find((tab, index) => index === parseInt(tabId))
    if (!tab) return
    setActiveTab(tab)
  }

  function isActivTab(tab: TTab) {
    if (!activeTab) return false
    return activeTab.title === tab.title
  }

  function renderTabNav() {
    if (!tabsList) return
    return tabsList.map((tab, index) => {
      return (
        <Button
          style="link"
          key={index}
          id={`${index}`}
          onClick={setTabActive}
          className={isActivTab(tab) ? 'active' : ''}
        >
          {tab.title}
        </Button>
      )
    })
  }

  function renderActiveTabContent() {
    if (!activeTab) return <></>
    return <>{activeTab.content}</>
  }

  useEffect(() => {
    if (!tabs) return
    setTabsList(tabs)
    const tbs: { [key: string]: any } = tabs
    setActiveTab(tbs[0])
  }, [tabs])

  return (
    <TabContext.Provider value={{ activeTab }}>
      <div className="tabs">
        <div className="tabs-nav">{renderTabNav()}</div>
        <div className="tabs-content">{renderActiveTabContent()}</div>
      </div>
    </TabContext.Provider>
  )
}

export default Tabs

export function useTabContext(): TTabContext {
  return useContext(TabContext)
}
