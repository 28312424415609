import React, { useEffect, useState } from 'react'
import DateField from 'view/components/formComponent/fields/dateField/dateField'
import { useFormContext } from 'view/components/formComponent/formComponent'
import { useAppContext } from 'view/contexts/appContext/appContext'
import { useBookingFormContext } from '../bookingFormProvider'
import { bookingRulesValidatior } from 'aplication/useCases/rentalRulesValidator/bookingRulesValidator'

type Props = {
  name: string
  listening: string
  disabled?: boolean
  minDate?: Date
  fieldRef?: any
  onChange?: (value: any) => void
}

const BookingDateField: React.FC<Props> = ({
  listening,
  name,
  minDate,
  disabled,
  fieldRef,
  onChange
}) => {
  const { locations } = useAppContext().locations
  const { fields } = useFormContext()
  const bookingRulesValidator = bookingRulesValidatior(locations)
  const [locationValue, setLocationValue] = useState<any>('')
  const { bookingDateBlocks } = useBookingFormContext()

  function dateFilterDayRuleIsNull(date: Date, location: string) {
    return bookingRulesValidator.dayRuleIsNull(date, location)
  }

  function dateFilterDayTimeValidation(date: Date, location: string) {
    return bookingRulesValidator.dayTimeValidation(date, location)
  }

  function makeDateFilter(location: string) {
    return (currentDate: Date) => {
      return [
        dateFilterDayRuleIsNull(currentDate, location),
        dateFilterDayTimeValidation(currentDate, location),
        bookingRulesValidator.dateFilterInBookingDateBlockPeriod({
          date: currentDate,
          location,
          bookingDateBlocks,
          fieldName: name
        })
      ].every(i => i)
    }
  }

  function handleChange(value: any) {
    if (onChange) onChange(value)
  }

  useEffect(() => {
    const locationField = fields.find(field => field.name === listening)
    if (locationField) {
      setLocationValue(locationField.value)
    }
  }, [fields])

  return (
    <DateField
      name={name}
      minDate={minDate || new Date()}
      filterDate={makeDateFilter(locationValue)}
      disabled={disabled}
      required
      disableWrite
      onChange={handleChange}
      fieldRef={fieldRef}
    />
  )
}

export default BookingDateField
