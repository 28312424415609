import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import { hCapchaValidator } from 'aplication/useCases/hCapcha/hCapchaValidator'

export type TCaptchaRef = {
  isValid: boolean
  loaded: boolean
}

const Captcha: React.ForwardRefRenderFunction<TCaptchaRef> = (_, ref) => {
  const [token, setToken] = useState<string>('')
  const validator = hCapchaValidator()

  useEffect(() => {
    validator.load(token)
  }, [token])

  useImperativeHandle(ref, () => {
    return { isValid: validator.isValid, loaded: validator.loaded }
  })

  return (
    <>
      <HCaptcha
        sitekey={
          process.env.REACT_APP_CAPTCHA_SITEKEY ||
          '20000000-ffff-ffff-ffff-000000000002'
        }
        onVerify={token => {
          setToken(token)
        }}
      />
    </>
  )
}

export default forwardRef(Captcha)
