import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TUserNotification } from 'aplication/useCases/userNotifications/useUserNotificationsTypes'
import './notification.css'

type TProps = {
  index: number
  notification: TUserNotification
  remove: (e: any) => void
}
const Notification: React.FC<TProps> = ({ notification, index, remove }) => {
  function renderReadBotton() {
    if (notification.read) return <></>
    return (
      <span className="read" title="Remover." onClick={remove}>
        <FontAwesomeIcon icon={['fas', 'check-circle']} />
      </span>
    )
  }

  return (
    <div className="notification" id={`${notification.id}`}>
      <div className="notification-title">
        <span>{notification.title}</span>
        {renderReadBotton()}
      </div>
      <div
        className="notification-description"
        dangerouslySetInnerHTML={{ __html: notification.description }}
      />
    </div>
  )
}

export default Notification
