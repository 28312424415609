import { useEffect, useState } from 'react'
import { TRentingData } from 'domain/entities/TRentingData'
import { useBookingDataContext } from 'view/contexts/bookingDataContext'
import * as rentingDataValidators from './useRentingDataValidators'
import { useHistory } from 'react-router-dom'

type TUseRentingDataValidation = {
  invalidData: boolean
  failedValidator: string
  goToInvalidPage: () => void
}

type TRentingDataValidator = (rentingData: TRentingData | null) => boolean

export function useRentingDataValidation(
  validatorsNames: string[]
): TUseRentingDataValidation {
  const { bookingData } = useBookingDataContext()
  const [invalidData, setInvalidData] = useState<boolean>(false)
  const [failedValidator, setFailedValidator] = useState<string>('')
  const validators: { [key: string]: TRentingDataValidator } =
    rentingDataValidators

  function goToInvalidPage() {
    const history = useHistory()
    const path = history.location.pathname.split('/')[1]
    history.push(`/${path}/reserva-invalida?failed=${failedValidator}`)
  }

  function isInvalidRentingData(data: TRentingData) {
    for (const name of validatorsNames) {
      const validator = validators[name]
      if (validator(data)) {
        setFailedValidator(name)
        return true
      }
    }
    return false
  }

  useEffect(() => {
    if (isInvalidRentingData(bookingData)) {
      setInvalidData(true)
    }
  }, [bookingData])

  return { invalidData, failedValidator, goToInvalidPage }
}
