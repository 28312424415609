import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { Modal, TModalRef } from 'view/components/modal'
import './modalDialog.css'

export type TModalDialogRef = {
  modal: { current: TModalRef }
  setClassName: (name: string) => void
  setContent: (content: JSX.Element) => void
}

const ModalDialog: React.ForwardRefRenderFunction<TModalDialogRef> = (
  props,
  ref
) => {
  const modalRef = useRef<TModalRef>({} as TModalRef)
  const [className, setClassName] = useState<string>('')
  const [content, setContent] = useState<JSX.Element>(<></>)

  useImperativeHandle(ref, () => {
    return {
      modal: modalRef,
      setClassName,
      setContent
    }
  })

  return (
    <>
      <Modal
        className={`modal-dialog ${className}`}
        content={content}
        ref={modalRef}
      />
    </>
  )
}

export default forwardRef(ModalDialog)
