import { TCarStockImageApi } from 'aplication/apiServices/carStockApiService/TCarStockApi'
import { TCarStockImage } from './TCarStock'

export function carStockImageDataParser(
  data: TCarStockImageApi
): TCarStockImage {
  if (!data) return {} as TCarStockImage
  return {
    group: data.grupo,
    id: data.id,
    image: data.image,
    uuid: data.uuid
  }
}

export function carStockImageArrayParser(
  data: TCarStockImageApi[]
): TCarStockImage[] {
  if (!data || data.length <= 0) return [] as TCarStockImage[]
  return data.map(d => carStockImageDataParser(d))
}
