import { TField, TValidator } from '../../formComponentsTypes'

export const requiredCheckbox: TValidator = {
  run: (field: TField) => {
    const { value, isRequired } = field
    if (!isRequired) return true
    if (value === false) {
      return false
    }
    return true
  },
  message: 'Este campo não pode estar desmarcado.'
}
