/* eslint-disable camelcase */
import { TResource } from 'domain/entities/TResource'
import { ApiService } from '../apiService'
import { HttpResponse } from '../protocols/IHttpClient'

function createAResource(resourceService: any): TResource {
  const { id, nome, preco, descricao, qtde_disponivel } = resourceService
  return {
    id,
    title: nome,
    price: preco || 0,
    description: descricao,
    available: qtde_disponivel
  }
}

export class ResourcesDataService extends ApiService {
  async load(id: number | null = null): Promise<HttpResponse> {
    const urlComplement = id ? `${id}` : ''

    const response = await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/acessorios/${urlComplement}`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })

    let resource: TResource = {} as TResource

    if (Object.keys(response.body).includes('results')) {
      response.body.results = response.body.results.map((r: any): TResource => {
        resource = createAResource(r)
        return resource
      })
    } else {
      response.body = createAResource(response.body)
    }

    return response
  }
}
