import {
  addPJDiscount,
  applyPriceRule,
  calcPriceValue,
  returnPriceRules
} from 'aplication/useCases/priceRulesUseCases/priceRulesUseCase'
import { isoDateToDate } from 'aplication/utils/app/dateTimeCare/dateTimeCare'
import { objectIsEmpty } from 'aplication/utils/app/testValues'
import { useEffect, useState } from 'react'
import { useAuthContext } from 'view/contexts/authContext'
import { useBookingDataContext } from 'view/contexts/bookingDataContext'

type TDiscount = {
  discountValue: number
  discountPercent: number
}

type TDates = {
  startDate: Date
  finalDate: Date
}

export type TUseCarGroupPriceRules = {
  priceRule: any
  carGroupPriceRule: (price: number) => number
  discount: TDiscount | null
}

export function useCarGroupPriceRules(): TUseCarGroupPriceRules {
  const { loggedUser } = useAuthContext()
  const { bookingData } = useBookingDataContext()
  const [dates, setDates] = useState<TDates>({} as TDates)
  const [priceRule, setPriceRule] = useState<any>(null)
  const [discount, setDiscount] = useState<TDiscount>({} as TDiscount)

  async function loadPriceRule(startDate: Date, finalDate: Date) {
    const rule = await returnPriceRules(startDate, finalDate)
    setPriceRule(rule)
  }

  function carGroupPriceRule(price: number) {
    const startDate = isoDateToDate(bookingData.pickup?.date)
    const finalDate = isoDateToDate(bookingData.return?.date)
    if (!startDate || !finalDate || !priceRule) return 0
    let discountPercent = applyPriceRule(0, priceRule, startDate, finalDate)
    discountPercent = addPJDiscount(discountPercent, loggedUser)
    const { newPrice, discountValue } = calcPriceValue(price, discountPercent)
    setDiscount({
      discountPercent,
      discountValue
    })
    return newPrice
  }

  function loadDefaultDiscount() {
    if (
      !bookingData ||
      !bookingData.discount ||
      Object.keys(bookingData.discount).length <= 0
    ) {
      return
    }
    setDiscount(bookingData.discount)
  }

  function isASameDatesValue(datesComing: TDates): boolean {
    const { startDate, finalDate } = datesComing
    return (
      dates &&
      !objectIsEmpty(dates) &&
      dates.startDate.getTime() === startDate.getTime() &&
      dates.finalDate.getTime() === finalDate.getTime()
    )
  }

  useEffect(() => {
    if (!bookingData || objectIsEmpty(bookingData)) return
    const startDate = isoDateToDate(bookingData?.pickup?.date)
    const finalDate = isoDateToDate(bookingData?.return?.date)

    if (
      !startDate ||
      !finalDate ||
      isASameDatesValue({ startDate, finalDate })
    ) {
      return
    }

    setDates({ startDate, finalDate })
    loadPriceRule(startDate, finalDate)
    loadDefaultDiscount()
  }, [bookingData])

  return { priceRule, carGroupPriceRule, discount }
}
