import { useEffect, useState } from 'react'
import { OrganizationServicesDataService } from 'aplication/apiServices/organizationServicesApiService/organizationServicesApiService'
import { TOrganizationServicesApi } from 'aplication/apiServices/organizationServicesApiService/organizationServicesApiServiceTypes'
import { TOrganizationService } from './organizationServicesTypes'
import { objectIsEmpty } from 'aplication/utils/app/testValues'

type TUseOrganizationServices = {
  services: TOrganizationService[]
  filterData: (slug: string) => TOrganizationService
}

export function useOrganizationServices(): TUseOrganizationServices {
  const [data, setData] = useState<TOrganizationService[]>(
    [] as TOrganizationService[]
  )

  function dataTypeParser(
    data: TOrganizationServicesApi[]
  ): TOrganizationService[] {
    return data.map(i => {
      return {
        id: i.id,
        slug: i.slug,
        title: i.title,
        content: i.content,
        uuid: i.uuid,
        brief: i.brief
      }
    })
  }

  function filterData(slug?: string | null): TOrganizationService {
    const emptyData = {} as TOrganizationService
    if (!slug || !data || objectIsEmpty(data)) return emptyData
    return data.find(d => d.slug === slug) || emptyData
  }

  function loadData() {
    const apiService = new OrganizationServicesDataService()
    apiService.load().then(response => {
      if (
        !response ||
        response.statusCode !== 200 ||
        !response.body ||
        !response.body.results
      ) {
        console.error('organization_services_service_error')
        return
      }
      setData(dataTypeParser(response.body.results))
    })
  }

  useEffect(() => loadData(), [])

  return { services: data, filterData }
}
