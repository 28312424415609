/**
 * Registro de todos os textos da aplicação passiveis de tradução
 */

const appTexts: { [key: string]: string } = {
  default_reserva_post_error: 'Não foi possível finalizar a reserva.',
  possui_reserva_em_aberto: 'Este cliente já possui uma reserva em aberto.',
  possui_reserva_aprovada_aguardando_retirada:
    'Este cliente já possui uma reserva aprovada e aguardando a retirada do veículo.',
  possui_reserva_em_andamento: 'O cliente já possui uma reserva em andamento.',
  possui_reserva_devolucao_do_veiculo_em_atraso:
    'Este cliente está com uma reserva em andamento e a devolução do veículo em atraso. Se o cliente deseja ficar mais tempo com o veículo, é necessário renovar a reserva atual, caso contrário, não poderá efetuar uma nova reserva enquanto a atual não for finalizada.',
  possui_cadastro_negativo:
    'Não foi possível registrar a reserva. Entre em contato para maiores informações.',
  cpf_existe:
    'Não foi possível realizar o seu cadastro. Já existe uma conta com o CPF informado.',
  email_existe:
    'Não foi possível realizar o seu cadastro. O e-mail informado já está sendo utilizado por outra conta. Digite outro e-mail para prosseguir.',
  email_existe_pj:
    'Não foi possível realizar o seu cadastro. O e-mail informado já está sendo utilizado por outra conta. Digite outro e-mail para prosseguir.',
  documento_cliente_not_found:
    'Não foi possível realizar o seu cadastro. Documento utilizado é inválido.',
  cpf_invalido:
    'Não foi possível realizar o seu cadastro. Utilize um CPF válido.',
  falhou_conta_nao_criada:
    'Não foi possível realizar o seu cadastro. Tente novamente mais tarde.',
  sem_veiculo_disponivel:
    'Não foi possível solicitar a reserva. Estamos sem veículos disponíveis no momento.',
  atingiu_limite_veiculos:
    'Não foi possível registrar a reserva, pois o limite de veículos foi excedido.',
  atingiu_limite_credito:
    'Não foi possível registrar a reserva, pois o limite de crédito excedido.',
  atingiu_limite_credito_veiculos:
    'Não foi possível registrar a reserva, pois os limites de crédito e veículos foram excedidos.',
  email_exists_validation_msg:
    'Este email já está cadastrado no sistema. Insira outro email válido.',
  reserva_id_not_found: 'se o id da reserva não passado no json (via post)',
  installments_params_not_found:
    'se o parâmetro referente ao parcelamento não for enviado',
  installments_params_value_error:
    'se o valor do parcelamento for menor ou igual a zero',
  invalid_card_params:
    "se os parâmetros 'card_uuid' ou 'card' não forem setados",
  reserva_not_found: 'se a reserva não for encontrada',
  reserva_expired:
    'se o status da reserva tiver sido expirada (mais de 3 dias sem atualização, o sistema expira e o cliente terá que abir outra)',
  status_not_available_to_pay:
    'se a reserva tiver sido canelada, expirada ou removida.',
  em_processamento:
    'tudo certo e o pagamento passou para processamento junto a operadora e isso ocorre em segundo plano',
  senha_atual_nao_confere:
    'Não foi possível atualizar os dados. Senha atual não confere.',
  display_field_password_instructions:
    'A senha deve ter no mínimo 7 caracteres, sendo pelo menos um número e uma letra. Também não deve ter uma sequência, como: 123456a ou abcdef1.'
}

export default appTexts
