import { HttpResponse } from '../protocols/IHttpClient'
import { TOrganizationInfoApiData } from '../../../domain/entities/api/TOrganizationInfoApiData'
import { ApiService } from '../apiService'

type TResponseBody = {
  results?: TOrganizationInfoApiData[]
}

export class OrganizationInfoDataService extends ApiService {
  async load(): Promise<HttpResponse<TResponseBody>> {
    return this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/dados-realiza/`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
