import { priceConverter } from 'aplication/utils/app/valueConverter'
import { TBooking } from 'domain/entities/TBooking'
import React from 'react'

type TProps = {
  bookingData: TBooking
}

const CheckoutLocationTax: React.FC<TProps> = ({ bookingData }) => {
  function renderTax(title: string, taxLocation?: number) {
    if (!taxLocation) return <></>
    return (
      <div className="checkout-values-item">
        <div className="checkout-values-item-info">
          <h3>{title}</h3>
        </div>
        <div className="checkout-values-item-value">
          {priceConverter(taxLocation)}
        </div>
      </div>
    )
  }

  return (
    <>
      {renderTax('Taxa de retirada', bookingData.pickup?.location?.tax)}
      {renderTax('Taxa de devolução', bookingData.return?.location?.tax)}
    </>
  )
}

export default CheckoutLocationTax
