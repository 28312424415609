import { useContext, useEffect, useState } from 'react'
import { CarouselContext } from './carouselContext'
import { TPosition } from './carouselTypes'

type TCarouselMouseActions = {
  isDown: boolean
  onMouseDown: (e: any) => void
  onMouseLeave: () => void
  onMouseUp: () => void
  onMouseMove: (e: any) => void
}

export function carouselMouseActions(): TCarouselMouseActions {
  const { scrollRef, positions, currentPosition, updateCurrentposition } =
    useContext(CarouselContext)

  let isDown = false
  let startX: number
  let scrollLeft: number

  function findNearestPosition(newPosition: number) {
    return positions.reduce(function (
      prev: TPosition,
      curr: TPosition
    ): TPosition {
      const valueA = Math.abs(curr.xPosition - newPosition)
      const valueB = Math.abs(prev.xPosition - newPosition)
      return valueA < valueB ? curr : prev
    })
  }

  function onMouseDown(e: any) {
    e.preventDefault()
    const slider = scrollRef.current.querySelector('.carousel-itens')
    slider.style['scroll-behavior'] = 'auto'
    isDown = true
    startX = e.pageX - slider.offsetLeft
    scrollLeft = slider.scrollLeft
    slider.classList.add('active')
  }

  function onMouseLeave() {
    const slider = scrollRef.current.querySelector('.carousel-itens')
    slider.style['scroll-behavior'] = 'smooth'
    isDown = false
    slider.classList.remove('active')
  }

  function onMouseUp() {
    const slider = scrollRef.current.querySelector('.carousel-itens')
    slider.style['scroll-behavior'] = 'smooth'
    isDown = false
    slider.classList.remove('active')
    const nearest = findNearestPosition(slider.scrollLeft).id
    updateCurrentposition(nearest)
  }

  function onMouseMove(e: any) {
    if (!isDown) return
    e.preventDefault()
    const slider = scrollRef.current.querySelector('.carousel-itens')
    const x = e.pageX - slider.offsetLeft
    const walk = (x - startX) * 2 // scroll-fast
    slider.scrollLeft = scrollLeft - walk
  }

  useEffect(() => {
    if (positions.length <= 0) return
    const slider = scrollRef.current.querySelector('.carousel-itens')
    slider.addEventListener('mousedown', onMouseDown)
    slider.addEventListener('mouseleave', onMouseLeave)
    slider.addEventListener('mouseup', onMouseUp)
    slider.addEventListener('mousemove', onMouseMove)
  }, [scrollRef, positions, currentPosition])

  return {
    isDown,
    onMouseDown,
    onMouseLeave,
    onMouseUp,
    onMouseMove
  }
}
