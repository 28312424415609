import {
  TActivationData,
  UserActivation
} from 'aplication/apiServices/userActivation/userActivation'

export async function userActivation(
  userData: TActivationData
): Promise<boolean> {
  const serviceData = new UserActivation()

  // userData.activationCode = userData.activationCode.replace(
  //   /(\d{2})(\d{1,2})/,
  //   '$1 $2 '
  // )

  const response = await serviceData.load(userData)

  if (!response || response.statusCode !== 200) return false

  return response.body.success
}
