import React from 'react'
import { useAppContext } from 'view/contexts/appContext/appContext'
import './contactTime.css'

const ContactTime: React.FC = () => {
  const { organizationInfo } = useAppContext()

  function loadInfo() {
    const info = organizationInfo?.timeOpen?.split('|') || []

    return info.map((i, index) => {
      return <span key={index}>{i}</span>
    })
  }

  return <div className="contact-time">{loadInfo()}</div>
}

export default ContactTime
