import React from 'react'
import './loadAnimation.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const LoadAnimation: React.FC = () => {
  return (
    <div className="load-animation">
      <FontAwesomeIcon
        icon={faSpinner}
        style={{ fontSize: '40px', color: '#f5821f' }}
      />
    </div>
  )
}

export default LoadAnimation
