import React, { useEffect, useState } from 'react'
import { TField } from 'view/components/formComponent'

type TReturnHook = {
  disabled: boolean
}

export function useDisabledController(
  fields: TField[],
  listeningField: string | any[]
): TReturnHook {
  const [disabled, setDisabled] = useState<boolean>(true)

  function testFieldValue(fieldName: string) {
    const listening = fields.find(field => field.name === fieldName)
    return (
      !listening ||
      !listening.value ||
      listening.value === '' ||
      listening.value === '0'
    )
  }

  function validateListening() {
    if (typeof listeningField === 'string') {
      return testFieldValue(listeningField)
    } else if (Array.isArray(listeningField)) {
      const results: boolean[] = []
      listeningField.forEach(field => results.push(testFieldValue(field)))
      return results.includes(true)
    }
  }

  useEffect(() => {
    if (validateListening()) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [fields])

  return { disabled }
}
