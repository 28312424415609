import { clientUpdate } from 'aplication/useCases/clientUpdate/clientUpdate'
import { objectIsEmpty } from 'aplication/utils/app/testValues'
import React, { useEffect, useState, useRef } from 'react'
import Button from 'view/components/button/button'
import { FormComponent } from 'view/components/formComponent'
import { Dialog, TDialogRef } from 'view/components/dialog'
import { useAuthContext } from 'view/contexts/authContext'
import AccountEditPersonPJFields from './accountEditPersonPJFields'
import AccountEditPersonPFFields from './accountEditPersonPFFields'

const AccountEditPersonForm: React.FC = () => {
  const { loggedUser, loadCurrentUser } = useAuthContext()
  const [formData, setFormData] = useState<any>({})
  const dialogRef = useRef<TDialogRef>({} as TDialogRef)

  function loadFormData() {
    if (!loggedUser || objectIsEmpty(loggedUser)) return
    setFormData({
      name: loggedUser.person.name,
      documento: loggedUser.person.documento,
      email: loggedUser.person.email,
      cep: loggedUser.person.cep,
      address: loggedUser.person.address,
      addressDistrict: loggedUser.person.addressDistrict,
      complement: loggedUser.person.complement,
      city: loggedUser.person.city,
      phone: loggedUser.person.phone,
      uuid: loggedUser.person.uuid,
      cnh: loggedUser.person.cnh,
      cnhSecurityNumber: loggedUser.person.cnhSecurityNumber,
      pJResponsible: loggedUser.person.pJResponsible,
      pJResponsiblePhone: loggedUser.person.pJResponsiblePhone
    })
  }

  function onSubmit(data: any) {
    if (!data) return
    let msg = 'Enviando dados...'
    dialogRef.current?.loadMessage(msg)
    clientUpdate(data).then(response => {
      if (!response || response.statusCode !== 200 || !response.body) {
        console.error('client_update_error')
        msg = response.body?.detail || 'Não foi possível atualizar os dados.'
        dialogRef.current?.changeMessage(msg, 'danger')
        return
      }
      msg = 'Dados atualizados com sucesso.'
      dialogRef.current?.changeMessage(msg, 'success')
      loadCurrentUser()
    })
  }

  function renderFields() {
    if (!loggedUser || !loggedUser.person) {
      return <></>
    } else if (loggedUser.person.clientType === 'PJ') {
      return <AccountEditPersonPJFields />
    } else {
      return <AccountEditPersonPFFields />
    }
  }

  useEffect(() => {
    loadFormData()
  }, [loggedUser])

  return (
    <div className="form-edit-person">
      <Dialog ref={dialogRef} />
      <FormComponent fieldValues={formData} onSubmit={onSubmit}>
        {renderFields()}
        <div className="form-button">
          <Button type="submit">Editar meus dados</Button>
        </div>
      </FormComponent>
    </div>
  )
}

export default AccountEditPersonForm
