import {
  TBookingApi,
  TBookingApiCardData
} from 'domain/entities/api/TBookingApi'
import { TBooking, TBookingCardData } from 'domain/entities/TBooking'
import { carGroupDataParser } from '../carGroup/carGroupDataParser'
import { driverArrayParser } from '../drivers/driverDataParser'
import { locationDataParser } from '../locations/locationDataParser'
import { protectionDataParser } from '../protection/protectionDataParser'
import { resourcesArrayTotalValue } from '../resources/resourcesArrayTotalValue'
import { resourceArrayDataParser } from '../resources/resourcesDataParser'

function totalDiscount(bookingApi: TBookingApi) {
  const daily = parseInt(bookingApi.reserva_valores.valor_diaria)
  const discountPercent =
    parseInt(bookingApi.reserva_valores.percentagem_desconto) || 0
  return daily * (discountPercent / 100)
}

function dailyWithDiscount(bookingApi: TBookingApi) {
  const daily = parseInt(bookingApi.reserva_valores.valor_diaria)
  const discountValue = totalDiscount(bookingApi)
  return daily - discountValue
}

function totalDaily(bookingApi: TBookingApi): number {
  const daily = bookingApi.reserva_valores.total_diarias
  const totalDaily = dailyWithDiscount(bookingApi)
  return daily * totalDaily
}

function totalProtection(bookingApi: TBookingApi): number {
  const daily = bookingApi.reserva_valores.total_diarias
  const totalProtection = bookingApi.reserva_valores.valor_protecao
  return daily * parseFloat(totalProtection)
}

function totalDriverTax(bookingApi: TBookingApi) {
  const daily = bookingApi.reserva_valores.total_diarias
  const taxValue = bookingApi.reserva_valores.custo_condutor
  const totalDrivers =
    bookingApi.reserva_valores.reserva_valores_condutores.length
  return taxValue * totalDrivers * daily
}

function renewParentParser(bookingApi: TBookingApi) {
  if (!bookingApi.renovacao) return undefined
  return {
    uuid: bookingApi?.renovacao?.uuid || '',
    code: bookingApi?.renovacao?.code || ''
  }
}

export function bookingDataParser(data: TBookingApi): TBooking {
  if (!data || !data.reserva_valores) return {} as TBooking
  return {
    id: data.uuid,
    numberId: data.id,
    renovationId: data.id,
    pickup: {
      date: data.data_hora_inicio,
      hour: data.data_hora_inicio,
      local: data.local_retirada.nome,
      location: locationDataParser(data.local_retirada)
    },
    return: {
      date: data.data_hora_fim,
      hour: data.data_hora_fim,
      local: data.local_devolucao.nome,
      location: locationDataParser(data.local_devolucao)
    },
    totalToPay: parseFloat(data.valor_total),
    client: data.cliente,
    status: data.status,
    daily: data.reserva_valores.total_diarias,
    dailyValue: dailyWithDiscount(data),
    dailyTotal: totalDaily(data),
    resourcesDetail: resourceArrayDataParser(
      data.reserva_valores.reserva_valores_acessorios
    ),
    resourcesTotal: resourcesArrayTotalValue(
      data.reserva_valores.reserva_valores_acessorios
    ),
    protectionTotal: totalProtection(data),
    protection: protectionDataParser(data.protecao),
    carGroup: carGroupDataParser(data.grupo),
    drivers: driverArrayParser(data.reserva_valores.reserva_valores_condutores),
    driversTotalTax: totalDriverTax(data),
    code: data.code,
    createdAt: data.created_at,
    pickupTax: data.reserva_valores.taxa_retirada || 0,
    returnTax: data.reserva_valores.taxa_devolucao || 0,
    discountTotal: totalDiscount(data),
    renewParent: renewParentParser(data),
    extraHour: data.reserva_valores.horas_extras || 0,
    extraHourValue: data.reserva_valores.horas_extras_valor || 0
  }
}

export function bookingArrayParser(data: TBookingApi[]): TBooking[] {
  if (!data || data.length <= 0) return [] as TBooking[]
  return data.map(booking => bookingDataParser(booking))
}

export function bookingCardDataParserApi(
  data: TBookingCardData
): TBookingApiCardData {
  if (!data) return {} as TBookingApiCardData
  return {
    card_brand: data.cardBrand,
    card_expiration_date: data.cardExpirationDate,
    card_number: data.cardNumber,
    card_name: data.cardName,
    card_security_code: data.cardSecurityCode
  }
}
