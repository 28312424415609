import { TCarStockProtectionApi } from 'aplication/apiServices/carStockApiService/TCarStockApi'
import { TProtection } from 'domain/entities/TProtection'

export function carStockProtectionDataParser(
  data: TCarStockProtectionApi
): TProtection {
  if (!data) return {} as TProtection
  return {
    id: data.id,
    uuid: data.uuid,
    price: data.valor,
    title: data.tipo,
    description: data.descricao
  }
}

export function carStockProtectionArrayParser(
  data: TCarStockProtectionApi[]
): TProtection[] {
  if (!data || data.length <= 0) return [] as TProtection[]
  return data.map(d => carStockProtectionDataParser(d))
}
