import React from 'react'
import TitlePage from 'view/components/text/titlePage/titlePage'
import { DashboardSingleTemplate } from '../dashboardSingleTemplate'
import DashboardCredCardList from './dashboardCredCardList/dashboardCredCardList'
import './dashboardCredCards.css'

const DashboardCredCards: React.FC = () => {
  return (
    <DashboardSingleTemplate>
      <TitlePage>Cartões de crédito</TitlePage>
      <DashboardCredCardList />
    </DashboardSingleTemplate>
  )
}

export default DashboardCredCards
