import { HttpResponse } from 'aplication/apiServices/protocols/IHttpClient'
import { TCarShopCarApi } from './carShopCarsApiServiceTypes'
import { ApiService } from '../apiService'

type TResponseData = {
  results: TCarShopCarApi[]
  count: number
}

export class CarShopCarsApiService extends ApiService {
  async load(
    filters?: string,
    page?: number
  ): Promise<HttpResponse<TResponseData>> {
    const urlFilters = filters ? `?${filters}` : ''
    const paged = page ? `page=${page}` : ''

    function pagedAndFilters() {
      if (urlFilters !== '' && paged !== '') {
        return `${urlFilters}&${paged}`
      } else if (paged !== '') {
        return `?${paged}`
      } else {
        return urlFilters
      }
    }

    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/seminovos/${pagedAndFilters()}`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }

  async read(id: string): Promise<HttpResponse<TCarShopCarApi>> {
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/seminovos/${id}/`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
