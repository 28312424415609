import { protectionsList } from 'aplication/useCases/protectionsList/protectionsList'
import { TProtection } from 'domain/entities/TProtection'
import { useEffect, useState } from 'react'
import { useBookingDataContext } from 'view/contexts/bookingDataContext'

export type TUseProtections = {
  protections: TProtection[]
  defaultProtectionValue: number
  protectionTotal: number
  protectionValue: number
  protectionSelected: TProtection
  selectDefaultProtection: () => TProtection | null
  calcProtectionDailyTotal: (
    daily: number | null,
    protection?: TProtection
  ) => void
}

export function useProtections(): TUseProtections {
  const { bookingData } = useBookingDataContext()
  const [protections, setProtections] = useState<TProtection[]>(
    [] as TProtection[]
  )
  const [defaultProtectionValue, setDefaultProtectionValue] =
    useState<number>(0)

  const [protectionTotal, setProtectionTotal] = useState<number>(0)
  const [protectionValue, setProtectionValue] = useState<number>(0)
  const [protectionSelected, setProtectionSelected] = useState<TProtection>(
    {} as TProtection
  )

  function loadProtections() {
    protectionsList().then(list => {
      if (!list) return
      setProtections(list)
    })
  }

  function selectDefaultProtection() {
    const prot: { [key: string]: any } = protections
    const protection = prot[0]
    if (!protection) return null
    setDefaultProtectionValue(protection?.price)
    return protection
  }

  function calcProtectionDailyTotal(
    daily: number | null,
    protection?: TProtection | null
  ): void {
    let protectionDaily = 0
    if (daily && protection) {
      protectionDaily = protection?.price * daily
      // adiciona proteção à hora extra
      if (bookingData.extraHour && bookingData.extraHour > 0) {
        protectionDaily = protectionDaily + protection?.price
      }
      setProtectionValue(protection?.price)
      setProtectionSelected(protection)
    }
    setProtectionTotal(protectionDaily)
  }

  useEffect(() => {
    loadProtections()
  }, [])

  return {
    protections,
    selectDefaultProtection,
    calcProtectionDailyTotal,
    defaultProtectionValue,
    protectionTotal,
    protectionValue,
    protectionSelected
  }
}
