import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef } from 'react'
import { Modal, TModalRef } from 'view/components/modal'
import InputField from '../inputField/inputField'
import './cnhSecurityNumber.css'

type TProps = {
  required?: boolean
}

const CnhSecuritynumberField: React.FC<TProps> = ({ required }) => {
  const modalRef = useRef<TModalRef>({} as TModalRef)

  function onClick() {
    modalRef.current.toggleOpen()
  }

  function setInputNumberMask(inputValue: string): string {
    return inputValue.match(/\d+/g)?.join('') || ''
  }

  return (
    <div className="cnh-security-number">
      <InputField
        name="cnhSecurityNumber"
        placeholder="CNH - Número de segurança"
        required={required}
        maxLength={11}
        regexMask={setInputNumberMask}
        title="CNH - Número de segurança"
      />
      <span onClick={onClick}>
        <FontAwesomeIcon icon={['fas', 'info-circle']} />
      </span>

      <Modal
        className="modal-cnh-security-number"
        ref={modalRef}
        content={
          <>
            <div className="modal-cnh-security-number-content">
              O número de segurança encontra-se no canto inferior direito do
              verso da sua CNH.
            </div>
            <img src="/media/outros/cnh_verso.png" />
          </>
        }
      />
    </div>
  )
}

export default CnhSecuritynumberField
