import React, { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'view/components/formComponent'
import { useDisabledController } from 'view/hooksPages'
import BookingDateField from './bookingDateField'
import ReactDatePicker from 'react-datepicker'
import { bookingFormFieldListener } from './bookingFormFieldListener'

const BookingDateReturnField: React.FC = () => {
  const fieldRef = useRef<ReactDatePicker>()
  const { fields, updateField } = useFormContext()
  const [minDate, setMinDate] = useState<Date>(new Date())

  const { disabled } = useDisabledController(fields, [
    'returnLocation',
    'pickupDate'
  ])

  function cleanIfSmallestThan() {
    const pickcupDate = fields.find(field => field.name === 'pickupDate')?.value
    const returnDate = fields.find(field => field.name === 'returnDate')?.value
    const isPickupAfter = pickcupDate > returnDate
    if (
      pickcupDate &&
      pickcupDate !== '' &&
      returnDate !== '' &&
      isPickupAfter
    ) {
      updateField({ name: 'returnDate', value: '' })
    }
  }

  useEffect(() => {
    const pickcupDate = fields.find(field => field.name === 'pickupDate')
    if (!pickcupDate) return
    const date = pickcupDate.value
    if (date !== '') {
      cleanIfSmallestThan()
    }
    setMinDate(date)
  }, [fields])

  // verifica se o returnLocation está habilitado
  function hasReturnLocation() {
    return fields.find(f => f.name === 'hasReturnLocation')?.value
  }

  // escura o valor de pickupTime para abrir automatico
  const pickupTime = bookingFormFieldListener('pickupTime')
  useEffect(() => {
    if (hasReturnLocation() || !pickupTime.change) {
      return
    }
    fieldRef.current?.setFocus()
  }, [pickupTime.change])

  // escura o valor de returnLocation para abrir automatico
  const returnLocation = bookingFormFieldListener('returnLocation')
  useEffect(() => {
    if (
      !hasReturnLocation() ||
      !returnLocation.change ||
      returnLocation.change === '0'
    ) {
      return
    }
    fieldRef.current?.setFocus()
  }, [returnLocation.change])

  return (
    <BookingDateField
      name="returnDate"
      listening="returnLocation"
      minDate={minDate}
      disabled={disabled}
      fieldRef={fieldRef}
    />
  )
}

export default BookingDateReturnField
