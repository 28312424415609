import { priceConverter } from 'aplication/utils/app/valueConverter'
import React from 'react'
import { Link } from 'react-router-dom'
import BookingAfterPaymentCondition from 'view/componentsPages/bookingPaymentForm/bookingAfterPaymentCondition/bookingAfterPaymentCondition'
import BookingPaymentFormSimple from 'view/componentsPages/bookingPaymentForm/bookingPaymentFormSimple'
import { useAppContext } from 'view/contexts/appContext/appContext'
import { useBookingDataContext } from 'view/contexts/bookingDataContext'

type TProps = {
  onSubmit: (values: any) => void
}

const PaymentUserPf: React.FC<TProps> = ({ onSubmit }) => {
  const { bookingData } = useBookingDataContext()
  const { faq } = useAppContext()

  return (
    <>
      <BookingAfterPaymentCondition
        title="Pagar na retirada do veículo"
        onSubmit={onSubmit}
      >
        <BookingPaymentFormSimple postAction={onSubmit} />
      </BookingAfterPaymentCondition>
      <div className="payment-calcao">
        <h2>Pré-autorização</h2>
        <p>
          No momento de retirada do veículo, é necessário ter em mãos o seu
          cartão de crédito. Será feita uma reserva no cartão no valor de{' '}
          <b>{priceConverter(bookingData.carGroup?.caucao)}</b>, para cobrir
          possíveis avarias e/ou multas de trânsito.{' '}
          <Link
            to={`/faq?item=${faq.findFaqItem('O que é pré-autorização?')}`}
            className="color-secondary"
          >
            Clique aqui e saiba mais.
          </Link>
        </p>
      </div>
    </>
  )
}

export function renderCustomMessagePf(): JSX.Element {
  const { faq } = useAppContext()
  const { bookingData } = useBookingDataContext()
  return (
    <>
      <p>
        Também é necessário o seu próprio cartão de crédito. Será feita uma
        pré-autorização no cartão no valor de{' '}
        <b>{priceConverter(bookingData.carGroup?.caucao)}</b>, denominada taxa
        de pré-autorização, para cobrir possíveis avarias e/ou multas de
        trânsito.
      </p>
      <p>
        Fique tranquilo, caso não ocorra nenhum problema com o veículo até a sua
        devolução, a taxa de pré-autorização será desconsiderada.
      </p>
      <Link
        to={`/faq?item=${faq.findFaqItem('O que é pré-autorização?')}`}
        className="color-secondary"
        target={'_blank'}
      >
        Clique aqui e saiba mais!
      </Link>
    </>
  )
}

export default PaymentUserPf
