import { RecoveryAccount } from 'aplication/apiServices/recoveryAccount/recoveryAccount'
import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from 'view/components/button/button'
import { Dialog, TDialogRef } from 'view/components/dialog'
import { FormComponent } from 'view/components/formComponent'
import TitlePage from 'view/components/text/titlePage/titlePage'
import InputLoginField from 'view/componentsPages/customFields/inputLoginField/inputLoginField'
import { useAuthContext } from 'view/contexts/authContext'

const RequestAccountForm: React.FC = () => {
  const { updateUserUuid } = useAuthContext()
  const dialogRef = useRef<TDialogRef>({} as TDialogRef)
  const history = useHistory()
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(false)

  function errorAction(response: any) {
    dialogRef.current?.changeMessage(response.body.msg, 'danger')
    setDisabledSubmit(false)
  }

  function successAction(response: any) {
    dialogRef.current?.changeMessage(response.body.msg, undefined, true)
    updateUserUuid(response.body.cliente_uuid)
    setTimeout(() => {
      history.replace('/login/recuperar_conta_codigo')
    }, 8000)
  }

  function onSubmit(value: any) {
    setDisabledSubmit(true)
    const { document } = value
    const documento = document
    dialogRef.current?.loadMessage('Enviando dados...')
    const apiService = new RecoveryAccount()
    apiService.load({ documento }).then(response => {
      if (!response) return
      setTimeout(() => {
        console.log(response)
        if (response.statusCode !== 200) {
          errorAction(response)
        } else {
          successAction(response)
        }
      }, 500)
    })
  }

  return (
    <>
      <Dialog ref={dialogRef} />
      <TitlePage>Recuperar senha</TitlePage>
      <span>Adicione seu documento abaixo</span>
      <FormComponent onSubmit={onSubmit}>
        <div className="form-session">
          <InputLoginField name="document" />
        </div>
        <Button type="submit" full disabled={disabledSubmit}>
          Enviar
        </Button>
      </FormComponent>
    </>
  )
}

export default RequestAccountForm
