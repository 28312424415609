import { User } from 'aplication/entities/User'
import { useAuthToken } from 'aplication/useCases/auth/useAuthToken'
import { useQuery } from 'aplication/utils/app/useQuery'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Button from 'view/components/button/button'
import { Dialog, TDialogRef } from 'view/components/dialog'
import { FormComponent } from 'view/components/formComponent'
import PasswordField from 'view/components/formComponent/fields/passwordField/passwordField'
import TitlePage from 'view/components/text/titlePage/titlePage'
import InputLoginField from 'view/componentsPages/customFields/inputLoginField/inputLoginField'
import { useAppContext } from 'view/contexts/appContext/appContext'
import { useAuthContext } from 'view/contexts/authContext'

const LoginPageForm: React.FC = () => {
  const dialogRef = useRef<TDialogRef>({} as TDialogRef)
  const { getUserUuid } = useAuthToken()
  const { loginUser, updateUserUuid } = useAuthContext()
  const { modalUserActivationRef } = useAppContext()
  const query = useQuery()
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(false)

  function authErrorAction() {
    dialogRef.current?.changeMessage(
      'Não foi possível efetuar o login',
      'danger'
    )
  }

  function activationAction() {
    const userUuId = getUserUuid()
    updateUserUuid(userUuId)
    dialogRef.current?.changeMessage('Ativação pendente.', 'warning', true)
    setTimeout(() => {
      modalUserActivationRef.current.toggle()
      dialogRef.current.toggle(false)
    }, 1000)
  }

  function onSubmit(value: any) {
    setDisabledSubmit(true)
    const { username, password } = value
    dialogRef.current?.loadMessage('Enviando dados...')
    loginUser({ username, password }).then(result => {
      setTimeout(() => {
        setDisabledSubmit(false)
        if (!result.hasUser) return authErrorAction()
        if (!result.isActivated) return activationAction()
      }, 1500)
    })
  }

  useEffect(() => {
    if (query.get('by_checkout')) {
      dialogRef.current?.changeMessage(
        'Faça login ou crie uma nova conta para continuar a reserva.'
      )
    }
  }, [])

  return (
    <>
      <Dialog ref={dialogRef} />
      <TitlePage>Login</TitlePage>
      <FormComponent onSubmit={onSubmit}>
        <div className="form-session">
          <InputLoginField />
          <PasswordField name="password" placeholder="Senha" maxLength={20} />
        </div>
        <div className="login-lost-password">
          <Link to="/login/recuperar_conta">Esqueceu sua senha?</Link>
        </div>
        <Button type="submit" full disabled={disabledSubmit}>
          Entrar
        </Button>
      </FormComponent>
    </>
  )
}

export default LoginPageForm
