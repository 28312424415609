import React from 'react'
import { useShortcodes } from 'view/hooksPages/useShortCodes'

type TProps = {
  content: string
}

const DinamicContent: React.FC<TProps> = ({ content }) => {
  const shortCodes = useShortcodes()

  return (
    <div
      className="box-content"
      dangerouslySetInnerHTML={{ __html: shortCodes.run(content) }}
    />
  )
}

export default DinamicContent
