import { SlideImagesApiService } from 'aplication/apiServices/slideImagesApiService/slideImagesApiService'
import { TSlideImage } from 'aplication/entities/TSlideImage'
import { useState } from 'react'
import { slideImagesArrayParser } from './slideImagesDataParser'

export type TSlideImagesLoader = {
  loading: boolean
  slideImages: TSlideImage[]
  loadSlideImages: (position: string) => void
}

export function slideImagesLoader(): TSlideImagesLoader {
  const [loading, setLoading] = useState<boolean>(false)
  const [slideImages, setSlideImages] = useState<TSlideImage[]>(
    [] as TSlideImage[]
  )

  function loadSlideImages(position: string) {
    if (!position) return
    const apiService = new SlideImagesApiService()
    apiService.load(position).then(response => {
      if (
        !response ||
        response.statusCode !== 200 ||
        !response.body ||
        !response.body.results
      ) {
        console.error('slide_images_load_error')
        return
      }

      const allimages = slideImagesArrayParser(response.body.results)
      setSlideImages(allimages)

      setTimeout(() => {
        setLoading(false)
      }, 100)
    })
  }

  return {
    loading,
    slideImages,
    loadSlideImages
  }
}
