import { useQuery } from 'aplication/utils/app/useQuery'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { InvalidRentingDataMessage } from 'view/componentsPages/invalidRentingDataMessage'
import { useRentingPageContext } from '../rentingPageContext'

const BookingInvalidData: React.FC = () => {
  const history = useHistory()
  const path = history.location.pathname.split('/')[1]
  const query = useQuery()
  const failed = query.get('failed')
  const { modalBookingRef } = useRentingPageContext()

  function openBookingModal() {
    modalBookingRef.current.modal.current.toggleOpen(true)
  }

  if (failed === 'dateTimeValidator') {
    return (
      <InvalidRentingDataMessage
        title="Sem informações de reserva!"
        buttonText="Abrir formulário de reserva"
        text="Preencha por completo os dados de local, data e hora da reserva."
        buttonOnClick={openBookingModal}
      />
    )
  } else if (failed === 'carGroupValidator') {
    return (
      <InvalidRentingDataMessage
        title="Grupo de carro não selecionado!"
        buttonText="Escolher grupo de carro"
        text="Para prosseguir com a reserva é necessário escolher um grupo de carro..."
        buttonOnClick={() => {
          history.push(`/${path}/grupos`)
        }}
      />
    )
  } else if (failed === 'pickupValidation') {
    return (
      <InvalidRentingDataMessage
        title="Horários de retirada do veículo está com problema!"
        text="A retirada do veículo só pode ser agendada com no mínimo uma hora de antecedencia. Favor alterar no formulário de reserva."
        buttonText="Abrir formulário de reserva"
        buttonOnClick={openBookingModal}
      />
    )
  } else {
    return (
      <InvalidRentingDataMessage
        title="Dados da reserva incompleto!"
        buttonText="Voltar para a reserva"
        text="Para prosseguir com a locação é necessário preencher todos os dados da reserva..."
        buttonOnClick={() => {
          history.push(`/${path}`)
        }}
      />
    )
  }
}

export default BookingInvalidData
