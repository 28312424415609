import { HttpResponse } from 'aplication/apiServices/protocols/IHttpClient'
import { TApiPagedBody } from 'domain/entities/api/TApiPagedBody'
import { TCredCardApi } from 'domain/entities/api/TCredCardApi'
import { ApiService } from '../apiService'

type TResponseData = TApiPagedBody & {
  results: TCredCardApi
}

export class CredCardApiService extends ApiService {
  async load(
    clientUuId: string,
    page?: number
  ): Promise<HttpResponse<TResponseData>> {
    const paged = page ? `&page=${page}` : ''
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/cliente-card/?cliente_uuid=${clientUuId}${paged}`,
      method: 'get',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }

  async create(body: TCredCardApi): Promise<HttpResponse> {
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/cliente-card/`,
      method: 'post',
      body,
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }

  async delete(credCardUuId: string): Promise<HttpResponse> {
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/cliente-card/${credCardUuId}/`,
      method: 'delete',
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }

  async setMain(credCardUuId: string): Promise<HttpResponse> {
    return await this.httpClient.request({
      url: `${process.env.REACT_APP_BASE_URL}/cliente-card/${credCardUuId}/`,
      method: 'patch',
      body: { is_main_card: true },
      headers: {
        authorization: `Bearer ${this.token?.access}`
      }
    })
  }
}
