import { useState } from 'react'
import { FaqDataService } from 'aplication/apiServices/faqDataService/faqDataService'
import { faqDataParser } from './faqDataParser'
import { TFaq } from 'domain/entities/TFaq'

type TUseFaqReader = {
  data: TFaq
  loading: boolean
  read: (id: string) => void
}

export function useFaqReader(): TUseFaqReader {
  const [data, setData] = useState<TFaq>({} as TFaq)
  const [loading, setLoading] = useState<boolean>(false)

  function read(slug: string) {
    setLoading(true)
    const apiService = new FaqDataService()
    apiService.read(slug).then(response => {
      if (!response || response.statusCode !== 200 || !response.body) {
        console.error('faq_read_error')
        return
      }
      const firstFronArray = response.body.results[0]
      const parserdData = faqDataParser(firstFronArray)
      setData(parserdData)
      setTimeout(() => {
        setLoading(false)
      }, 500)
    })
  }

  return { data, read, loading }
}
