import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'view/components/button/button'
import { useAppContext } from 'view/contexts/appContext/appContext'

type TProps = {
  onClick: (e: any) => void
}

const NotificationButton: React.FC<TProps> = ({ onClick }) => {
  const [unread, setUnread] = useState<number>(0)
  const { notifications, totalNotifications } = useAppContext().notifications

  useEffect(() => {
    if (!totalNotifications) return
    setUnread(totalNotifications)
  }, [totalNotifications])

  return (
    <Button className="main-nav-notifications" style="link" onClick={onClick}>
      {unread ? <span className="has-notification">{unread}</span> : ''}
      <FontAwesomeIcon icon={['far', 'bell']} />
    </Button>
  )
}

export default NotificationButton
