import { TApiClient } from 'domain/entities/api/TApiClient'
import { TClient } from 'domain/entities/TClient'

export function parseToAppClient(apiClient: TApiClient): TClient {
  const appClient = {
    address: apiClient.logradouro,
    addressDistrict: apiClient.bairro,
    cep: apiClient.cep,
    city: apiClient.cidade,
    complement: apiClient.complemento,
    documento: apiClient.documento,
    documentType: apiClient.tipo_documento,
    email: apiClient.email,
    id: apiClient.id,
    name: apiClient.nome,
    lastName: apiClient.sobrenome,
    passport: apiClient.passaporte,
    phone: apiClient.telefone,
    uuid: apiClient.uuid,
    userId: apiClient.user,
    clientType: apiClient.tipo,
    clientCarLimit: apiClient.limite_veiculos,
    clientCarLimitNow: apiClient.limite_veiculos_atual,
    clientCredit: apiClient.limite_credito,
    clientCreditNow: apiClient.limite_credito_atual,
    clientDiscount: apiClient.total_desconto_adicional,
    cnh: apiClient.cnh,
    cnhSecurityNumber: apiClient.numero_seguranca_cnh,
    pJResponsible: apiClient.responsavel,
    pJResponsiblePhone: apiClient.telefone_responsavel
  } as TClient

  return appClient
}

export function parseToApiClient(appClient: TClient): TApiClient {
  const apiClient = {
    activation_account_code: null,
    activation_account_date: appClient.activationAccountDate,
    bairro: appClient.addressDistrict,
    telefone: appClient.phone,
    cep: appClient.cep,
    cidade: appClient.city,
    complemento: appClient.complement,
    documento: appClient.documento,
    email: appClient.email,
    logradouro: appClient.address,
    nome: appClient.name,
    sobrenome: appClient.lastName,
    passaporte: appClient.passport,
    uuid: appClient.uuid,
    id: appClient.id,
    user: appClient.userId,
    tipo_documento: appClient.documentType,
    cnh: appClient.cnh,
    numero_seguranca_cnh: appClient.cnhSecurityNumber,
    responsavel: appClient.pJResponsible,
    telefone_responsavel: appClient.pJResponsiblePhone
  } as TApiClient

  return apiClient
}
