import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import { TFieldElement } from '../..'
import SelectFieldStyled from './selectFieldStyled'
import { SelectFieldContext, TOption, TSelected } from './selectFieldContext'
import './selectField.css'
import FieldProvider, { FieldContext } from '../fieldContext'

type TProps = TFieldElement & {
  options: TOption[]
  value?: string
  fieldRef?: any
}

export type TSelectComponentRef = {
  setFocus: () => void
}

const SelectComponent: React.ForwardRefRenderFunction<
  TSelectComponentRef,
  any
> = (_, ref) => {
  const { field, updateField, stateValue, fieldProps } =
    useContext(FieldContext)
  const { name, placeholder, onChange } = fieldProps
  const [open, setOpen] = useState<boolean>(false)
  const emptySelected = {
    value: '',
    label: placeholder || ''
  }
  const [selected, setSelected] = useState<TSelected>(emptySelected)
  const [options, setOptions] = useState<TOption[]>([] as TOption[])

  function setFocus() {
    setOpen(true)
  }

  useEffect(() => {
    if (!selected) return
    const selectedValue = selected.value
    if (selectedValue === stateValue) return
    const newValue = selectedValue === '' ? stateValue : selectedValue
    if (onChange) onChange(newValue)
    updateField({ name, value: newValue })
  }, [selected])

  useEffect(() => {
    if (!fieldProps.options) return
    setOptions(fieldProps.options)
  }, [fieldProps.options])

  useEffect(() => {
    if (!stateValue && stateValue !== '') return
    let selectedOption = options.find(o => `${o.value}` === stateValue)
    if (stateValue === '' && !selectedOption) {
      selectedOption = { label: placeholder || '', value: '' }
    }
    if (selectedOption) setSelected(selectedOption)
  }, [stateValue, options])

  useImperativeHandle(ref, () => {
    return {
      setFocus
    }
  })

  return (
    <div className="select-field">
      <SelectFieldContext.Provider
        value={{ open, selected, options, setOptions, setSelected, setOpen }}
      >
        <SelectFieldStyled options={options} />
        <input type="hidden" name={name} value={stateValue || ''} />
      </SelectFieldContext.Provider>
    </div>
  )
}

const SelectComponentFwrd = forwardRef(SelectComponent)

const SelectField: React.FC<TProps> = props => {
  return (
    <FieldProvider fieldProps={props}>
      <SelectComponentFwrd ref={props.fieldRef} />
    </FieldProvider>
  )
}

export default SelectField
