import { TCarGroupImage } from 'domain/entities/TCarGroup'

/**
 * Monta lista de imagens vazias (ou não) para carousel de carros
 * @param bookingImages
 * @returns
 */
export function pushEmptyPositions(
  bookingImages?: TCarGroupImage[]
): TCarGroupImage[] {
  const emptyImage: TCarGroupImage = { image: '/media/empty.png' }
  const newImages = bookingImages || ([] as TCarGroupImage[])
  if (!newImages || newImages.length < 3) {
    const diff = newImages.length > 0 ? Math.abs(newImages.length - 3) : 3
    for (let i = 0; i < diff; i++) {
      newImages.push(emptyImage)
    }
  }
  return newImages
}
